import React, { useState, useEffect } from 'react';
import MDMNavTabs from '../components/MDMNavTabs';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import NucleusCard from '../../../components/NucleusCard';
import { useForm } from 'react-hook-form';
import { NucleusControlledWhiteBgInput } from './../../../components/NucleusControlledInput';
import {
  fetchMdmDeviceDetails,
  updateMdmDeviceData,
  getExternalAppsListThunk,
  resetFetchDeviceDetails,
  refreshMdmDevicePolicy,
} from '@nucleus-care/nucleuscare-backend-client';
import MdmDevicePolicies from './components/MdmDevicePolicies';
import MdmDeviceDetailsNavTabs from './components/MdmDeviceDetailsNavTabs';
import { SpinnerCircular } from 'spinners-react';
import MdmComplianceDetails from './components/MdmComplianceDetails';
import MdmDeviceLocation from './components/MdmDeviceLocation';
import RefreshPolicyButton from './components/RefreshPolicyButton';
import Message from '../../../utils/Message';
import MdmDeviceApi from '../../../apis/MdmDeviceApi';
const MDMDeviceDetails = () => {
  const { apps: externalApps } = useSelector(
    ({ accountExternalApps }) => accountExternalApps.appsConfig,
  );

  const { register, setValue, reset, watch } = useForm();
  const route = useRouteMatch();

  // check if there's a redirectTab query param in the url in which case set the active tab to that
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirectTab = searchParams.get('redirectTab');
  const [activeTab, setActiveTab] = useState(redirectTab);

  const [mdmDeviceId, setMdmDeviceId] = useState(route?.params?.mdmDeviceId);
  const [parsedDeviceInfo, setParsedDeviceInfo] = useState(null);
  const dispatch = useDispatch();
  const { fetchDeviceDetails } = useSelector(({ mdmDevice }) => mdmDevice.data);
  const { success, loading, deviceDetails } = fetchDeviceDetails;

  const refreshMdmDevicePolicyState = useSelector(({ mdmDevice }) => {
    return mdmDevice.data.refreshMdmDevicePolicy;
  });

  useEffect(() => {
    const mdmDeviceIdParam = route?.params?.mdmDeviceId;
    console.log('mdmDeviceId', mdmDeviceIdParam);
    setMdmDeviceId(mdmDeviceIdParam);
    if (!mdmDeviceIdParam) return;
    dispatch(fetchMdmDeviceDetails(mdmDeviceId));
    dispatch(getExternalAppsListThunk());
  }, [route]);

  useEffect(() => {
    return () => {
      reset();
      dispatch(resetFetchDeviceDetails());
    };
  }, []);
  useEffect(() => {
    if (loading) return;
    if (success) {
      setValue('Name', deviceDetails?.MdmName);
    }
  }, [loading, success, deviceDetails]);

  useEffect(() => {
    if (refreshMdmDevicePolicyState.error) {
      Message.show('Error refreshing Device Policy');
    }
  }, [refreshMdmDevicePolicyState.success, refreshMdmDevicePolicyState.error]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const getTabContent = () => {
    switch (activeTab) {
      case 'policies':
        return <MdmDevicePolicies mdmDeviceId={mdmDeviceId} />;
      case 'compliance':
        return <MdmComplianceDetails />;
      case 'location': 
        return <MdmDeviceLocation mdmDeviceId={mdmDeviceId}/>
    }
  };

  useEffect(() => {
    if (deviceDetails?.MdmDeviceInfo) {
      setParsedDeviceInfo(JSON.parse(deviceDetails.MdmDeviceInfo));
    }
  }, [deviceDetails]);

  const [kioskAppDetails, setKioskAppDetails] = useState({});
  useEffect(() => {
    if (!deviceDetails.MdmDevicePolicyJSON || !externalApps) {
      return;
    }
    const parsedPolicyJson = JSON.parse(deviceDetails.MdmDevicePolicyJSON);

    // find the kiosk app in the device policy json
    const deviceKioskApp = (parsedPolicyJson?.applications || []).find(
      (deviceApp) => deviceApp.installType == 'KIOSK',
    );
    const deviceKioskAppPackageName = deviceKioskApp?.packageName;
    let deviceKioskAppIconUrl;
    let deviceKioskAppName;

    // if there's a kioskAppPackageName, find the app in the external apps list to get the icon url and name
    if (deviceKioskAppPackageName) {
      const externalKioskApp = externalApps.find(
        (externalApp) =>
          externalApp.AndroidIdentifier == deviceKioskAppPackageName,
      );
      deviceKioskAppIconUrl = externalKioskApp.IconUrl;
      deviceKioskAppName = externalKioskApp.Name;
    }

    const kioskAppDetails = {
      packageName: deviceKioskAppPackageName,
      name: deviceKioskAppName,
      iconUrl: deviceKioskAppIconUrl,
    };
    setKioskAppDetails(kioskAppDetails);
  }, [deviceDetails, externalApps]);

  const saveMdmDeviceData = () => {
    dispatch(
      updateMdmDeviceData({
        mdmDeviceId,
        mdmDeviceData: { Name: watch('Name') },
      }),
    );
  };

  const refreshDevicePolicy = () => {
    dispatch(refreshMdmDevicePolicy({ mdmDeviceId }));
    Message.show('Refresh device policy requested');
  };

  return (
    <div className="nucleus-tools-container">
      <div>
        <p className="nucleus-tools-page-title" style={{ marginBottom: 0 }}>
          <div
            className="row a-bit-lower left-align"
            style={{ marginBottom: 0 }}
          >
            <MDMNavTabs activeTab="devices" type="main" />
            <div className="nucleus-news-separator">&nbsp;</div>
          </div>
        </p>
        <br />
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end'
        }}
      >
        <span
          style={{
            fontFamily: 'Fira Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 15,
            display: 'flex',
            alignItems: 'center',
            textAlign: 'right',
            color: '#0092FF',
          }}
        >
          Restart device
        </span>
        <button
          onClick={(e) => {
            e.stopPropagation();
            if (!deviceDetails?.MdmID) {
              Message.show('Failed to send device reboot request', 'error');
              return;
            }
            console.log('handleDeviceReboot');
            const mdmDeviceApi = new MdmDeviceApi();
            mdmDeviceApi.requestDeviceReboot(
              {
                MdmID: deviceDetails?.MdmID,
              },
              (response) => {
                console.log('requestDeviceReboot response', response);
                if (response.ok) {
                  Message.show(
                    'Device reboot request has been sent',
                    'success',
                  );
                } else {
                  Message.show('Failed to send device reboot request', 'error');
                }
              },
            );
          }}
          style={{ border: 'none', background: 'transparent' }}
          className="nucleus-table-icon nucleus-link tooltipped "
          data-delay="50"
          data-position="top"
          data-tooltip="Remote restart"
        >
          <img
            src="img/power.svg"
            className="nucleus-link"
            style={{
              marginTop: 5,
              width: 18,
              height: 18,
            }}
          />
        </button>
      </div>
      <div
        style={{ display: 'flex', width: '100%', justifyContent: 'flex-start' }}
      >
        <div style={{ width: '60%', display: 'flex' }}>
          <NucleusCard width={'40%'} style={{ marginLeft: '2%' }}>
            <div
              style={{
                display: 'flex',
                width: '50%',
                flexDirection: 'column',
                padding: '5%',
                alignItems: 'center',
              }}
            >
              <form>
                <NucleusControlledWhiteBgInput
                  label={'Device Name'}
                  disabled={loading}
                  name={'Name'}
                  containerStyle={{
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                  setValue={() => setValue('Name')}
                  register={register('Name')}
                  //value={deviceDetails?.MdmName || "Empty"}
                  saveHandler={() => {
                    saveMdmDeviceData();
                  }}
                />
                <br />
                <NucleusControlledWhiteBgInput
                  disabled
                  label={'Account Name'}
                  name={'CareAccountName'}
                  containerStyle={{
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                  value={deviceDetails?.CareAccountName || 'Empty'}
                />
                <br />
                <NucleusControlledWhiteBgInput
                  disabled
                  label={'Nucleus Patient'}
                  name={'NucleusPatient'}
                  containerStyle={{
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                  value={deviceDetails?.CarePatientName || 'Empty'}
                />
                <br />
                <NucleusControlledWhiteBgInput
                  disabled
                  label={'Kiosk App'}
                  name={'KioskApp'}
                  containerStyle={{
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                  value={kioskAppDetails.name || 'Empty'}
                />
              </form>
            </div>
            <div
              style={{
                display: 'flex',
                width: '50%',
                flexDirection: 'column',
                padding: '5%',
                alignItems: 'center',
                justifyContent: 'space-between',
                position: 'relative',
              }}
            >
              <label
                style={{
                  position: 'absolute',
                  bottom: '74%',
                  fontSize: 12,
                  color: '#0A313F',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {loading ? (
                  <SpinnerCircular
                    color="#2096F3"
                    secondaryColor="rgba(0,0,0,0.16)"
                    size="50"
                    thickness="100"
                  />
                ) : (
                  <React.Fragment>
                    Status:{' '}
                    <img
                      src={
                        !!deviceDetails?.CareStatus === true
                          ? './img/icon_device_active.png'
                          : './img/icon_device_inactive.png'
                      }
                      style={{ width: 20 }}
                      alt="device active/inactive"
                    />
                  </React.Fragment>
                )}
              </label>
              <img
                src="./img/tablet-new.svg"
                style={{ width: 125, display: loading ? 'none' : 'flex' }}
                alt="tablet icon"
              />
              <label
                style={{
                  display: loading ? 'none' : 'flex',
                  color: '#0A313F',
                  fontSize: 10,
                  marginBottom: '90%',
                }}
              >
                {deviceDetails &&
                  `${parsedDeviceInfo?.hardwareInfo?.brand} ${parsedDeviceInfo?.hardwareInfo?.model}`}
              </label>
              <img
                src={kioskAppDetails.iconUrl || './img/device-logo-lg.png'}
                style={{
                  display: loading ? 'none' : 'flex',
                  width: 85,
                  height: 85,
                  resize: 'contain',
                  borderRadius: 10,
                }}
                alt="No Kiosk app set"
              />
            </div>
          </NucleusCard>
          <NucleusCard width={'25%'} style={{ marginLeft: '5%' }}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: '5%',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '71%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <label
                  style={{ color: '#0A313F', fontSize: 20, fontWeight: 'bold' }}
                >
                  ID:{' '}
                </label>
                <label style={{ color: '#0A313F', fontSize: 16 }}>
                  {deviceDetails?.MdmDeviceID}
                </label>
              </div>
              <br />
              <div
                style={{
                  width: '71%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <label
                  style={{ color: '#0A313F', fontSize: 20, fontWeight: 'bold' }}
                >
                  Serial Number:{' '}
                </label>
                <labe style={{ color: '#0A313F', fontSize: 16 }}>
                  {deviceDetails?.SerialNumber}
                </labe>
              </div>
              <br />
              <div
                style={{
                  width: '71%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <label
                  style={{ color: '#0A313F', fontSize: 20, fontWeight: 'bold' }}
                >
                  IMEI:{' '}
                </label>
                <labe style={{ color: '#0A313F', fontSize: 16 }}>
                  {deviceDetails &&
                    parsedDeviceInfo?.networkInfo &&
                    parsedDeviceInfo?.networkInfo?.imei}
                </labe>
              </div>
            </div>
          </NucleusCard>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <MdmDeviceDetailsNavTabs
          handleTabChange={handleTabChange}
          activeTab={activeTab}
        />
        <RefreshPolicyButton onClick={refreshDevicePolicy} />
      </div>
      <br />
      {getTabContent()}
    </div>
  );
};

export default MDMDeviceDetails;
