import { useState, useEffect, useMemo } from 'react';
import Message from "../../utils/Message";
import {
    httpApi
} from '@nucleus-care/nucleuscare-backend-client';
import { SpinnerCircular } from 'spinners-react';
import AuthStore from "../../stores/AuthStore";
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import { UIButton, useModal } from '../../components/UI';
import NucleusSearchInput from '../../components/NucleusSearchInput';
import ImportOrdersModal from './components/ImportOrdersModal';
import NucleusPaginatedTable from '../../components/NucleusPaginatedTable';
import NucleusTable from '../../components/NucleusTable';
import { ArrowIcon, CopyIcon, GoToArrow, TrashIcon } from '../../components/UI/assets/SVG';
import { HoverToast, useHoverToast } from '../../components/HoverToast';
import RemoveOrderModal from './components/RemoveOrderModal';

type IConsumerOrder = {
    id: string;
    deviceOrdererFirstName: string;
    deviceOrdererLastName: string;
    deviceOrdererPhoneNumber: string;
    mdmId: number | null;
    shippedStatus: boolean;
    shippingAddress: string;
    smsStatus: string | null; // Sent, Failed
    patientId: string;
    patientFirstName: string;
    patientLastName: string;
    patientPhoneNumber: string;
    patientAccountId: string;
    checkoutSessionID?: string | null;
    created?: string;
    customerID?: string | null;
    deviceName?: string | null;
    deviceOrdererEmail?: string | null;
    eventID?: string | null;
    externalOrderID?: string;
    familyMemberEmail?: string;
    familyMemberID?: string | null;
    familyMemberPhone?: string | null;
    hexnodeID?: string | null;
    metadata?: any | null;
    orderDetails?: any | null;
    orderState?: boolean;
    smsLink?: string | null;
    shipstationOrderResponse?: any | null;
    shipstationShipmentResponse?: any | null;
    shipstationTrackingNumber?: string | null;
}

type SortParamObj = {
    id: string;
    desc: boolean;
}

type TableState = {
    pageIndex: number;
    pageSize: number;
    sortBy: SortParamObj[];
}
type GetConsumerParams = {
    limit: number;
    offset: number;
    searchText: string;
    _sort: string | null;

}
const Consumers = () => {
    const location = useLocation();
    const history = useHistory();

    const search = location.search;
    const {
        closeModal: closeImportOrdersModal,
        isOpen: isOpenImportOrdersModal,
        toggleModal: toggleImportOrdersModal,
    } = useModal()
    const {
        closeModal: closeRemoveOrderModal,
        isOpen: isOpenRemoveOrderModal,
        toggleModal: toggleRemoveOrderModal,
    } = useModal()
    const [orderToRemove, setOrderToRemove] = useState<IConsumerOrder | null>(null);
    const [initialTableState, setInitialTableState] = useState<TableState | undefined>(undefined);
    const [consumerOrders, setConsumerOrders] = useState<IConsumerOrder[]>([]);
    const [stateLoading, setStateLoading] = useState(true);
    const [stateSearchText, setStateSearchText] = useState("");
    const [totalCount, setTotalCount] = useState(0);
    const [selectedPageIndex, setSelectedPageIndex] = useState(0);
    const [lastFetchParams, setLastFetchParams] = useState({
        pageIndex: 0,
        limit: 10,
        _sort: [] as SortParamObj[]
    });
    const onClickOrderTrashIcon = (order: IConsumerOrder | null) => {
        if (!order) return
        setOrderToRemove(order);
        toggleRemoveOrderModal();
    }

    const cancelAndCloseRemoveOrder = () => {
        setOrderToRemove(null);
        closeRemoveOrderModal();
    }
    useEffect(() => {
        const initialTableState: TableState = {
            pageIndex: 0,
            pageSize: 0,
            sortBy: [] as SortParamObj[]
        };
        const queryParams = new URLSearchParams(search);
        const page = queryParams.get('page');
        if (page) {
            initialTableState.pageIndex = parseInt(page) - 1;
        }
        const pageSize = queryParams.get('pageSize');
        if (pageSize) {
            initialTableState.pageSize = parseInt(pageSize);
        }
        const sortBy = queryParams.get('sortBy');
        const sortDir = queryParams.get('sortDir');
        if (sortBy && sortDir) {
            initialTableState.sortBy = [
                {
                    id: sortBy,
                    desc: sortDir === 'DESC',
                },
            ];
        }
        setInitialTableState(initialTableState);
    }, [search]);

    const onPageChange = ({ pageIndex, pageSize, sortBy }) => {
        fetchData({ pageIndex, limit: pageSize, _sort: sortBy });
        let url = location.pathname + '?';
        let queryParams: string[] = [];
        if (pageIndex !== undefined) {
            queryParams.push(`page=${pageIndex}`);
        }
        if (pageSize !== undefined) {
            queryParams.push(`pageSize=${pageSize}`);
        }
        if (sortBy && sortBy.length > 0) {
            const sortColumn = sortBy[0];
            const sortDir = sortColumn.desc ? 'DESC' : 'ASC';
            queryParams.push(`sortBy=${sortColumn.id}&sortDir=${sortDir}`);
        }
        history.push(url + queryParams.join('&'));
    };

    const fetchData = async ({ pageIndex = 0, limit = 10, _sort = [] as SortParamObj[] }) => {
        try {
            setStateLoading(true);
            const offset = pageIndex * limit;
            const searchText = stateSearchText;
            let sortParam: string | null = null;
            if (_sort !== null && _sort.length > 0) {
                const sortColumn: SortParamObj = _sort[0];
                const sortByDir = sortColumn.desc ? 'DESC' : 'ASC';
                sortParam = `${sortColumn.id}:${sortByDir}`;
            }
            await getConsumerOrders({
                limit,
                offset,
                searchText,
                _sort: sortParam,
            })
            setLastFetchParams({ pageIndex, limit, _sort });
        } catch (error) {
            console.error('Error fetching consumer orders');
        }
    };

    const getConsumerOrders = async (params: GetConsumerParams) => {
        try {
            setStateLoading(true);
            let extraQueryParams = ''
            if (params?._sort) {
                extraQueryParams += `&_sort=${params._sort}`
            }
            if (stateSearchText) {
                const encodedSearchText = encodeURIComponent(stateSearchText);
                extraQueryParams += `&searchText=${encodedSearchText}`;
            }
            const { data: { consumerOrders, count } } = await httpApi.get(`/consumer-orders?${extraQueryParams && extraQueryParams}`) as { data: { consumerOrders: IConsumerOrder[], count: number } };
            setConsumerOrders(consumerOrders);
            setTotalCount(count);
            setStateLoading(false);
        } catch (error) {
            setStateLoading(false);
            console.error("getConsumerOrders error", error);
            Message.error("Failed to fetch consumer orders")

        }
    }
    const exportConsumerOrders = async () => {
        try {
            let extraQueryParams = ''
            if (lastFetchParams?._sort) {
                extraQueryParams += `&_sort=${lastFetchParams._sort}`
            }
            if (stateSearchText) {
                const encodedSearchText = encodeURIComponent(stateSearchText);
                extraQueryParams += `&searchText=${encodedSearchText}`;
            }
            const { data } = await httpApi.get(`/consumer-orders/csv?${extraQueryParams}`) as { data: string };
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'consumer-orders.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("exportConsumerOrders error", error);
            Message.error("Failed to export consumer orders")

        }
    }
    const debounce = (func, delay) => {
        let timer;
        return function (...args) {
            clearTimeout(timer);
            timer = setTimeout(() => func.apply(args), delay);
        };
    };
    const refreshReport = debounce(async () => {
        await fetchData(lastFetchParams)
    }, 500);
    useEffect(() => {
        if (!AuthStore.superUser()) {
            window.location.assign("#/dashboard");
        }
        fetchData(lastFetchParams)
    }, []);
    const removeOrderRequest = async (orderId: string) => {
        try {
            await httpApi.delete(`/consumer-orders/${orderId}`);
            Message.show("Order removed successfully");
        } catch (error) {
            Message.error("Failed to remove order");
        }
    }
    const removeOrder = async () => {
        if (orderToRemove?.id) await removeOrderRequest(orderToRemove?.id)
        refreshReport();
        closeRemoveOrderModal();
    }

    const onSearchTextChange = (textInput) => {
        setStateSearchText(textInput.target.value);
    }
    const columns = useMemo(() => {
        return [
            {
                Header: 'Order ID',
                accessor: 'id',
                Cell: ShowValueOnHover,
                disableSortBy: true,
            },
            {
                Header: 'MDM ID',
                accessor: 'mdmId',
                Cell: ({ cell }: { cell: { value: string } }) => <CopyValueCell showCopy={false} cell={cell} />,
                centerAlign: true,
            },
            {
                Header: 'Device Orderer/Phone',
                accessor: 'deviceOrdererPhoneNumber',
                Cell: DeviceOrderPhoneCell,
                disableSortBy: true,
                centerAlign: true,
            },
            {
                Header: 'Patient/Phone',
                accessor: 'patientPhoneNumber',
                Cell: PatientPhoneCell,
                disableSortBy: true,
                centerAlign: true,
            },
            {
                Header: 'Shipping Address',
                accessor: 'shippingAddress',
                disableSortBy: true,
                Cell: ShippingAddressCell,
                centerAlign: true,

            },
            {
                Header: 'Go to Patient',
                accessor: 'patientId',
                Cell: ({ cell: { row } }) => <GoToPatientCell row={row} history={history} />,
                disableSortBy: true,
                centerAlign: true,
            },
            {
                Header: 'SMS Link',
                accessor: 'smsLink',
                Cell: ({ cell }: { cell: { value: string } }) => <CopyValueCell showCopy cell={cell} isLinkValue />,
                disableSortBy: true,
                centerAlign: true,
            },
            {
                Header: 'Shipped Status',
                accessor: 'shippedStatus',
                Cell: ShippedStatusCell,
                disableSortBy: true,
                centerAlign: true,
            },
            {
                Header: 'Resend SMS',
                accessor: 'patientAccountId',
                Cell: ResendSMSCell,
                disableSortBy: true,
                centerAlign: true,
            },
            {
                Header: 'SMS Status',
                accessor: 'smsStatus',
                Cell: SmsStatusCell,
                disableSortBy: true,
                centerAlign: true,
            },
        ]
    }, [])
    return (
        <div className="nucleus-tools-container">
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
                <div style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <p className="nucleus-tools-page-title"><b>Consumer Orders</b></p>
                    {
                        (stateLoading) &&
                        <div style={{ margin: 0, padding: 0, marginTop: 0, marginBottom: 0 }}>
                            <SpinnerCircular
                                color="#2096F3"
                                secondaryColor='rgba(0,0,0,0.16)'
                                size='30'
                                thickness={100}
                            />
                        </div>
                    }
                </div>
                <TopBarSection>
                    <UIButton onClick={toggleImportOrdersModal} numberOfLines={1} text='Import from CSV' variant='secondary' />
                    <UIButton numberOfLines={1} text='Export to CSV' variant='secondary' onClick={exportConsumerOrders} />
                </TopBarSection>
            </div>
            <PageTopBarActions>
                <TopBarSection>
                    <form onSubmit={(e) => {
                        e.preventDefault()
                        refreshReport()
                    }}>
                        <NucleusSearchInput
                            buttonType="submit"
                            loading={stateLoading}
                            onChange={onSearchTextChange}
                            value={stateSearchText}
                            placeholder="Filter by  Order ID / MDM ID / Device Orderer / Patient / Shipping Address"
                            inputMinWidth={500}
                            actionsActive={true}
                        />
                    </form>
                </TopBarSection>
            </PageTopBarActions>

            <div className="row nucleus-fixed-container nucleus-75-table-container">
                {/* <NucleusPaginatedTable
                    columns={columns}
                    loading={stateLoading}
                    data={consumerOrders}
                    showResultsCount={false}
                    resultsCount={totalCount}
                    fetchData={fetchData}
                    onTableLoad={() => {
                        fetchData(lastFetchParams);
                    }}
                    tableState={initialTableState}
                    onPageChange={onPageChange}
                    pageIndexState={selectedPageIndex}
                    indexChangeCallback={setSelectedPageIndex}
                /> */}
                <NucleusTable
                    columns={columns}
                    data={consumerOrders}
                />
            </div>
            <RemoveOrderModal
                order={orderToRemove}
                isOpen={isOpenRemoveOrderModal}
                onClose={cancelAndCloseRemoveOrder}
                onRemove={removeOrder}
            />
            <ImportOrdersModal
                closeModal={closeImportOrdersModal}
                isOpen={isOpenImportOrdersModal}
            />
        </div>
    );
}

export default Consumers;

const ShowValueOnHover = ({ cell: { row } }) => {
    const { id: ID } = row.original as IConsumerOrder;
    const { handleMouseEnter, handleMouseLeave, handleMouseMove, showToast, coords } = useHoverToast();
    const [justClicked, setJustClicked] = useState(false);
    const copyToClipboard = () => {
        navigator.clipboard.writeText(ID);
    }
    const handleClick = () => {
        setJustClicked(true);
        copyToClipboard();
        setTimeout(() => {
            setJustClicked(false);
        }, 1000);
    }
    return (
        <IDContainer>
            {(ID) && <HoverToast
                key={ID}
                message={justClicked ? 'Copied!' : ID}
                showToast={showToast}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                handleMouseMove={handleMouseMove}
                coords={coords}
                toastInfoStyle={{
                    width: '300px',
                }}
            >
                <EllipsisText maxWidth='80px' onClick={handleClick}>{ID}</EllipsisText>
            </HoverToast>}
        </IDContainer >
    )
}

type CopyValueProps = {
    cell: {
        value: string;
    };
    showCopy?: boolean;
    isLinkValue?: boolean;
}
const CopyValueCell = ({ cell, showCopy, isLinkValue }: CopyValueProps) => {
    const { value } = cell;
    const { handleMouseEnter, handleMouseLeave, handleMouseMove, showToast, coords } = useHoverToast();
    const [justClicked, setJustClicked] = useState(false);
    const copyToClipboard = () => {
        navigator.clipboard.writeText(value);
    }
    const handleClick = () => {
        setJustClicked(true);
        copyToClipboard();
        setTimeout(() => {
            setJustClicked(false);
        }, 1000);
    }
    return (
        <RowInfoContainer>
            {isLinkValue && value ? <a target='_blank' href={value}><EllipsisText style={{ textDecoration: 'underline' }}>{value}</EllipsisText></a> : <EllipsisText>{value}</EllipsisText>}
            {(showCopy && value) && <HoverToast
                key={value}
                message={'Copied!'}
                showToast={showToast && justClicked}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                handleMouseMove={handleMouseMove}
                coords={coords}
            >
                <CopyIcon onClick={handleClick} />
            </HoverToast>}
        </RowInfoContainer >
    )
}

const SmsStatusCell = ({ cell: { value } }) => {
    return (
        <ColInfoContainer>
            <span>{value}</span>
        </ColInfoContainer>
    )
}
const GoToPatientCell = ({ row, history }) => {
    const { patientId, patientAccountId: accountId } = row.original as IConsumerOrder
    const navigateToPatientProfile = () => {
        history.push(`/consumerPatientProfile/${patientId}/${accountId}`);
    }
    return (
        <ColInfoContainer>
            <ArrowIcon size={22} onClick={navigateToPatientProfile} />
        </ColInfoContainer>
    )
}
const ResendSMSCell = ({ row }) => {
    const { id: orderId } = row.original as IConsumerOrder;
    const resendSMS = async () => {
        try {
            await httpApi.post(`/consumer-orders/${orderId}/resend-sms`)
            Message.show('SMS resent successfully');
        } catch (e) {
            console.error('Error resending SMS', e);
            Message.error('Error resending SMS');
        }
    }
    return (
        <ColInfoContainer>
            <GoToArrow size={22} onClick={resendSMS} />
        </ColInfoContainer>
    )
}
const ActionsCell = ({ toggleRemoveOrderModal }) => {
    return (
        <RowInfoContainer>
            <TrashIcon size={22} onClick={toggleRemoveOrderModal} />
        </RowInfoContainer>
    )
}
const DeviceOrderPhoneCell = ({ cell: { row } }) => {
    const { deviceOrdererFirstName, deviceOrdererLastName, deviceOrdererPhoneNumber: orderPhone } = row.original as IConsumerOrder
    const fullName = `${deviceOrdererFirstName} ${deviceOrdererLastName}`;
    return (
        <ColInfoContainer>
            <span>{fullName}</span>
            <span>{orderPhone}</span>
        </ColInfoContainer>
    )
}
const PatientPhoneCell = ({ cell: { row } }) => {
    const { patientFirstName, patientLastName, patientPhoneNumber: orderPhone } = row.original as IConsumerOrder
    const fullName = `${patientFirstName} ${patientLastName}`;
    return (
        <ColInfoContainer>
            <span>{fullName}</span>
            <span>{orderPhone}</span>
        </ColInfoContainer>
    )
}
const ShippingAddressCell = ({ cell: { row } }) => {
    const rowOriginal = row.original as IConsumerOrder
    const shippingAddress = rowOriginal?.shippingAddress;
    let city = '';
    let street = '';
    if (shippingAddress) {
        const address = shippingAddress?.split(',');
        street = `${address?.[0] ?? ''}`;
        city = address?.slice(1)?.join(',') ?? '';
    }
    return (
        <ColInfoContainer>
            <span>{`${street}${city ? ',' : ''}`}</span>
            <span>{`${city}`}</span>
        </ColInfoContainer>
    );
}
const ShippedStatusCell = ({ cell: { value } }) => {
    return (
        <ColInfoContainer>
            <span>{value ? 'Yes' : 'No'}</span>
        </ColInfoContainer>
    )
}

// Styled Components
const EllipsisText = styled.p<{ maxWidth?: string }>`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: ${props => props.maxWidth || '100px'};
    text-align: center;
`;
const RowInfoContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
`;
const IDContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
`;
const ColInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const PageTopBarActions = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
    margin-bottom: 10px;
    justify-content: space-between;
`;
const TopBarSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`;