import React, { useCallback, useEffect, useState } from 'react';
import { UINucleusContentContainer } from '../../../components/UI/NucleusContainer/Content';
import { UIButton, UICard } from '../../../components/UI';
import { useForm } from 'react-hook-form';
import { useNucleusProviders, ProviderCallRequestConfigResponse } from '../../../context-api/nucleusProvidersContext/NucleusProvidersContext';
import { getProviderCallRequestConfig, updateProviderCallRequestConfig } from '../utils/providerApi';
import Message from '../../../utils/Message';

const ProviderCallRequestConfig = () => {
  const {
    state: {
      provider: {
        data,
        state: { loading },
      },
    },
  } = useNucleusProviders();
  const { handleSubmit } = useForm();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [stateRequestRingtone, setStateRequestRingtone] = useState<string>('Ding');
  const [stateSelectedRequestRingtone, setStateSelectedRequestRingtone] = useState<string>('Ding');

  useEffect(() => {
    if (data?.ID) fetchProviderCallRequestConfig(data?.ID);
  }, [data?.ID]);

  const getStringValue = (soundType: number) => {
    if (soundType === 1) return 'Ding';
    if (soundType === 2) return 'Ring';
    if (soundType === 0) return 'Silent';
    return 'Ding';
  };
  const getNumberValue = (soundType: string) => {
    if (soundType === 'Ding') return 1;
    if (soundType === 'Ring') return 2;
    if (soundType === 'Silent') return 0;
    return 1;
  };

  const fetchProviderCallRequestConfig = async (providerId): Promise<ProviderCallRequestConfigResponse> => {
    setIsLoading(true);
    const response = await getProviderCallRequestConfig(providerId);
    if (response?.ID) {
      setStateRequestRingtone(getStringValue(response?.CallRequestRingtone));
      setStateSelectedRequestRingtone(getStringValue(response?.CallRequestRingtone));
    }
    setIsLoading(false);
    return response;
  };

  const disableSubmit = useCallback(() => {
    if (stateRequestRingtone === stateSelectedRequestRingtone) return true;
    return false;
  }, [data, isLoading, stateSelectedRequestRingtone]);

  const handleRequestSoundChange = (event) => {
    setStateSelectedRequestRingtone(event.target.value);
  };

  const onSubmitCallRequestConfig = async (formData) => {
    if (!data?.ID) return;
    const formValues = {
      CallRequestRingtone: getNumberValue(stateSelectedRequestRingtone),
    };
    setIsLoading(true);
    const response = await updateProviderCallRequestConfig({
      providerId: data?.ID,
      callRequestConfigForm: formValues,
    });
    setIsLoading(false);
    if (response?.providerCallRequestConfigUpdate) {
      Message.show('Provider Call Request Queue ringtone Config updated');
      fetchProviderCallRequestConfig(data?.ID);
    } else {
      Message.error('Error updating Provider Escalation Config');
    }
  };

  return (
    <UINucleusContentContainer style={{ paddingTop: 30 }}>
      <UICard
        title="Provider Portal Settings"
        cardHeaderStyle={{
          fontFamily: 'Barlow',
          fontSize: '20px',
          fontStyle: 'italic',
          fontWeight: 500,
          lineHeight: '24px',
          letterSpacing: '0px',
          textAlign: 'left',
        }}
        style={{
          backgroundColor: '#EBF2FE',
          width: '24vw',
          paddingBottom: 20,
        }}
      >
        <UINucleusContentContainer>
          <div
            style={{
              display: 'flex',
              width: '50%',
              flexDirection: 'row',
              marginBottom: 24,
              gap: 24,
            }}
          >
            <div>
              <input
                type="radio"
                name="callRequestDing"
                id="callRequestDing"
                value="Ding"
                checked={stateSelectedRequestRingtone === 'Ding'}
                onChange={handleRequestSoundChange}
                className="with-gap"
                disabled={false}
              />
              <label htmlFor="callRequestDing" id="callRequestDing" style={{ color: '#0A313F', paddingLeft: 24 }}>
                <span>Ding</span>
              </label>
            </div>
            <div>
              <input
                type="radio"
                name="callRequestRing"
                id="callRequestRing"
                value="Ring"
                checked={stateSelectedRequestRingtone === 'Ring'}
                onChange={handleRequestSoundChange}
                className="with-gap"
                disabled={false}
              />
              <label htmlFor="callRequestRing" id="callRequestRing" style={{ color: '#0A313F', paddingLeft: 24 }}>
                <span>Ring</span>
              </label>
            </div>
            <div>
              <input
                type="radio"
                name="callRequestSilent"
                id="callRequestSilent"
                value="Silent"
                checked={stateSelectedRequestRingtone === 'Silent'}
                onChange={handleRequestSoundChange}
                className="with-gap"
                disabled={false}
              />
              <label htmlFor="callRequestSilent" id="callRequestSilent" style={{ color: '#0A313F', paddingLeft: 24 }}>
                <span>Silent</span>
              </label>
            </div>
          </div>
          <UIButton disabled={disableSubmit()} onClick={handleSubmit(onSubmitCallRequestConfig)} text="Save" variant={disableSubmit() === true ? 'disabled' : 'primary'} />
        </UINucleusContentContainer>
      </UICard>
    </UINucleusContentContainer>
  );
};

export default ProviderCallRequestConfig;
