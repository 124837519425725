import React, { useMemo, useState, useEffect, useRef } from 'react';
import MomentUtils from '@date-io/moment';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import NucleusTable from '../../components/NucleusTable';
import NucleusButtonV2 from '../../components/NucleusButtonV2';
import { SpinnerCircular } from 'spinners-react';
import { CSVLink } from 'react-csv';
import styled from 'styled-components';
import { getAccountsUsageReportData } from './ReportsApi';
import Message from '../../utils/Message';

const AccountsUsageReport = () => {
  const [reportData, setReportData] = React.useState([]);

  const [stateLoading, setStateLoading] = useState(true);
  const [stateFromDate, setStateFromDate] = useState(null);
  const [stateToDate, setStateToDate] = useState(null);
  const dateParamFormat = 'YYYY-MM-DD HH:mm:ss';

  const fromDateTimePickerRef = useRef('');
  const toDateTimePickerRef = useRef('');

  const [stateExportFileName, setStateExportFileName] = useState('AccountsFullUsageReport_');

  useEffect(() => {
    let fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 7);
    let currentDate = new Date();
    setStateFromDate(fromDate);
    setStateToDate(currentDate);
    refreshReport(fromDate, currentDate);
  }, []);

  const refreshReport = async (fromDate, toDate) => {
    setStateLoading(true);
    let fromDateParam = moment(fromDate).utc().format(dateParamFormat);
    let toDateParam = moment(toDate).utc().add(1, 'minute').format(dateParamFormat);
    const response = await getAccountsUsageReportData(fromDateParam, toDateParam);
    console.log('getAccountsUsageReportData response ->', response);
    setStateLoading(false);
    if (response?.data) {
      setReportData(response?.data);
    } else {
      Message.warning('Error loading report, please try again.');
      setReportData([]);
    }
    let fromExportParam = moment(fromDate).format('MM_DD_YYYY');
    let toExportParam = moment(toDate).format('MM_DD_YYYY');
    let exportCSVFileName = 'AccountsFullUsageReport_' + fromExportParam + '_to_' + toExportParam + '.csv';
    setStateExportFileName(exportCSVFileName);
  };

  const tableData = useMemo(() => {
    return reportData.map((row) => {
      return {
        ...row,
        HoursOnline: row.Hours,
        TotalDevicesWereOnline: row.WereOnline,
      };
    });
  }, [reportData]);

  const columns = useMemo(() => {
    const returnCols = [
      {
        Header: 'Account',
        accessor: 'AccountName',
        key: 'AccountName',
      },
      {
        Header: 'Number of Devices',
        accessor: 'TotalDevicesNow',
        key: 'TotalDevicesNow',
      },
      {
        Header: 'Devices Online',
        accessor: 'TotalDevicesWereOnline',
        key: 'TotalDevicesWereOnline',
      },
      {
        Header: 'Hours Online',
        accessor: 'HoursOnline',
        key: 'HoursOnline',
      },
      {
        Header: 'Calls Made',
        accessor: 'CallsMade',
        key: 'CallsMade',
      },
      {
        Header: 'Calls Connected',
        accessor: 'CallsConnected',
        key: 'CallsConnected',
      },
      {
        Header: 'Messages Sent',
        accessor: 'MessagesSent',
        key: 'MessagesSent',
      },
    ];
    return returnCols;
  }, [tableData]);

  const appsUsageReportRef = useRef([]);
  appsUsageReportRef.current = tableData;

  const csvFileHeadersRef = useRef([]);
  csvFileHeadersRef.current = [
    { label: 'Account', key: 'AccountName' },
    { label: 'Number of Devices', key: 'TotalDevicesNow' },
    { label: 'Devices Online', key: 'TotalDevicesWereOnline' },
    { label: 'Hours Online', key: 'HoursOnline' },
    { label: 'Calls Made', key: 'CallsMade' },
    { label: 'Calls Connected', key: 'CallsConnected' },
    { label: 'Messages Sent', key: 'MessagesSent' },
  ];

  const onFromDateChange = (date, value) => {
    setStateFromDate(value);
  };

  const onToDateChange = (date, value) => {
    setStateToDate(value);
  };

  return (
    <div className="nucleus-tools-container">
      <div>
        <p className="nucleus-tools-page-title">
          <b>Reports</b>
        </p>
      </div>
      <ReportContainer>
        <div>
          <p style={{ color: '#0A313F', fontSize: 20 }}>
            <b>Full Accounts Usage</b>
          </p>
        </div>
        <ReportControllsContainer>
          <div>
            <ReportDateControllsTitle>From</ReportDateControllsTitle>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDateTimePicker
                style={{
                  backgroundColor: 'white',
                  padding: 10,
                  borderRadius: 10,
                }}
                ref={fromDateTimePickerRef}
                variant="dialog"
                class="browser-default"
                value={stateFromDate}
                onChange={onFromDateChange}
                onError={console.warn}
                onClose={() => {}}
                format="MM/DD/YYYY hh:mm A"
              />
            </MuiPickersUtilsProvider>
          </div>
          <ReportControllsSeparator>-</ReportControllsSeparator>
          <div>
            <ReportDateControllsTitle>To</ReportDateControllsTitle>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDateTimePicker
                style={{
                  backgroundColor: 'white',
                  padding: 10,
                  borderRadius: 10,
                }}
                ref={toDateTimePickerRef}
                variant="dialog"
                class="browser-default"
                value={stateToDate}
                onChange={onToDateChange}
                onError={console.warn}
                format="MM/DD/YYYY hh:mm A"
              />
            </MuiPickersUtilsProvider>
          </div>
          <div style={{ marginLeft: 55 }}>
            <NucleusButtonV2 onClick={() => refreshReport(stateFromDate, stateToDate)} styleType="outline">
              Apply
            </NucleusButtonV2>
          </div>
        </ReportControllsContainer>
        <div style={{ height: '55vh', overflowY: 'scroll', borderRadius: 10 }}>
          {stateLoading ? (
            <LoadingView>
              <SpinnerCircular color="#2096F3" secondaryColor="rgba(0,0,0,0.16)" size="50" thickness="100" />
            </LoadingView>
          ) : (
            <NucleusTable
              stickyThead
              tableStyles={{
                backgroundColor: 'white',
                paddingLeft: 10,
                paddingRight: 10,
                borderRadius: 10,
              }}
              columns={columns}
              data={tableData}
            />
          )}
        </div>
      </ReportContainer>
      <ReportFooterButtons>
        {tableData && tableData.length > 0 && (
          <CSVLink headers={csvFileHeadersRef.current} data={tableData} filename={stateExportFileName} target="_blank">
            <NucleusButtonV2 styleType="outline">Export to CSV</NucleusButtonV2>
          </CSVLink>
        )}
      </ReportFooterButtons>
    </div>
  );
};

export default AccountsUsageReport;

const ReportContainer = styled.div`
  background-color: #ebf2fe;
  min-height: 75vh;
  padding: 10px;
  padding-bottom: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  //border-bottom: 2px solid #B4C6D3;
`;

const ReportControllsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end; /* Cambiado 'end' por 'flex-end' que es la propiedad correcta */
  padding-bottom: 20px;
`;

const ReportDateControllsTitle = styled.span`
  margin-right: 28px;
  color: #0092ff;
`;

const ReportControllsSeparator = styled.div`
  font-size: 28px;
  font-weight: bold;
  margin: 20px 20px 5px;
`;

const LoadingView = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ReportFooterButtons = styled.div`
  background-color: #ebf2fe;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  justify-content: flex-end;
  display: flex;
`;
