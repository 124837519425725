import React, { useEffect, useState } from 'react';
import AuthStore from '../stores/AuthStore';
import CarePatientStore from '../stores/CarePatientStore';
import IntlTelInput from 'react-intl-tel-input';
import { IndexLink, Link, useParams } from "react-router-dom";
import CompressUtil from '../components/CompressUtil';
import classNames from 'classnames';
import Message from "../utils/Message";

let clientImageFile = null;


const NewAccountPatient = ({}) => {
    let params = useParams();
    const [clientImageFilePreviewURL, setClientImageFilePreviewURL] = useState("")
    //const [clientImageFile, setClientImageFile] = useState(null);
    const [panel, setPanel] = useState("Form");
    const [errorTelephoneVisibility, setErrorTelephoneVisibility] = useState("hide");
    const [errorTelephoneText, setErrorTelephoneText] = useState("Telephone already registered");
    const [messageVisibility, setMessageVisibility] = useState("");
    const [newPatientFullName, setNewPatientFullName] = useState("");
    const [btnStyle, setBtnStyle] = useState("disabled");
    const [newPatient, setNewPatient] = useState(false);
    const [formValues, setFormValues] = useState({
        'firstName': '',
        'lastName': '',
        'telephone': '',
        'address': ''
    });
    const [txtNewPatientTelephone, setTxtNewPatientTelephone] = useState("");

    let formVisibility = classNames({
        hide : (panel != "Form")
    });
    let messageVisible = classNames({
        hide : (panel != "Message")
    });

    const validateTxtInput = (event) => {
        event.persist();
        setFormValues((values) => ({
            ...values,
            [event.target.id]: event.target.value,
        }));

        if(formValues.firstName.length > 0 && formValues.lastName.length > 0) {
            setBtnStyle("");
        } else {
            setBtnStyle("disabled");
        }
        if (formValues.telephone.length > 0){
            setErrorTelephoneVisibility("hide");
        }
    }

    const validateTxtTelephone = (status, value, countryData, number, id) => {
        //console.log("validateTxtTelephone", status, value, countryData, number, id);
        //console.log("validateTxtTelephone status ", status);
        console.log("validateTxtTelephone VALUE ", value);
        console.log("validateTxtTelephone NUMBER ", number);
        //console.log("validateTxtTelephone COUNTRY ", countryData);

        if (number){
            setFormValues((values) => ({
                ...values,
                telephone: number
            }))
            if (value.length > 0){
                setErrorTelephoneVisibility("hide")
            }
        }
        else {
            setFormValues((values) => ({
                ...values,
                telephone: "+" + value.dialCode + formValues.telephone 
            }))
        }
    };

    const submitCreatePatientForm = () => {
        if (formValues.firstName === "") {
            Message.show('FirstName is required.');
            return;
        }

        if (formValues.lastName === "") {
            Message.show('LastName is required.');
            return;
        }
        let dateParam = "";

        if (formValues.telephone.length > 0 ){

            let number=formValues.telephone;
            number = number.replace(/\(/g, "");
            number = number.replace(/\)/g, "");
            number = number.replace(/-/g, "");
            number = number.replace(/ /g, "");

            
            if (number.length > 0 && number.length < 10){
                setErrorTelephoneVisibility('');
                setErrorTelephoneText('Invalid Telephone. It must be at least 10 digits.');
                return;
            }
            let containsLetter = /[a-zA-Z]/.test(number);
            if (containsLetter){
                setErrorTelephoneVisibility('');
                setErrorTelephoneText('Invalid Telephone.');
                return;
            } 
            console.log("Le nombre finale est:", number, number.length);
            const phoneNumberRegex = /^(?:\+\d{1,3})?\d{10,10}$/;   
            if (!phoneNumberRegex.test(number)) {
                setErrorTelephoneVisibility('');
                setErrorTelephoneText('Invalid Telephone.');
                return;
            }   
        }
        // if (this.refs.txtNewPatientContactNumber.value.length > 0 && this.refs.txtNewPatientContactNumber.value.length < 10){
        //     this.setState({
        //         errorContactTelephoneVisibility : '',
        //         errorContactTelephoneText : 'Invalid Telephone. It must be at least 10 digits.'
        //     });
        //     return;
        // }
        const CreateNewPatientData = {
            UserID : "",
            AccountID : params.accountId,
            FirstName : formValues.firstName,
            LastName : formValues.lastName,
            Telephone: formValues.telephone,
            Address: formValues.address,
            DateOfBirth: dateParam,
            EmergencyContactName: "", //this.refs.txtNewPatientContactName.value,           //  Deprecated on Feb 2023
            EmergencyContactTelephone: "", //this.refs.txtNewPatientContactNumber.value     // Deprecated on Feb 2023     CARE-10239 Remove Emergency Contact and Date of Birth from patient
            //ShowWeather : this.refs.checkShowWeather.checked
        }

        setBtnStyle("disabled");
        CarePatientStore.CreateNewPatient(CreateNewPatientData);
        setErrorTelephoneVisibility("hide");
    }

    useEffect(() => {
        if(newPatient) {
            return;
        }
        CarePatientStore.on("onCreatePatientAction", onCreatePatientAction);

        return () => {
            CarePatientStore.removeListener("onCreatePatientAction", onCreatePatientAction);
        };

    }, [newPatient]);

    const onCreatePatientAction = (response) => {
        if (response.ok){
                
            /*CarePatientStore.getAllPatientsData({
                UserID : AuthStore.getCsUserID()
            });*/
            console.log("RESPONSE NEW PATIENT",response);
            console.log("image: ", clientImageFile)
            if (clientImageFile != null) {
                console.log("uploading image", clientImageFile)
                CarePatientStore.UploadPatientImage({
                    PatientID : response.PatientID,
                    File: clientImageFile
                });
               clientImageFile = null;
            }
            window.location.assign("#/accountDetails/" +  params.accountId);
            setTimeout(function(){ 
                Message.show(AuthStore.getPatientLabel() + " created");
            }, 100);
            setNewPatient(true);
        } else if (response.telephoneRegistered) {
            setErrorTelephoneVisibility('');
            setErrorTelephoneText('Telephone number already registered ')
            setNewPatient(true);
        }else {
            Message.show("Error creating new " + AuthStore.getPatientLabel());

            if (response.error){
                Message.show("Error: " + response.error);
            }
            setNewPatient(true);
        }
        setBtnStyle("");
        
    }

    return(
        <div class="nucleus-hard-container">
                <p id={'patient-page-title'} class="nucleus-page-title">New { AuthStore.getPatientLabel() }</p>
                <br/>
                <div class={ "nucleus-patient-form-pending " + formVisibility} >
                    <br/>
                    <div class="row no-margin">
                        <div class="col s2 m2 l2 no-margin">
                        <div style={{display:'flex',alignItems: 'flex-start', justifyContent: 'center'}}>
                            <style>
                            {
                            `
                            .img-circle {
                                border-radius: 50%;
                            }
                            .img-circle {
                                border-radius: 0;
                            }
                            
                            .ratio {
                                background-position: center center;
                                background-repeat: no-repeat;
                                background-size: cover;
                            
                                height: 0;
                                padding-bottom: 100%;
                                position: relative;
                                width: 100%;
                            }
                            .img-circle {
                                border-radius: 50%;
                            }
                            .img-responsive {
                                display: block;
                                height: auto;
                                max-width: 100%;
                            }
                            .inputfile {
                                width: 0.1px;
                                height: 0.1px;
                                opacity: 0;
                                overflow: hidden;
                                position: absolute;
                                z-index: -1;
                            }
                            .inputfile + label {
                                margin-top:10px;
                                font-size: 1.25em;
                                // font-weight: 700;
                                text-decoration: underline;
                                color: gray;
                                // background-color: black;
                                display: inline-block;
                            }
                            
                            .inputfile:focus + label,
                            .inputfile + label:hover {
                                cursor: pointer;
                                // background-color: red;
                            }
                            .inputfile {
                                cursor: pointer;
                            }
                            `}</style>
                            <div style={{width:"70%", display:'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                <div  class="ratio img-responsive img-circle" style={{ backgroundImage: clientImageFilePreviewURL ? `url(${clientImageFilePreviewURL})` : true ? `url("img/user_placeholder.png")` : `url(${"https://images.unsplash.com/photo-1633113216120-53ca0a7be5bc"})`, borderWidth:"1px solid lightgray"}}></div>
                                <div>
                                    <input 
                                        accept="image/*"
                                        onChange={(e)=>{
                                            let value = e.target.files[0]
                                            console.log("value:",value);
                                            let reader = new FileReader();
                                            let url = reader.readAsDataURL(value);
                                            let urlImage = URL.createObjectURL(value)
                                            setClientImageFilePreviewURL(urlImage);
                                            reader.onloadend = function (e) {
                                                CompressUtil(value).then((data) => {
                                                    console.log("the data: ", data)
                                                    clientImageFile = data;
                                                })
                                              }.bind(this);
                                            console.log("Value image:image:",[reader.result]);
                                            console.log(url) // Would see a path?
                                        }}
                                        multiple={false}
                                        type="file" 
                                        name="file" 
                                        id="file" 
                                        class="inputfile" 
                                    />
                                    <label for="file">Upload Photo</label>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="col s8 m8 l8">
                            <div class="row">
                                <div class="col s3 valgin-wrapper left-align">
                                    <span class=" nucleus-labels"> First Name</span> <span class="nucleus-labels txt-red">*</span>
                                </div>
                                <div class="col s9">
                                    <input 
                                        id='firstName' 
                                        type="text" 
                                        value={formValues.firstName} 
                                        class="validate nucleus-input-form" 
                                        placeholder="First Name" 
                                        onChange={validateTxtInput}
                                    />
                                </div>
                            </div>

                            <div class="row">
                                <div class="col s3 valgin-wrapper left-align">
                                    <span class=" nucleus-labels"> Last Name</span> <span class="nucleus-labels txt-red">*</span>
                                </div>
                                <div class="col s9">
                                    <input 
                                        id='lastName' 
                                        value={formValues.lastName}  
                                        type="text" 
                                        class="validate nucleus-input-form" 
                                        placeholder="Last Name" 
                                        onChange={validateTxtInput}/>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col s3 left-align">
                                    <span class=" nucleus-labels"> Telephone</span> 
                                </div>
                                <div class="col s9" style={{paddingRight:0}}>
                                    {/*<input  ref="txtNewPatientTelephone" id="txtPhone" type="text" class="validate nucleus-input-form" placeholder="(000)000-0000" onKeyUp={this.validateTxtInput } onChange={this.validateTxtInput}/>*/}
                                    <IntlTelInput 
                                        fieldId="telephone"
                                        css={['intl-tel-input validate width100', 'nucleus-input-form width83']} 
                                        utilsScript={'libphonenumber.js'} 
                                        autoHideDialCode={true}
                                        separateDialCode={true}
                                        nationalMode={false}
                                        format={false}
                                        preferredCountries={['us']}
                                        //onPhoneNumberBlur={(e) => validateTxtInput(e)}
                                        onSelectFlag={validateTxtTelephone}
                                        onPhoneNumberChange={validateTxtTelephone}
                                        style={{fontSize:16, width:'75%'}}
                                    />
                                    <br/>
                                    <span class={"nucleus-form-error left-align txt-black-forced " }>
                                        Click the country code icon for more country codes.
                                    </span>
                                    <br/>
                                    <span class={"nucleus-form-error center-align " + errorTelephoneVisibility }>{errorTelephoneText}</span>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col s3 left-align">
                                    <span class=" nucleus-labels"> Address</span> 
                                </div>
                                <div class="col s9">
                                    <input 
                                        id="address" 
                                        value={formValues.address} 
                                        type="text" 
                                        class="validate nucleus-input-form" 
                                        placeholder="1234 Street Lane, New City, NC 00000" 
                                        onChange={validateTxtInput}
                                    />
                                </div>
                            </div>
                        </div>
                      
                    </div>
                    <div class="fixed-action-btn btn-add-new">
                        <a id={'save-patient-btn'} class="btn-floating btn-large waves-effect waves-light light-blue darken-4 tooltipped " disabled={btnStyle} data-position="left" data-tooltip={"Save new " + AuthStore.getPatientLabel().toLowerCase() } onClick={submitCreatePatientForm}>
                          <i class="material-icons">save</i>
                        </a>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                </div>

                <div class={ "nucleus-new-patient-form-pending " + messageVisible }>
                    <br/>
                    <div class="row">
                        <div class="col s12">
                            <p class=" nucleus-labels"> Complete! The { AuthStore.getPatientLabel().toLowerCase() + " " + newPatientFullName } has been created.  </p>
                               <Link to="/patients">
                               <span class="nucleus-link nucleus-font-medium">Go to { AuthStore.getPatientLabelPlural() } List </span>
                               </Link>
                        </div>
                    </div>
                        
                </div>
            </div>
    )
};

export default NewAccountPatient;