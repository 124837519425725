import React from 'react';
import AuthStore from "../stores/AuthStore";
import DashboardContent from "../components/DashboardContent";
import '../../css/nucleus_care_customer_support.css';

class Dashboard extends React.Component {


    constructor() {
        super();

        this.state = {
            csUserID : AuthStore.getCsUserID(),
        	userFullName : AuthStore.getCsUserEmail()
         //    isNewSession : AuthStore.getIsNewSession(),
         //    companyName : AuthStore.getCompanyName(),
         //    companyLogo : AuthStore.getCompanyLogo()
        };

    }


    componentDidMount() {

        //alert("Profile Completed");
        if (AuthStore.getIsNewSession() != "yes"){
            //Materialize.toast('Welcome ' + this.state.userFullName + '!', 3000, 'rounded');
            AuthStore.clearIsNewSession();
        }

    }

    componentWillUnmount() {


    }


    render() {

        return (
            <div class="nucleus-hard-container">
            <div class="nucleus-all-scrollable-page">
                <div class="row ">
                    <div class="col s8 no-margin">
                        <p class="nucleus-page-title"> Nucleus Admin - Welcome { this.state.userFullName }!!</p>
                    </div>
                    <div class="col s4 right-align "> </div>
                </div>

                <DashboardContent/>

            </div>
            </div>
        );
    }
}

export default Dashboard;
