import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import CsApp from './App.jsx';
import {ClearCacheProvider} from "react-clear-cache";
import reportWebVitals from './reportWebVitals';
import 'react-toastify/dist/ReactToastify.css';//css needed for toast

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ClearCacheProvider duration={5000}>
      <CsApp></CsApp>
  </ClearCacheProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
