import { httpApi as httpNodeApi } from '@nucleus-care/nucleuscare-backend-client';

export interface IAccountUsageReportData {
  AccountName: string;
  TotalDevicesNow: number;
  WereOnline: number;
  Hours: number;
  CallsMade: number;
  CallsConnected: number;
  MessagesSent: number;
}

export type tAccountUsageReportData = {
  AccountName: string;
  TotalDevicesNow: number;
  WereOnline: number;
  Hours: number;
  CallsMade: number;
  CallsConnected: number;
  MessagesSent: number;
};

export const getAccountsUsageReportData = async (startDate: string, endDate: string): Promise<IAccountUsageReportData[] | any> => {
  try {
    const response = await httpNodeApi.get(`report/accounts_full_usage?startDate=${startDate}&endDate=${endDate}`);
    //console.log("getAccountsUsageReportData =>", response);
    if (response?.data) return { data: response?.data };
    return null;
  } catch (error) {
    console.log('getAccountsUsageReportData Error =>', error);
    return error;
  }
};
