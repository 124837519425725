import React from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import Message from "../utils/Message";

import CarePatientStore from "../stores/CarePatientStore";
import ReactTooltip from "react-tooltip";
import moment from "moment";

class AccountUserPatientProfileDevicesItemRow extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			DeviceID: this.props.ID,
			volumeBarStyle: "deviceVolume",
			deviceVolume: 0,
			deviceConcierge: false,
		};
		this.handleRowClicked = this.handleRowClicked.bind(this);

		this.handleUnassignDevice = this.handleUnassignDevice.bind(this);

		// DEPRECATED Dic 2017 - CARE-1895
		// this.handleMouseUp = this.handleMouseUp.bind(this);
		this.onDeviceVolumeUpdatedAction = this.onDeviceVolumeUpdatedAction.bind(this);

		//DEPRECATED: November 2nd 2021
		//this.onDeviceConciergeUpdatedAction = this.onDeviceConciergeUpdatedAction.bind(this);

		this.handleCopyDeviceID = this.handleCopyDeviceID.bind(this);
		this.requestRestart = this.requestRestart.bind(this);

		this.isConnectDevice = true;
	}

	componentDidMount() {
		//console.log("this.state.MemberID", this.state.MemberID);
		/*
		let patientInfo = CarePatientStore.getPatientInfo(this.props.PatientID);
		console.log("PatientInfo:", patientInfo);
		if (patientInfo){
				this.setState({
						assignmentPatientName : patientInfo.Name
				});
		}
		*/

		CarePatientStore.on("onSelectedDeviceVolumeUpdated", this.onDeviceVolumeUpdatedAction);

		//DEPRECATED: November 2nd 2021
		//CarePatientStore.on("onDeviceConciergeUpdated", this.onDeviceConciergeUpdatedAction);

		window.$(".tooltipped").tooltip({ delay: 50 });
		// DEPRECATED Dic 2017 - CARE-1895
		/*
				if (this.props.Volume >= 0){
						if (this.props.Volume == 0){
								this.setState({
										volumeBarStyle : 'deviceVolume0'
								});
						} else{
								this.setState({
										volumeBarStyle : 'deviceVolume'
								});
						}
				}
				*/

		this.setState({
			deviceVolume: this.props.Volume,
			deviceConcierge: this.props.Concierge,
		});

		if (this.props.DeviceInfo && this.props.DeviceInfo != null){
			let device_Info = this.props.DeviceInfo;

			if (device_Info && device_Info.brand != null && device_Info.brand == "goldtek"){
				this.isConnectDevice = false;
				console.log("The info", device_Info.brand);
			}
		}
	}

	componentWillUnmount() {
		CarePatientStore.removeListener(
			"onSelectedDeviceVolumeUpdated",
			this.onDeviceVolumeUpdatedAction
		);
		//DEPRECATED: November 2nd 2021
		//CarePatientStore.removeListener("onDeviceConciergeUpdated", this.onDeviceConciergeUpdatedAction);
	}

	handleRowClicked() {}

	// DEPRECATED Dic 2017 - CARE-1895
	/*
		handleRefresh(){
				console.log("handleRefresh ", this.props.ID);
				CarePatientStore.handlePatientProfileInfoRefresh({
						DeviceID : this.props.ID,
						DeviceMac : this.props.Mac
				});
		}
		*/



	// DEPRECATED Dic 2017 - CARE-1895
	/*
		handleNewMessageToDevice(){
				console.log("handleNewMessageToDevice ", this.props.ID);
				CarePatientStore.handlePatientProfileInfoNewMessageToDevice({
						DeviceID : this.props.ID,
						DeviceName : this.props.Name
				});
		}
		*/

	handleUnassignDevice() {
		console.log("handleUnassignDevice ", this.props);
		CarePatientStore.handlePatientProfileInfoUnassignDevice({
			DeviceID: this.props.ID,
			DeviceMac: this.props.Mac,
			DeviceName: this.props.Name,
			HexnodeID: this.props.HexnodeID,
			DeviceLabel: this.props.Label,
			DeviceVolume: this.state.deviceVolume,
			DeviceConcierge: this.state.deviceConcierge,
			DeviceOS: this.props.OS,
			IsLegacyDevice: !this.isConnectDevice
		});
	}
	// DEPRECATED Dic 2017 - CARE-1895
	/*handleMouseUp(e){
				console.log("Up", e.target.value);
				CarePatientStore.updateDeviceVolume({
						DeviceID : this.props.ID,
						RingtoneVolume : e.target.value
				});
		}
		*/

	onDeviceVolumeUpdatedAction(response) {
		console.log("onDeviceVolumeUpdatedAction");
		if (response.deviceID == this.props.ID) {
			if (response.ok) {
				this.setState({
					deviceVolume: response.volume,
				});
			}
		}
	}

	// DEPRECATED: November 2nd 2021
	// onDeviceConciergeUpdatedAction(response) {
	// 	console.log("onDeviceConciergeUpdatedAction");
	// 	if (response.deviceID == this.props.ID) {
	// 		if (response.ok) {
	// 			this.setState({
	// 				deviceConcierge: response.enable,
	// 			});
	// 		}
	// 	}
	// }

	handleCopyDeviceID() {
		console.log("handleCopyDeviceID");

		var deviceID = this.props.ID;

		var copyText = document.createTextNode(deviceID);
		var textArea = document.createElement("textarea");

		textArea.value = copyText.textContent;
		document.body.appendChild(textArea);
		textArea.select();
		document.execCommand("Copy");
		textArea.remove();

		console.log("handleCopyDeviceID", deviceID);

		Message.show("Device " + this.props.Name + " ID has been copied!");
	}

	requestRestart(){
		console.log("requestRestart Mac", this.props.Mac);
		console.log("requestRestart HexnodeID", this.props.HexnodeID);

		CarePatientStore.launchRequestRestart({
			DeviceID: this.props.ID,
			DeviceMac: this.props.Mac,
			HexnodeID: this.props.HexnodeID
		});
	}

	render() {
		//console.log("DEVICE STATUS2: ", this.props.patientDevices);
		let deviceName = this.props.Name;
		if (deviceName != null && deviceName.length > 22) {
			deviceName = deviceName.substring(0, 22) + " ...";
		}

		let deviceOnlineVisibility = "";
		let deviceOfflineVisibility = "hide";
		let lastOnline = this.props.LastOnline;

		if (!this.props.Online) {
			deviceOnlineVisibility = "hide";
			deviceOfflineVisibility = "";

			lastOnline = "Device not connected    ";
		}
		if (this.props.LastOnline && this.props.LastOnline.length > 0){
			/*let _date = new Date(this.props.LastOnline)
			_date.setHours(_date.getHours() + this.props.TimezoneOffset);
			lastOnline = moment(_date).format("MM/DD/YYYY hh:mm A");*/
			lastOnline = this.props.LastOnline
		}

		lastOnline += " - " + this.props.TimeZone;

		let canSeeUnassign = this.props.currentUserAdmin ? " " : " hide";
		//let temp = this.props.Temperature.length > 0 ? this.props.Temperature : " ";
		//let hum = this.props.Humidity.length > 1 ? this.props.Humidity : " ";
		let ver = (this.props.Version && this.props.Version.length > 0) ? this.props.Version : "--";

		// let room = temp + " / " + hum;
		// if (hum.includes("o") || !this.props.Online || room == "  /  ") {
		// 	room = " ";
		// }

		//console.log("AutoAnswer", );
		let autoAnswerAllowedStyle = (this.props.AutoAnswerAllowed && this.props.UserBargeInEnabled) ? " " : " hide";
		//let identifier = this.props.Serial ? this.props.Serial : this.props.Mac;

		return (
			<tr id={`device-${this.props.index}`} class="">
				<td class="nucleus-row-small">
					&nbsp;&nbsp;&nbsp;
					{
						//identifier
					}
					<a
						class="btn-floating btn waves-effect waves-light dark-blue nucleus-floating-btn a-left"
						onClick={this.handleCopyDeviceID}
						data-tip={"Copy DeviceID"}
					>
						<i class="material-icons nucleus-floating-btn-icon-copy-to-cb">
							file_copy
						</i>
						<ReactTooltip
							place="top"
							type="dark"
							effect="float"
							delayShow={50}
						/>
					</a>
				</td>
				<td class="nucleus-row-small">{deviceName}</td>
				<td class="nucleus-row-small">
					<div class="row no-margin a-bit-lower3">
						<div class="col s11 m11 no-margin">{lastOnline}</div>
						<div class="col s1 m1 no-margin">
							<span class={deviceOnlineVisibility}>
								<img
									src="img/icon_device_active.png"
									class="tooltipped a-little-bit-lower"
									data-position="top"
									data-delay="50"
									data-tooltip="Online"
								/>
							</span>
							<span class={deviceOfflineVisibility}>
								<img
									src="img/icon_device_inactive.png"
									class="tooltipped a-little-bit-lower"
									data-position="top"
									data-delay="50"
									data-tooltip="Offline"
								/>
							</span>
						</div>
					</div>
				</td>
				{/* <td class="nucleus-row-small center-align">{room}</td> */}
				<td
					class="nucleus-row-small center-align"
					style={{
						color: this.props.Battery < 50 ? "red" : "black",
					}}
				>
					{this.props.Battery ? this.props.Battery + "%" : ""}
				</td>
				<td class="nucleus-row-small center-align">{ver}</td>
				{/* <td class="nucleus-row-small center-align ">
					<a
						class="btn-floating btn waves-effect waves-light dark-blue nucleus-floating-btn a-left"
						onClick={this.requestRestart}
						data-tip={"Restart"}
						style={{marginRight:10}} >
						<i class="material-icons nucleus-floating-btn-icon-copy-to-cb"> autorenew </i>
						<ReactTooltip place="top" type="dark" effect="float" delayShow={50}/>
					</a>
				</td> */}

				<td class="nucleus-row-small left-align ">
					<a
						id={`edit-device-${this.props.index}`}
						class={
							"nucleus-link nucleus-font-14 row-action tooltipped " +
							canSeeUnassign
						}
						data-position="top"
						data-delay="10"
						data-tooltip="Open device details"
						onClick={this.handleUnassignDevice}
					>
						Edit
						&nbsp;&nbsp;
					</a>
				</td>
			</tr>
		);
	}
}

export default AccountUserPatientProfileDevicesItemRow;
