import React from 'react';
import ReactDOM from "react-dom";
import $ from "jquery";
import { IndexLink, Link } from "react-router-dom";
import Message from "../../utils/Message";

import { useState, useEffect, useRef } from 'react';

import NucleuscareConnectData,
{
	RootState,
    getAccountAppsAvailabilityThunk, updateAccountAppAvailabilityThunk, updateAccountAppsAvailabilityThunk
} from '@nucleus-care/nucleuscare-backend-client';

import {
	useSelector,
	useDispatch
	//DefaultRootState
} from 'react-redux';

import AuthStore from '../../stores/AuthStore';

import AccountExternalAppTableRow from './AccountExternalAppTableRow';

import { SpinnerCircular } from 'spinners-react';

type AccountExternalAppsParams = {
    AccountID:String
}

const AccountExternalApps = (props:AccountExternalAppsParams) => {

    const dispatch = useDispatch();

    const accountAppsStore = useSelector((state: RootState) => {
		//console.log("ExternalAppsPage useSelector", state.accountExternalApps.accountApps);
		return state.accountExternalApps.accountApps;
	});

    const [stateLoading, setStateLoading] = useState(false);
	const [stateSearchText, setStateSearchText] = useState("");

    const [stateSelectedApp, setStateSelectedApp] = useState({});
    const [stateSelectedAppName, setStateSelectedAppName] = useState("");
    const [stateSaveButtonStyle, setStateSaveButtonStyle] = useState("nucleus-submit-btn");

    const [stateBtnAllAppsStyle, setStateBtnAllAppsStyle] = useState("nucleus-submit-btn-small");

    const [stateSortingStyles, setStateSortingStyles] = useState({

        appNameAsc: " nucleus-icon-inactive ",
        appNameDesc : " nucleus-icon-inactive hide "
    });

    const [stateSortingMode, setStateSortingMode] = useState("appNameAsc");

    const handleSortingMode = (tag, tag2) => {
        console.log("handleSortingMode", tag, tag2);

        let object = {

            appNameAsc: " nucleus-icon-inactive ",
            appNameDesc : " nucleus-icon-inactive hide "

        };
        object[tag] = " nucleus-icon-active ";
        object[tag2] = " nucleus-icon-inactive hide ";
        setStateSortingStyles(object);
        setStateSortingMode(tag);
    }

	useEffect(() => {
		console.log("AccountExternalApps Initial useEffect");
        window.$('#modalConfirmConfig').modal({
            dismissible: false,
            complete: function() {  } // Callback for Modal close
        });
        window.$('#modalConfirmReset').modal({
            dismissible: false,
            complete: function() {  } // Callback for Modal close
        });
		refreshReport();
	}, []);


	useEffect(() => {
		console.log("AccountExternalApps useEffect accountAppsStore");
		console.log("accountAppsStore", accountAppsStore);

		if (!accountAppsStore.getAvailabilityLoading){
			if (accountAppsStore.getAvailabilityComplete){
				//Message.show("Report updated");
                setStateLoading(false);
			}
		} else if (accountAppsStore.error){
			Message.show("Error getting External Apps");

		}

	}, [accountAppsStore.getAvailabilityLoading, accountAppsStore.getAvailabilityComplete])

    useEffect(() => {
		console.log("AccountExternalApps:useEffect accountAppsStore:Add");
		console.log("accountAppsStore", accountAppsStore);

		if (!accountAppsStore.updateAvailabilityLoading){
			if (accountAppsStore.updateAvailabilityComplete){
				Message.show("External App configuration updated");
                setStateBtnAllAppsStyle("nucleus-submit-btn-small");
                setStateLoading(false);
                handleCloseAddModal();
			}
            refreshReport();
		} else if (accountAppsStore.error){
			Message.show("Error updating External App...");
		}

	}, [accountAppsStore.updateAvailabilityLoading, accountAppsStore.updateAvailabilityComplete])


    const onSearchTextChange = (textInput) =>{
        console.log("onSearchTextChange", textInput.target.value);
		setStateSearchText(textInput.target.value);
    }

    const refreshReport = ()=>{
		console.log("refreshReport 2", props.AccountID);
        setStateLoading(true);
        dispatch(
            getAccountAppsAvailabilityThunk(props.AccountID)
        );
	}

    const onRowClicked = (appData)=>{
        console.log("onRowClicked", appData);

        let tempObject = Object.assign({}, stateSelectedApp);
        tempObject.externalAppId = appData.ExternalAppID;
        tempObject.appEnabled = appData.enabled;
        tempObject.overwriteConfig = false;
        setStateSelectedApp(tempObject);
        setStateSelectedAppName(appData.Name);

        if (appData.enabled){
            // When is Enable true, there is no need to show the modal
            // It should continue by default, doing what is done on the "saveAccountConfig" method
            //window.$('#modalConfirmConfig').modal('open');
            //setStateSaveButtonStyle("nucleus-submit-btn");
            
            setStateSaveButtonStyle("nucleus-submit-btn-disabled");
            dispatch(
                updateAccountAppAvailabilityThunk({
                    accountId: props.AccountID,
                    externalAppId: appData.ExternalAppID, //stateSelectedApp.externalAppId,
                    appEnabled: appData.enabled, //stateSelectedApp.appEnabled,
                    overwriteConfig: false
                })
            );
        } 
        else{
            window.$('#modalConfirmReset').modal('open');
            setStateSaveButtonStyle("nucleus-submit-btn");
        }
    }

    const handleCloseAddModal=()=>{
            window.$('#modalConfirmReset').modal('close');
            window.$('#modalConfirmConfig').modal('close');
    }

    // Not needed
    const saveAccountConfig = ()=>{
        console.log("saveAccountConfig");
        setStateSaveButtonStyle("nucleus-submit-btn-disabled");
        dispatch(
            updateAccountAppAvailabilityThunk({
                accountId: props.AccountID,
                externalAppId: stateSelectedApp.externalAppId,
                appEnabled: stateSelectedApp.appEnabled,
                overwriteConfig: false
            })
        );
    }

    const saveResetConfig=()=>{
        console.log("saveResetConfig");
        setStateSaveButtonStyle("nucleus-submit-btn-disabled");

        dispatch(
            updateAccountAppAvailabilityThunk({
                accountId: props.AccountID,
                externalAppId: stateSelectedApp.externalAppId,
                appEnabled: stateSelectedApp.appEnabled,
                overwriteConfig: false
            })
        );
    }

    const handleEnableAllApps = () =>{
        console.log("handleEnableAllApps");
        setStateBtnAllAppsStyle("nucleus-submit-btn-small-disabled");

        dispatch(
            updateAccountAppsAvailabilityThunk({
                accountId: props.AccountID,
                appsEnabled: true,
            })
        )
    }

    const handleDisableAllApps = () => {
        console.log("handleDisableAllApps");
        setStateBtnAllAppsStyle("nucleus-submit-btn-small-disabled");

        dispatch(
            updateAccountAppsAvailabilityThunk({
                accountId: props.AccountID,
                appsEnabled: false,
            })
        )

    }

    const getTableRows = ()=>{
        console.log("render getTableRows>", accountAppsStore);

        let appRowsArray = [];
        if (accountAppsStore && accountAppsStore.apps && accountAppsStore.apps.length > 0){

            let array = [].concat(accountAppsStore.apps);

            if (stateSortingMode == "appNameDesc"){
                array = array.sort((a, b) => {
					let textA = a.Name.toLowerCase();
                    let textB = b.Name.toLowerCase();
                    if (textB < textA) //sort string ascending
                        return -1
                    if (textB > textA)
                        return 1
                    return 0
                });
            }
            else if (stateSortingMode == "appNameAsc"){
                array = array.sort((a, b) => {
					let textA = a.Name.toLowerCase();
                    let textB = b.Name.toLowerCase();
                    if (textA < textB) //sort string ascending
                        return -1
                    if (textA > textB)
                        return 1
                    return 0
                });
            }

            array.map((appData, key) => {

				if (stateSearchText.length == 0){
					appRowsArray.push(
                        <AccountExternalAppTableRow 
                            key={key} 
                            data={appData} 
                            mdmAccountApps={accountAppsStore.mdmAccountApps}
                            onToggle={onRowClicked} ></AccountExternalAppTableRow>
                    );
				}
				else {
					let text = stateSearchText.toLocaleLowerCase();
					if (
						(appData.Name.toLowerCase().indexOf(text) >= 0)
					){
						appRowsArray.push(
                            <AccountExternalAppTableRow 
                                key={key} 
                                data={appData} 
                                mdmAccountApps={accountAppsStore.mdmAccountApps}
                                onToggle={onRowClicked} ></AccountExternalAppTableRow>
                        );
					}
				}
            });

            return (
                <tbody >
                    {appRowsArray}
                </tbody>
            );
        }
        return(
            <tbody class="center-align ">
                <tr style={{borderBottomStyle:"none"}}>
                    <td colSpan="7" class="center-align ">
                        <br/><br/><br/>
                        <span class="nucleus-table-header-medium gray"> {`There are no External Apps`} </span>
                        <br/><br/><br/>
                    </td>
                </tr>
            </tbody>
        );
    }

    return(
        <div class="nucleus-tools-container">
            {/* <div class="nucleus-all-scrollable-page"> */}

                <div style={{}}>
                    <div class="row a-bit-lower left-align">
                        <div class="col m8 no-margin">
                            <span class="nucleus-tools-page-text-title a-bit-lower">
                                Search &nbsp;&nbsp;&nbsp;
                            </span>
                            <input
                                type="text"
                                class="nucleus-input-form-small-left"
                                style={{width:500}}
                                onChange={onSearchTextChange}
                                placeholder={"Search for an App"}
                            />
                            &nbsp;&nbsp;&nbsp;
                                <a  class="txt-white nucleus-font-small nucleus-submit-btn-small"
                                    onClick={()=>{refreshReport();}}> Refresh
                                </a>
                        </div>

                        <div class="col m4 no-margin left-align">
                            {
                                (!accountAppsStore.mdmAccountApps) && 
                                <a  class={"txt-white nucleus-font-small " + stateBtnAllAppsStyle }
                                    onClick={handleEnableAllApps}>
                                    Enable all apps
                                </a>
                            }
                            &nbsp;&nbsp;&nbsp;
                            {
                                (!accountAppsStore.mdmAccountApps) && 
                                <a  class={"txt-white nucleus-font-small " + stateBtnAllAppsStyle }
                                    onClick={handleDisableAllApps}>
                                    Disable all apps
                                </a>
                            }
                            {/* {
                                (stateLoading) &&
                                <div style={{margin:0, padding:0, marginTop:-16, marginBottom:0}}>
                                    <Spinner color="#2096F3" size={32} speed={0.5} animating={true} />
                                </div>
                            } */}
                        </div>
                    </div>
                </div>


                <div class="assignments-table-pending" >
                    {
                        (accountAppsStore.mdmAccountApps) &&
                        <div class="row left-align">
                            <span class="nucleus-table-header-medium gray"> {`Apps set by the Nucleus MDM`} </span>
                        </div>
                    }
                <style>
                    {`
                        .nucleus-table-external-apps{
                            width: 600px !important;
                        }
                    `}
                </style>
                    <table class="bordered highlight nucleus-table nucleus-table-external-apps" style={{tableLayout:"fixed"}}>
                        <thead>
                            <tr>
                                <th class="table-col-80">
                                    <span class="nucleus-table-header-medium">{"External App"}</span>
                                    <a class={ "nucleus-font-small " + stateSortingStyles.appNameDesc } onClick={()=>handleSortingMode("appNameAsc","appNameDesc")}> &nbsp;▼</a>
                                    <a class={ "nucleus-font-small " + stateSortingStyles.appNameAsc  } onClick={()=>handleSortingMode("appNameDesc","appNameAsc")} >&nbsp;▲</a>
                                </th>
                                <th class="table-col-20">
                                    {
                                        (!accountAppsStore.mdmAccountApps) &&
                                        <span class="nucleus-table-header-medium">{"Make Available"}</span>
                                    }
                                </th>
                            </tr>
                        </thead>
                        {
                            (!stateLoading) &&
                            getTableRows()
                        }

                        {
                            (stateLoading) &&
                            <td class="nucleus-row center-align" rowSpan={3} colSpan={5}>
                                <div style={{marginTop:120}}>
                                    <SpinnerCircular
                                        color="#2096F3"
                                        secondaryColor='rgba(0,0,0,0.16)'
                                        size='50'
                                        thickness='100'
                                        />
                                </div>
                            </td>
                        }
                    </table>
                </div>

                <div id="modalConfirmConfig" class="modal modalScrollableMedium">
                    <div class="modal-content nucleus-modal-wrapper2 nucleus-modal-content txt-special-color">
                        <img
                            class="responsive-img img-close-modal"
                            src="img/close_modal.png"
                            onClick={()=>{
                                window.$('#modalConfirmConfig').modal('close');
                            }}
                        />
                        <h3 class="nucleus-page-subtitle">{"Make App Available"}</h3>
                        <br/><br/>
                        <div class="row">
                            <span class="nucleus-labels"> Do you want to make available {stateSelectedAppName} for this Account? </span>
                        </div>
                        <div class="input-field col s12 modalCallLogContent2">
                            <br/>
                            <div class="row no-margin">
                                <div class="col s12 center-align no-margin">
                                    <p></p>
                                    <span class=" modalSeparator"> <label> &nbsp;</label></span>
                                    <a class={"txt-white nucleus-font " + stateSaveButtonStyle }
                                        onClick={()=>{ window.$('#modalConfirmConfig').modal('close');}}
                                    >
                                        Cancel
                                    </a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a class={"txt-white nucleus-font " + stateSaveButtonStyle } onClick={()=>{ saveAccountConfig()}}>
                                        Confirm
                                    </a>
                                    <div>&nbsp;</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div id="modalConfirmReset" class="modal modalScrollableMedium">
                    <div class="modal-content nucleus-modal-wrapper2 nucleus-modal-content txt-special-color">
                        <img
                            class="responsive-img img-close-modal"
                            src="img/close_modal.png"
                            onClick={()=>{
                                window.$('#modalConfirmReset').modal('close');
                            }}
                        />
                        <h3 class="nucleus-page-subtitle">{"Disable App"}</h3>
                        <br/><br/>
                        <div class="row">
                            <span class="nucleus-labels">
                                This will make '{stateSelectedAppName}' unavailable from this Account and it will remove the app for all {AuthStore.getPatientLabelPlural().toLowerCase()}.
                                <br/>
                                Are you sure you want to continue?
                            </span>
                        </div>
                        <div class="input-field col s12 modalCallLogContent2">
                            <br/>
                            <div class="row no-margin">
                                <div class="col s12 center-align no-margin">
                                    <p></p>
                                    <span class=" modalSeparator"> <label> &nbsp;</label></span>
                                    <a class={"txt-white nucleus-font " + stateSaveButtonStyle } onClick={()=>{ window.$('#modalConfirmReset').modal('close');}}>Cancel</a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a class={"txt-white nucleus-font " + stateSaveButtonStyle } onClick={()=>{ saveResetConfig()}}>Confirm</a>
                                    <div>&nbsp;</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            {/* </div> */}
        </div>
    );
}

export default AccountExternalApps;
