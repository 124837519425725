import { useEffect } from 'react';

import {
	setDevEnv,
    setTestEnv,
    setProdEnv,
		setLocalEnv
} from '@nucleus-care/nucleuscare-backend-client';
import {
	useDispatch
	//DefaultRootState
} from 'react-redux';

import api from './httpLegacyClient'
// type BackendClientParams = {
// 	CurrentDomain: string;
// };

const BackendClientLauncher = () => {

	const dispatch = useDispatch();

	useEffect(() => {
		console.log("BackendClientLauncher useEffect");
		//console.log("BackendClientLauncher CurrentDomain", props.CurrentDomain);
		console.log("BackendClientLauncher location ", window.location.hostname);

		switch(window.location.hostname){
			case "localhost":
				api.defaults.baseURL = 'https://care-dev.nucleuslife.io/ws'
				dispatch(
					setLocalEnv()
				);
				break;
			case "admin-dev.nucleuscare.com":
			case "admin-dev.nucleusportal.com":
				api.defaults.baseURL = 'https://care-dev.nucleuslife.io/ws'
				dispatch(
					setDevEnv()
				);
				break;
			case "admin-test.nucleuscare.com":
			case "admin-test.nucleusportal.com":
				api.defaults.baseURL = 'https://care-test.nucleuslife.io/ws'
				dispatch(
					setTestEnv()
				);
				break;
			default:
				dispatch(
					setProdEnv()
				);
				break;
		}
	}, []);

	return (
		null
	);

}


export default BackendClientLauncher;
