import React from 'react';
import ReactDOM from "react-dom";
import $ from "jquery";
import AuthStore from "../stores/AuthStore";
import CareAccountStore from "../stores/CareAccountStore";
import GeneralStore from "../stores/GeneralStore";

import AccountPatientsTimezoneRow from "../components/AccountPatientsTimezoneRow";

import Message from "../utils/Message";

import { IndexLink, Link } from "react-router-dom";

import { SpinnerCircular } from 'spinners-react';

class AccountPatients extends React.Component {
    constructor(props){
        super(props);

        this.state = {

            patientsTabArray : [],
            loading :false,
            results :false,
            emptyError:false,
            searchText: "",

            sortPatientDesc : ' nucleus-icon-inactive hide ',
            sortPatientAsc : ' nucleus-icon-inactive ',

            modalBtnStyle: 'nucleus-submit-btn',

            selectedPatientID: null,
            selectedPatientName: null,
            selectedPatientTimezoneOffset:null,
            selectedPatientTimezoneString:null,

            modalLoadingText : ""

            // accountScheduleModified : (GeneralStore.getDefaultScheduleModified().toString() == "true"),
            // accountScheduleUpdateDefaultStyle : 'disabled', // (GeneralStore.getDefaultScheduleModified().toString() == "true") ? '' : 'disabled',

            // newScheduleButtonsStyle : '',

            // selectedScheduleID : '',
            // editScheduleButtonsStyle : '',

            // selectedScheduleData : '',
            // deleteScheduleBtnStyle : 'nucleus-submit-btn',

            // newScheduleItemError : ' hide',
            // editScheduleItemError : ' hide',

            // updateDefaultScheduleBtnStyle : 'nucleus-submit-btn',

        };

        this.sortedArray1 = [];
        this.sortedArray2 = [];

        this.onGetAccountPatientsAction = this.onGetAccountPatientsAction.bind(this);
        this.handleSortPatientAsc = this.handleSortPatientAsc.bind(this);
        this.handleSortPatientDesc = this.handleSortPatientDesc.bind(this);
        this.resetSortIconsState = this.resetSortIconsState.bind(this);
        this.onRowClicked = this.onRowClicked.bind(this);

        // Timezones changes https://www.timeanddate.com/time/change/usa
        this.isDST = false;
        this.dstStartDate = null;
        this.dstEndDate = null;

        this.StandardTimezones = [
			{ valueString : "+1", name : "(GMT+01) Central European Time - Stockholm", shortName: "CET", selected:false},
			{ valueString : "+2", name : "(GMT+02) Israel Standard Time - Jerusalem",  shortName: "IST", selected:false},
			{ valueString : "-5", name : "(GMT-05) Eastern Standard Time - New York",  shortName: "EST", selected:false},
			{ valueString : "-6", name : "(GMT-06) Central Standard Time - Chicago",   shortName: "CST", selected:false},
			{ valueString : "-7", name : "(GMT-07) Mountain Standard Time - Salt Lake City", shortName: "MST", selected:false},
			{ valueString : "-8", name : "(GMT-08) Pacific Standard Time - Los Angeles", shortName: "PST", selected:false},
			{ valueString : "-9", name : "(GMT-09) Alaska Standard Time - Anchorage", shortName: "AKST", selected:false},
			{ valueString : "-10", name : "(GMT-10) Hawaii Standard Time - Honolulu", shortName: "HST", selected:false}
		];

		this.DaylightTimezones = [
			{ valueString : "+2", name : "(GMT+02) Central European Summer Time - Stockholm", shortName: "CEST", selected:false},
			{ valueString : "+3", name : "(GMT+03) Israel Daylight Time - Jerusalem", shortName: "IDT", selected:false},
			{ valueString : "-4", name : "(GMT-04) Eastern Daylight Time - New York", shortName: "EDT", selected:false},
			{ valueString : "-5", name : "(GMT-05) Central Daylight Time - Chicago", shortName: "CDT", selected:false},
			{ valueString : "-6", name : "(GMT-06) Mountain Daylight Time - Salt Lake City", shortName: "MDT", selected:false},
			{ valueString : "-7", name : "(GMT-07) Pacific Daylight Time - Los Angeles", shortName: "PDT", selected:false},
			{ valueString : "-8", name : "(GMT-08) Alaska Daylight Time - Anchorage", shortName: "AKDT", selected:false},
			{ valueString : "-9", name : "(GMT-09) Hawaii Daylight Time - Honolulu", shortName: "HDT", selected:false}
		];

        this.StandardTimezonesKeys = {
			"+1": "CET",
			"+2": "IST",
			"-5": "EST",
			"-6": "CST",
			"-7": "MST",
			"-8": "PST",
			"-9": "AKST",
			"-10": "HST"
		};

		this.DaylightTimezonesKeys = {
			"+2": "CEST",
			"+3": "IDT",
			"-4": "EDT",
			"-5": "CDT",
			"-6": "MDT",
			"-7": "PDT",
			"-8": "AKDT",
			"-9": "HDT"
        };

        this.filterTimezonesArray = [];
        this.messageFilterTimezonesArray = [];
        this.selectTimeZoneRef = React.createRef();
    }

    componentDidMount(){

        console.log("Patient componentDidMount", this.props.accountId);

        //Register Listener
        CareAccountStore.on("onGetAccountPatients",this.onGetAccountPatientsAction);
        //CarePatientStore.on("onUpdatePatientTimeZone",this.onUpdatePatientTimeZoneAction);

        this.setState({
            loading : true
        });
        CareAccountStore.getAccountPatients({
            UserID : AuthStore.getCsUserID(),
            Token : AuthStore.getCsUserToken(),
            AccountID : this.props.accountId
        });

        this.getDSTDates();
        window.$('.modal').modal({
            dismissible: false,
            complete: () => {  } // Callback for Modal close
        });
    }

    componentWillUnmount(){
        //Remove Listener
        CareAccountStore.removeListener("onGetAccountPatients",this.onGetAccountPatientsAction);
       // CarePatientStore.removeListener("onUpdatePatientTimeZone",this.onUpdatePatientTimeZoneAction);
    }

    getDSTDates=()=>{
        console.log("PatientTimezones getDSTDates");
		let currentDate = new Date();
        let currentYear = currentDate.getFullYear();

        // https://gist.github.com/danalloway/17b48fddab9028432c68

        // DST Start
        let firstOfMarch = new Date(currentYear, 2, 1);
        let daysUntilFirstSundayInMarch = (7 - firstOfMarch.getDay()) % 7;
        let secondSundayInMarch = firstOfMarch.getDate() + daysUntilFirstSundayInMarch + 7;
        this.dstStartDate = new Date(currentYear, 2, secondSundayInMarch);

        // DST End
        let firstOfNovember = new Date(currentYear, 10, 1);
        let daysUntilFirstSundayInNov = (7 - firstOfNovember.getDay()) % 7;
        let firstSundayInNovember = firstOfNovember.getDate() + daysUntilFirstSundayInNov;
        this.dstEndDate = new Date(currentYear, 10, firstSundayInNovember);

        //console.log("DST Start", this.dstStartDate);
        //console.log("DST End", this.dstEndDate);

        let isDST = false;
		let today = new Date();
		if (this.dstStartDate != null && this.dstEndDate != null && today >= this.dstStartDate && today <= this.dstEndDate){
			isDST = true;
            this.isDST = true;
		}
        console.log("getIsDST =>", isDST);
        this.filterTimezonesArray = isDST ? this.DaylightTimezones : this.StandardTimezones;


	}

    onGetAccountPatientsAction(response) {
		console.log("onGetAllPatientsData");
		console.log(response);

		if (response.ok) {

            this.state.patientsTabArray = [];

            this.sortedArray1 =  [].concat(response.Data);
            this.sortedArray1 =  this.sortedArray1.sort((a, b) => {
                let textA = a.FirstName ? a.FirstName.toLowerCase() : "";
                let textB = b.FirstName ? b.FirstName.toLowerCase() : "";
                if (textB < textA) //sort string ascending
                    return -1
                if (textB > textA)
                    return 1
                return 0
            });

            this.sortedArray2 = [].concat(response.Data);
            this.sortedArray2 = this.sortedArray2.sort((a, b) => {
                let textA = a.FirstName ? a.FirstName.toLowerCase() : "";
                let textB = b.FirstName ? b.FirstName.toLowerCase() : "";
                if (textA < textB) //sort string ascending
                    return -1
                if (textA > textB)
                    return 1
                return 0
            });

            this.setState({
				patientsTabArray: this.sortedArray2,
                loading: false,
                sortPatientAsc  : ' nucleus-icon-active ',
                sortPatientDesc : ' nucleus-icon-inactive hide '
			});

		} else {
			Message.show("Error getting account patients, please try again.");
		}
	}

    handleSortPatientDesc(){
        console.log("handleSortPatientDesc");
        this.state.patientsTabArray = [];

        this.resetSortIconsState();

        this.setState({
            sortPatientAsc : ' nucleus-icon-inactive hide ',
            sortPatientDesc : ' nucleus-icon-active ',
            patientsTabArray : this.sortedArray1
        });
    }

    handleSortPatientAsc(){
        console.log("handleSortPatientAsc");
        this.state.patientsTabArray = [];
        this.resetSortIconsState();
        this.setState({
            sortPatientAsc : ' nucleus-icon-active ',
            sortPatientDesc : ' nucleus-icon-inactive hide ',
            patientsTabArray : this.sortedArray2
        });
    }

    resetSortIconsState(){
        console.log("resetSortIconsState");

        this.setState({

            sortPatientAsc : ' nucleus-icon-inactive ',
            sortPatientDesc : ' nucleus-icon-inactive hide '
        });
    }

    onRowClicked = (patientData)=>{
        console.log("onRowClicked patientData", patientData);

        this.setState({
            selectedPatientID: patientData.ID,
            selectedPatientName: patientData.PatientName,
            selectedPatientTimezoneOffset:patientData.TimezoneOffset,
            selectedPatientTimezoneString:patientData.TimezoneString
        });
        window.$("#modalPatientTimezone").modal("open");

        if (patientData.TimezoneOffset != null ){
            let defaultTimezone = patientData.TimezoneOffset.toString();
            console.log("TIMEZONE FORMAT 1 :", defaultTimezone);
            this.selectTimeZoneRef.current.value = defaultTimezone;
        }
        else{
            let today = new Date();
            let currentTimezone = (today.getTimezoneOffset() / 60) * -1;

            let formatedTimezone = "" + currentTimezone;
            if (currentTimezone > 0) {
                formatedTimezone = "+" + currentTimezone;
            }
            console.log("TIMEZONE FORMAT 2 :", formatedTimezone);
            this.selectTimeZoneRef.current.value = formatedTimezone;
        }
    }

    handleEditClicked = (onClick, data) => {
		//window.location.assign("#/patientProfile/" + this.props.PatientID + "/null" );
		//console.log("handleRowClicked", this.props.data);
		onClick && onClick(data);
	}

    handlePatientsRowClick = (PatientID) => {
        CareAccountStore.setFamilyMemberAccountId(this.props.accountId);
        window.location.assign("#/accountUserPatientProfile/" + PatientID + "/" + this.props.accountId );
        console.log("clicked row")
    }

    getTableRows = ()=>{
        console.log("render getTableRows>");

        let patientRowArray = [];
        if (this.state.patientsTabArray && this.state.patientsTabArray.length > 0){

            let array = [].concat(this.state.patientsTabArray);
            //let arrayToExport = [];
            //let deviceData = {};
            array.map((patient, key) => {
                //deviceData = Object.assign({},device);
                patientRowArray.push(
                    <AccountPatientsTimezoneRow key={key} data={patient} onClick={this.onRowClicked} editOnClick={this.handleEditClicked} handlePatientsRowClick={this.handlePatientsRowClick} type="patients" />
                );
                //arrayToExport.push(deviceData);
            });
            return (
                <tbody class="tbody-ss">
                    {patientRowArray}
                </tbody>
            );
        }
        return(
            <tbody class="center-align">
                <tr style={{borderBottomStyle:"none"}}>
                    <td colSpan="6" class="center-align ">
                        <br/><br/><br/>
                        <span class="nucleus-table-header-medium text-gray"> {`There are no ${this.props.patientsLabel} for this account`} </span>
                        <br/><br/><br/>
                    </td>
                </tr>
            </tbody>
        );
    }


    render()
    {
        let tableResultsView = ' hide';
		let tableLoadingView = '';
		if (this.state.loading){
			tableResultsView = ' hide';
			tableLoadingView = '';
		} else {
			tableResultsView = '';
			tableLoadingView = 'hide';
		}

        return (
            <div class="">
                <table class="bordered highlight nucleus-table">
                    <thead class="thead-ss">
                        <tr>
                            <th class="table-col-35 left-align">
                                <span class="nucleus-table-header-medium">Name</span>
                                <a class={ "nucleus-font-medium " + this.state.sortPatientAsc } onClick={this.handleSortPatientDesc} > &nbsp;▲</a>
                                <a class={ "nucleus-font-medium " + this.state.sortPatientDesc }  onClick={this.handleSortPatientAsc} >&nbsp;▼</a>
                            </th>
                            <th class="table-col-20 center-align">
                                <span class="nucleus-table-header-medium">Telephone</span>
                                {/* <a class={ "nucleus-font-medium " + this.state.sortDeviceNameAsc } onClick={this.handleSortDeviceNameDesc} > &nbsp;▲</a>
                                <a class={ "nucleus-font-medium " + this.state.sortDeviceNameDesc }  onClick={this.handleSortDeviceNameAsc} >&nbsp;▼</a> */}
                            </th>

                            <th class="table-col-35 center-align">
                                <span class="nucleus-table-header-medium">Device Count</span>
                                {/* <a class={ "nucleus-font-medium " + this.state.sortVersionAsc } onClick={this.handleSortVersionDesc} > &nbsp;▲</a>
                                <a class={ "nucleus-font-medium " + this.state.sortVersionDesc }  onClick={this.handleSortVersionAsc} >&nbsp;▼</a> */}
                            </th>
                            <th class="table-col-35 center-align">
                                <span class="nucleus-table-header-medium">Contacts</span>
                                {/* <a class={ "nucleus-font-medium " + this.state.sortVersionAsc } onClick={this.handleSortVersionDesc} > &nbsp;▲</a>
                                <a class={ "nucleus-font-medium " + this.state.sortVersionDesc }  onClick={this.handleSortVersionAsc} >&nbsp;▼</a> */}
                            </th>
                            <th class="table-col-35 center-align">
                                <span class="nucleus-table-header-medium">Memory Box Items</span>
                                {/* <a class={ "nucleus-font-medium " + this.state.sortVersionAsc } onClick={this.handleSortVersionDesc} > &nbsp;▲</a>
                                <a class={ "nucleus-font-medium " + this.state.sortVersionDesc }  onClick={this.handleSortVersionAsc} >&nbsp;▼</a> */}
                            </th>
                            <th class="table-col-35 center-align">
                                <span class="nucleus-table-header-medium">Status</span>
                                {/* <a class={ "nucleus-font-medium " + this.state.sortVersionAsc } onClick={this.handleSortVersionDesc} > &nbsp;▲</a>
                                <a class={ "nucleus-font-medium " + this.state.sortVersionDesc }  onClick={this.handleSortVersionAsc} >&nbsp;▼</a> */}
                            </th>
                        </tr>
                    </thead>
                    {
                        this.getTableRows()
                    }
                </table>
                <div class={"row center-align " + tableLoadingView}>
                    <br />
                    <br />
                    <br />
                    <SpinnerCircular
                        color="#2096F3"
                        secondaryColor='rgba(0,0,0,0.16)'
                        size='50'
                        thickness='100'
                        />

                    <br />
                    <br />
                    <br />
                </div>

                <div class="fixed-action-btn btn-add-new">
                    <Link id={'btn-new-patient'} to={ "/newPatient/" + this.props.accountId }>
                        <span
                            class="btn-floating btn-large waves-effect waves-light orange tooltipped "
                            data-position="left"
                            data-delay="50"
                            data-tooltip={"New " + AuthStore.getPatientLabel()}
                        >
                            <i class="material-icons">add</i>
                        </span>
                    </Link>
                    &nbsp;
                </div>
            </div>
        )
    }

}
export default AccountPatients;
