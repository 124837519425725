import React from 'react';
import ReactDOM from "react-dom";



import Message from "../utils/Message";
import CareAccountStore from "../stores/CareAccountStore";

const AccountPatientsRow = ({type, data, editOnClick, handlePatientsRowClick}) => {

	let deviceStatusIcon = "img/icon_no_device.png";
	let deviceStatus = "No devices assigned to the patient";

	if (data.TotalDevices > 0) {
		if (data.OnlineDevices == data.TotalDevices){
		  deviceStatusIcon = "img/icon_device_active.png";
		  deviceStatus = "All devices are online";
		}
		else if (data.OnlineDevices == 0){
			deviceStatusIcon = "img/icon_device_inactive.png";
			deviceStatus = "All devices are offline";
		}
		else{
			deviceStatusIcon = "img/icon_device_semi_active.png";
			deviceStatus = "There are some devices offline";
		}
	  }
	return (
		<React.Fragment>
			{
				type === 'patients'
					?
						<tr className="nucleus-row" onClick={() => handlePatientsRowClick(data.PatientID)}>
							<td class="table-col-35 left-align">
								{ (data && data.FirstName) ? data.FirstName + " " + data.LastName : " - "}
							</td>
							<td class="table-col-20 center-align">
								{ (data && data.Telephone) ? data.Telephone : " - "}
							</td>
							<td
								style={{
									textAlign: "center",
								}}
								class="nucleus-row"
								>
								{data.TotalDevices}
							</td>
							<td class="nucleus-row center-align">
								{data.FamilyMembersActive + " / " + data.FamilyMembers}
							</td>
							<td class="nucleus-row center-align">
								{data.MemoryBoxItems}
							</td>
							<td class="center-align">
								<img
									class=" tooltipped "
									src={deviceStatusIcon}
									data-position="left"
									data-delay="50"
									data-tooltip={deviceStatus}
								/>
							</td>
						</tr>
					:
						<tr>		
							<td class="table-col-35 left-align">
								{ (data && data.PatientName) ? data.PatientName : " - "}
							</td>
							<td class="table-col-20 center-align">
								{ (data && data.TimezoneOffset) ? data.TimezoneOffset : " - "}
							</td>
							<td class="table-col-35 center-align">
								{ (data && data.TimezoneString) ? data.TimezoneString : " - "}
							</td>
							<td class="table-col-10 nucleus-row center-align">
								<span class="nucleus-table-icon nucleus-link" onClick={() => editOnClick(data)}>
									<i class="Tiny material-icons">edit</i>
								</span>
							</td>
					    </tr>

			}
		</React.Fragment>
	);

}

export default AccountPatientsRow;
