import React from 'react';
import ReactDOM from "react-dom";
import { IndexLink, Link } from "react-router-dom";
import Message from "../utils/Message";


import { useState, useEffect, useRef } from 'react';

import NucleuscareConnectData,
{
	RootState,
	getAllCsUsers,
    updateCsUser,
	csUsersSliceClean,
} from '@nucleus-care/nucleuscare-backend-client';

import {
	useSelector,
	useDispatch
	//DefaultRootState 
} from 'react-redux';

import Spinner from 'react-activity/dist/Spinner';

import DayjsUtils from '@date-io/dayjs';
import MomentUtils from '@date-io/moment';
import { 
	DateTimePicker, 
	KeyboardDateTimePicker, 
	MuiPickersUtilsProvider 
} from "@material-ui/pickers";

import moment from 'moment';

import AuthStore from "../stores/AuthStore";


const NewCsUser = () => {

    const csUsersStore = useSelector((state: RootState) => {
		//console.log("ReportCsUsers useSelector", state);
		return state.csUsersStore.data;
	});

    const [stateLoading, setStateLoading] = useState(false);

    const dispatch = useDispatch();

    const [stateFirstName, setStateFirstName] = useState("");
    const [stateLastName, setStateLastName] = useState("");
    const [stateEmail, setStateEmail] = useState("");
    const [stateTelephone, setStateTelephone] = useState("");
    const [statePassword, setStatePassword] = useState("");

    const [stateInputSecret, setStateInputSecret] =  useState(true);
    const [stateEditUserAllowed, setStateEditUserAllowed] = useState(false);
    const [stateEditUserBtnStyle, setStateEditUserBtnStyle] = useState("nucleus-submit-btn-disabled");

    const [stateErrorEmail, setStateErrorEmail] = useState(null);
    const [stateErrorTelephone, setStateErrorTelephone] = useState(null);
    const [stateErrorPassword, setStateErrorPassword] = useState(null);

    useEffect(() => {
		console.log("CsUsers useEffect csUsersStore");
		console.log("csUsersStore", csUsersStore);

		if (!csUsersStore.loading){
			setStateLoading(false);
			if (csUsersStore.complete){
				//Message.show("Report updated");
			}
		} else if (csUsersStore.error){
			Message.show("Error in CsUsers");
			
		}
        dispatch(
            csUsersSliceClean()
        );
	}, [csUsersStore.loading, csUsersStore.complete]);

    const onSwitchPasswordView = ()=>{
        setStateInputSecret(!stateInputSecret);
    }

    const validateData = ()=>{
            if (stateFirstName.length > 0 && 
                stateLastName.length > 0 &&
                stateEmail.length > 0 &&
                statePassword.length > 0){
                
                    setStateEditUserBtnStyle("nucleus-submit-btn");
            }
            else{
                setStateEditUserBtnStyle("nucleus-submit-btn-disabled");
            }
    }

    const onSaveUserClick = ()=>{
        console.log("onSaveUserClick");
       
        // console.log("stateFirstName", stateFirstName);
        // console.log("stateLastName", stateLastName);
        // console.log("stateEmail", stateEmail);
        // console.log("stateTelephone", stateTelephone);
        // console.log("statePassword", statePassword);

        let formValid = true;

        if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/.test(stateEmail) ){
            //Message.show('Ups! The email is not valid.');
            setStateErrorEmail("Invalid Email");
            formValid = false;
        }

        if (stateTelephone.length > 0 ){

            let telephone = stateTelephone;
            telephone = telephone.replace(/\(/g, "");
            telephone = telephone.replace(/\)/g, "");
            telephone = telephone.replace(/-/g, "");
            telephone = telephone.replace(/ /g, "");

            telephone.replace(/\s/g, '');
            console.log("Le nombre est:", telephone, telephone.length);

            if (telephone.length > 0 && telephone.length != 10){
                setStateErrorTelephone('Telephone must be 10 digits');
                formValid = false;
            } 
            // let containsLetter = /[a-zA-Z]/.test(this.txtNewUserTelephone);
            // if (containsLetter){
            //     this.setState({
            //         errorEmailVisibility : 'hide',
            //         errorTelephoneVisibility : '',
            //         errorTelephoneText : 'Invalid Telephone.'
            //     });
            //     return;
            // }
        }

        if (statePassword.length > 0 && statePassword.length < 8){
            setStateErrorPassword('Password must be at least 8 digits');
            formValid = false;
        } 

        if (formValid){
            setStateEditUserBtnStyle("nucleus-submit-btn-disabled");
            AuthStore.createNewCsUser(
                {
                    FirstName: stateFirstName,
                    LastName: stateLastName,
                    Email: stateEmail,
                    Telephone: stateTelephone,
                    Password: statePassword
                },
                (response)=>{
                    console.log("New CS User -> AuthStore.createNewCsUser ->", response);
                    if (response.ok){
                        Message.show("User created");
                        window.location.assign("#/csUsers");
                    }
                    else{
                        setStateEditUserBtnStyle("nucleus-submit-btn");
                        if (response.error){
                            Message.show(response.error);
                        }
                        else{
                            Message.show("Error creating user!");
                        }
                    }
                }
            );
            // dispatch(
            //     updateCsUser({
            //         userId: stateSelectedUser.ID,
            //         firstName: stateSelectedUser.FirstName, 
            //         lastName: stateSelectedUser.LastName, 
            //         telephone: stateSelectedUser.Telephone
            //     })
            // );
        }
        
    }
    return (
        <div class="nucleus-hard-container">
            <div class="nucleus-all-scrollable-page">
                <p class="nucleus-page-title">New User</p>
                <br/>
                <div class={ "nucleus-user-form-pending nucleus-form-container "}>
                    <br/>
                    <div class="row">
                        <div class="col s2 m1 l1">
                            
                        </div>
                        <div class="col s7 m7 l6">
                            <div class="row">
                                <div class="col s4 m4 l5 valgin-wrapper left-align">
                                    <span class="nucleus-labels">First Name</span> <span class="nucleus-labels txt-red">*</span>
                                </div>
                                <div class="col s8 m8 l7">
                                    <input 
                                        type="text" 
                                        class="validate nucleus-input-form" 
                                        placeholder="First Name" 
                                        value={stateFirstName}
                                        onChange={(e)=>{
                                            //console.log("Value", e.target.value);
                                            setStateFirstName(e.target.value);
                                            validateData();
                                        }}/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s4 m4 l5 valgin-wrapper left-align">
                                    <span class="nucleus-labels">Last Name</span> <span class="nucleus-labels txt-red">*</span> 
                                </div>
                                <div class="col s8 m8 l7">
                                    <input 
                                        type="text" 
                                        class="validate nucleus-input-form" 
                                        placeholder="Last Name" 
                                        value={stateLastName}
                                        onChange={(e)=>{
                                            //console.log("Value", e.target.value);
                                            setStateLastName(e.target.value);
                                            validateData();
                                        }}/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s4 m4 l5 left-align">
                                    <span class="nucleus-labels"> Email</span> <span class="nucleus-labels txt-red">*</span> 
                                </div>
                                <div class="col s8 m8 l7">
                                    <input 
                                        type="email" 
                                        class="validate nucleus-input-form" 
                                        placeholder="Email" 
                                        value={stateEmail}
                                        onChange={(e)=>{
                                            //console.log("Value", e.target.value);
                                            setStateEmail(e.target.value);
                                            validateData();
                                            setStateErrorEmail("");
                                        }}
                                        autocomplete="new-password"
                                    />
                                    {
                                        (stateErrorEmail) &&
                                        <span class={ "nucleus-form-error center-align "} > {stateErrorEmail}</span>
                                    }
                                </div>
                            </div>

                            <div class="row">
                                <div class="col s4 m4 l5 left-align">
                                    <span class=" nucleus-labels"> Telephone</span> 
                                </div>
                                <div class="col s8 m8 l7">
                                    <input 
                                        type="text" 
                                        class="validate nucleus-input-form" 
                                        placeholder="201-555-0123" 
                                        value={stateTelephone}
                                        onChange={(e)=>{
                                            //console.log("Value", e.target.value);
                                            setStateTelephone( e.target.value);
                                            validateData();
                                            setStateErrorTelephone("");
                                        }}
                                        autocomplete="new-password"
                                        />
                                        {
                                            (stateErrorTelephone) &&
                                            <span class={ "nucleus-form-error center-align "} > {stateErrorTelephone}</span>
                                        }
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s4 m4 l5 left-align">
                                    <span class="nucleus-labels"> Password</span> <span class="nucleus-labels txt-red">*</span> 
                                </div>
                                <div class="col s8 m8 l7">
                                    <div style={{display:'flex'}}>
                                    <input 
                                        type={stateInputSecret ? "password" : "text"}
                                        class="validate nucleus-input-form" 
                                        placeholder="Password" 
                                        value={statePassword}
                                        onChange={(e)=>{
                                            //console.log("Value", e.target.value);
                                            setStatePassword(e.target.value);
                                            validateData();
                                            setStateErrorPassword("");
                                        }}
                                        autocomplete="new-password"
                                    />
                                     <img
                                        src="img/view_password.png"
                                        class="nucleus-link"
                                        style={{width:24, height:16, marginLeft:-24, marginTop:8}}
                                        onClick={onSwitchPasswordView}
                                        />
                                    </div>
                                    {
                                        (stateErrorPassword) &&
                                        <span class={ "nucleus-form-error center-align "} > {stateErrorPassword}</span>
                                    }
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s4 m4 l5 left-align">
                                </div>
                                <div class="col s8 m8 l7 center-align">
                                    <br/>
                                    <a class={"txt-white nucleus-font-small " + stateEditUserBtnStyle } onClick={onSaveUserClick}>Save</a>
                                </div>
                            </div>
                            
                        </div>
                        <div class="col s3 m4 l5">
                        </div>
                    </div>
                    
                </div>

            </div>
        </div>
    );
}


export default NewCsUser;