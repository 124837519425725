import React from 'react';
import ReactDOM from "react-dom";
import Dispatcher from "../Dispatcher";
import { IndexLink, Link } from "react-router-dom";
import classNames from "classnames";
import Message from "../utils/Message";
import CarePatientStore from "../stores/CarePatientStore";
import AuthStore from "../stores/AuthStore";

import IntlTelInput from 'react-intl-tel-input';
//import '../../../node_modules/react-intl-tel-input/dist/libphonenumber.js';
import 'react-intl-tel-input/dist/main.css';

import MomentUtils from '@date-io/moment';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import CompressUtil from './CompressUtil';

class PatientProfileEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clientImageFilePreviewURL: '',
            name: '',
            deviceMacValue: this.props.patientDeviceMac || '',
            errorTelephoneVisibility: 'hide',
            errorTelephoneText: 'Telephone already registered ',

            patientTelephoneValue: '',
            patientTelephoneNumber: '',

            txtPatientDate: (this.props.patientDateOfBirth && this.props.patientDateOfBirth != null) ? new Date(this.props.patientDateOfBirth) : null,

            wrongDateInfo: ""
        };
        this.submitUpdateCarePatientForm = this.submitUpdateCarePatientForm.bind(this);
        //this.handleUnassignDevice = this.handleUnassignDevice.bind(this);
        this.validateTxtInput = this.validateTxtInput.bind(this);
        this.onUpdatePatientResponseArrivedAction = this.onUpdatePatientResponseArrivedAction.bind(this);
        this.onZipCodeUpdatedAction = this.onZipCodeUpdatedAction.bind(this);

        this.txtNewPatientTelephone = this.props.patientTelephone || '';
        //console.log("PROP", this.props.patientTelephone);
        this.validateTxtTelephone = this.validateTxtTelephone.bind(this);
        this.selectedPatientImageFile = this.selectedPatientImageFile.bind(this)
        //this.txtPatientDateRef = React.createRef();

        this.dateOptions = { day: "2-digit", month: "2-digit", year: "numeric" };

        console.log("MAC, EDIT: ", this.state.deviceMacValue);
    }

    componentDidMount() {
        console.log("PROPS", this.props);

        CarePatientStore.on("onUpdatePatientResponseArrived", this.onUpdatePatientResponseArrivedAction);
        CarePatientStore.on("onZipCodeUpdated", this.onZipCodeUpdatedAction);
        this.setState({
            name: this.props.FirstName,
            macPanel: (this.props.patientDeviceMac == "No Device assigned") ? " hide " : "",
            txtPatientDate: (this.props.patientDateOfBirth && this.props.patientDateOfBirth != null) ? new Date(this.props.patientDateOfBirth) : null
        });
        this.txtNewPatientTelephone = this.props.patientTelephone || '';
        //console.log("PROP 2", this.props.patientTelephone);
    }

    componentWillReceiveProps(updatedProps) {
        //console.log("PatientProfileEdit componentWillReceiveProps", updatedProps);
        this.txtNewPatientTelephone = this.props.patientTelephone || '';
        //console.log("PROP 3", this.props.patientTelephone);
        this.setState({
            txtPatientDate: (updatedProps.patientDateOfBirth && updatedProps.patientDateOfBirth != null) ? new Date(updatedProps.patientDateOfBirth) : null
        });
    }

    componentWillUnmount() {
        CarePatientStore.removeListener("onUpdatePatientResponseArrived", this.onUpdatePatientResponseArrivedAction);
        CarePatientStore.removeListener("onZipCodeUpdated", this.onZipCodeUpdatedAction);
    }


    validateTxtInput() {
        if (this.txtNewPatientTelephone.length > 0) {
            this.setState({
                errorTelephoneVisibility: 'hide'
            });
        }
    }

    validateTxtTelephone(status, value, countryData, number, id) {
        //console.log("validateTxtTelephone", status, value, countryData, number, id);
        //console.log("validateTxtTelephone status ", status);
        //console.log("validateTxtTelephone VALUE ", value);
        //console.log("validateTxtTelephone NUMBER ", number);
        //console.log("validateTxtTelephone COUNTRY ", countryData);

        if (number) {
            this.txtNewPatientTelephone = number;
            console.log("txtNewPatientTelephone", this.txtNewPatientTelephone);
            if (value.length > 0) {
                this.setState({
                    errorTelephoneVisibility: 'hide'
                });
            }
            this.setState({
                patientTelephoneValue: value,
                patientTelephoneNumber: number
            });
        }
        else {
            this.txtNewPatientTelephone = "+" + value.dialCode + this.state.patientTelephoneValue;
        }
    };

    submitUpdateCarePatientForm() {

        console.log("submitUpdateCarePatientForm");

        if (this.refs.txtNewPatientFirstName.value === "") {
            Message.show('FirstName is required.');
            return;
        }

        if (this.refs.txtNewPatientLastName.value === "") {
            Message.show('LastName is required.');
            return;
        }

        // Telephone and Address Validation Deprecated for CARE-1366
        // if (this.refs.txtNewPatientTelephone.value === "") {
        //     Message.show('Telephone is required.');
        //     return;
        // }
        // if (this.refs.txtNewPatientAddress.value === "") {
        //     Message.show('Address is required.');
        //     return;
        // }


        if (this.txtNewPatientTelephone.length > 0) {

            this.txtNewPatientTelephone = this.txtNewPatientTelephone.replace(/\(/g, "");
            this.txtNewPatientTelephone = this.txtNewPatientTelephone.replace(/\)/g, "");
            this.txtNewPatientTelephone = this.txtNewPatientTelephone.replace(/-/g, "");
            this.txtNewPatientTelephone = this.txtNewPatientTelephone.replace(/ /g, "");

            let number = this.state.patientTelephoneValue;
            number = number.replace(/\(/g, "");
            number = number.replace(/\)/g, "");
            number = number.replace(/-/g, "");
            number = number.replace(/ /g, "");

            if (number.length == 0) {
                this.txtNewPatientTelephone = "";
            }
            else {
                if (number.length > 0 && number.length < 10) {
                    this.setState({
                        errorTelephoneVisibility: '',
                        errorTelephoneText: 'Telephone must be at least 10 digits'
                    });
                    return;
                }
                let containsLetter = /[a-zA-Z]/.test(this.txtNewPatientTelephone);
                if (containsLetter) {
                    this.setState({
                        errorTelephoneVisibility: '',
                        errorTelephoneText: 'Invalid Telephone.'
                    });
                    return;
                }

                const phoneNumberRegex = /^(?:\+\d{1,3})?\d{10,10}$/;   
                if (!phoneNumberRegex.test(this.txtNewPatientTelephone)) {
                this.setState({
                    errorTelephoneVisibility: '',
                        errorTelephoneText: 'Invalid Telephone.'
                });
                return;
                } 
            }
        }

        let patientDateParam = "";
        // if (this.state.txtPatientDate && this.state.txtPatientDate.toString() != "") {

        //     var regexGoodDate = /^(?:(0[1-9]|1[012])[\/.](0[1-9]|[12][0-9]|3[01])[\/.](19|20)[0-9]{2})$/;
        //     let stringDate = this.state.txtPatientDate.toLocaleDateString("en-US", this.dateOptions);
        //     let goodDate = regexGoodDate.test(stringDate);
        //     if (goodDate) {
        //         this.setState({
        //             wrongDateInfo: ""
        //         });
        //         patientDateParam = stringDate;
        //     }
        //     else {
        //         this.setState({
        //             wrongDateInfo: "Invalid Date Format. It should be MM/DD/YYYY"
        //         });
        //         return;
        //     }
        // }

        const updateAccountPatientData = {
            PatientID: this.props.patientId,
            FirstName: this.refs.txtNewPatientFirstName.value,
            LastName: this.refs.txtNewPatientLastName.value,
            Telephone: this.txtNewPatientTelephone,
            Address: this.refs.txtNewPatientAddress.value,
            //DateOfBirth: patientDateParam,
            //EmergencyContactName: this.refs.txtNewPatientContactName.value,
            //EmergencyContactTelephone: this.refs.txtNewPatientContactNumber.value,
            ZipCode: this.refs.txtNewPatientZipCode.value
            //ShowWeather : this.refs.checkShowWeather.checked
        };

        CarePatientStore.UpdatePatientData(updateAccountPatientData);

    }

    onUpdatePatientResponseArrivedAction(response) {
        console.log("onUpdatePatientResponseArrivedAction", response);

        if (!response.ok && response.telephoneRegistered) {
            this.setState({
                errorTelephoneVisibility: '',
                errorTelephoneText: response.error
            });
        }
        // CarePatientStore.updatePatientZipCode({
        //     PatientID : this.props.patientId,
        //     ZipCode : this.refs.txtNewPatientZipCode.value
        // });
    }

    onZipCodeUpdatedAction(response) {

        if (!response.ok) {
            Message.show("Error updating " + AuthStore.getPatientLabel() + " Zip Code");
        }
    }
    selectedPatientImageFile(e) {
        let files = e.target.files;
        if (files.length > 0) {
            console.log("selectedPatientImageFile", e);
            let value = files[0]
            CompressUtil(files[0]).then((data) => {
                console.log("data", data)
                let selectedImage = data;
                let reader = new FileReader();
                let url = reader.readAsDataURL(value);
                let urlImage = URL.createObjectURL(value);
                // reader.onloadend = function (e) {
                this.setState({
                    clientImageFilePreviewURL: urlImage,
                })
                // };
                console.log("LocalURL image:patient:", [reader.result]);
                console.log("LocalURL:", url); // Would see a path?
                console.log("selectedImage", selectedImage);
                this.setState({
                    uploadingPatientImageStatus: "Uploading...",
                });
                CarePatientStore.UploadPatientImage({
                    PatientID: this.props.patientId,
                    File: selectedImage
                });
            })

        } else {
            console.log("No File Selected");
        }
    }
    // DEPRECATED
    /*
    handleUnassignDevice(){
        console.log("handleUnassignDevice");

        CarePatientStore.unassignDeviceToPatient({
            PatientID : this.props.patientId
        });
    }
    */


    render() {

        let currentSection = classNames({
            hide: (this.props.currentMode != "Edit")
        });

        // console.log("PROPS2");
        // console.log(this.props);
        // console.log("PROPS2");

        let deviceMacAddress = "00:00:00:00:00";
        let deviceVisibility = " hide";
        let noDeviceVisibility = "";
        if (this.props.patientDeviceMac != "No Device assigned") {
            deviceMacAddress = this.props.patientDeviceMac;
            deviceVisibility = "";
            noDeviceVisibility = " hide";
        }

        //let showWeatherValue = this.props.patientShowWeather ? "checked" : "";

        return (
            <div class={"nucleus-patient-edit-pending nucleus-form-container " + currentSection}>

                <div class="row">
                    <div className="patient-edit-title-container">
                        <p className="nucleus-page-title">{this.props.patientFirstName + " " + this.props.patientLastName}</p>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <a id={'edit-patient'} class="nucleus-font-small nucleus-link mt-10" onClick={this.props.handleProfileMode}> 
                            Cancel
                        </a>
                    </div>
                    <div class="col s4 m3 l3">
                        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                            <style>
                                {
                                    `
                            .img-circle {
                                border-radius: 50%;
                            }
                            .img-circle {
                                border-radius: 0;
                            }
                            
                            .ratio {
                                background-position: center center;
                                background-repeat: no-repeat;
                                background-size: cover;
                            
                                height: 0;
                                padding-bottom: 100%;
                                position: relative;
                                width: 100%;
                            }
                            .img-circle {
                                border-radius: 50%;
                            }
                            .img-responsive {
                                display: block;
                                height: auto;
                                max-width: 100%;
                            }
                            .inputfile {
                                width: 0.1px;
                                height: 0.1px;
                                opacity: 0;
                                overflow: hidden;
                                position: absolute;
                                z-index: -1;
                            }
                            .inputfile + label {
                                margin-top:10px;
                                font-size: 1.25em;
                                // font-weight: 700;
                                text-decoration: underline;
                                color: gray;
                                // background-color: black;
                                display: inline-block;
                            }
                            
                            .inputfile:focus + label,
                            .inputfile + label:hover {
                                cursor: pointer;
                                // background-color: red;
                            }
                            .inputfile {
                                cursor: pointer;
                            }
                            `}</style>
                            <div style={{ width: "70%", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <img
                                    class=""
                                    style={{ width: 140, height: 140, borderRadius: 70, objectFit: 'cover' }}
                                    src={this.state.clientImageFilePreviewURL ? this.state.clientImageFilePreviewURL : this.props.patientImageThumb ? this.props.patientImageThumb : "img/user_placeholder.png"}
                                    ref={img => this.imgRef = img}
                                    onError={() => this.imgRef.src = 'img/user_placeholder.png'}
                                />
                                <div>
                                    <input
                                        onChange={(e) => {
                                            this.selectedPatientImageFile(e)
                                        }}
                                        multiple={false}
                                        type="file"
                                        name="file"
                                        id="file"
                                        class="inputfile"
                                    />
                                    <label for="file">Upload Photo</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col s8 m8 l6">
                        <div class="row">
                            <div class="col s4 valgin-wrapper">
                                <span class=" nucleus-labels">First Name</span> <span class="nucleus-labels txt-red">*</span>
                            </div>
                            <div class="col s8" key={this.props.patientFirstName}>
                                <input id={'edit-firstname'} ref="txtNewPatientFirstName" type="text" class="validate nucleus-input-form" defaultValue={this.props.patientFirstName} />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col s4 valgin-wrapper">
                                <span class=" nucleus-labels">Last Name</span> <span class="nucleus-labels txt-red">*</span>
                            </div>
                            <div class="col s8" key={this.props.patientLastName}>
                                <input id={'edit-lastname'} ref="txtNewPatientLastName" type="text" class="validate nucleus-input-form" defaultValue={this.props.patientLastName} />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col s4">
                                <span class=" nucleus-labels"> Telephone</span>
                            </div>
                            <div class="col s8" key={this.props.patientTelephone}>
                                {/*<input ref="txtNewPatientTelephone" type="text" class="validate nucleus-input-form" defaultValue={ this.props.patientTelephone} onChange={this.validateTxtInput}/>*/}
                                <IntlTelInput
                                    css={['intl-tel-input validate width100', 'nucleus-input-form width83']}
                                    utilsScript={'libphonenumber.js'}
                                    autoHideDialCode={true}
                                    separateDialCode={true}
                                    nationalMode={false}
                                    format={false}
                                    preferredCountries={['us']}
                                    defaultValue={this.props.patientTelephone || ''}
                                    onPhoneNumberBlur={this.validateTxtInput}
                                    onSelectFlag={this.validateTxtTelephone}
                                    onPhoneNumberChange={this.validateTxtTelephone} />
                                <div>
                                    <span class={"nucleus-form-error center-align " + this.state.errorTelephoneVisibility}>{this.state.errorTelephoneText}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col s4">
                                <span class=" nucleus-labels"> Address</span>
                            </div>
                            <div class="col s8" key={this.props.patientAddress}>
                                <input id={'edit-address'} ref="txtNewPatientAddress" type="text" class="validate nucleus-input-form" defaultValue={this.props.patientAddress || ''} />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col s4">
                                <span class=" nucleus-labels"> Zip Code </span>
                            </div>
                            <div class="col s8" key={this.props.patientZipCode}>
                                <input id={'edit-zipcode'} ref="txtNewPatientZipCode" type="text" class="validate nucleus-input-form" defaultValue={this.props.patientZipCode || ''} />
                            </div>
                        </div>
                        
                        {/* 
                        Removed on Feb 15, 2023
                        <div class="row">
                            <div class="col s4">
                                <span class=" nucleus-labels"> Date of Birth</span>
                            </div>
                            <div class="col s8">
                                
                                <div style={{ width: 190, display: 'inline-flex' }}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            //ref={this.txtPatientDateRef} 
                                            variant="dialog"
                                            class="browser-default"
                                            value={this.state.txtPatientDate}
                                            onChange={date => {
                                                this.setState({
                                                    txtPatientDate: date ? new Date(date) : date
                                                });
                                            }}
                                            autoOk={true}
                                            onError={console.log}
                                            emptyLabel="MM/DD/YYYY"
                                            //minDate={new Date("2018-01-01T00:00")}
                                            format={"MM/DD/YYYY"}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                {
                                    (this.state.wrongDateInfo != "") &&
                                    <span class={"nucleus-form-error center-align "} > {this.state.wrongDateInfo}</span>
                                }
                            </div>
                        </div>
                        <br />
                        <div class="row">
                            <div class="col s8">
                                <span class=" nucleus-labels"> Emergency Contact</span>
                            </div>
                            <div class="col s4">

                            </div>
                        </div>

                        <div class="row">
                            <div class="col s4">
                                <span class=" nucleus-labels"> Name</span>
                            </div>
                            <div class="col s8" key={this.props.patientContactName}>
                                <input ref="txtNewPatientContactName" type="text" class="validate nucleus-input-form" defaultValue={this.props.patientContactName || ''} />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col s4">
                                <span class=" nucleus-labels"> Telephone</span>
                            </div>
                            <div class="col s8" key={this.props.patientContactTelephone}>
                                <input ref="txtNewPatientContactNumber" type="text" class="validate nucleus-input-form" defaultValue={this.props.patientContactTelephone || ''} />
                            </div>
                        </div> 
                        */}

                        {/*
                        // Removed on Nov 2nd, 2017 - CARE-1343
                        <div class="row ">
                            <div class="col s4">
                                <span class=" nucleus-labels"> Show Weather</span> 
                            </div>
                            <div class="col s8">
                                <div class="switch">
                                    <label key={showWeatherValue}>
                                      <input type="checkbox" defaultChecked={showWeatherValue} ref="checkShowWeather"/>
                                      <span class="lever"></span>
                                    </label>
                                  </div>
                            </div>
                        </div>
                        { /*
                        <br/>
                        <div class={"row " + deviceVisibility}>
                            <div class="col s4">
                                <span class=" nucleus-labels"> Device </span> 
                            </div>
                            <div class="col s6">
                                <input ref="txtNewPatientDeviceMac" type="text" class="validate nucleus-input-form" disabled value={ deviceMacAddress }/>
                            </div>
                            <div class="col s2">
                                &nbsp;&nbsp;&nbsp;
                                <a class="nucleus-link nucleus-font-small" onClick={this.handleUnassignDevice}>Unassign</a>
                            </div>
                        </div>

                         <div class={ "row " + noDeviceVisibility }>
                            <div class="col s4">
                                <span class=" nucleus-labels"> Device </span> 
                            </div>
                            <div class="col s6">
                                 { "No Device assigned" }
                            </div>
                            <div class="col s2">
                            </div>
                        </div>
                        */ }
                        { /*
                        <br/><br/>
                        <div class="row">
                            <div class="col s4">
                            </div>
                            <div class="col s8 center-align">
                                <a class="txt-white nucleus-font-small nucleus-submit-btn " onClick={this.submitUpdateCarePatientForm}>Submit</a>
                            </div>
                        </div>
                        */ }

                    </div>
                    <div class="col s0 m1 l3">
                    </div>
                </div>

                <div class="fixed-action-btn btn-add-new">
                    <a id={'save-edit'} class="btn-floating btn-large waves-effect waves-light light-blue darken-4 tooltipped " data-position="left" data-tooltip={"Save " + AuthStore.getPatientLabel()} onClick={this.submitUpdateCarePatientForm}>
                        <i class="material-icons">save</i>
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>

            </div>

        );
    }
}

export default PatientProfileEdit;