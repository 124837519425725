import React, { useMemo } from 'react'
import { UIModal } from '../../../components/UI'
import styled from 'styled-components';
import { ModalAction } from '../../../components/UI/Modals/Base/ActionButtons';

const RemoveOrderModal = ({
    order,
    isOpen,
    onClose,
    onRemove,
}) => {
    const actions: ModalAction[] = useMemo(() => [
        {
            label: 'Cancel',
            onClick: onClose,
            buttonVariant: 'secondary',
        },
        {
            label: 'Proceed',
            onClick: onRemove,
            buttonVariant: 'primary',
        },
    ], [onRemove, onClose]);
    return (
        <UIModal
            isOpen={isOpen}
            close={onClose}
            actions={actions}
            size='large'
        >
            <Title>WARNING!</Title>

            <RowContainer>
                <Note>You are about to delete</Note>
            </RowContainer>
            <RowContainer>
                <LabelContainer>
                    <BoldNote>Order ID:</BoldNote>
                </LabelContainer>
                <ValueContainer>
                    <NotBoldNote>{order?.id}</NotBoldNote>
                </ValueContainer>
            </RowContainer>
            <RowContainer>
                <LabelContainer>
                    <BoldNote>Device Orderer:</BoldNote>
                </LabelContainer>
                <ValueContainer>
                    <NotBoldNote>{order?.deviceOrdererFirstName ?? ''}{' '}{order?.deviceOrdererLastName ?? ''}</NotBoldNote>
                </ValueContainer>
            </RowContainer>
            <RowContainer>
                <LabelContainer>
                    <BoldNote>Device Order Phone:</BoldNote>
                </LabelContainer>
                <ValueContainer>
                    <NotBoldNote>{order?.deviceOrdererPhoneNumber}</NotBoldNote>
                </ValueContainer>
            </RowContainer>
            <br />
            <Note>This action is permanent</Note>
            <Note>Would you like to proceed?</Note>
        </UIModal>
    )
}

export default RemoveOrderModal

const Title = styled.p`
  color: #f00;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  margin: 0;
`;
const LabelContainer = styled.div`
    display: flex;
    flex:1;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    margin-bottom: 10px;
`;
const ValueContainer = styled.div`
    display: flex;
    flex:1;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-bottom: 10px;
`;
const RowContainer = styled.div`
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 20px;
`;
const Note = styled.p`
  color: #0a313f;
  text-align: center;
  font-size: 16px;
`;

const BoldNote = styled.span`
    color: #0a313f;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
`;
const NotBoldNote = styled.span`
    color: #0a313f;
    font-size: 16px;
    margin-left: 5px;
`;
