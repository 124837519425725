import React from 'react';
import ReactDOM from "react-dom";
import Dispatcher from "../Dispatcher";
import { IndexLink, Link } from "react-router-dom";
import classNames from "classnames";

import _ from "underscore";

class DashboardInfo extends React.Component
{
    constructor(props)
    {
        super();
        this.state = {
            dateString : '',
            newsContent : 'Nucleus is excited to launch Nucleus Care, a new way for the home health industry to interact with their patients. Using simple, large touchscreen buttons patients can request a video call from a healthcare provide at any time. The Nucleus Care mobile app allows providers to respond quickly no matter where they are.\nWe hope to provide a magical experience for patients and providers alike. Let us know if you have any questions or feedback.'
        };
    }

    componentDidMount()
    {
        let newsDate = new Date();
        let monthNames = [ "January", "February", "March","April", "May", "June", "July", "August", "September", "October","November", "December" ];

        let day = newsDate.getDate();
        let monthIndex = newsDate.getMonth();

        let monthName = monthNames[monthIndex];
        let dayName ='';
        if (day == 1 || day == 21 || day == 31){
          dayName = "st";
        } else if (day == 2 || day == 22){
          dayName = "nd";
        } else if (day == 3 || day == 23){
          dayName = "rd";
        } else {
          dayName = "th";
        }

        this.setState({
            dateString : monthName + " " + day + dayName + ", " + newsDate.getFullYear()
        });
    }

    componentWillUnmount()
    {
        
    }


    render()
    {   
        
        return (
            <div class="nucleus-news-pending">
                <p class="nucleus-font-medium">{ this.state.dateString }</p>
                <div class="nucleus-font">
                    <span class="nucleus-font nucleus-font-medium">
                        { this.state.newsContent }
                    </span>
                    <br/><br/><br/>
                </div>
                <div class="nucleus-news-separator">
                    &nbsp;
                </div>
            </div>
               
        );
    }
}

export default DashboardInfo;
