import AuthStore from "../stores/AuthStore";
import Config from "../Config";
import $ from "jquery";
import api from './httpLegacyClient'

export const ControlSecret = "f33c6a40-bd07-11eb-8529-0242ac130003"
//httpClient.setAuthorization("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJRCI6IjI5MzM2NDc4LTVEMkEtNDdFOC04RTdBLTk5QUI1MkJFMUJGMyIsIkVtYWlsIjoidGVzdEBhbG9uc28uY29tIiwiaWF0IjoxNjIxMjg2NzY5LCJleHAiOjE2MjE4OTE1Njl9.ZLdKa0lkQtZkn-hjfJQaCo7uieHSZnroKcAYpZdZLqU", "Caregiver", ControlSecret)


class NucleusNetwork {
	constructor() {
		this.token = null
	}

	setToken(jwt) {
		this.token = jwt
	}
	getToken() {
		return this.token
	}

	request({ type, headers, url, callback, data = {}, context }) {
		//console.log(type + " - " + url);
		//console.log(data);
		//headers['Authorization'] = AuthStore.getToken();
		//console.log("Network.request ");

		let finalUrl = Config.domain;

		console.log("NucleusNetwork");
		if (url && url.includes(finalUrl)){
			url = "/" + url.replace(finalUrl, "");
		}
		// console.log("NucleusNetwork url", url);
		// console.log("NucleusNetwork finalUrl", finalUrl);
		// console.log("NucleusNetwork type", type.toLowerCase());
		// console.log("NucleusNetwork data", data);
		// //console.log("NucleusNetwork callback", callback);

		var params = new URLSearchParams(data);
		//console.log("NucleusNetwork formData", params);

		// All request to C# are type POST !!!!
		api.post(
			url, 
			params
		)
		.then((response) => {
			//console.log("NucleusNetwork Response for: " + url);
			//console.log("NucleusNetwork Response ", response );
			//console.log("NucleusNetwork Response Data", response.data );
			callback(response.data)
		})
		.catch((e) => {
			console.log("NucleusNetwork Error on ", url);
			console.log("NucleusNetwork Error ", e);

			// if (xmlhttprequest.status === 401) {
			// 	window.location.href = '/control'
			// }
			// try {
			// 	callback(JSON.parse(xmlhttprequest.responseText));
			// } catch (ex) {
			// 	console.log("Catch Error");
			// 	var catchErrorResponse = {
			// 		ok: false,
			// 		errorInternetConnection: true,
			// 		errorNucleusNetwork: message
			// 	};
			// 	callback(catchErrorResponse);
			// }

			callback({
				ok:false,
				...e
			})
		})

		// DEPRECATED Not JQuery anymore Aug 2nd 2023
		/*
		if (headers == null && this.token)
			headers = Object.assign({}, {
				Authorization: `Bearer ${this.token}`,
				"X-EntityType": "Control",
				"X-Secret": ControlSecret
			}, headers)

		$.ajax({
			type: type,
			context: context,
			url: url,
			data: data,
			headers: headers,
			success: (rsp) => {
				//console.log(rsp);
				callback(rsp);
				callback = null;
			},
			error: (xmlhttprequest, textstatus, message) => {

				// xmlhttprequest : objeto response
				// textstatus : status of the response
				// message : description
				console.log("ERROR");
				console.log("1", xmlhttprequest);
				//console.log("2", textstatus);
				//console.log("3", message);
				if (xmlhttprequest.status === 401) {
				window.location.href = '/control'
				}
				try {
					callback(JSON.parse(xmlhttprequest.responseText));
					callback = null;
				} catch (ex) {
					console.log("Catch Error");
					var catchErrorResponse = {
						ok: false,
						errorInternetConnection: true,
						errorNucleusNetwork: message
					};
					callback(catchErrorResponse);
					callback = null;
				}
			},
			timeout: 1000 * 60 * 20
		});
		*/
	}

	// DEPRECATED Not JQuery anymore Aug 2nd 2023
	/*
	requestWithFile({ type, headers, url, callback, data = {}, context }) {
		//headers['Authorization'] = AuthStore.getToken();

		if (headers == null && this.token)
			headers = Object.assign({}, {
				Authorization: `Bearer ${this.token}`,
				"X-EntityType": "Control",
				"X-Secret": ControlSecret
			}, headers)

		$.ajax({
			type: type,
			context: context,
			url: url,
			data: data,
			processData: false,
			contentType: false,
			headers: headers,
			success: (rsp) => {
				callback(rsp);
				callback = null;
			},
			error: (xmlhttprequest, textstatus, message) => {

				// xmlhttprequest : objeto response
				// textstatus : status of the response
				// message : description
				console.log("ERROR");
				console.log("1", xmlhttprequest);
				//console.log("2", textstatus);
				//console.log("3", message);
				try {
					callback(JSON.parse(xmlhttprequest.responseText));
					callback = null;
				} catch (ex) {
					console.log("Catch Error");
					var catchErrorResponse = {
						ok: false,
						errorInternetConnection: true,
						errorNucleusNetwork: message
					};
					callback(catchErrorResponse);
					callback = null;
				}
			},
			timeout: 1000 * 60 * 20
		});
	}
	*/

}

const nucleusNetwork = new NucleusNetwork();
export default nucleusNetwork;
