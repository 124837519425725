import { EventEmitter } from "events";
import { setTokenToHttpClients } from '../utils/get-token'
import { httpApi, setUser, resetUser } from '@nucleus-care/nucleuscare-backend-client'
import { getInstance } from '../utils/auth-client'
import AuthApi from "../apis/AuthApi";
import nucleusNetwork from "../utils/NucleusNetwork";

class AuthStore extends EventEmitter {
	constructor() {
		super();

		this.AuthApi = new AuthApi(this, getInstance());
		this.nucleusCsUserID = localStorage.nucleusCsUserID;
		this.nucleusCsUserToken = localStorage.nucleusCsUserToken;
		this.deletePatientsAccess = localStorage.deletePatientsAccess  === "true"  || false;
		this.nucleusCsUserEmail = localStorage.nucleusCsUserEmail;
		this.csSuperUser = (String(localStorage.csSuperUser) == "true") || false;
		//this.nucleusUserFirstName = localStorage.nucleusUserFirstName;
		// this.nucleusUserLastName = localStorage.nucleusUserLastName;
		// this.nucleusUserFullName = localStorage.nucleusUserFullName;
		this.nucleusAccountID = localStorage.nucleusAccountID;
		// this.nucleusUserRol = localStorage.nucleusUserRol;
		// this.nucleusOnDuty = localStorage.nucleusOnDuty || false;
		// this.nucleusProfileCreated = localStorage.nucleusProfileCreated || false;
		this.nucleusNewSession = localStorage.nucleusNewSession ;

		// this.nucleusCompanyName = localStorage.nucleusCompanyName;
		// this.nucleusCompanyLogo = localStorage.nucleusCompanyLogo;

		// this.nucleusTemporalPassword = localStorage.nucleusTemporalPassword || "";

		//console.warn(this);

		this.userLabel = "User";
		this.caregiverLabel = "Caregiver";
		this.patientLabel = "Patient";

		this.userLabelPlural = "Users";
		this.caregiverLabelPlural = "Caregivers";
		this.patientLabelPlural = "Patients";
		this.familyLabelPlural = localStorage.familyLabelPlural || "Family Members";
		this.familyLabel = localStorage.familyLabel || "Family Member";
		this.clientsAccess = localStorage.clientsAccess === "true"  || false;

		console.warn(this);
	}

	getCsUserID() {
		return this.nucleusCsUserID;
	}

	getCsUserToken(){
		return this.nucleusCsUserToken;
	}

	getCsUserEmail() {
		return this.nucleusCsUserEmail;
	}
	superUser() {
		//console.log("AuthStore this.csSuperUser", this.csSuperUser);
		return this.csSuperUser;
	}
	getUserLabel(){
		return this.userLabel;
	}
	getCaregiverLabel(){
		return this.caregiverLabel;
	}
	getPatientLabel(){
		return this.patientLabel;
	}
	getUserLabelPlural(){
		return this.userLabelPlural;
	}
	getFamilyLabel() {
		return this.familyLabel;
	}
	getFamilyLabelPlural() {
		return this.familyLabelPlural;
	}
	getCaregiverLabelPlural(){
		return this.caregiverLabelPlural;
	}
	getPatientLabelPlural(){
		return this.patientLabelPlural;
	}
	canAccessClients() {
		return this.clientsAccess;
	}

	canDeletePatients() {
		//console.log("canDeletePatients", (this.deletePatientsAccess.toString() == "true"));
		return this.deletePatientsAccess == "true";
	}

	// getUserFirstName() {
	// 	return this.nucleusUserFirstName;
	// }

	// getUserLastName() {
	// 	return this.nucleusUserLastName;
	// }

	// getUserFullName(){
	// 	return this.nucleusUserFullName;
	// }


	getUserAccountID() {
		// NOTE: This is not the accountID of a nucleus account!!!
	 	return this.nucleusAccountID;
	}

	// getCompanyName(){

	// 	if (this.nucleusCompanyName != undefined &&  this.nucleusCompanyName != 'undefined' && this.nucleusCompanyName != null){
	// 		return this.nucleusCompanyName + " - ";
	// 	} else {
	// 		return "";
	// 	}
	// }
	// getCompanyLogo(){
	// 	console.log("LOGO",this.nucleusCompanyLogo);
	// 	return this.nucleusCompanyLogo;
	// }

	// setIsAdmin(value){
	// 	this.nucleusUserRol = value ? 'admin' : 'user';
	// 	this.saveToLocalStorage();
	// }
	// getUserRol() {
	// 	return this.nucleusUserRol;
	// }


	// setOnDuty(value){
	// 	this.nucleusOnDuty = value;
	// 	this.saveToLocalStorage();
	// }
	// getOnDuty() {
	// 	return this.nucleusOnDuty;
	// }

	// getProfileCreated() {
	// 	return this.nucleusProfileCreated;
	// }

	// setProfileCreated(profileCreated) {
	// 	this.nucleusProfileCreated = profileCreated;
	// 	this.saveToLocalStorage();
	// }

	getIsNewSession() {
		this.saveToLocalStorage();
		return this.nucleusNewSession;
	}
	clearIsNewSession() {
		this.nucleusNewSession = "yes";
		this.saveToLocalStorage();
	}

	// getNucleusUserTemporalPassword(){
	// 	return this.nucleusTemporalPassword;
	// }


	login ({Email , Password, dispatch}) {
		//console.log("AuthStore:login", Email, Password );
		this.nucleusCsUserEmail = Email;

		this.AuthApi.login({Email, Password},(rsp) => {

			console.log("Nucleus Login Response");
			//console.log(rsp);
			if (rsp.status === 200) {
				console.log("LOOKING FOR THIS: ", rsp)
				this.nucleusCsUserID = rsp.ID;
				this.nucleusAccountID = rsp.ID;
				this.nucleusCsUserToken = rsp.CacheToken;
				this.csSuperUser = rsp.SuperUser;
				localStorage.setItem("nucleusTokenCs", rsp.Jwt);

				setTokenToHttpClients(rsp.Jwt);
				dispatch(setUser(rsp))
			} else{
				dispatch(resetUser())
			}
			this.emit("onAuthStoreLogin",{
				ok : rsp.status === 200,
				internetError : rsp.errorInternetConnection
			});
		});
	}

	requestResetPassword({ Email }) {
		console.log("AuthStoreCS:requestResetPassword ", Email);

		this.AuthApi.requestResetPassword({Email},(rsp) => {

			console.log("AuthStoreCS:requestResetPassword Response");
			console.log(rsp);
			this.emit("onResetRequestedAction", rsp);
			// this.emit("onAuthStoreLogin",{
			// 	ok : rsp.status === 200,
			// 	internetError : rsp.errorInternetConnection
			// });
		});

		// this.LoginApi.requestResetPassword({ Email }, (rsp) => {
		//   //console.log("Nucleus Response", rsp);

		//   this.emit("onResetRequestedAction", rsp);
		// });
	}

	resetTempPassword(UserID, callback) {
		console.log("AuthStoreCS:resetTempPassword ", UserID);
		let paramData = {
			userId: UserID
		};
		this.AuthApi.resetTempPassword(paramData, (rsp) => {
			//console.log("AuthStoreCS:resetTempPassword Response", rsp);
			callback && callback(rsp);
		});
	}


	resetPassword({ Email, Token, Password }) {
		console.log("resetPassword2", Email, Token, Password);

		let paramsData = {
			email: Email,
		  password: Password,
		  tempToken: Token,
		};

		this.AuthApi.resetPassword2(paramsData, (rsp) => {
		  console.log("Nucleus Response Reset Cs Password", rsp);
		  let response = Object.assign({}, rsp, {
			ok: rsp.ok
		  });
		  this.emit("onResetPasswordAction", response);
		});
	}

	createNewCsUser(newUserData, responseCallback) {
		console.log("resetPassword2", newUserData, responseCallback);

		this.AuthApi.createNewCsUser(newUserData, (rsp) => {
			console.log("Nucleus Response Create Cs User", rsp);
			let response = Object.assign({}, rsp, {
				ok: rsp.ok
			});
			responseCallback(response);
		});
	}

	// resetCustomerPassword ({Email, Token, Password}) {
	// 	console.log("resetCustomerPassword ", Email, Token, Password );

	// 	this.LoginApi.resetCustomerPassword({Email, Token, Password},(rsp) => {

	// 		console.log("Nucleus Response");
	// 		console.log(rsp);


	// 		this.emit("onResetCustomerPasswordAction",rsp);
	// 	});
	// }

  	getMe(dispatch) {
		//console.log("/cs_user/me");
		let rsp;
		httpApi
		.get("/cs_user/me")
		.then((response) => {
			const { data } = response;
			rsp = {
			ok: true,
			...data,
			};
		})
		.catch((e) => {
			console.error("/cs_user/me error: ", e)
			const { data } = e.message;
			rsp = {
			ok: false,
			...data,
			};
		})
		.finally(() => {
			if (rsp && rsp.ok) {
				this.nucleusCsUserID = rsp.ID;
				this.nucleusAccountID = rsp.ID;
				this.nucleusCsUserToken = rsp.CacheToken;
				this.familyLabel = rsp.FamilyRoleName || "Family Member";
				this.familyLabelPlural = rsp.FamilyRoleNamePlural || "Family Members";
				this.clientsAccess = rsp.CanSeeClientsTab || true;
				this.deletePatientsAccess = rsp.CanDeletePatients;
				this.saveToLocalStorage()
				dispatch(setUser(rsp))
			}
			else{
				dispatch(resetUser())
			}
			this.emit('onGetMe', rsp)
		})
  	}

	saveToLocalStorage(){
		console.log("saveToLocalStorage");

		localStorage.nucleusCsUserID = this.nucleusCsUserID;
		localStorage.nucleusCsUserToken = this.nucleusCsUserToken;

		localStorage.nucleusCsUserEmail = this.nucleusCsUserEmail;
		localStorage.csSuperUser =this.csSuperUser;
		//localStorage.nucleusUserFirstName = this.nucleusUserFirstName;
		// localStorage.nucleusUserLastName = this.nucleusUserLastName;
		// localStorage.nucleusUserFullName = this.nucleusUserFullName;
		 localStorage.nucleusAccountID = this.nucleusAccountID;
		// localStorage.nucleusUserRol = this.nucleusUserRol;
		// localStorage.nucleusOnDuty = this.nucleusOnDuty;

		// localStorage.nucleusProfileCreated = this.nucleusProfileCreated;
		localStorage.nucleusNewSession = this.nucleusNewSession;
		localStorage.deletePatientsAccess = this.deletePatientsAccess;
		// localStorage.nucleusTemporalPassword = this.nucleusTemporalPassword;

		// localStorage.nucleusCompanyName = this.nucleusCompanyName;
		// localStorage.nucleusCompanyLogo = this.nucleusCompanyLogo;
		localStorage.familyLabel = this.familyLabel;
		localStorage.familyLabelPlural = this.familyLabelPlural;
		localStorage.clientsAccess = this.clientsAccess;
	}

	logout(dispatch){
		console.log("logout");

		this.AuthApi.logout()
			.catch(() => {
			console.log('Error logout request')
		  })
		  .then(() => {
			this.emit("onAuthStateChanged",{
				ok : false
			});
			dispatch(resetUser());


			this.nucleusCsUserID = null;
			this.nucleusCsUserToken = null;
			this.nucleusCsUserEmail = null;
			this.csSuperUser = null;
			this.familyLabel = null;
			this.familyLabelPlural = null;
			this.clientsAccess = null;
			this.deletePatientsAccess = null;
			//this.nucleusUserFirstName = null;
			// this.nucleusUserLastName = null;
			// this.nucleusUserFullName = null;
			this.nucleusAccountID = null;
			// this.nucleusUserRol = null;
			// this.nucleusOnDuty = null;
			// this.nucleusProfileCreated = null;
			this.nucleusNewSession = false;
			// this.nucleusTemporalPassword = null;
			delete localStorage.nucleusCsUserID ;
			delete localStorage.nucleusCsUserToken;
			delete localStorage.nucleusCsUserEmail;
			delete localStorage.csSuperUser;
			//delete localStorage.nucleusUserFirstName ;
			// delete localStorage.nucleusUserLastName ;
			// delete localStorage.nucleusUserFullName ;
			delete localStorage.nucleusAccountID ;
			// delete localStorage.nucleusUserRol ;
			// delete localStorage.nucleusProfileCreated ;
			delete localStorage.nucleusNewSession ;
			// delete localStorage.nucleusTemporalPassword ;
			localStorage.removeItem('nucleusTokenCs')
			delete localStorage.familyLabel;
			delete localStorage.familyLabelPlural;
			delete localStorage.clientsAccess;
			delete localStorage.deletePatientsAccess;
		})
	}


}

const authStore = new AuthStore;

export default authStore;
