import { EventEmitter } from "events";
//import Dispatcher from "../Dispatcher";
//import Config from "../Config";
import CareAccountApi from "../apis/CareAccountApi";
import { getInstance } from '../utils/auth-client'

import { httpApi } from '@nucleus-care/nucleuscare-backend-client';	// This two are the same, use backendClient
import backendClient from "../utils/BackendClient";		// This two are the same, use backendClient
import { httpApi as httpNodeApi } from '@nucleus-care/nucleuscare-backend-client';
class CareAccountStore extends EventEmitter {
	constructor() {
		super();

		this.CareAccountApi = new CareAccountApi(this, getInstance());
		try{
			this.AccountsList = JSON.parse(localStorage.AccountsList) || [];
			this.TwoFactorAuthEnabled = (String(localStorage.TwoFactorAuthEnabled) == "true") || false;
		}
		catch(e){
			this.AccountsList = [];
			this.TwoFactorAuthEnabled = false;
		}
		
		this.UpdateGroups = [];
		this.AccountGroups = [];
		this.AccountLocale = [];
		this.AccountAlerts = [];
		this.AccountUsers = [];
		this.NewFamilyMemberAccountId = "";
		this.AccountsCountries = [];
		this.Templates = [];
	}

	getAllAccounts(){
		//console.log("this.AccountsList", this.AccountsList);
		return this.AccountsList;
	}

	setFamilyMemberAccountId(accountId) {
		console.log("setting family member account id: ", accountId)
		this.NewFamilyMemberAccountId = accountId;
		console.log("set: ", this.NewFamilyMemberAccountId)
	}

	getFamilyMemberAccountId() {
		return this.NewFamilyMemberAccountId;
	}

	getAccountGroups(){
		//console.log("this.AccountGroups", this.AccountGroups);
		return this.AccountGroups;
	}

	getUpdateGroups(){
		//console.log("this.UpdateGroups", this.UpdateGroups);
		return this.UpdateGroups;
	}

	getAccountsCountries(){
		//console.log("this.AccountsCountries", this.AccountsCountries);
		return this.AccountsCountries;
	}

	getAccountLocale(){
		// console.log("this.AccountLocale: ", this.AccountLocale);
		return this.AccountLocale;
	}

	getAccountAlerts(){
		//console.log("getAccountAlerts", this.AccountAlerts);
		return this.AccountAlerts;
	}

	getAvailableTemplates(){
		console.log("getAvailableTemplates", this.Templates);
		return this.Templates;
	}

	getAccountInfo(accountId){
		console.log("getAccountInfo", accountId);

		let account = null;

		for(var i = 0; i < this.AccountsList.length; i++){
		    
		    if (this.AccountsList[i].ID == accountId){
		    	account = this.AccountsList[i];
		    	break;
		    }
	    }
	    return account;
	}

	getUserInfo(userId){
		console.log("getUserInfo", userId);

		let user = null;

		for(var i = 0; i < this.AccountUsers.length; i++){
		    
		    if (this.AccountUsers[i].UserID == userId){
		    	user = this.AccountUsers[i];
		    	break;
		    }
	    }
	    return user;
	}

	get2FactorAuthEnabled() {
		return this.TwoFactorAuthEnabled;
	}

	set2FactorAuthEnabled(value){
		console.log("set2FactorAuthEnabled", value);
		this.TwoFactorAuthEnabled = value;
	}

	getAllAccountsData ({ UserID, Token }) {
		console.log("getAllAccountsData ", UserID, Token );

		backendClient.get(`account/${UserID}/all_data`, (rsp)=>{
			console.log("getAllAccountsData Response Node");
			console.log(rsp);
			if (rsp && rsp.ok){
				this.AccountsList = rsp.accountList;
				this.saveToLocalStorage();
				this.emit("onGetAllAccountsData", rsp.ok);
			}
		})
		// this.CareAccountApi.GetAllAccounts({ UserID, Token },(rsp) => {
		// 	console.log("getAllAccountsData Response");
		// 	console.log(rsp);
		// 	if (rsp.ok){
		// 		this.AccountsList = rsp.accountList;
		// 		this.saveToLocalStorage();
		// 	}
		// 	this.emit("onGetAllAccountsData", rsp.ok);
		// });
	}

	getEnterprisesData () {
		console.log("getEnterprisesData " );

		backendClient.get(`mdm/enterprises`, (rsp)=>{
			console.log("getEnterprisesData Response Node");
			console.log(rsp);
			this.emit("onEnterprisesData", rsp);
		})
	}

	
	getAccountData({AccountID, UserID, Token }) {
		console.log("getAccountData ",AccountID, UserID, Token );

		backendClient.get(`account/${AccountID}/get_data`, (rsp)=>{
			//console.log("getAccountData Response 2", rsp);
			this.emit("onGetAccountData", rsp);
		})
		// this.CareAccountApi.GetAccount({AccountID, UserID, Token },(rsp) => {
		// 	console.log("getAccountData Response 1", rsp);
		// 	this.emit("onGetAccountData", rsp);
		// });
	}

	createAccount ({Name, EnterpriseID}) {
		console.log("createAccount ", Name, EnterpriseID);
		// this.CareAccountApi.CreateNewAccount({Name},(rsp) => {
		// 	//console.log("Nucleus Response", rsp);
		// 	this.emit("onCreateAccountAction", rsp);
		// });
        backendClient.post(
            `account/new`,
            {
                Name: Name, 
				EnterpriseID : EnterpriseID 
            },
            (response)=>{
				console.log("backendNode createAccount response", response);
                this.emit("onCreateAccountAction", response);
            });
	}

	DeleteAccount({AccountID}) {
		console.log("DeleteAccount ", AccountID);

		this.CareAccountApi.DeleteAccount({AccountID},(rsp) => {

			//console.log("Nucleus Response", rsp);
			if (rsp && rsp.ok){
				backendClient.delete(`account/${AccountID}/delete_mdm_connection`, (response)=>{
					console.log("DeleteAccount delete_mdm_connection Response Node");
					console.log(response);
					
					this.emit("onAccountDeleted",rsp);
				})
			}
			else{
				this.emit("onAccountDeleted", rsp);
			}
		});
	}
	getAccountGroupsData() {
		console.log("getAccountGroupsData " );

		this.CareAccountApi.GetAccountGroupsData({ },(rsp) => {
			// console.log("getAccountGroupsData Response");
			// console.log(rsp);
			if (rsp.ok){
				this.AccountGroups = rsp.groups;
				//this.saveToLocalStorage();
			}
			this.emit("onGetAccountGroupsData", rsp.ok);
		});
	}

	
	getUpdateGroupsData() {
		console.log("getUpdateGroupsData " );

		this.CareAccountApi.GetUpdateGroupsData({ },(rsp) => {
			// console.log("getUpdateGroupsData Response");
			// console.log(rsp);
			if (rsp.ok){
				this.UpdateGroups = rsp.groups;
				//this.saveToLocalStorage();
			}
			this.emit("onGetUpdateGroupsData", rsp.ok);
		});
	}

	getAccountsCountriesData(){
		console.log("getAccountsCountriesData " );

		this.CareAccountApi.GetAccountsCountriesData({ },(rsp) => {
			//console.log("getAccountsCountriesData Response", rsp);

			if (rsp.ok){
				this.AccountsCountries = rsp.countries;
			}
			this.emit("onGetCountriesData", rsp.ok);
		});
	}

	getLocalesData() {
		// Deprecated!
		console.log("getLocalesData" );
		this.CareAccountApi.GetLocalesData({ },(rsp) => {
			console.log(rsp);
			if (rsp.ok){
				this.AccountLocale = rsp.localeList;
			// 	//this.saveToLocalStorage();
			}
			//this.emit("onGetAccountLocaleData", rsp.ok);
			//Not needed 
		});
	}

	getAccountLocaleData(accountId){
		console.log("getAccountLocaleData =>", accountId);
		if (accountId){
			backendClient.get(`account/${accountId}/get_languages`, (rsp)=>{
				//console.log("getAccountActiveLanguages NodeResponse", rsp);
				this.emit("onGetAccountLocaleData", rsp);
			})
		}
		else{
			this.emit("onGetAccountLocaleData", false);
		}
	}

	getTemplatesData(){
		console.log("getTemplatesData " );

		this.CareAccountApi.GetTemplatesData({ },(rsp) => {
			if (rsp.ok){
				this.Templates = rsp.templates;
			}
			this.emit("onTemplatesData", rsp.ok);
		});
	}

	getAccountAlertsData({AccountID}){
		console.log("getAccountAlertsData ", AccountID );

		this.CareAccountApi.GetAccountAlerts({AccountID},(rsp) =>{

			console.log("getAccountAlertsData Response");
			console.log(rsp);

			if(rsp.ok){
				this.AccountAlerts = rsp.alerts;
			}
			this.emit("onGetAccountAlertsData", rsp.ok);
		});
	}
	

	updateAccountName ({AccountID, AccountName}) {
		console.log("updateAccountName ", AccountID, AccountName);

		this.CareAccountApi.UpdateAccountName({AccountID, AccountName},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onAccountNameUpdated", rsp);
		});
	}

	unblockApi ({AccountID}) {
		console.log("unblockApi ", AccountID); 

		this.CareAccountApi.UnblockApi({AccountID}, (rsp) => {
			console.log("NUCLEUS RESPONSE", rsp);

			this.emit("onUnblockApiUpdated", rsp);
		});
	}

	updateApiToken  ({AccountID}) {
		console.log("updateApiToken ", AccountID); 

		this.CareAccountApi.UpdateApiToken({AccountID}, (rsp) => {
			console.log("NUCLEUS RESPONSE", rsp);

			this.emit("onApiTokenUpdated", rsp);
		});
	}

	
	updateAccountLogo ({AccountID, Logo}) {
		console.log("updateAccountLogo ", AccountID, Logo);

		this.CareAccountApi.UpdateAccountLogo({AccountID, Logo},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onAccountLogoUpdated", rsp);
		});
	}

		
	updateAccountGroup({AccountID, AccountGroupID}) {
		console.log("updateAccountGroup ", AccountID, AccountGroupID);

		let rsp;
		httpApi
		.put(`account/${AccountID}/set_account_group`,{
			accountGroupId : AccountGroupID
		})
		.then((response) => {
			const { data } = response;
			//console.log("response", data);
			rsp = {
				ok: true,
				...data,
			};
			this.emit("onAccountGroupUpdated", rsp);
		})
		.catch((e) => {
			console.log("error", e.response);
			const { data } = e.response;
			rsp = {
				ok: false,
				...data,
			};
			this.emit("onAccountGroupUpdated", rsp);
		})
		// this.CareAccountApi.UpdateAccountGroup({AccountID, AccountGroupID},(rsp) => {
		// 	//console.log("Nucleus Response", rsp);
		// 	this.emit("onAccountGroupUpdated", rsp);
		// });
	}

	getAccountActiveLanguages(accountId, callback){
		console.log("getAccountActiveLanguages");

		backendClient.get(`account/${accountId}/get_languages`, (rsp)=>{
			console.log("getAccountActiveLanguages NodeResponse");
			callback(rsp);
		})
	}

	getAccountAvailableLanguages(accountId, callback){
		console.log("getAccountAvailableLanguages");

		backendClient.get(`account/${accountId}/get_available_languages`, (rsp)=>{
			console.log("getAccountAvailableLanguages NodeResponse");
			callback(rsp);
		})
	}

	getAccountStringsConfig(accountId, lang, callback){
		console.log("getAccountStringsConfig");

		backendClient.get(`account/${accountId}/lang/${lang}/get_strings`, (rsp)=>{
			console.log("getAccountStringsConfig NodeResponse");
			callback(rsp);
		})
	}

	// * * * * * * * * * * * * * * * * * * * * * * * *
	// Update Account Config
	// * * * * * * * * * * * * * * * * * * * * * * * *
	updateAccountConfig(AccountID, AccountConfig, responseCallback){
		console.log("updateAccountConfig AccountID", AccountID);
		console.log("updateAccountConfig AccountConfig", AccountConfig);
		let rsp;
		httpApi.put(`account/${AccountID}/update_account_config`, AccountConfig)
			.then((response) => {
				const { data } = response;
				rsp = {
					ok: true,
					...data,
				};
				console.log(`Response account/${AccountID}/update_account_config:`, rsp);
			})
			.catch((e) => {
				console.log("updateAccountConfig error", e.response);
				const { data } = e.response;
				rsp = {
					ok: false,
					...data,
				};
				console.log(`Error account/${AccountID}/update_account_config:`, rsp);
			})
			.finally(() => {				
				responseCallback(rsp);
			});
	}

	// Updated April 11, 2023. Use this way.
	// * * * * * * * * * * * * * * * * * * * * * * * *
	// Update Account Strings Config				 * 
	// * * * * * * * * * * * * * * * * * * * * * * * *
	updateAccountStringsConfig(AccountID, Lang, StringConfig, responseCallback){
		console.log("updateAccountStringsConfig AccountID", AccountID);
		console.log("updateAccountStringsConfig Lang", Lang);
		console.log("updateAccountStringsConfig StringConfig", StringConfig);

		backendClient.put(`account/${AccountID}/lang/${Lang}/update_account_string`, StringConfig, (rsp)=>{
			console.log("updateAccountStringsConfig NodeResponse");
			responseCallback(rsp);
		})
	}

	addAccountStringsConfig(AccountID, NewLang, responseCallback){
		console.log("addAccountStringsConfig AccountID", AccountID);
		console.log("addAccountStringsConfig Lang", NewLang);

		backendClient.put(`account/${AccountID}/add_lang_strings`, 
						{
							NewLang : NewLang
						}, 
						(rsp)=>{
							console.log("addAccountStringsConfig NodeResponse");
							responseCallback(rsp);
						}
					)
	}

	// ======================================
  // Update Account Configs (Node Backend)
	// ======================================
	updateAccountHomeButtonEnabled(AccountID, AccountConfig, responseCallback){
		//console.log("updateAccountHomeButtonEnabled AccountID", AccountID);
		//console.log("updateAccountHomeButtonEnabled AccountConfig", AccountConfig);
		backendClient.put(`account/${AccountID}/update_home_button_enabled`, 
			AccountConfig, 
			(rsp)=>{
				//console.log("updateAccountHomeButtonEnabled NodeResponse", rsp);
				responseCallback(rsp);
			}
		)
	}

	updateAccountEnableTalkToADoctor(AccountID, AccountConfig, responseCallback){
		backendClient.put(`account/${AccountID}/enable-talk-to-a-doctor-now`, 
			AccountConfig, 
			(rsp)=>{
				responseCallback(rsp);
			}
		)
	}

	updateAccountEnableScheduleADoctor(AccountID, AccountConfig, responseCallback){
		backendClient.put(`account/${AccountID}/enable-schedule-a-doctor`, 
			AccountConfig, 
			(rsp)=>{
				responseCallback(rsp);
			}
		)
	}

	updateAccountDeviceUXVersion = async (AccountID, NucleusDeviceUXVersion) => {
		const response = await httpNodeApi.put(`/account/${AccountID}/device-ux-version`, 
			{
				deviceUXVersion : NucleusDeviceUXVersion
			}
		);
		//console.log("updateAccountDeviceUXVersion response", response);
    return (response.status >= 200 && response.status < 300);
	}

	updateUpdatesGroup({AccountID, UpdateGroupID}) {
		console.log("updateUpdatesGroup ", AccountID, UpdateGroupID);

		this.CareAccountApi.UpdateUpdatesGroup({AccountID, UpdateGroupID},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onUpdatesGroupUpdated", rsp);
		});
	}

	updateAccountCountry({AccountID, CountryCode}) {
		console.log("updateAccountCountry ", AccountID, CountryCode);

		this.CareAccountApi.UpdateAccountCountry({AccountID, CountryCode},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onAccountCountryUpdated", rsp);
		});
	}

	updateZipCode({AccountID, ZipCode}) {
		console.log("updateZipCode ", AccountID, ZipCode);

		this.CareAccountApi.UpdateZipCode({AccountID, ZipCode},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onZipCodeUpdated", rsp);
		});
	}

	updateAccountLocale({AccountID, LocaleTag}) {
		console.log("updateAccountLocale ", AccountID, LocaleTag);

		this.CareAccountApi.UpdateAccountLocale({AccountID, LocaleTag},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onAccountLocaleUpdated", rsp);
		});
	}

	getDailyCallRequests({ Token}) {
		console.log("getDailyCallRequests ", Token);

		this.CareAccountApi.getDailyCallRequests({ Token},(rsp) => {

			this.emit("onGetDailyCallsAction", rsp);
		});
	}

	getDailyCallsMade({ Token}) {
		console.log("getDailyCallsMade ", Token);

		this.CareAccountApi.getDailyCallsMade({ Token},(rsp) => {

			console.log("getDailyHandledCalls Response");
			console.log(rsp);

			this.emit("onGetDailyHandledCallsAction", rsp);
		});
	}


	updateShowCallButton ({AccountID, Show}) {
		console.log("updateShowCallButton ", AccountID, Show);

		this.CareAccountApi.UpdateShowCallButton({AccountID, Show},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onShowCallButtonUpdated", rsp);
		});
	}

	updateShowCallButton2({AccountID, Show}) {
		console.log("updateShowCallButton2 ", AccountID, Show);

		this.CareAccountApi.UpdateShowCallButton2({AccountID, Show},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onShowCallButton2Updated", rsp);
		});
	}

	updateShowCheckInButton({AccountID, Show}) {
		console.log("updateShowCheckInButton ", AccountID, Show);

		this.CareAccountApi.UpdateShowCheckInButton({AccountID, Show},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onShowCheckInButtonUpdated", rsp);
		});
	}

	updateShowEmergencyButton({AccountID, Show}) {
		console.log("updateShowEmergencyButton ", AccountID, Show);

		this.CareAccountApi.UpdateShowEmergencyButton({AccountID, Show},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onShowEmergencyButtonUpdated", rsp);
		});
	}

	
	updateRingOnRequest ({AccountID, ring}) {
		console.log("updateRingOnRequest ", AccountID, ring);

		this.CareAccountApi.UpdateRingOnRequest({AccountID, ring},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onRingOnRequestUpdated", rsp);
		});
	}

	updateEnableBiometrics({AccountID, EnableBiometrics}) {
		console.log("updateEnableBiometrics ", AccountID, EnableBiometrics);

		this.CareAccountApi.UpdateEnableBiometrics({AccountID, EnableBiometrics},(rsp) => {

			console.log("Nucleus Response");
			//console.log(rsp);

			rsp["enable"] = EnableBiometrics;

			this.emit("onEnableBiometricsUpdated", rsp);
		});
	}

	updateEnableBluetoothButtons({AccountID, EnableBluetoothButtons}) {
		console.log("updateEnableBluetoothButtons ", AccountID, EnableBluetoothButtons);

		this.CareAccountApi.UpdateEnableBluetoothButtons({AccountID, EnableBluetoothButtons},(rsp) => {

			console.log("Nucleus Response");
			//console.log(rsp);

			rsp["enableBluetoothButtons"] = EnableBluetoothButtons;

			this.emit("onEnableBluetoothButtonsUpdated", rsp);
		});
	}

	updateShowCallControls({AccountID, ShowCallButtonsBar}) {
		console.log("updateShowCallControls ", AccountID, ShowCallButtonsBar);

		this.CareAccountApi.UpdateShowCallControls({AccountID, ShowCallButtonsBar},(rsp) => {

			//console.log("Nucleus Response", rsp);

			rsp["show"] = ShowCallButtonsBar;

			this.emit("onShowCallControlsUpdated", rsp);
		});
	}

	updateShowVideoStats({AccountID, ShowVideoStats}) {
		console.log("updateShowVideoStats ", AccountID, ShowVideoStats);

		this.CareAccountApi.UpdateShowVideoStats({AccountID, ShowVideoStats},(rsp) => {

			//console.log("Nucleus Response", rsp);

			rsp["show"] = ShowVideoStats;

			this.emit("onShowVideoStatsUpdated", rsp);
		});
	}


	updateShowFamilyCallBtn({AccountID, ShowFamilyCallButton}) {
		console.log("updateShowFamilyCallBtn ", AccountID, ShowFamilyCallButton);

		this.CareAccountApi.UpdateShowFamilyCallBtn({AccountID, ShowFamilyCallButton},(rsp) => {

			//console.log("Nucleus Response", rsp);

			rsp["show"] = ShowFamilyCallButton;

			this.emit("onShowFamilyCallButtonUpdated", rsp);
		});
	}

	updateEnableAutoAnswer({AccountID, EnableAutoAnswer}) {
		console.log("updateEnableAutoAnswer ", AccountID, EnableAutoAnswer);

		this.CareAccountApi.UpdateEnableAutoAnswer({AccountID, EnableAutoAnswer},(rsp) => {

			//console.log("Nucleus Response", rsp);

			rsp["enable"] = EnableAutoAnswer;

			this.emit("onEnableAutoAnswerUpdated", rsp);
		});
	}

	updateEnableFamilyAutoAnswer({AccountID, EnableFamilyAutoAnswer}) {
		console.log("updateEnableFamilyAutoAnswer ", AccountID, EnableFamilyAutoAnswer);

		this.CareAccountApi.UpdateEnableFamilyAutoAnswer({AccountID, EnableFamilyAutoAnswer},(rsp) => {

			//console.log("Nucleus Response", rsp);

			rsp["enable"] = EnableFamilyAutoAnswer;

			this.emit("onEnableFamilyAutoAnswerUpdated", rsp);
		});
	}

	updateEnableFamilyAppAutoAnswer({AccountID, EnableAutoAnswerForFamilyApp}) {
		console.log("updateEnableFamilyAppAutoAnswer ", AccountID, EnableAutoAnswerForFamilyApp);

		this.CareAccountApi.UpdateEnableAutoAnswerForFamilyApp({AccountID, EnableAutoAnswerForFamilyApp},(rsp) => {

			//console.log("Nucleus Response", rsp);

			rsp["enable"] = EnableAutoAnswerForFamilyApp;

			this.emit("onEnableAutoAnswerForFamilyAppUpdated", rsp);
		});
	}

	updateMobileCallResponse({AccountID, MobileCallResponse}) {
		console.log("updateMobileCallResponse ", AccountID, MobileCallResponse);

		this.CareAccountApi.UpdateMobileCallResponse({AccountID, MobileCallResponse},(rsp) => {

			console.log("Nucleus Response", rsp);

			this.emit("onMobileCallResponseUpdated", rsp);
		});
	}

	updateForceLandscapeVideo({AccountID, ForceLandscapeVideo}) {
		console.log("updateForceLandscapeVideo ", AccountID, ForceLandscapeVideo);

		this.CareAccountApi.UpdateForceLandscapeVideo({AccountID, ForceLandscapeVideo},(rsp) => {

			//console.log("Nucleus Response", rsp);

			rsp["force"] = ForceLandscapeVideo;

			this.emit("onForceLandscapeVideoUpdated", rsp);
		});
	}

	updateRingIncomingCall({AccountID, EnableRingOnIncomingCall}) {
		console.log("updateRingIncomingCall ", AccountID, EnableRingOnIncomingCall);

		this.CareAccountApi.UpdateRingIncomingCall({AccountID, EnableRingOnIncomingCall},(rsp) => {

			//console.log("Nucleus Response", rsp);

			rsp["enable"] = EnableRingOnIncomingCall;

			this.emit("onEnableRingOnIncomingCallUpdated", rsp);
		});
	}

	updateVoiceMessage({AccountID, EnableVoiceMessage}) {
		console.log("updateVoiceMessage ", AccountID, EnableVoiceMessage);

		this.CareAccountApi.UpdateVoiceMessage({AccountID, EnableVoiceMessage},(rsp) => {

			//console.log("Nucleus Response", rsp);

			rsp["enable"] = EnableVoiceMessage;

			this.emit("onEnableVoiceMessageUpdated", rsp);
		});
	}
	

	// DEPRECATED on March 1st 2023, https://nucleusintercom.atlassian.net/browse/CARE-10228 
	// updateEnablePhoneCall({AccountID, EnablePhoneCallFromDevice}) {
	// 	console.log("updateEnablePhoneCall ", AccountID, EnablePhoneCallFromDevice);
	// 	this.CareAccountApi.UpdateEnablePhoneCall({AccountID, EnablePhoneCallFromDevice},(rsp) => {
	// 		//console.log("Nucleus Response", rsp);
	// 		rsp["enable"] = EnablePhoneCallFromDevice;
	// 		this.emit("onEnablePhoneCallUpdated", rsp);
	// 	});
	// }

	// updatePhoneNumberDevice({AccountID, PhoneCallFromDeviceNumber}) {
	// 	console.log("updatePhoneNumberDevice ", AccountID, PhoneCallFromDeviceNumber);
	// 	this.CareAccountApi.UpdatePhoneNumberDevice({AccountID, PhoneCallFromDeviceNumber},(rsp) => {
	// 		//console.log("Nucleus Response", rsp);
	// 		this.emit("onPhoneNumberDeviceUpdated", rsp);
	// 	});
	// }

	updateEmergencyBtnText({AccountID, EmergencyButtonText}) {
		console.log("updateEmergencyBtnText ", AccountID, EmergencyButtonText);
		this.CareAccountApi.UpdateEmergencyBtnText({AccountID, EmergencyButtonText},(rsp) => {
			//console.log("Nucleus Response", rsp);
			this.emit("onEmergencyBtnTextUpdated", rsp);
		});
	}

	updateEmergencySchedule({AccountID, Enabled}) {
		console.log("updateEmergencySchedule ", AccountID, Enabled);

		this.CareAccountApi.UpdateEmergencySchedule({AccountID, Enabled},(rsp) => {
			//console.log("Nucleus Response", rsp);
			rsp["enabled"] = Enabled;

			this.emit("onEnableEmergencyScheduleUpdated", rsp);
		});
	}

	updateCallSchedule({AccountID, Enabled}) {
		console.log("updateCallSchedule ", AccountID, Enabled);

		this.CareAccountApi.UpdateCallSchedule({AccountID, Enabled},(rsp) => {
			//console.log("Nucleus Response", rsp);
			rsp["enabled"] = Enabled;

			this.emit("onEnableCallScheduleUpdated", rsp);
		});
	}

	updateAudioCallsOnly({AccountID, Enabled}) {
		console.log("updateAudioCallsOnly ", AccountID, Enabled);
		const endpoint = "CareAccount.asmx/UpdateAudioCallsOnly";
		this.CareAccountApi.UpdateAccountConfigurationFlag(
			{AccountID, Enabled},
			endpoint,
			(rsp) => {
				//console.log(`Nucleus ${endpoint} Response`, rsp);
				rsp["enabled"] = Enabled;
				this.emit("onEnableAudioCallsOnlyUpdated", rsp);
			}
		);
	}



	updateScheduleTimezone({AccountID, Timezone}) {
		console.log("updateScheduleTimezone ", AccountID, Timezone);

		this.CareAccountApi.UpdateScheduleTimezone({AccountID, Timezone},(rsp) => {

			this.emit("onScheduleTimezoneUpdated", rsp);
		});
	}

	getAccountSchedule({UserID, Token, AccountID}) {
		console.log("getAccountSchedule ", UserID, Token, AccountID);

		this.CareAccountApi.GetAccountSchedule({UserID, Token, AccountID},(rsp) => {

			this.emit("onGetAccountSchedule", rsp);
		});
	}

	addNewSchedule({AccountID, Day, From, To}) {
		console.log("addNewSchedule ", AccountID, Day, From, To);

		this.CareAccountApi.AddNewAccountSchedule({AccountID, Day, From, To},(rsp) => {

			this.emit("onNewAccountScheduleAdded", rsp);
		});
	}

	handleOpenEditScheduleModal({ ID, Day, From, To}) {
		console.log("handleOpenEditScheduleModal", ID, Day, From, To);

		this.emit("onOpenEditScheduleModal", {
			ScheduleID : ID,
			ScheduleDay : Day,
			ScheduleFrom : From,
			ScheduleTo : To
		});
	}

	editSchedule({ID, Day, From, To}) {
		console.log("editSchedule ", ID, Day, From, To);

		this.CareAccountApi.EditAccountSchedule({ID, Day, From, To},(rsp) => {

			this.emit("onAccountScheduleEdited", rsp);
		});
	}

	handleOpenDeleteScheduleModal({ ID, Day, From, To}) {
		console.log("handleOpenDeleteScheduleModal", ID, Day, From, To);

		this.emit("onOpenRemoveScheduleModal", {
			ScheduleID : ID,
			ScheduleDay : Day,
			ScheduleFrom : From,
			ScheduleTo : To
		});
	}
	removeSchedule({ID}) {
		console.log("removeSchedule ", ID);

		this.CareAccountApi.RemoveAccountSchedule({ID},(rsp) => {

			this.emit("onAccountScheduleRemoved", rsp);
		});
	}

	applyDefaultScheduleToAllPatients({AccountID}) {
		console.log("applyDefaultScheduleToAllPatients ", AccountID);

		this.CareAccountApi.ApplyDefaultScheduleToAllPatients({AccountID},(rsp) => {

			this.emit("onDefaultScheduleApplied", rsp);
		});
	}



	getAccountDevices({ UserID, Token, AccountID }) {
		console.log("getAccountDevices ", UserID, Token, AccountID );

		this.CareAccountApi.GetAccountDevices({ UserID, Token, AccountID },(rsp) => {

			//console.log("getAccountDevices Response", rsp);

			this.emit("onGetAccountDevices", rsp);
		});
	}

	getDeviceInfo({ UserID, Token, DeviceID }) {
		console.log("getDeviceInfo ", UserID, Token, DeviceID );

		this.CareAccountApi.GetDeviceInfo({ UserID, Token, DeviceID },(rsp) => {

			//console.log("getDeviceInfo Response", rsp);

			this.emit("onGetDeviceInfo", rsp);
		});
	}


	handleOpenDeleteDeviceModal (data) {
		console.log("handleOpenDeleteDeviceModal", data);
		backendClient.get(`device/${data.DeviceID}/mdmData`, (rsp)=>{
			console.log("handleOpenDeleteDeviceModal Response Node");
			console.log(rsp);
			if (rsp && rsp.ok && rsp.deviceMdmInfo){
				data.OnHexnode = rsp.deviceMdmInfo.OnHexnode;
				data.OnNucleusMDM = rsp.deviceMdmInfo.OnNucleusMDM;
				data.MdmDeviceID = rsp.deviceMdmInfo.MdmDeviceID;
				this.emit("onDeleteDeviceModal",data);
			}
			else{
				this.emit("onDeleteDeviceModal",data);
			}
		  }, 
		  { timeout: 5000 }
		)
	}

	handleEnabledRemoteLogs({DeviceID, Enabled }) {
		console.log("handleEnabledRemoteLogs ",DeviceID, Enabled);

		this.CareAccountApi.EnableRemoteLogs({DeviceID, Enabled }, (rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onEnableRemoteLogsChanged", rsp);
		});
	}


	handleOpenCategoryModal(categoryData) {
		//console.log("handleOpenCategoryModal", categoryData);

		this.emit("onOpenCategoryModal", categoryData );
	}

	removeDevice({AccountID, CsUserID, DeviceID, Unassign, DisenrollHexnode, RemoveFromHexnode, RemoveFromSystem, RemoveFromNucleusMdm, MdmDeviceID}) {
		console.log("removeDevice ", 
				AccountID, CsUserID, DeviceID, Unassign, DisenrollHexnode, RemoveFromHexnode, RemoveFromSystem, RemoveFromNucleusMdm, MdmDeviceID);

		this.CareAccountApi.RemoveDeviceFromAccount({
			AccountID, 
			CsUserID,
			DeviceID, 
			Unassign, 
			DisenrollHexnode,
			RemoveFromHexnode,
			RemoveFromSystem
			},(rsp) => {
			//console.log("Nucleus Response", rsp);
			let response = Object.assign(rsp, {Unassign, DisenrollHexnode, RemoveFromSystem});

			if (rsp && rsp.ok && RemoveFromNucleusMdm && MdmDeviceID){

				backendClient.delete(`mdm/devices/${MdmDeviceID}`, (response)=>{
					console.log("RemoveDeviceMdm Response Node");
					console.log(response);
					if (response && response.ok){
						rsp.RemoveFromNucleusMDM = true;
					}
					this.emit("onDeviceFromAccountRemoved",rsp);
				  }
				)
			}
			else {
				this.emit("onDeviceFromAccountRemoved", rsp);
			}
		});
	}

	addDevice({AccountID, Mac, cleanLegacy}) {
		console.log("addDevice ", AccountID, Mac, cleanLegacy);

		this.CareAccountApi.AddDeviceToAccount({AccountID, Mac, cleanLegacy},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onDeviceAdded", rsp);
		});
	}

	addDeviceFromLegacy({AccountID, Mac, cleanLegacy}) {
		console.log("addDeviceFromLegacy ", AccountID, Mac, cleanLegacy);

		this.CareAccountApi.AddDeviceToAccount({AccountID, Mac, cleanLegacy},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onDeviceFromLegacyAdded", rsp);
		});
	}

	requestRebootDevice ({Mac, DeviceID}) {
		console.log("requestRebootDevice ", Mac, DeviceID);

		this.CareAccountApi.RequestRebootDevice({Mac, DeviceID},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onRebootDeviceRequested", rsp);
		});
	}

	handleOpenDownloadDeviceLogsModal({ DeviceMac}) {
		console.log("handleOpenDownloadDeviceLogsModal", DeviceMac);

		this.emit("onDownloadDeviceLogsModal", {
			DeviceMac : DeviceMac
		});
	}

	getAccountUsers({ UserID, Token, AccountID }) {
		console.log("getAccountUsers ", UserID, Token, AccountID );

		this.CareAccountApi.NewGetAccountUsers({ UserID, Token, AccountID },(rsp) => {

			console.log("Nucleus Response", rsp);

			if (rsp.ok){
				this.AccountUsers = rsp.users;
				//this.saveToLocalStorage();
			}

			this.emit("onGetAccountUsers", rsp);
		});
	}

	getAccountPatients({ UserID, Token, AccountID }) {
		console.log("getAccountPatients ", UserID, Token, AccountID );

		this.CareAccountApi.GetAccountPatients({ UserID, Token, AccountID },(rsp) => {
			//console.log("Nucleus Response", rsp);
			this.emit("onGetAccountPatients", rsp);
		});
	}

	getAccountPatientsStats({ UserID, Token, AccountID }) {
		console.log("getAccountPatientsStats ", UserID, Token, AccountID );

		this.CareAccountApi.GetAccountPatientsStats({ UserID, Token, AccountID },(rsp) => {
			//console.log("Nucleus Response", rsp);
			this.emit("onGetAccountPatientsStats", rsp);
		});
	}

	getAccountPatientsTimezones({ UserID, Token, AccountID }) {
		console.log("getAccountPatientsTimezones ", UserID, Token, AccountID );

		this.CareAccountApi.GetAccountPatientsTimezones({ UserID, Token, AccountID },(rsp) => {
			////console.log("Nucleus Response", rsp);
			this.emit("onGetAccountPatientsTimezones", rsp);
		});
	}
	 

	UpdatePatientTimeZone({ UserID, Token, PatientID, TimeZoneString, GMTOffset}) {
		console.log("UpdatePatientTimeZone ", UserID, Token, PatientID, TimeZoneString, GMTOffset);

		this.CareAccountApi.UpdatePatientTimeZone({ UserID, Token, PatientID, TimeZoneString, GMTOffset },(rsp) => {
			////console.log("Nucleus Response", rsp);
			this.emit("onUpdatePatientTimeZone", rsp);
		});
	}

	//BUTTONS
	// DEPRECATED - Fri Dec 6th 2019
    // https://nucleusintercom.atlassian.net/browse/CARE-6239
	// getAccountButtons({ AccountID }) {
	// 	console.log("getAccountButtons ", AccountID );

	// 	this.CareAccountApi.GetAccountButtons({ AccountID },(rsp) => {

	// 		//console.log("Nucleus Response", rsp);

	// 		this.emit("onGetAccountButtons", rsp);
	// 	});
	// }

	// addButton({ AccountID, Mac, Type }) {
	// 	console.log("addButton ", AccountID, Mac, Type );

	// 	this.CareAccountApi.AddButton({ AccountID, Mac, Type },(rsp) => {

	// 		//console.log("Nucleus Response", rsp);

	// 		this.emit("onButtonAdded", rsp);
	// 	});
	// }

	// handleOpenDeleteButtonModal({  Mac}) {
	// 	console.log("handleOpenDeleteButtonModal",  Mac);

	// 	this.emit("onDeleteButtonModal", {
	// 		ButtonMac : Mac
	// 	});
	// }

	// handleOpenEditButtonModal({ Mac, Type}) {
	// 	console.log("handleOpenEditButtonModal", Mac, Type);

	// 	this.emit("onEditButtonModal", {
	// 		ButtonMac : Mac,
	// 		ButtonType : Type
	// 	});
	// }

	// removeButton({ Mac }) {
	// 	console.log("removeButton ", Mac );

	// 	this.CareAccountApi.RemoveButton({ Mac },(rsp) => {

	// 		//console.log("Nucleus Response", rsp);

	// 		this.emit("onButtonRemoved", rsp);
	// 	});
	// }

	// updateButtonType({ Mac, Type }) {
	// 	console.log("updateButtonType ", Mac, Type );

	// 	this.CareAccountApi.UpdateButtonType({ Mac, Type },(rsp) => {

	// 		//console.log("Nucleus Response", rsp);

	// 		this.emit("onButtonTypeUpdated", rsp);
	// 	});
	// }

	// Bulletin Butons
	getAccountBulletinButtons({ AccountID }) {
		console.log("getAccountBulletinButtons ", AccountID );

		this.CareAccountApi.GetAccountBulletinButtons({ AccountID },(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onGetAccountBulletinButtons", rsp);
		});
	}

	//Biometric Devices
	getAccountBiometricDevices({ AccountID }) {
		console.log("getAccountBiometricDevices ", AccountID );

		this.CareAccountApi.GetAccountBiometricDevices({ AccountID },(rsp) => {

			this.emit("onGetAccountBiometricDevices", rsp);
		});
	}

	addBiometricDevice({ AccountID, Mac, Type }) {
		console.log("addBiometricDevice ", AccountID, Mac, Type );

		this.CareAccountApi.AddBiometricDevice({ AccountID, Mac, Type },(rsp) => {

			////console.log("Nucleus Response", rsp);

			this.emit("onBiometricDeviceAdded", rsp);
		});
	}

	handleOpenEditBioDeviceModal({ Mac, Type}) {
		console.log("handleOpenEditBioDeviceModal", Mac, Type);

		this.emit("onEditBioDeviceModal", {
			BioDeviceMac : Mac,
			BioDeviceType : Type
		});
	}

	updateBioDeviceType({ Mac, Type }) {
		console.log("updateBioDeviceType ", Mac, Type );

		this.CareAccountApi.UpdateBioDeviceType({ Mac, Type },(rsp) => {

			////console.log("Nucleus Response", rsp);

			this.emit("onBiometricDeviceUpdated", rsp);
		});
	}

	handleOpenDeleteBioDeviceModal({  Mac}) {
		console.log("handleOpenDeleteBioDeviceModal",  Mac);

		this.emit("onDeleteBioDeviceModal", {
			BioDeviceMac : Mac
		});
	}

	removeBioDevice({ Mac }) {
		console.log("removeBioDevice ", Mac );

		this.CareAccountApi.RemoveBioDevice({ Mac },(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onBioDeviceRemoved", rsp);
		});
	}
	


	createNewUser ({AccountID, FirstName, LastName, Email, Telephone, Address, IsAdmin, ReceiveEscalationAlerts, CanSeeStaffTab, CanSeeClientsTab, CanSeeQueueTab, CanSeeMessageCenterTab, CanSeeAlertsTab, CanSeeMemoryBox, CanSeeBulletinBoard }) {
		console.log("createNewUser ", AccountID, FirstName, LastName, Email, Telephone, Address, IsAdmin, ReceiveEscalationAlerts, CanSeeStaffTab, CanSeeClientsTab, CanSeeQueueTab, CanSeeMessageCenterTab, CanSeeAlertsTab, CanSeeMemoryBox, CanSeeBulletinBoard );

		this.CareAccountApi.CreateNewUser({
			AccountID,
			FirstName,
			LastName,
			Email,
			Telephone,
			Address,
			IsAdmin,
			//ReceiveEscalationCallRequest: ReceiveEscalationAlerts,
			CanSeeStaffTab,
			CanSeeClientsTab,
			CanSeeQueueTab,
			CanSeeMessageCenterTab,
			CanSeeAlertsTab,
			CanSeeMemoryBox,
			CanSeeBulletinBoard,
			CanSendMessageToAllPatients: false,
			},(rsp) => {

			console.log("Nucleus Response", rsp);

			this.emit("onAccountUserCreated", rsp);
		});
	}

	updateAccountUser ({UserID , FirstName, LastName, Email, Telephone, Address, Password, IsAdmin, IsOnDuty}) {
		console.log("updateAccountUser ", UserID , FirstName, LastName, Email, Telephone, Address, Password, IsAdmin, IsOnDuty);

		this.CareAccountApi.UpdateAccountUser({UserID , FirstName, LastName, Email, Telephone, Address, Password, IsAdmin, IsOnDuty},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onAccountUserUpdated", rsp);
		});
	}


	UpdateReceiveEscalation({UserID , ReceiveEscalationCallRequest}) {
		console.log("UpdateReceiveEscalation ", UserID , ReceiveEscalationCallRequest);

		this.CareAccountApi.UpdateReceiveEscalation({UserID , ReceiveEscalationCallRequest},(rsp) => {

			rsp["canReceive"] = ReceiveEscalationCallRequest;

			this.emit("onUpdateReceiveEscalation", rsp);
		});
	}


	setStaffAccess({UserID , CanSeeStaffTab}) {
		console.log("SetStaffAccess ", UserID , CanSeeStaffTab);

		this.CareAccountApi.SetStaffAccess({UserID , CanSeeStaffTab},(rsp) => {

			rsp["enable"] = CanSeeStaffTab;

			this.emit("onSetStaffAccess", rsp);
		});
	}

	setClientsAccess({UserID , CanSeeClientsTab}) {
		console.log("SetClientsAccess ", UserID , CanSeeClientsTab);

		this.CareAccountApi.SetClientsAccess({UserID , CanSeeClientsTab},(rsp) => {

			rsp["enable"] = CanSeeClientsTab;

			this.emit("onSetClientsAccess", rsp);
		});
	}

	setQueueAccess({UserID , CanSeeQueueTab}) {
		console.log("SetQueueAccess ", UserID , CanSeeQueueTab);

		this.CareAccountApi.SetQueueAccess({UserID , CanSeeQueueTab},(rsp) => {

			rsp["enable"] = CanSeeQueueTab;

			this.emit("onSetQueueAccess", rsp);
		});
	}

	setMessagesAccess({UserID , CanSeeMessageCenterTab}) {
		console.log("SetMessagesAccess ", UserID , CanSeeMessageCenterTab);

		this.CareAccountApi.SetMessagesAccess({UserID , CanSeeMessageCenterTab},(rsp) => {

			rsp["enable"] = CanSeeMessageCenterTab;

			this.emit("onSetMessagesAccess", rsp);
		});
	}

	setAlertsAccess({UserID , CanSeeAlertsTab}) {
		console.log("setAlertsAccess ", UserID , CanSeeAlertsTab);

		this.CareAccountApi.SetAlertsAccess({UserID , CanSeeAlertsTab},(rsp) => {

			rsp["enable"] = CanSeeAlertsTab;

			this.emit("onSetAlertsAccess", rsp);
		});
	}

	setMemoryAccess({UserID , CanSeeMemoryBox}) {
		console.log("setMemoryAccess ", UserID , CanSeeMemoryBox);

		this.CareAccountApi.SetMemoryAccess({UserID , CanSeeMemoryBox},(rsp) => {

			rsp["enable"] = CanSeeMemoryBox;

			this.emit("onSetMemoryAccess", rsp);
		});
	}

	setBulletinBoardAccess({UserID, CanSeeBulletinBoard}){
		console.log("setBulletinBoardAccess", UserID, CanSeeBulletinBoard);

		this.CareAccountApi.SetBulletinBoardAccess({UserID, CanSeeBulletinBoard},(rsp) =>{
			rsp["enable"] = CanSeeBulletinBoard;

			this.emit("onSetBulletinBoardAccess", rsp);
		});

	}

	UpdateUserBroadcastMessage ({UserID , CanSendMessageToAllPatients}) {
		console.log("UpdateUserBroadcastMessage ", UserID , CanSendMessageToAllPatients);

		this.CareAccountApi.UpdateUserBroadcastMessage({UserID , CanSendMessageToAllPatients},(rsp) => {

			//console.log("Nucleus Response", rsp);

			rsp["canSend"] = CanSendMessageToAllPatients;

			this.emit("onUpdateUserBroadcastMessage", rsp);
		});
	}

	deleteAccountUser({UserID}) {
		console.log("deleteAccountUser ", UserID);

		this.CareAccountApi.DeleteAccountUser({UserID},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onAccountUserDeleted", rsp);
		});
	}

	
	// getUserData ({ UserID }) {
	// 	console.log("getUserData ", UserID );

	// 	this.UserApi.GetCareUserData({ UserID },(rsp) => {

	// 		console.log("getUserData Response");
	// 		console.log(rsp);
	// 		if (rsp.ok){
	// 			this.CareUserID = UserID;
	// 		}
	// 		this.emit("onUserGetData", rsp);
	// 	});
	// }

	// updateAccountClientRoleName({AccountID, ClientRoleName}) {
	// 	console.log("updateAccountClientRoleName ", AccountID, ClientRoleName);
	// 	this.CareAccountApi.UpdateAccountClientRoleName({AccountID, ClientRoleName},(rsp) => {
	// 		//console.log("Nucleus Response", rsp);
	// 		this.emit("onClientRoleNameUpdated", rsp);
	// 	});
	// }
	// updateAccountClientRoleNamePlural({AccountID, ClientRoleNamePlural}) {
	// 	console.log("updateAccountClientRoleNamePlural ", AccountID, ClientRoleNamePlural);
	// 	this.CareAccountApi.UpdateAccountClientRoleNamePlural({AccountID, ClientRoleNamePlural},(rsp) => {
	// 		//console.log("Nucleus Response", rsp);
	// 		this.emit("onClientRoleNamePluralUpdated", rsp);
	// 	});
	// }
	// updateAccountFamilyRoleName({AccountID, FamilyRoleName}) {
	// 	console.log("updateAccountFamilyRoleName ", AccountID, FamilyRoleName);
	// 	this.CareAccountApi.UpdateAccountFamilyRoleName({AccountID, FamilyRoleName},(rsp) => {
	// 		//console.log("Nucleus Response", rsp);
	// 		this.emit("onFamilyRoleNameUpdated", rsp);
	// 	});
	// }

	// updateAccountFamilyRoleNamePlural({AccountID, FamilyRoleNamePlural}) {
	// 	console.log("updateAccountFamilyRoleNamePlural ", AccountID, FamilyRoleNamePlural);
	// 	this.CareAccountApi.updateAccountFamilyRoleNamePlural({AccountID, FamilyRoleNamePlural},(rsp) => {
	// 		//console.log("Nucleus Response", rsp);
	// 		this.emit("onFamilyRoleNameUpdated", rsp);
	// 	});
	// }
	
	// updateAccountUserRoleName({AccountID, UserRoleName}) {
	// 	console.log("updateAccountUserRoleName ", AccountID, UserRoleName);
	// 	this.CareAccountApi.UpdateAccountUserRoleName({AccountID, UserRoleName},(rsp) => {
	// 		//console.log("Nucleus Response", rsp);
	// 		this.emit("onUserRoleNameUpdated", rsp);
	// 	});
	// }

	// updateAccountUserRoleNamePlural({AccountID, UserRoleNamePlural}) {
	// 	console.log("updateAccountUserRoleNamePlural ", AccountID, UserRoleNamePlural);
	// 	this.CareAccountApi.UpdateAccountUserRoleNamePlural({AccountID, UserRoleNamePlural},(rsp) => {
	// 		//console.log("Nucleus Response", rsp);
	// 		this.emit("onUserRoleNamePluralUpdated", rsp);
	// 	});
	// }

	// updateAccountCaregiverRoleName({AccountID, CaregiverRoleName}) {
	// 	console.log("updateAccountCaregiverRoleName ", AccountID, CaregiverRoleName);
	// 	this.CareAccountApi.UpdateAccountCaregiverRoleName({AccountID, CaregiverRoleName},(rsp) => {
	// 		//console.log("Nucleus Response", rsp);
	// 		this.emit("onCaregiverRoleNameUpdated", rsp);
	// 	});
	// }

	// updateAccountCaregiverRoleNamePlural({AccountID, CaregiverRoleNamePlural}) {
	// 	console.log("updateAccountCaregiverRoleNamePlural ", AccountID, CaregiverRoleNamePlural);
	// 	this.CareAccountApi.UpdateAccountCaregiverRoleNamePlural({AccountID, CaregiverRoleNamePlural},(rsp) => {
	// 		//console.log("Nucleus Response", rsp);
	// 		this.emit("onCaregiverRoleNamePluralUpdated", rsp);
	// 	});
	// }


	// updateActionButtonText({AccountID, CallButtonText}) {
	// 	console.log("updateActionButtonText ", AccountID, CallButtonText);
	// 	this.CareAccountApi.UpdateActionButtonText({AccountID, CallButtonText},(rsp) => {
	// 		//console.log("Nucleus Response", rsp);
	// 		this.emit("onActionButtonTextUpdated", rsp);
	// 	});
	// }

	// updateCallButtonText2({AccountID, CallButtonText2}) {
	// 	console.log("updateCallButtonText2 ", AccountID, CallButtonText2);
	// 	this.CareAccountApi.UpdateCallButtonText2({AccountID, CallButtonText2},(rsp) => {
	// 		//console.log("Nucleus Response", rsp);
	// 		this.emit("onCallButtonText2Updated", rsp);
	// 	});
	// }


	updateSendToPagerDuty({AccountID, SendToPagerDuty}) {
		console.log("updateSendToPagerDuty ", AccountID, SendToPagerDuty);

		this.CareAccountApi.UpdateSendToPagerDuty({AccountID, SendToPagerDuty},(rsp) => {

			//console.log("Nucleus Response", rsp);
			rsp["enable"] = SendToPagerDuty;

			this.emit("onSendToPagerDutyUpdated", rsp);
		});
	}

	updatePagerDutyEmail({AccountID, PagerDutyEmailAddress}) {
		console.log("updatePagerDutyEmail ", AccountID, PagerDutyEmailAddress);

		this.CareAccountApi.UpdatePagerDutyEmail({AccountID, PagerDutyEmailAddress},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onPagerDutyEmailUpdated", rsp);
		});
	}

	updateSendWelcomeEmail({AccountID, ShouldSendWelcomeEmail}) {
		console.log("updateSendWelcomeEmail ", AccountID, ShouldSendWelcomeEmail);

		this.CareAccountApi.UpdateSendWelcomeEmail({AccountID, ShouldSendWelcomeEmail},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onSendWelcomeEmailUpdated", rsp);
		});
	}

	updateSendWelcomeSms({AccountID, ShouldSendWelcomeSms}) {
		console.log("updateSendWelcomeSms ", AccountID, ShouldSendWelcomeSms);

		this.CareAccountApi.UpdateSendWelcomeSms({AccountID, ShouldSendWelcomeSms},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onSendWelcomeSmsUpdated", rsp);
		});
	}

	updateEnableMoveFamily({AccountID, EnableMoveFamily}) {
		console.log("updateEnableMoveFamily ", AccountID, EnableMoveFamily);

		this.CareAccountApi.UpdateEnableMoveFamily({AccountID, EnableMoveFamily},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onEnableMoveFamilyUpdated", rsp);
		});
	}

	updateOnDutyUsersByEmail({AccountID, Enabled}){
		console.log("updateOnDutyUsersByEmail ", AccountID, Enabled)

		this.CareAccountApi.UpdateOnDutyUsersByEmail({AccountID, Enabled}, (rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onAlertOnDutyUsersByEmailUpdated", rsp);
		});
	}

	updateNotifyDeviceOffline({AccountID, NotifyOnDeviceOffline}) {
		console.log("updateNotifyDeviceOffline ", AccountID, NotifyOnDeviceOffline);

		this.CareAccountApi.UpdateNotifyDeviceOffline({AccountID, NotifyOnDeviceOffline},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onNotifyOnDeviceOfflineUpdated", rsp);
		});
	}

	clearSMSandEmailAccountAlerts({AccountID, AlertTypeTag}){
		this.CareAccountApi.ClearSMSandEmailAccountAlerts({AccountID,AlertTypeTag},rsp =>{

			//console.log("Nucleus Response", rsp);

			//this.emit("onClearingSMSandEmailAccountAlertsUpdated", rsp);
		})
	}

	updateDeviceOfflineEnable({AccountID, AlertTypeTag, Enable}) {
		console.log("updateDeviceOfflineEnable", AccountID, AlertTypeTag, Enable);

		this.CareAccountApi.UpdateAccountAlertSettingEnable({AccountID, AlertTypeTag, Enable},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onDeviceOfflineEnableUpdated", rsp);
		});
	}

	updateDeviceOfflineSMS({AccountID, AlertTypeTag, ShouldSendSMS}) {
		console.log("updateDeviceOfflineSMS ", AccountID, AlertTypeTag, ShouldSendSMS);

		this.CareAccountApi.UpdateAccountAlertSettingSMS({AccountID, AlertTypeTag, ShouldSendSMS},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onDeviceOfflineSMSUpdated", rsp);
		});
	}

	updateDeviceOfflineEmail({AccountID, AlertTypeTag, ShouldSend}){
		console.log("updateDeviceOfflineEmail ", AccountID, AlertTypeTag, ShouldSend);

		this.CareAccountApi.UpdateAccountAlertSettingEmail({AccountID, AlertTypeTag, ShouldSend},(rsp) => {

			//console.log("Device Offline Email", rsp);

			this.emit("onDeviceOfflineEmailUpdated", rsp);
		});
	}

	updateCallNotAnsweredEnable({AccountID, AlertTypeTag, Enable}){
		console.log("updateCallNotAnsweredEnable ", AccountID, AlertTypeTag, Enable);

		this.CareAccountApi.UpdateAccountAlertSettingEnable({AccountID, AlertTypeTag, Enable},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onCallNotAnsweredEnableUpdated", rsp);
		});
	}

	updateCallNotEstablishedEnabled({AccountID, AlertTypeTag, Enable}){
		console.log("updateCallNotEstablishedEnabled ", AccountID, AlertTypeTag, Enable);

		this.CareAccountApi.UpdateAccountAlertSettingEnable({AccountID, AlertTypeTag, Enable},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onCallNotEstablishedEnabledUpdated", rsp);
		});
	}

	updateCallNotAnsweredSMS({AccountID, AlertTypeTag, ShouldSendSMS}){
		console.log("updateCallNotAnsweredSMS ", AccountID, AlertTypeTag, ShouldSendSMS);

		this.CareAccountApi.UpdateAccountAlertSettingSMS({AccountID, AlertTypeTag, ShouldSendSMS},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onCallNotAnsweredSMSUpdated", rsp);
		});
	}

	updateCallNotEstablishedSMS({AccountID, AlertTypeTag, ShouldSendSMS}){
		console.log("updateCallNotEstablishedSMS ", AccountID, AlertTypeTag, ShouldSendSMS);

		this.CareAccountApi.UpdateAccountAlertSettingSMS({AccountID, AlertTypeTag, ShouldSendSMS},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onCallNotEstablishedSMSUpdated", rsp);
		});
	}

	updateCallNotAnsweredEmail({AccountID, AlertTypeTag, ShouldSend}){
		console.log("updateCallNotAnsweredEmail ", AccountID, AlertTypeTag, ShouldSend);

		this.CareAccountApi.UpdateAccountAlertSettingEmail({AccountID, AlertTypeTag, ShouldSend},(rsp) => {

			//console.log("Call Not Answered", rsp);

			this.emit("onCallNotAnsweredEmailUpdated", rsp);
		});
	}

	updateCallNotEstablishedEmail({AccountID, AlertTypeTag, ShouldSend}){
		console.log("updateCallNotEstablishedEmail ", AccountID, AlertTypeTag, ShouldSend);

		this.CareAccountApi.UpdateAccountAlertSettingEmail({AccountID, AlertTypeTag, ShouldSend},(rsp) => {

			//console.log("Call Not Established", rsp);

			this.emit("onCallNotEstablishedEmailUpdated", rsp);
		});
	}

	updateMessageAlertEnable({AccountID, AlertTypeTag, Enable}){
		console.log("updateMessageAlertEnable ", AccountID, AlertTypeTag, Enable);

		this.CareAccountApi.UpdateAccountAlertSettingEnable({AccountID, AlertTypeTag, Enable},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onMessageAlertEnableUpdated", rsp);
		});
	}

	updateMessageAlertSMS({AccountID, AlertTypeTag, ShouldSendSMS}){
		console.log("updateMessageAlertSMS ", AccountID, AlertTypeTag, ShouldSendSMS);

		this.CareAccountApi.UpdateAccountAlertSettingSMS({AccountID, AlertTypeTag, ShouldSendSMS},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onMessageAlertSMSUpdated", rsp);
		});
	}

	

	updateMessageAlertEmail({AccountID, AlertTypeTag, ShouldSend}){
		console.log("updateMessageAlertEmail ", AccountID, AlertTypeTag, ShouldSend);

		this.CareAccountApi.UpdateAccountAlertSettingEmail({AccountID, AlertTypeTag, ShouldSend},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onMessageAlertEmailUpdated", rsp);
		});
	}

	updateBiometricAlertEnable({AccountID, AlertTypeTag, Enable}){
		console.log("updateBiometricAlertEnable ", AccountID, AlertTypeTag, Enable);

		this.CareAccountApi.UpdateAccountAlertSettingEnable({AccountID, AlertTypeTag, Enable},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onBiometricAlertEnableUpdated", rsp);
		});
	}

	updateBiometricAlertSMS({AccountID, AlertTypeTag, ShouldSendSMS}){
		console.log("updateBiometricAlertSMS ", AccountID, AlertTypeTag, ShouldSendSMS);

		this.CareAccountApi.UpdateAccountAlertSettingSMS({AccountID, AlertTypeTag, ShouldSendSMS},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onBiometricAlertSMSUpdated", rsp);
		});
	}

	updateBiometricAlertEmail({AccountID, AlertTypeTag, ShouldSend}){
		console.log("updateBiometricAlertEmail ", AccountID, AlertTypeTag, ShouldSend);

		this.CareAccountApi.UpdateAccountAlertSettingEmail({AccountID, AlertTypeTag, ShouldSend},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onBiometricAlertEmailUpdated", rsp);
		});
	}

	updateMiscAlertEnable({AccountID, AlertTypeTag, Enable}){
		console.log("updateMiscAlertEnable ", AccountID, AlertTypeTag, Enable);

		this.CareAccountApi.UpdateAccountAlertSettingEnable({AccountID, AlertTypeTag, Enable},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onMiscAlertEnableUpdated", rsp);
		});
	}
	updateBatteryAlertEnable({AccountID, AlertTypeTag, Enable}){
		console.log("updateBatteryAlertEnable ", AccountID, AlertTypeTag, Enable);

		this.CareAccountApi.UpdateAccountAlertSettingEnable({AccountID, AlertTypeTag, Enable},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onBatteryAlertEnableUpdated", rsp);
		});
	}

	updateMiscAlertSMS({AccountID, AlertTypeTag, ShouldSendSMS}){
		console.log("updateMiscAlertSMS ", AccountID, AlertTypeTag, ShouldSendSMS);

		this.CareAccountApi.UpdateAccountAlertSettingSMS({AccountID, AlertTypeTag, ShouldSendSMS},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onMiscAlertSMSUpdated", rsp);
		});
	}

	updateMiscAlertEmail({AccountID, AlertTypeTag, ShouldSend}){
		console.log("updateMiscAlertEmail ", AccountID, AlertTypeTag, ShouldSend);

		this.CareAccountApi.UpdateAccountAlertSettingEmail({AccountID, AlertTypeTag, ShouldSend},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onMiscAlertEmailUpdated", rsp);
		});
	}

	updateBatteryAlertSMS({AccountID, AlertTypeTag, ShouldSendSMS}){
		console.log("updateBatteryAlertSMS ", AccountID, AlertTypeTag, ShouldSendSMS);

		this.CareAccountApi.UpdateAccountAlertSettingSMS({AccountID, AlertTypeTag, ShouldSendSMS},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onBatteryAlertSMSUpdated", rsp);
		});
	}

	updateBatteryAlertEmail({AccountID, AlertTypeTag, ShouldSend}){
		console.log("updateBatteryAlertEmail ", AccountID, AlertTypeTag, ShouldSend);

		this.CareAccountApi.UpdateAccountAlertSettingEmail({AccountID, AlertTypeTag, ShouldSend},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onBatteryAlertEmailUpdated", rsp);
		});
	}

	updateHomeAlertEnable({AccountID, AlertTypeTag, Enable}){
		console.log("updateHomeAlertEnable ", AccountID, AlertTypeTag, Enable);

		this.CareAccountApi.UpdateAccountAlertSettingEnable({AccountID, AlertTypeTag, Enable},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onHomeAlertEnableUpdated", rsp);
		});
	}

	updateHomeAlertSMS({AccountID, AlertTypeTag, ShouldSendSMS}){
		console.log("updateHomeAlertSMS ", AccountID, AlertTypeTag, ShouldSendSMS);

		this.CareAccountApi.UpdateAccountAlertSettingSMS({AccountID, AlertTypeTag, ShouldSendSMS},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onHomeAlertSMSUpdated", rsp);
		});
	}

	updateHomeAlertEmail({AccountID, AlertTypeTag, ShouldSend}){
		console.log("updateHomeAlertEmail ", AccountID, AlertTypeTag, ShouldSend);

		this.CareAccountApi.UpdateAccountAlertSettingEmail({AccountID, AlertTypeTag, ShouldSend},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onHomeAlertEmailUpdated", rsp);
		});
	}

	updateBleDevicesAlertEnable({AccountID, AlertTypeTag, Enable}){
		console.log("updateBleDevicesAlertEnable ", AccountID, AlertTypeTag, Enable);

		this.CareAccountApi.UpdateAccountAlertSettingEnable({AccountID, AlertTypeTag, Enable},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onBleDevicesAlertEnableUpdated", rsp);
		});
	}

	updateBleDevicesAlertSMS({AccountID, AlertTypeTag, ShouldSendSMS}){
		console.log("updateBleDevicesAlertSMS ", AccountID, AlertTypeTag, ShouldSendSMS);

		this.CareAccountApi.UpdateAccountAlertSettingSMS({AccountID, AlertTypeTag, ShouldSendSMS},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onBleDevicesAlertSMSUpdated", rsp);
		});
	}

	updateBleDevicesAlertEmail({AccountID, AlertTypeTag, ShouldSend}){
		console.log("updateBleDevicesAlertEmail ", AccountID, AlertTypeTag, ShouldSend);

		this.CareAccountApi.UpdateAccountAlertSettingEmail({AccountID, AlertTypeTag, ShouldSend},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onBleDevicesAlertEmailUpdated", rsp);
		});
	}

	updateAlertExtremeTemperature({AccountID, Enabled}){
		console.log("updateAlertExtremeTemperature", AccountID, Enabled);
		
		this.CareAccountApi.UpdateAlertExtremeTemperature({AccountID, Enabled}, (rsp) => {
			
			//console.log("Nucleus Response", rsp);

			this.emit("onAlertOnExtremeRoomTemperatureUpdated", rsp);
		});
	}

	updateUsePanicRingtone({AccountID, UsePanicEmergencyRingtone}) {
		console.log("updateUsePanicRingtone ", AccountID, UsePanicEmergencyRingtone);

		this.CareAccountApi.UpdateUsePanicRingtone({AccountID, UsePanicEmergencyRingtone},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onUsePanicRingtoneUpdated", rsp);
		});
	}

	updateMessageToAllPatients({AccountID, EnableMessageToAllPatients}) {
		console.log("updateMessageToAllPatients ", AccountID, EnableMessageToAllPatients);

		this.CareAccountApi.UpdateMessageToAllPatients({AccountID, EnableMessageToAllPatients},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onMessageToAllPatientsUpdated", rsp);
		});
	}

	updateControlIncomingCall({AccountID, EnableControlIncomingCall}) {
		console.log("updateControlIncomingCall ", AccountID, EnableControlIncomingCall);

		this.CareAccountApi.UpdateControlIncomingCall({AccountID, EnableControlIncomingCall},(rsp) => {

			//console.log("Nucleus Response", rsp);

			rsp["status"] = EnableControlIncomingCall;

			this.emit("onEnableControlIncomingCallUpdated", rsp);
		});
	}

	updateAppIncomingCall({AccountID, EnableAppIncomingCall}) {
		console.log("updateAppIncomingCall ", AccountID, EnableAppIncomingCall);

		this.CareAccountApi.UpdateAppIncomingCall({AccountID, EnableAppIncomingCall},(rsp) => {

			//console.log("Nucleus Response", rsp);

			rsp["status"] = EnableAppIncomingCall;

			this.emit("onEnableAppIncomingCallUpdated", rsp);
		});
	}

	updateControlVideoCallShortcut({AccountID, EnableControlVideoCallShortcut}) {
		console.log("updateControlVideoCallShortcut ", AccountID, EnableControlVideoCallShortcut);

		this.CareAccountApi.UpdateControlVideoCallShortcut({AccountID, EnableControlVideoCallShortcut},(rsp) => {

			//console.log("Nucleus Response", rsp);

			rsp["status"] = EnableControlVideoCallShortcut;

			this.emit("onEnableControlVideoCallShortcutUpdated", rsp);
		});
	}
	updateControlAudioCallShortcut({AccountID, EnableControlAudioCallShortcut}) {
		console.log("updateControlAudioCallShortcut ", AccountID, EnableControlAudioCallShortcut);

		this.CareAccountApi.UpdateControlAudioCallShortcut({AccountID, EnableControlAudioCallShortcut},(rsp) => {

			//console.log("Nucleus Response", rsp);

			rsp["status"] = EnableControlAudioCallShortcut;

			this.emit("onEnableControlAudioCallShortcutUpdated", rsp);
		});
	}
	updateControlBargeInCallShortcut({AccountID, EnableControlBargeInCallShortcut}) {
		console.log("updateControlBargeInCallShortcut ", AccountID, EnableControlBargeInCallShortcut);

		this.CareAccountApi.UpdateControlBargeInCallShortcut({AccountID, EnableControlBargeInCallShortcut},(rsp) => {

			//console.log("Nucleus Response", rsp);

			rsp["status"] = EnableControlBargeInCallShortcut;

			this.emit("onEnableControlBargeInCallShortcutUpdated", rsp);
		});
	}
	updateControlSilentBargeInCallShortcut({AccountID, EnableControlSilentBargeInCallShortcut}) {
		console.log("updateControlSilentBargeInCallShortcut ", AccountID, EnableControlSilentBargeInCallShortcut);

		this.CareAccountApi.UpdateControlSilentBargeInCallShortcut({AccountID, EnableControlSilentBargeInCallShortcut},(rsp) => {

			//console.log("Nucleus Response", rsp);

			rsp["status"] = EnableControlSilentBargeInCallShortcut;

			this.emit("onEnableControlSilentBargeInCallShortcutUpdated", rsp);
		});
	}
	updateControlAutomaticCallRequestBargeIn({AccountID, EnableControlAutomaticCallRequestBargeIn}) {
		console.log("updateControlAutomaticCallRequestBargeIn ", AccountID, EnableControlAutomaticCallRequestBargeIn);

		this.CareAccountApi.UpdateControlAutomaticCallRequestBargeIn({AccountID, EnableControlAutomaticCallRequestBargeIn},(rsp) => {

			console.log("Nucleus Response", rsp);

			rsp["status"] = EnableControlAutomaticCallRequestBargeIn;

			this.emit("onEnableControlAutomaticCallRequestBargeInUpdated", rsp);
		});
	}

	update2FactorAuth({ AccountID, Enabled }) {
		console.log("update2FactorAuth ", AccountID, Enabled);

		this.CareAccountApi.Update2FactorAuth({ AccountID, Enabled }, (rsp) => {

			console.log("Nucleus Response", rsp);

			this.emit("on2FactorAuthUpdated", rsp);
		});
	}

	updateRemoteLogs({ AccountID, Enabled }) {
		console.log("updateRemoteLogs ", AccountID, Enabled);

		this.CareAccountApi.UpdateRemoteLogs({ AccountID, Enabled }, (rsp) => {

			console.log("Nucleus Response", rsp);

			this.emit("onRemoteLogsUpdated", rsp);
		});
	}

	updateEnableMemoryBox({AccountID, EnableMemoryBox}) {
		console.log("updateEnableMemoryBox ", AccountID, EnableMemoryBox);

		this.CareAccountApi.UpdateEnableMemoryBox({AccountID, EnableMemoryBox},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onEnableMemoryBoxUpdated", rsp);
		});
	}

	// updateMemoryBoxLabel({AccountID, MemoryBoxButtonText}) {
	// 	console.log("updateMemoryBoxLabel ", AccountID, MemoryBoxButtonText);
	// 	this.CareAccountApi.UpdateMemoryBoxLabel({AccountID, MemoryBoxButtonText},(rsp) => {
	// 		//console.log("Nucleus Response", rsp);
	// 		this.emit("onMemoryBoxLabelUpdated", rsp);
	// 	});
	// }

	// updateFamilyCallButtonText({AccountID, FamilyCallButtonText}) {
	// 	console.log("updateFamilyCallButtonText ", AccountID, FamilyCallButtonText);
	// 	this.CareAccountApi.UpdateFamilyCallButtonText({AccountID, FamilyCallButtonText},(rsp) => {
	// 		//console.log("Nucleus Response", rsp);
	// 		this.emit("onFamilyMemberLabelUpdated", rsp);
	// 	});
	// }

	// updateBiometricsButtonText({AccountID, BiometricsButtonText}) {
	// 	console.log("updateBiometricsButtonText ", AccountID, BiometricsButtonText);
	// 	this.CareAccountApi.UpdateBiometricsButtonText({AccountID, BiometricsButtonText},(rsp) => {
	// 		//console.log("Nucleus Response", rsp);
	// 		this.emit("onBiometricsButtonLabelUpdated", rsp);
	// 	});
	// }

	// updateAddFamilyButtonText({AccountID, AddFamilyButtonText}) {
	// 	console.log("updateAddFamilyButtonText ", AccountID, AddFamilyButtonText);
	// 	this.CareAccountApi.UpdateAddFamilyButtonText({AccountID, AddFamilyButtonText},(rsp) => {
	// 		//console.log("Nucleus Response", rsp);
	// 		this.emit("onAddFamilyButtonLabelUpdated", rsp);
	// 	});
	// }

	// updateRequestTimeoutMessage({AccountID, RequestTimeoutText}) {
	// 	console.log("updateRequestTimeoutMessage ", AccountID, RequestTimeoutText);
	// 	this.CareAccountApi.UpdateRequestTimeoutMessage({AccountID, RequestTimeoutText},(rsp) => {
	// 		////console.log("Nucleus Response", rsp);
	// 		this.emit("onRequestTimeoutMessageUpdated", rsp);
	// 	});
	// }

	// updateOffHoursMessage({AccountID, Message}) {
	// 	console.log("updateOffHoursMessage ", AccountID, Message);
	// 	this.CareAccountApi.UpdateOffHoursMessage({AccountID, Message},(rsp) => {
	// 		this.emit("onOffHoursMessageUpdated", rsp);
	// 	});
	// }

	// Deprecated
	updateUseDeviceTemplate({AccountID, EnableDeviceTemplate}) {
		console.log("updateUseDeviceTemplate ", AccountID, EnableDeviceTemplate);

		this.CareAccountApi.UpdateUseDeviceTemplate({AccountID, EnableDeviceTemplate},(rsp) => {

			////console.log("Nucleus Response", rsp);

			this.emit("onUseDeviceTemplateUpdated", rsp);
		});
	}

	updateAccountTemplate({AccountID, TemplateID}) {
		console.log("updateAccountTemplate ", AccountID, TemplateID);

		this.CareAccountApi.UpdateDeviceTemplate({AccountID, TemplateID},(rsp) => {

			////console.log("Nucleus Response", rsp);

			this.emit("onAccountTemplateUpdated", rsp);
		});
	}

	updateEnableBackgroundImage({AccountID, Enable}) {
		console.log("updateEnableBackgroundImage ", AccountID, Enable);

		this.CareAccountApi.UpdateEnableBackgroundImage({AccountID, Enable},(rsp) => {

			////console.log("Nucleus Response", rsp);

			this.emit("onEnableBackgroundImageUpdated", rsp);
		});
	}

	updateEnableBackgroundLogo({AccountID, Enable}) {
		console.log("updateEnableBackgroundLogo ", AccountID, Enable);

		this.CareAccountApi.UpdateEnableBackgroundLogo({AccountID, Enable},(rsp) => {

			////console.log("Nucleus Response", rsp);

			this.emit("onEnableBackgroundLogoUpdated", rsp);
		});
	}

	uploadBackgroundImage({ AccountID,  MediaType, File }) {
		//console.log("UploadMedia ", UserID,  MediaType, File  );

		const data = new FormData();
            data.append('AccountID', AccountID);
            data.append('MediaType', MediaType);
            data.append('File',File);

            //console.log("data", data);

		this.CareAccountApi.UploadBackgroundImage(data,(rsp) => {

			this.emit("onUploadBackgroundImageComplete", rsp);
		});

		// if (this.timeout != null){
		// 	clearTimeout(this.timeout);
		// }
		// this.timeout = setTimeout(() =>{
		// 	this.emit("onUploadMediaComplete", { ok : true});
		// }, 1000);
	}

	uploadBackgroundLogo({ AccountID,  MediaType, File }) {
		//console.log("UploadMedia ", UserID,  MediaType, File  );

		const data = new FormData();
            data.append('AccountID', AccountID);
            data.append('MediaType', MediaType);
            data.append('File',File);

            //console.log("data", data);

		this.CareAccountApi.UploadBackgroundLogo(data,(rsp) => {

			this.emit("onUploadBackgroundLogoComplete", rsp);
		});
	}

	updateEnableBulletinButton({ButtonID, Enable}) {
		console.log("updateEnableBulletinButton ", ButtonID, Enable);

		this.CareAccountApi.UpdateEnableBulletinButton({ButtonID, Enable},(rsp) => {

			////console.log("Nucleus Response", rsp);

			this.emit("onEnableBulletinButtonUpdated", rsp);
		});
	}

	updateBulletinButtonName({ButtonID, Name}) {
		console.log("updateBulletinButtonName ", ButtonID, Name);

		this.CareAccountApi.UpdateBulletinButtonName({ButtonID, Name},(rsp) => {

			////console.log("Nucleus Response", rsp);

			this.emit("onBulletinButtonNameUpdated", rsp);
		});
	}

	updateBackgroundImgUrl({AccountID, UpdateBackgroundImageUrl}) {
		console.log("updateBackgroundImgUrl ", AccountID, UpdateBackgroundImageUrl);

		this.CareAccountApi.UpdateBackgroundImgUrl({AccountID, UpdateBackgroundImageUrl},(rsp) => {

			////console.log("Nucleus Response", rsp);

			this.emit("onBackgroundImgUrlUpdated", rsp);
		});
	}



	updateScreenSaverDelay({AccountID, ScreenSaverDelayInSeconds}) {
		console.log("updateScreenSaverDelay ", AccountID, ScreenSaverDelayInSeconds);

		this.CareAccountApi.UpdateScreenSaverDelay({AccountID, ScreenSaverDelayInSeconds},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onScreenSaverDelayUpdated", rsp);
		});
	}

	updateDimOffValue({AccountID, DimOffValue}){
		console.log("updateDimOffValue ", AccountID, DimOffValue);

		this.CareAccountApi.UpdateDimOffValue({AccountID, DimOffValue}, (rsp)=>{

			console.log("updateDimOffValue Response", rsp);

			this.emit("onDimOffBrightnessUpdated", rsp);
		});
	}

	updateDimOnValue({AccountID, DimOnValue}){
		console.log("updateDimOnValue ", AccountID, DimOnValue);

		this.CareAccountApi.UpdateDimOnValue({AccountID, DimOnValue}, (rsp)=>{

			console.log("updateDimOnValue Response", rsp);

			this.emit("onDimOnBrightnessUpdated", rsp);
		});
	}

	updateDimFrom({AccountID, DimFrom}){
		console.log("updateDimFrom ", AccountID, DimFrom);

		this.CareAccountApi.UpdateDimFrom({AccountID, DimFrom}, (rsp) => {

			console.log("updateDimFrom Response ", rsp);

			this.emit("onDimStartUpdated", rsp);
		});
	}

	updateDimTo({AccountID, DimTo}){
		console.log("updateDimTo ", AccountID, DimTo);

		this.CareAccountApi.UpdateDimTo({AccountID, DimTo}, (rsp) => {

			console.log("updateDimTo Response ", rsp);

			this.emit("onDimEndUpdated", rsp);
		});
	}

	updateCallTimeoutDelay({AccountID, CallTimeoutDelayInSeconds}) {
		console.log("updateCallTimeoutDelay ", AccountID, CallTimeoutDelayInSeconds);

		this.CareAccountApi.UpdateCallTimeoutDelay({AccountID, CallTimeoutDelayInSeconds},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onCallTimeoutDelayUpdated", rsp);
		});
	}

	updateEscalationTimeOut({AccountID, ReceiveEscalationCallRequestTimeout}) {
		console.log("updateEscalationTimeOut ", AccountID, ReceiveEscalationCallRequestTimeout);

		this.CareAccountApi.UpdateEscalationTimeOut({AccountID, ReceiveEscalationCallRequestTimeout},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onEscalationTimeOutUpdated", rsp);
		});
	}

	updateOfflineTimeSpan({AccountID, Minutes}) {
		console.log("updateOfflineTimeSpan ", AccountID, Minutes);

		this.CareAccountApi.UpdateOfflineTimeSpan({AccountID, Minutes},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onOfflineTimeSpanUpdated", rsp);
		});
	}

	updateInactiveDays({AccountID, Days}){
		console.log("updateInactiveDays ", AccountID, Days);

		this.CareAccountApi.UpdateInactiveDays({AccountID, Days}, (rsp)=>{

			console.log("updateInactiveDays Response", rsp);

			this.emit("onInactiveDaysUpdated", rsp);
		});
	}

	updateInactiveDaysAlert({AccountID, Days}){
		console.log("updateInactiveDaysAlert ", AccountID, Days);

		this.CareAccountApi.UpdateInactiveDaysAlert({AccountID, Days}, (rsp)=>{

			console.log("updateInactiveDaysAlert Response", rsp);

			this.emit("onInactiveDaysAlertUpdated", rsp);
		});
	}

	updateHexnodeGroupID({AccountID, HexnodeGroupID}){
		console.log("updateHexnodeGroupID ", AccountID, HexnodeGroupID);
		this.CareAccountApi.UpdateHexnodeGroupID({AccountID, HexnodeGroupID}, (rsp)=>{
			console.log("updateHexnodeGroupID Response", rsp);
			this.emit("onHexnodeGroupIDUpdated", rsp);
		});
	}

	updateHexnodePolicyID({AccountID, HexnodePolicyID}){
		console.log("updateHexnodePolicyID ", AccountID, HexnodePolicyID);
		this.CareAccountApi.UpdateHexnodePolicyID({AccountID, HexnodePolicyID}, (rsp)=>{
			console.log("UpdateHexnodePolicyID Response", rsp);
			this.emit("onHexnodePolicyIDUpdated", rsp);
		});
	}
	
	updateHexnodeUrl({AccountID, HexnodeUrl}){
		console.log("updateHexnodeUrl ", AccountID, HexnodeUrl);

		this.CareAccountApi.UpdateHexnodeUrl({AccountID, HexnodeUrl}, (rsp)=>{

			console.log("updateHexnodeUrl Response", rsp);

			this.emit("onHexnodeUrlUpdated", rsp);
		});
	}

	updateHexnodeUserName({AccountID, UserName}){
		console.log("updateHexnodeUserName ", AccountID, UserName);

		this.CareAccountApi.UpdateHexnodeUserName({AccountID, UserName}, (rsp)=>{

			console.log("updateHexnodeUserName Response", rsp);

			this.emit("onHexnodeUserNameUpdated", rsp);
		});
	}

	updateHexnodeAPI({AccountID, HexnodeAPI}){
		console.log("updateHexnodeHexnodeAPI ", AccountID, HexnodeAPI);

		this.CareAccountApi.updateHexnodeAPI({AccountID, HexnodeAPI}, (rsp)=>{

			console.log("updateHexnodeHexnodeAPI Response", rsp);

			this.emit("onHexnodeAPIUpdated", rsp);
		});
	}

	updateHexnodeIdRequired({AccountID, Required}){
		console.log("updateHexnodeIdRequired ", AccountID, Required);

		this.CareAccountApi.UpdateHexnodeIdRequired({AccountID, Required}, (rsp)=>{

			console.log("updateHexnodeIdRequired Response", rsp);
			rsp.Required = Required;
			this.emit("onHexnodeIdRequiredUpdated", rsp);
		});
	}

	updateSettingsPinCode({AccountID, SettingsAccessPIN}) {
		console.log("updateSettingsPinCode ", AccountID, SettingsAccessPIN);

		this.CareAccountApi.UpdateSettingsPinCode({AccountID, SettingsAccessPIN},(rsp) => {

			////console.log("Nucleus Response", rsp);

			this.emit("onSettingsAccessPINUpdated", rsp);
		});
	}

	updateIsCheckedInScreenStaysOn({AccountID, IsCheckedInScreenStaysOn}){
		console.log("updateIsCheckedInScreenStaysOn", AccountID, IsCheckedInScreenStaysOn);

		this.CareAccountApi.UpdateIsCheckedInScreenStaysOn({AccountID, IsCheckedInScreenStaysOn},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onCheckedInScreenStaysOnUpdated", rsp);
		});
	}

	updateRestartDeviceOffline({AccountID, Enabled}){
		console.log("updateRestartDeviceOffline", AccountID, Enabled);

		this.CareAccountApi.UpdateRestartDeviceOfflineEnabled({AccountID, Enabled},(rsp) => {

			//console.log("Nucleus Response", rsp);
			rsp["enabled"] = Enabled;

			this.emit("onRestartDeviceOfflineUpdated", rsp);
		});
	}

	updateRestartOfflineDeviceTimeSpan({AccountID, Minutes}){
		console.log("updateRestartOfflineDeviceTimeSpan", AccountID, Minutes);

		this.CareAccountApi.UpdateRestartOfflineDeviceTimeSpan({AccountID, Minutes},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onRestartOfflineDeviceTimeSpanUpdated", rsp);
		});
	}

	updateShowBatteryStatus({AccountID, ShowBatteryStatus}){
		console.log("updateShowBatteryStatus", AccountID, ShowBatteryStatus);
		this.CareAccountApi.UpdateShowBatteryStatus({AccountID, ShowBatteryStatus},(rsp) => {
			//console.log("Nucleus Response", rsp);
			rsp["enabled"] = ShowBatteryStatus;
			this.emit("onShowBatteryStatusUpdated", rsp);
		});
	}

	/* MOVED FROM USER STORE */
	getDailyCalls({ AccountID}) {
		console.log("getDailyCalls ", AccountID);

		this.CareAccountApi.getDailyCalls({ AccountID},(rsp) => {

			this.emit("onGetDailyCallsAction", rsp);
		});
	}

	getDailyHandledCalls({ AccountID}) {
		console.log("getDailyHandledCalls ", AccountID);

		this.CareAccountApi.getDailyHandledCalls({ AccountID},(rsp) => {

			console.log("getDailyHandledCalls Response");
			console.log(rsp);

			this.emit("onGetDailyHandledCallsAction", rsp);
		});
	}

	getFamilyMembers({ AccountID}) {
		console.log("getFamilyMembers ", AccountID);

		this.CareAccountApi.GetFamilyMembers({ AccountID},(rsp) => {

			console.log("getFamilyMembers Response");
			console.log(rsp);

			this.emit("onGetFamilyMembers", rsp);
		});
	}

	getMessagesReport({ AccountID}) {
		console.log("getMessagesReport ", AccountID);

		this.CareAccountApi.GetMessagesReport({ AccountID},(rsp) => {
			//console.log("getMessagesReport response ", rsp);
			this.emit("onGetMessagesReport", rsp);
		});
	}

	getFamilyCalls({ AccountID}) {
		console.log("getFamilyCalls ", AccountID);

		this.CareAccountApi.GetFamilyCalls({ AccountID},(rsp) => {

			//console.log("getFamilyCalls Response", rsp);

			this.emit("onGetFamilyCalls", rsp);
		});
	}

	getMediaItemsPerDayReport({ AccountID}) {
		console.log("getMediaItemsPerDayReport ", AccountID);

		this.CareAccountApi.GetMediaItemsPerDayReport({ AccountID},(rsp) => {

			//console.log("getMediaItemsPerDayReport Response", rsp);

			this.emit("onGetMediaItemsPerDayReport", rsp);
		});
	}


	getMessageCategories() {
		console.log("getMessageCategories ");

		this.CareAccountApi.GetMessageCategories({ },(rsp) => {

			//console.log("getMessageCategories Response", rsp);

			this.emit("onGetMessageCategories", rsp);
		});
	}

	saveMessageCategory({ Category, Icon, SoundEffect, TTLHours, DingRepeatInMin}) {
		console.log("saveMessageCategory ", Category, Icon, SoundEffect, TTLHours, DingRepeatInMin);

		this.CareAccountApi.SaveMessageCategory({ Category, Icon, SoundEffect, TTLHours, DingRepeatInMin },(rsp) => {

			//console.log("saveMessageCategory Response", rsp);

			this.emit("onMessageCategorySaved", rsp);
		});
	}

	updateMessageCategory({ ID, Category, Icon, SoundEffect, TTLHours, DingRepeatInMin}) {
		console.log("updateMessageCategory ", ID, Category, Icon, SoundEffect, TTLHours, DingRepeatInMin);

		this.CareAccountApi.UpdateMessageCategory({ ID, Category, Icon, SoundEffect, TTLHours, DingRepeatInMin },(rsp) => {

			//console.log("updateMessageCategory Response", rsp);

			this.emit("onMessageCategoryUpdated", rsp);
		});
	}

	// DEPRECATED on March 1st 2023, https://nucleusintercom.atlassian.net/browse/CARE-10228 
	// updateSendXmppPalCare({AccountID, Send}) {
	// 	console.log("updateSendXmppPalCare ", AccountID, Send);
	// 	this.CareAccountApi.UpdateSendXmppPalCare({AccountID, Send},(rsp) => {
	// 		////console.log("Nucleus Response", rsp);
	// 		rsp["send"] = Send;
	// 		this.emit("onSendXmppPalCareUpdated", rsp);
	// 	});
	// }

	// updateXmppPalCareAddress({AccountID, XMPPAddress}) {
	// 	console.log("updateXmppPalCareAddress ", AccountID, XMPPAddress);
	// 	this.CareAccountApi.UpdateXmppPalCareAddress({AccountID, XMPPAddress},(rsp) => {
	// 		////console.log("Nucleus Response", rsp);
	// 		this.emit("onXmppPalCareAddressUpdated", rsp);
	// 	});
	// }

	updateEnableMessageResponse({AccountID, Enable}) {
		console.log("updateEnableMessageResponse ", AccountID, Enable);

		this.CareAccountApi.UpdateEnableMessageResponse({AccountID, Enable},(rsp) => {

			////console.log("Nucleus Response", rsp);
			rsp["enable"] = Enable;

			this.emit("onEnableMessageResponseUpdated", rsp);
		});
	}

	updateMessageResponseEmail({AccountID, EmailAddress}) {
		console.log("updateMessageResponseEmail ", AccountID, EmailAddress);

		this.CareAccountApi.UpdateMessageResponseEmail({AccountID, EmailAddress},(rsp) => {

			////console.log("Nucleus Response", rsp);

			this.emit("onMessageResponseEmailUpdated", rsp);
		});
	}

	updateEscalationEmail({AccountID, EmailAddress}) {
		console.log("updateEscalationEmail ", AccountID, EmailAddress);

		this.CareAccountApi.UpdateEscalationEmail({AccountID, EmailAddress},(rsp) => {

			////console.log("Nucleus Response", rsp);

			this.emit("onEscalationEmailUpdated", rsp);
		});
	}


	saveToLocalStorage(){
		console.log("saveToLocalStorage");

		localStorage.AccountsList = JSON.stringify(this.AccountsList);
		localStorage.AccountGroups = this.AccountGroups;
		localStorage.UpdateGroups = this.UpdateGroups;
		localStorage.AccountLocale = this.AccountLocale;
		localStorage.AccountAlerts = this.AccountAlerts;
	}

	getAccountDataByMac({Mac}){
		console.log("getAccountDataByMac", Mac);

		this.CareAccountApi.GetAccountDataByMac({Mac},(rsp) => {
			
			//console.log("Nucleus Response ", rsp);

			this.emit("onSubmitData", rsp);
		});

	}

	updateIsHipaaCompliant({AccountID, IsHipaaCompliant}){
		console.log("updateIsHipaaCompliant ", AccountID, IsHipaaCompliant);

		this.CareAccountApi.UpdateIsHipaaCompliant({AccountID, IsHipaaCompliant},(rsp) =>{

			console.log("Nucleus Response ", rsp);

			this.emit("onHipaaCompliantUpdated", rsp);
		});
	}

	updateAutoLogoutTimeout({AccountID, AutoLogoutTimeout}) {
		console.log("updateAutoLogoutTimeout ", AccountID, AutoLogoutTimeout);

		this.CareAccountApi.UpdateAutoLogoutTimeout({AccountID, AutoLogoutTimeout},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onAutoLogoutUpdated", rsp);
		});
	}

	updateWeightUnit({AccountID, WeightUnit}) {
		console.log("updateWeightUnit ", AccountID, WeightUnit);

		this.CareAccountApi.UpdateWeightUnit({AccountID, WeightUnit},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onWeightUnitUpdated", rsp);
		});
	}
	

	updateHoursFormat({AccountID, HoursFormat}) {
		console.log("updateHoursFormat ", AccountID, HoursFormat);

		this.CareAccountApi.UpdateHoursFormat({AccountID, HoursFormat},(rsp) => {

			//console.log("Nucleus Response", rsp);

			this.emit("onHoursFormatUpdated", rsp);
		});
	}



	updateSalesforceEnabled({AccountID, Enabled}){
		console.log("updateSalesforceEnabled ", AccountID, Enabled);

		this.CareAccountApi.UpdateSalesforceEnabled({AccountID, Enabled},(rsp) =>{
			//console.log("Nucleus Response ", rsp);
			this.emit("onSalesforceEnabledUpdated", rsp);
		});
	}

	updateSalesforceOrgUrl({AccountID, OrgUrl}){
		console.log("updateSalesforceOrgUrl ", AccountID, OrgUrl);

		this.CareAccountApi.UpdateSalesforceOrgUrl({AccountID, OrgUrl}, (rsp)=>{
			//console.log("updateSalesforceOrgUrl Response", rsp);
			this.emit("onSalesforceOrgUrlUpdated", rsp);
		});
	}

	updateSalesforceLoginUrl({AccountID, LoginUrl}){
		console.log("updateSalesforceLoginUrl ", AccountID, LoginUrl);

		this.CareAccountApi.UpdateSalesforceLoginUrl({AccountID, LoginUrl}, (rsp)=>{
			//console.log("updateSalesforceOrgUrl Response", rsp);
			this.emit("onSalesforceLoginUrlUpdated", rsp);
		});
	}

	updateSalesforceClientID({AccountID, ClientID}){
		console.log("updateSalesforceClientID ", AccountID, ClientID);

		this.CareAccountApi.UpdateSalesforceClientID({AccountID, ClientID}, (rsp)=>{
			//console.log("updateSalesforceClientID Response", rsp);
			this.emit("onSalesforceClientIDUpdated", rsp);
		});
	}

	updateSalesforceClientSecret({AccountID, ClientSecret}){
		console.log("updateSalesforceClientSecret ", AccountID, ClientSecret);

		this.CareAccountApi.UpdateSalesforceClientSecret({AccountID, ClientSecret}, (rsp)=>{
			//console.log("updateSalesforceClientSecret Response", rsp);
			this.emit("onSalesforceClientSecretUpdated", rsp);
		});
	}

	updateSalesforceUserName({AccountID, UserName}){
		console.log("updateSalesforceUserName ", AccountID, UserName);

		this.CareAccountApi.UpdateSalesforceUserName({AccountID, UserName}, (rsp)=>{
			//console.log("updateSalesforceUserName Response", rsp);
			this.emit("onSalesforceUserNameUpdated", rsp);
		});
	}

	updateSalesforcePassword({AccountID, Password}){
		console.log("updateSalesforcePassword ", AccountID, Password);

		this.CareAccountApi.UpdateSalesforcePassword({AccountID, Password}, (rsp)=>{
			//console.log("updateSalesforcePassword Response", rsp);
			this.emit("onSalesforcePasswordUpdated", rsp);
		});
	}

	updateSalesforceRedirectUri({AccountID, RedirectUri}){
		console.log("updateSalesforceRedirectUri ", AccountID, RedirectUri);

		this.CareAccountApi.UpdateSalesforceRedirectUri({AccountID, RedirectUri}, (rsp)=>{
			//console.log("updateSalesforceRedirectUri Response", rsp);
			this.emit("onSalesforceRedirectUriUpdated", rsp);
		});
	}

	updateSalesforceSecurityToken({AccountID, SecurityToken}){
		console.log("updateSalesforceSecurityToken ", AccountID, SecurityToken);

		this.CareAccountApi.UpdateSalesforceSecurityToken({AccountID, SecurityToken}, (rsp)=>{
			//console.log("updateSalesforceSecurityToken Response", rsp);
			this.emit("onSalesforceSecurityTokenUpdated", rsp);
		});
	}

	checkSalesforceConfiguration({AccountID}){
		console.log("checkSalesforceConfiguration ", AccountID);

		this.CareAccountApi.onNucleusApiRequest(
			"Salesforce.asmx/CheckSalesforceConfiguration", {AccountID}, (rsp)=>{
			this.emit("onCheckSalesforceConfigurationResponse", rsp);
		});
	}

	requestNucleusApi(EndPoint, Params, ResponseEventString){
		console.log("requestNucleusApi ", EndPoint, Params, ResponseEventString);

		this.CareAccountApi.onNucleusApiRequest(
			EndPoint, Params, (rsp)=>{
			this.emit(ResponseEventString, rsp);
		});
	}

	// FAMILY MEMBERS
	// getAllFamilyMembers({CsUserID, SearchText}){
	// 	console.log("getAllFamilyMembers ", CsUserID, SearchText);

	// 	this.CareAccountApi.GetFamilyMembersData({CsUserID, SearchText}, (rsp)=>{
	// 		//console.log("updateSalesforcePassword Response", rsp);
	// 		this.emit("onGetFamilyMembersData", rsp);
	// 	});
	// }

	getAllFamilyMembers({CsUserID, SearchText}) {
		console.log("getAllFamilyMembers");
		let rsp;
		httpApi
		.post("/family_member/search",{
			FamilyInfo : SearchText
		})
		.then((response) => {
			
			const { data } = response;
			//console.log("response", data);
			rsp = {
				ok: true,
				FamilyMembers :data,
			};
			this.emit("onGetFamilyMembersData", rsp);
		})
		.catch((e) => {
			console.log("error", e.response);
			const { data } = e.response;
			rsp = {
				ok: false,
				...data,
			};
			this.emit("onGetFamilyMembersData", rsp);
		})
	
  	}

}

const careAccountStore = new CareAccountStore;

export default careAccountStore;
