import React from 'react';
import ReactDOM from "react-dom";
import Dispatcher from "../Dispatcher";
import { IndexLink, Link } from "react-router-dom";
import classNames from "classnames";

import DashboardInfo from "../components/DashboardInfo";

import AuthStore from "../stores/AuthStore";
import CareAccountStore from "../stores/CareAccountStore";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import { LineChart } from '@mui/x-charts/LineChart';

class DashboardContent extends React.Component
{
    constructor(props)
    {
        super();
        this.state = {
            chartData1 : []
        };
        this.onGetDailyCallsAction = this.onGetDailyCallsAction.bind(this);
        this.onGetDailyHandledCallsAction = this.onGetDailyHandledCallsAction.bind(this);

        this.root1 = null;
        this.root2 = null;
    }

    componentDidMount()
    {
        //Register Listener
        CareAccountStore.on("onGetDailyCallsAction", this.onGetDailyCallsAction);
        CareAccountStore.on("onGetDailyHandledCallsAction", this.onGetDailyHandledCallsAction);

        CareAccountStore.getDailyCallRequests({
            Token : AuthStore.getCsUserToken()
        });
        CareAccountStore.getDailyCallsMade({
            Token : AuthStore.getCsUserToken()
        });

        this.renderChart1 = this.renderChart1.bind(this);
    }

    componentWillUnmount()
    {
        //Remove Listener
        CareAccountStore.removeListener("onGetDailyCallsAction", this.onGetDailyCallsAction);
        CareAccountStore.removeListener("onGetDailyHandledCallsAction", this.onGetDailyHandledCallsAction);

        if (this.root1) {
            this.root1.dispose();
        }
				if (this.root2) {
					this.root2.dispose();
				}
    }

    onGetDailyCallsAction(data){

        this.setState({
            chartData1 : data.calls
        });

        this.drawChartCallsRequested(data.calls);
    }

    onGetDailyHandledCallsAction(data){
        // this.setState({
        //     chartData2 : data.calls
        // });

        this.drawChartCallsHandled(data.calls);
    }

    drawChartCallsRequested(calls){

        console.log("drawChartCallsRequested");
        console.log(calls);

        const root = am5.Root.new("chartdiv");
        
        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        let chart = root.container.children.push( 
            am5xy.XYChart.new(root, {
              panY: false,
              layout: root.verticalLayout,
							maxTooltipDistance: 0,
							arrangeTooltips: false
            }) 
        );

				// Define data
				let data = calls;

				// Create Y-axis
				let yAxis = chart.yAxes.push(
					am5xy.ValueAxis.new(root, {
						renderer: am5xy.AxisRendererY.new(root, {})
					})
				);
		
				// Create X-Axis
				let xAxis = chart.xAxes.push(
					am5xy.CategoryAxis.new(root, {
						renderer: am5xy.AxisRendererX.new(root, {}),
						categoryField: "date"
					})
				);
				xAxis.data.setAll(data);
		

				const seriesColumnsConfig = {
					fillOpacity: 0.5,
					strokeWidth: 2,
					cornerRadiusTL: 5,
					cornerRadiusTR: 5
				};
				// Create series
				let series1 = chart.series.push(
					am5xy.ColumnSeries.new(root, {
						name: "Calls",
						xAxis: xAxis,
						yAxis: yAxis,
						valueYField: "request",
						valueXField: "date",
						categoryXField: "date",
						fill: am5.color(0x018FFF),
    				stroke: am5.color(0x018FFF)

					})
				);
				series1.data.setAll(data);
				series1.set("active", am5.color(0x018FFF)); // set Series color to red
				series1.columns.template.setAll(seriesColumnsConfig);
		
				let series2 = chart.series.push(
					am5xy.ColumnSeries.new(root, {
						name: "Emergency",
						xAxis: xAxis,
						yAxis: yAxis,
						valueYField: "emergency",
						valueXField: "date",
						categoryXField: "date",
						fill: am5.color(0xFFCD01),
    				stroke: am5.color(0xFFCD01)
					})
				);
				series2.data.setAll(data);
				series2.set("fill", am5.color("#FFCD01")); // set Series color to green
				series2.columns.template.setAll(seriesColumnsConfig);
				// Add legend
				let legend = chart.children.push(am5.Legend.new(root, {}));
				legend.data.setAll(chart.series.values);
		
				// Add cursor
				chart.set("cursor", am5xy.XYCursor.new(root, {
					maxTooltipDistance: 0,
							arrangeTooltips: false
				}));
		
				this.root1 = root;
        
        // var chart = AmCharts.makeChart("chartdiv", {
        //     "type": "serial",
        //     "addClassNames": true,
        //     "theme": "light",
        //     "autoMargins": false,
        //     "marginLeft": 30,
        //     "marginRight": 8,
        //     "marginTop": 10,
        //     "marginBottom": 26,
        //     "balloon": {
        //         "adjustBorderColor": false,
        //         "horizontalPadding": 10,
        //         "verticalPadding": 8,
        //         "color": "#ffffff"
        //     },
        //     "dataProvider": calls ,
        //
        //     "valueAxes": [{
        //         "axisAlpha": 0,
        //         "position": "left"
        //     }],
        //     "startDuration": 0,
        //     "graphs": [{
        //         "id": "graph1",
        //         "balloonText": "<span style='font-size:12px;'>[[title]] in [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
        //         "bullet": "round",
        //         "lineThickness": 2,
        //         "bulletSize": 4,
        //         "bulletBorderAlpha": 1,
        //         "bulletColor": "#FFFFFF",
        //         "lineColor":"#FA6500",
        //         "useLineColorForBulletBorder": true,
        //         "bulletBorderThickness": 3,
        //         "fillAlphas": 0,
        //         "lineAlpha": 1,
        //         "title": "Emergency",
        //         "valueField": "emergency",
        //         "dashLengthField": "dashLengthLine"
        //     }, {
        //         "id": "graph2",
        //         "balloonText": "<span style='font-size:12px;'>[[title]] in [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
        //         "bullet": "round",
        //         "lineThickness": 2,
        //         "bulletSize": 4,
        //         "bulletBorderAlpha": 1,
        //         "bulletColor": "#FFFFFF",
        //         "useLineColorForBulletBorder": true,
        //         "bulletBorderThickness": 3,
        //         "fillAlphas": 0,
        //         "lineAlpha": 1,
        //         "title": "Request",
        //         "valueField": "request",
        //         "dashLengthField": "dashLengthLine"
        //     }],
        //     "categoryField": "date",
        //     "categoryAxis":{
        //         "parseDates": true,
        //         "axisColor": "#DADADA",
        //         "minorGridEnabled": true
        //     },
        //     "export": {
        //         "enabled": true
        //     }
        // });
    }

    drawChartCallsHandled(calls){

        console.log("drawChartCallsHandled");
        console.log(calls);

        const root = am5.Root.new("chartdiv2");
        
        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        let chart = root.container.children.push( 
            am5xy.XYChart.new(root, {
              panY: false,
              layout: root.verticalLayout,
							maxTooltipDistance: 0,
							arrangeTooltips: false
            }) 
        );

				// Define data
				let data = calls;

				// Create Y-axis
				let yAxis = chart.yAxes.push(
					am5xy.ValueAxis.new(root, {
						renderer: am5xy.AxisRendererY.new(root, {})
					})
				);
		
				// Create X-Axis
				let xAxis = chart.xAxes.push(
					am5xy.CategoryAxis.new(root, {
						renderer: am5xy.AxisRendererX.new(root, {}),
						categoryField: "date"
					})
				);
				xAxis.data.setAll(data);
		

				const seriesColumnsConfig = {
					fillOpacity: 0.5,
					strokeWidth: 2,
					cornerRadiusTL: 5,
					cornerRadiusTR: 5
				};
				// Create series
				let series1 = chart.series.push(
					am5xy.ColumnSeries.new(root, {
						name: "Answered",
						xAxis: xAxis,
						yAxis: yAxis,
						valueYField: "answered",
						valueXField: "date",
						categoryXField: "date",
						fill: am5.color(0x018FFF),
    				stroke: am5.color(0x018FFF)

					})
				);
				series1.data.setAll(data);
				series1.set("active", am5.color(0x018FFF)); // set Series color to red
				series1.columns.template.setAll(seriesColumnsConfig);
		
				let series2 = chart.series.push(
					am5xy.ColumnSeries.new(root, {
						name: "Not Answered",
						xAxis: xAxis,
						yAxis: yAxis,
						valueYField: "not_answered",
						valueXField: "date",
						categoryXField: "date",
						fill: am5.color(0xFFCD01),
    				stroke: am5.color(0xFFCD01)
					})
				);
				series2.data.setAll(data);
				series2.set("fill", am5.color("#FFCD01")); // set Series color to green
				series2.columns.template.setAll(seriesColumnsConfig);
				// Add legend
				let legend = chart.children.push(am5.Legend.new(root, {}));
				legend.data.setAll(chart.series.values);
		
				// Add cursor
				chart.set("cursor", am5xy.XYCursor.new(root, {
					maxTooltipDistance: 0,
							arrangeTooltips: false
				}));
		
				this.root2 = root;


        // var chart = AmCharts.makeChart("chartdiv2", {
        //     "type": "serial",
        //     "addClassNames": true,
        //     "theme": "light",
        //     "autoMargins": false,
        //     "marginLeft": 30,
        //     "marginRight": 8,
        //     "marginTop": 10,
        //     "marginBottom": 26,
        //     "balloon": {
        //         "adjustBorderColor": false,
        //         "horizontalPadding": 10,
        //         "verticalPadding": 8,
        //         "color": "#ffffff"
        //     },
        //     "dataProvider": calls ,
        //
        //     "valueAxes": [{
        //         "axisAlpha": 0,
        //         "position": "left"
        //     }],
        //     "startDuration": 0,
        //     "graphs": [{
        //         "id": "graph1",
        //         "balloonText": "<span style='font-size:12px;'>[[title]] in [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
        //         "bullet": "round",
        //         "lineThickness": 2,
        //         "bulletSize": 4,
        //         "bulletBorderAlpha": 1,
        //         "bulletColor": "#FFFFFF",
        //         "lineColor":"#FA6500",
        //         "useLineColorForBulletBorder": true,
        //         "bulletBorderThickness": 3,
        //         "fillAlphas": 0,
        //         "lineAlpha": 1,
        //         "title": "Not Answered",
        //         "valueField": "not_answered",
        //         "dashLengthField": "dashLengthLine"
        //     }, {
        //         "id": "graph2",
        //         "balloonText": "<span style='font-size:12px;'>[[title]] in [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
        //         "bullet": "round",
        //         "lineThickness": 2,
        //         "bulletSize": 4,
        //         "bulletBorderAlpha": 1,
        //         "bulletColor": "#FFFFFF",
        //         "useLineColorForBulletBorder": true,
        //         "bulletBorderThickness": 3,
        //         "fillAlphas": 0,
        //         "lineAlpha": 1,
        //         "title": "Answered",
        //         "valueField": "answered",
        //         "dashLengthField": "dashLengthLine"
        //     }],
        //     "categoryField": "date",
        //     "categoryAxis":{
        //         "parseDates": true,
        //         "axisColor": "#DADADA",
        //         "minorGridEnabled": true
        //     },
        //     "export": {
        //         "enabled": true
        //     }
        // });
    }

		renderChart1(){

			// APPROACH NOT USED
			
			// if (this.state.chartData1 && this.state.chartData1.length>0){
			// 	console.log("renderChart1", this.state.chartData1);

			// 	const keyToLabel = {
			// 		request: 'Regular call requested',
			// 		emergency: 'Emergency call requested'
			// 	};
			// 	const colors = {
			// 		request: 'blue',
			// 		emergency: 'yellow',
			// 	};
			// 	const stackStrategy = {
			// 		stack: 'total',
			// 		area: false,
			// 		stackOffset: 'none', // To stack 0 on top of others
			// 	};
			// 	const customize = {
			// 		height: 300,
			// 		legend: { hidden: true },
			// 		margin: { top: 5 },
			// 		//stackingOrder: 'descending',
			// 		sx: {
			// 			'& .MuiMarkElement-root': {
			// 				display: 'none',
			// 			},
			// 		},
			// 	};

			// 	return (

			// 		// <LineChart
			// 		// 	xAxis={[
			// 		// 		{
			// 		// 			dataKey: 'date',
			// 		// 			//valueFormatter: (v) => v.toString(),
			// 		// 			//min: 1985,
			// 		// 			//max: 2022,
			// 		// 		},
			// 		// 	]}
			// 		// 	series={Object.keys(keyToLabel).map((key) => ({
			// 		// 		dataKey: key,
			// 		// 		label: keyToLabel[key],
			// 		// 		color: colors[key],
			// 		// 		//...stackStrategy,
			// 		// 	}))}
			// 		// 	dataset={this.state.chartData1}
			// 		// 	{...customize}
			// 		// />


			// 			<LineChart
			// 				xAxis={[{ dataKey: 'date' }]}
			// 				series={Object.keys(keyToLabel).map((key) => ({
			// 					dataKey: key,
			// 					label: keyToLabel[key],
			// 					color: colors[key],
			// 					//...stackStrategy,
			// 				}))}
			// 				dataset={this.state.chartData1}
			// 				height={400}
			// 			/>

			// 		// <LineChart
			// 		// 	xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
			// 		// 	series={[
			// 		// 		{
			// 		// 			data: [2, 5.5, 2, 8.5, 1.5, 5],
			// 		// 		},
			// 		// 	]}
			// 		// 	width={500}
			// 		// 	height={300}
			// 		// />
			// 	)
			// } else {
			// 	return (
			// 		<span>Loading data...</span>
			// 	)
			// }
		}

    render()
    {


        return (
            <div class="nucleus-dashboard-news-pending">
                <br/>
                <div class="row content">
                    <div class="col s6 center-align">
                        <div class="nucleus-chart center-align">
                            <div class="nucleus-chart-title valign-wrapper">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span class="nucleus-font-family-medium nucleus-font-medium nucleus-chart-title-text">
                                    Daily Call Request
                                </span>
                            </div>
                            <div id="chartdiv" class="nucleus-chart-body">
															{
																//this.renderChart1()
															}
                            </div>
                            {/* <p class="nucleus-font nucleus-font-small left-align">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span class="chart-orange"> &bull; Emergency Calls</span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span class="chart-yellow"> &bull; Requested Calls</span>
                            </p> */}
                        </div>
                    </div>
                    <div class="col s6 center-align">
                        <div class="nucleus-chart center-align">
                            <div class="nucleus-chart-title valign-wrapper">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span class="nucleus-font-family-medium nucleus-font-medium nucleus-chart-title-text">
                                    Daily Calls Made
                                </span>
                            </div>
                            <div id="chartdiv2" class="nucleus-chart-body">

                            </div>
                            {/* <p class="nucleus-font nucleus-font-small left-align">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span class="chart-orange"> &bull; Not Answered</span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span class="chart-yellow"> &bull; Answered</span>
                            </p> */}
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default DashboardContent;
