import React from 'react';
import { connect } from 'react-redux'
import AuthStore from "../stores/AuthStore";
import { authenticateBeforeRendering } from '../components/AuthenticateBeforeRendering'
import { useUserMeSelector } from '../selectors/user'
import { Redirect, Link, useLocation } from "react-router-dom";

function RedirectIfAuthenticated () {
  const location = useLocation()
  const user = useUserMeSelector()
  let pathname = '/dashboard'

  const state = location && location.state
  if (state && state.from) {
    pathname = state.from && state.from.pathname
  }
  if (user) {
    return (
      <Redirect to={{
        pathname,
        state: {
          from: location
        }
      }} />
    )
  }
  return null
}

class Login extends React.Component {

    constructor() {
        super();
        this.state = {
          doneFetchingMe: false,
          loginFailedStyle : " hide",
          btnStyle : " nucleus-submit-btn-disabled",
          loginFailedText : ""
        };

        this.onAuthStoreLogin = this.onAuthStoreLogin.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.validateFieldsFromKeyboard = this.validateFieldsFromKeyboard.bind(this);
        this.validateButton = this.validateButton.bind(this);
        this.onGetMe = this.onGetMe.bind(this)
    }

    componentDidMount() {
        //Register Listener
        AuthStore.on("onAuthStoreLogin", this.onAuthStoreLogin);
        AuthStore.on('onGetMe', this.onGetMe)
        this.setState({
            loginFailedStyle : " hide"
        });
    }

    componentWillUnmount() {
        //Remove Listener
        AuthStore.removeListener("onAuthStoreLogin", this.onAuthStoreLogin);
    }


    validateFields() {

        if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/.test(this.refs.nucleusCsUsername.value) ){
            //Materialize.toast('Ups! The email is not valid', 3000, 'rounded');
            this.setState({
                loginFailedStyle : " ",
                loginFailedText : "Email invalid."
            });
            return;
        }
        if(this.refs.nucleusCsUserPassword.value === ""){
            //Materialize.toast('Hey, you forgot the password!', 3000, 'rounded');
            return;
        }
        this.doLogin();
    }

    validateFieldsFromKeyboard(e){
        if ((e.charCode == 13) || (e.keyCode == 13) || (e.key == 13) ){
            this.validateFields();
        }
    }

    validateButton(){
        //console.log("Login:validateButton");

        this.setState({
            loginFailedStyle : " hide"
        });

        if (this.refs.nucleusCsUsername.value.length > 0 && this.refs.nucleusCsUserPassword.value.length > 0 ){
            this.setState({
                btnStyle : " nucleus-submit-btn"
            });
        } else {

            this.setState({
                btnStyle : " nucleus-submit-btn-disabled"
            });
        }
    }

    doLogin () {
      const { dispatch } = this.props
        this.setState({
            loginFailedStyle : " hide"
        });

        AuthStore.login({
          Email : this.refs.nucleusCsUsername.value,
          Password : this.refs.nucleusCsUserPassword.value,
          dispatch,
        });
    }

  onGetMe () {
    this.setState({
      doneFetchingMe: true
    })
  }

    onAuthStoreLogin(response){
      const { location } = this.props
        console.log("Login:onAuthStoreLogin");
        console.log(response);

        if (response.ok){
          let { from } = location.state || { from: { pathname: "/dashboard" } };
          this.props.history.replace(from);
        }else {
            let errorMessage = (response.internetError) ? "Login error, please check you internet connection and try again." : "Email address and password do not match.";
            this.setState({
                loginFailedStyle : " ",
                loginFailedText : errorMessage
            });
        }
    }


    render() {
      if (!this.state.doneFetchingMe) {
        return null
      }
        let style = {
            height:window.innerHeight,
            background:'#008FFF'
          };

        return (
            <div class="nucleus-login " style={style}>
              <RedirectIfAuthenticated />
                <div class="alto valign-wrapper">
                    <div class="row ancho">
                        <div class="col s2 m3 l3 border"></div>
                        <div class="col s8 m6 l6 border center-align">
                            <img class="responsive-img" src="img/nucleusCareLogo_new.png"/>
                            <br/><br/>
                            <p class="txt-white nucleus-page-subtitle">Welcome to Admin Portal!</p>
                            <p class="login-info login-error nucleus-font "> &nbsp; <span class={" "  + this.state.loginFailedStyle }> {this.state.loginFailedText} </span> &nbsp; </p>

                            <div class="row center-align no-margin">
                                <div class="input-field col s1 m3 l3"></div>
                                <div class="input-field col s10 m6 l6">
                                  <input ref="nucleusCsUsername" type="email" name="nucleus-user" class="validate nucleus-input-login nucleus-font" placeholder="Email" onKeyPress={this.validateFieldsFromKeyboard } onChange={this.validateButton}/>
                                </div>
                                <div class="input-field col s1 m3 l3"></div>
                            </div>

                            <div class="row center-align no-margin">
                                <div class="input-field col s1 m3 l3"></div>
                                <div class="input-field col s10 m6 l6">
                                    <input ref="nucleusCsUserPassword" type="password" name="nucleus-password" class="validate nucleus-input-login nucleus-font" placeholder="Password" onKeyPress={this.validateFieldsFromKeyboard } onChange={this.validateButton}/>
                                </div>
                                <div class="input-field col s1 m3 l3"></div>
                            </div>
                            <div>
                              <Link to="/forgotMyPassword" class="forgot-style">
                                <span class="txt-white nucleus-font nucleus-font-small">Forgot your password?</span>
                              </Link>
                            </div>
                            <br/><br/>
                            <div class="row center-align">
                                <div class="col s12">
                                    <a class={"txt-white nucleus-font-small nucleus-font " + this.state.btnStyle } onClick={this.validateFields}>Submit</a>
                                </div>
                            </div>
                            <br/><br/>
                        </div>
                        <div class="col s2 m3 l3 border"></div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps (dispatch) {
  return {
    dispatch,
  }
}

export default authenticateBeforeRendering(connect(null, mapDispatchToProps)(Login));
