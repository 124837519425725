import { FC, ChangeEvent, useEffect, useState, useRef } from "react";
import ErrorImg from "../../components/UI/Inputs/assets/error-icon.svg";
import Select from 'react-select'
//UI
import {
  UIModal, UIWhiteBoxInput, UIWhiteBoxTelInput,
  UICheckbox, UICheckboxLine,
  UINucleusColumnsContainer, UINucleusColumnContent,
  UIDividerLine, useModal, UINucleusContainer, UINucleusContentContainer
} from "../../components/UI";
import { ModalAction } from "../../components/UI/Modals/Base/ActionButtons";
import styled from 'styled-components';

// TYPES
import { ProviderUser } from "./types";
import {
  useNucleusProviders,
  IProviderUser, INewProviderUser
} from '../../context-api/nucleusProvidersContext/NucleusProvidersContext';
import { addProviderUser, getProviderUserConfigData, getProviderUserStates, updateProviderUser } from './utils/providerApi';

import Message from '../../utils/Message';
import StateSelector from "./components/StateSelector/StateSelector";
import useStateSelector, { IState, IUserState } from "./components/StateSelector/useStateSelector";
interface Props {
  closeModal: () => void;
  isOpen: boolean;
  providerUser?: ProviderUser | null;
}

const TITLES = {
  add: "Add a New User",
  edit: "Edit User",
};

const ProviderUserModal: FC<Props> = ({ closeModal, isOpen, providerUser }) => {

  const fastFilter = true;
  const { state: { provider: { data, state: { error, loading, success } } } } = useNucleusProviders();

  const [mode, setMode] = useState("add");

  // FirstName
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  // LastName
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  // Email 
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  // Telephone 
  const [telephone, setTelephone] = useState("");
  const [telephoneError, setTelephoneError] = useState("");
  // Address 
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");
  // licensedState 
  const [licensedStateError, setLicensedStateError] = useState("");

  // Account level Permissions
  const [isAdmin, setIsAdmin] = useState(false);
  const [isHippa, setIsHippa] = useState(false);
  const [canAccessStaff, setCanAccessStaff] = useState(false);
  const [canAccessClients, setCanAccessClients] = useState(false);
  const [canAccessQueue, setCanAccessQueue] = useState(false);
  const [canAccessMessages, setCanAccessMessages] = useState(false);
  const [canAccessAlerts, setCanAccessAlerts] = useState(false);
  const [canAccessPhotos, setCanAccessPhotos] = useState(false);
  const [canAccessBulletin, setCanAccessBulletin] = useState(false);

  // Provider level Permissions
  const [canAccessProvidersSchedule, setCanAccessProvidersSchedule] = useState(false);
  const [canAccessTodaySchedule, setCanAccessTodaySchedule] = useState(false);
  const [canAccessScheduleAvailability, setCanAccessScheduleAvailability] = useState(false);
  const [canAccessPatientAppointments, setCanAccessPatientAppointments] = useState(false);
  const [canAccessProvidersQueue, setCanAccessProvidersQueue] = useState(false);
  const [canAccessProvidersAccounts, setCanAccessProvidersAccounts] = useState(true);
  const [canAccessProvidersCallLogs, setCanAccessProvidersCallLogs] = useState(false);
  const [canAccessProvidersGrouping, setCanAccessProvidersGrouping] = useState(false);
  const [canAccessManageUsers, setCanAccessManageUsers] = useState(false);

  const [saveProviderUserError, setSaveProviderUserError] = useState("sfdsf");
  const [isLoading, setIsLoading] = useState(false);
  const [userConfigLoaded, setUserConfigLoaded] = useState(false);

  const currentStatesMap = useRef(new Map<string, boolean>());
  const [statesToAdd, setStatesToAdd] = useState<IState[]>([]);
  const [statesToRemove, setStatesToRemove] = useState<IState[]>([]);

  const {
    isLoading: statesLoading,
    states,
    reset: resetStates,
    searchTerm,
    selectedStates,
    setSearchTerm,
    toggleStateSelection
  } = useStateSelector()

  const fetchUserStates = async () => {
    if (data?.ID && providerUser?.id) {
      const currentStates = await getProviderUserStates(data?.ID, providerUser?.id);
      currentStates.forEach((state) => {
        toggleStateSelection(state.StateID);
        currentStatesMap.current.set(state.StateID, true);
      });
    }
  }

  const getStatesBySelection = (isSelected) => {
    return Array.from(currentStatesMap.current.entries())
      .filter(([_, value]) => value === isSelected)
      .map(([key, _]) => ({ ID: key }));
  };

  const toggleStateSelectionCb = (stateId: string) => {
    toggleStateSelection(stateId);
    if (providerUser?.id) {
      console.log('currentStatesMap', currentStatesMap);
      currentStatesMap.current.set(stateId, !currentStatesMap.current.get(stateId));
      const arrStatesToRemove = getStatesBySelection(false);
      setStatesToRemove(arrStatesToRemove);
      const arrStatesToAdd = getStatesBySelection(true);
      setStatesToAdd(arrStatesToAdd);
    }
  };
  useEffect(() => {
    if (providerUser?.id) return
    setStatesToAdd(selectedStates.map((state_s) => ({ ID: state_s.ID })));
  }, [selectedStates])
  useEffect(() => {
    if (providerUser) {
      setMode("edit");
      setFirstName(providerUser?.firstName || "");
      setLastName(providerUser?.lastName || "");
      setEmail(providerUser?.email);
      setTelephone(providerUser?.telephone || "");
      setAddress(providerUser?.address || "")
      setIsAdmin(providerUser.isAdmin);

      if (!userConfigLoaded) {
        getProviderUserConfig();
      }
      fetchUserStates();
    } else {
      setMode("add");
    }
  }, [providerUser]);

  useEffect(() => {
    if (firstNameError) {
      setFirstNameError("");
    }
    if (lastNameError) {
      setLastNameError("");
    }
    if (emailError) {
      setEmailError("");
    }
    if (telephoneError) {
      setTelephoneError("");
    }
    if (addressError) {
      setAddressError("");
    }
    if (licensedStateError) {
      setLicensedStateError("");
    }
  }, [firstName, lastName, email, telephone, address, selectedStates]);

  useEffect(() => {
    return () => {
      cleanValues();
    };
  }, []);

  const cleanValues = () => {
    setFirstName("");
    setFirstNameError("");
    setLastName("");
    setLastNameError("");
    setEmail("");
    setEmailError("");
    setTelephone("");
    setTelephoneError("");
    setAddress("");
    setAddressError("");
    resetStates();
    setLicensedStateError("");

    setIsAdmin(false);
    setIsHippa(false);
    setCanAccessStaff(false);
    setCanAccessClients(false);
    setCanAccessQueue(false);
    setCanAccessMessages(false);
    setCanAccessAlerts(false);
    setCanAccessPhotos(false);
    setCanAccessBulletin(false);

    setCanAccessProvidersSchedule(false);
    setCanAccessTodaySchedule(false);
    setCanAccessScheduleAvailability(false);
    setCanAccessPatientAppointments(false);
    setCanAccessProvidersQueue(false);
    //setCanAccessProvidersAccounts(false);
    setCanAccessProvidersCallLogs(false);
    setCanAccessProvidersGrouping(false);
    setCanAccessManageUsers(false);
    setUserConfigLoaded(false);
  }

  const disableConfirmButton = () => {
    console.log("disableConfirmButton");
    if (lastName.length > 0 && firstName.length > 0 && email.length > 0 && telephone.length > 0  && (selectedStates?.length > 0)) {
      return false;
    } else {
      return true;
    }
  }

  const getModalActionButtons = () => {
    const data: ModalAction[] = [
      {
        label: "Cancel",
        onClick: handleCloseModal,
        buttonVariant: "secondary",
        disabled: false,
      },
    ];
    if (mode === "edit") {
      data.push(
        {
          label: "Save",
          onClick: submit,
          disabled: disableConfirmButton() || isLoading,
          buttonVariant: disableConfirmButton() === true ? 'disabled' : 'primary'
        });
    }
    else {
      data.push({
        label: "Add",
        onClick: submit,
        disabled: disableConfirmButton() || isLoading,
        buttonVariant: disableConfirmButton() === true ? 'disabled' : 'primary'
      });
    }
    return data;
  }

  const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  };
  const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handleTelephoneChange = (fullNumber: string) => {
    setTelephone(fullNumber);
  };
  const handleAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAddress(e.target.value);
  };


  const handleCloseModal = () => {
    closeModal();
    resetStates();
    setSaveProviderUserError("");
    cleanValues();
  };

  const validate = () => {
    let noErrors = true;
    if (!firstName) {
      setFirstNameError("First Name is required");
      console.log("First Name is required");
      noErrors = false;
    }
    if (!lastName) {
      setLastNameError("Last Name is required");
      console.log("Last Name is required");
      noErrors = false;
    }
    if (selectedStates.length < 1) {
      setLicensedStateError("State is required");
      Message.error("Please select at least one State.");
      console.log("State is required");
      noErrors = false;
    }
    if (!email) {
      setEmailError("Email is required");
      console.log("Email is required");
      noErrors = false;
    } else if (!/^\w+([\.-]?\w+)*(\+\w+)?@\w+([\.-]?\w+)*(\.\w{2,15})+$/.test(email)) {
      setEmailError("Invalid email");
      console.log("Invalid email");
      noErrors = false;
    }

    if (!telephone){
      setTelephoneError("Telephone is required");
      console.log("Telephone is required");
      noErrors = false;
    }
    else if ((/[a-zA-Z]/.test(telephone)) || !(/^(?:\+\d{1,3})?\d{10,11}$/.test(telephone))) {
      setTelephoneError("Ivalid telephone");
      console.log("Ivalid telephone");
      noErrors = false;
    }
    return noErrors;
  };

  const submit = () => {
    console.log("submit isAdmin", isAdmin, isLoading);
    if (!validate() || isLoading) return;
    setIsLoading(true);
    if (mode === "add") {
      saveUser();
    } else {
      editUser();
    }
  };

  const buildFormProviderUserData = (): INewProviderUser => {
    const formProviderUserData = {
      email: email,
      firstName: firstName,
      lastName: lastName,
      telephone: telephone,
      address: address,
      states: statesToAdd,
      statesToRemove: statesToRemove,

      accountPermissions: {
        isAdmin: isAdmin,
        isHipaaAuthorized: isHippa,
        canAccessStaff: canAccessStaff,
        canAccessClients: canAccessClients,
        canAccessQueue: canAccessQueue,
        canAccessMessageCenter: canAccessMessages,
        canAccessAlerts: canAccessAlerts,
        canAccessPhotos: canAccessPhotos,
        canAccessBulletinBoard: canAccessBulletin,
      },
      providerPermissions: {
        canAccessProvidersSchedule: canAccessProvidersSchedule,
        canAccessTodaysSchedule: canAccessTodaySchedule,
        canAccessScheduleAvailability: canAccessScheduleAvailability,
        canAccessPatientAppointments: canAccessPatientAppointments,
        canAccessProvidersQueue: canAccessProvidersQueue,
        canAccessProvidersAccounts: canAccessProvidersAccounts,
        canAccessProvidersCallLogs: canAccessProvidersCallLogs,
        canAccessProvidersGrouping: canAccessProvidersGrouping,
        canAccessManageUsers: canAccessManageUsers,
      }
    }
    return formProviderUserData;
  }

  const saveUser = async () => {
    if (!data?.ID) return;
    const newProviderUser: INewProviderUser = buildFormProviderUserData();
    await addProviderUser(data?.ID, newProviderUser, addProvidersUsersCallBack);
  }

  const addProvidersUsersCallBack = (responseData) => {
    setIsLoading(false);
    if (!responseData?.ok && responseData?.message) {
      Message.error("Error : " + responseData.message);
      return;
    }
    handleCloseModal();
  }

  const getProviderUserConfig = async () => {
    if (!data?.ID) return;
    if (!providerUser?.id) return;
    // console.log("getProviderUserConfig providerUser", providerUser);
    await getProviderUserConfigData(data?.ID, providerUser?.id, getProviderUserConfigDataCallBack);
  }

  const getProviderUserConfigDataCallBack = (responseData) => {
    // console.log("getProviderUserConfigDataCallBack", responseData);
    setUserConfigLoaded(true);

    setIsHippa(responseData?.accountPermissions?.isHipaaAuthorized);
    setCanAccessStaff(responseData?.accountPermissions?.canAccessStaff);
    setCanAccessClients(responseData?.accountPermissions?.canAccessClients);
    setCanAccessQueue(responseData?.accountPermissions?.canAccessQueue);
    setCanAccessMessages(responseData?.accountPermissions?.canAccessMessageCenter);
    setCanAccessAlerts(responseData?.accountPermissions?.canAccessAlerts);
    setCanAccessPhotos(responseData?.accountPermissions?.canAccessPhotos);
    setCanAccessBulletin(responseData?.accountPermissions?.canAccessBulletinBoard);

    setCanAccessProvidersSchedule(responseData?.providerPermissions?.canAccessProvidersSchedule);
    setCanAccessTodaySchedule(responseData?.providerPermissions?.canAccessTodaysSchedule);
    setCanAccessScheduleAvailability(responseData?.providerPermissions?.canAccessScheduleAvailability);
    setCanAccessPatientAppointments(responseData?.providerPermissions?.canAccessPatientAppointments);
    setCanAccessProvidersQueue(responseData?.providerPermissions?.canAccessProvidersQueue);
    setCanAccessProvidersAccounts(responseData?.providerPermissions?.canAccessProvidersAccounts);
    setCanAccessProvidersCallLogs(responseData?.providerPermissions?.canAccessProvidersCallLogs);
    setCanAccessProvidersGrouping(responseData?.providerPermissions?.canAccessProvidersGrouping);
    setCanAccessManageUsers(responseData?.providerPermissions?.canAccessManageUsers);
    setTelephone(providerUser?.telephone || "");
    setIsLoading(false);
  }

  const editUser = async () => {
    console.log("Edit User");
    if (!data?.ID) return;
    if (!providerUser?.id) return;
    const selectedProviderUser: INewProviderUser = buildFormProviderUserData();
    await updateProviderUser(data?.ID, providerUser?.id, selectedProviderUser, updateProvidersUserCallBack);
  }

  const updateProvidersUserCallBack = (responseData) => {
    setIsLoading(false);
    if (!responseData?.ok && responseData?.message) {
      Message.error("Error : " + responseData.message);
      return;
    }
    handleCloseModal();
  }

  return (<UIModal
    size="large"
    isOpen={isOpen}
    close={handleCloseModal}
    title={TITLES[mode]}
    actions={getModalActionButtons()}
  >
    <UINucleusContainer style={{ overflowY: 'auto', height: 520 }}>
      <UINucleusContentContainer>
        <InputGroup>
          <UIWhiteBoxInput
            label="First name:"
            placeholder="Empty"
            value={firstName}
            onChange={handleFirstNameChange}
            error={firstNameError}
            required
          />

          <UIWhiteBoxInput
            label="Last name:"
            placeholder="Empty"
            value={lastName}
            onChange={handleLastNameChange}
            error={lastNameError}
            required
          />

          <UIWhiteBoxInput
            label="Email:"
            placeholder="Empty"
            value={email}
            onChange={handleEmailChange}
            error={emailError}
            required
          />

          {
            (mode == "add") &&
            <UIWhiteBoxTelInput
              label="Telephone:"
              placeholder="Empty"
              value={telephone}
              onPhoneChange={handleTelephoneChange}
              error={telephoneError}
              info="Click the country code dropdown for more options"
              required
            />
          }
          {
            (mode == "edit" && userConfigLoaded) &&
            <UIWhiteBoxTelInput
              label="Telephone:"
              placeholder="Empty"
              value={telephone}
              defaultValue={telephone}
              onPhoneChange={handleTelephoneChange}
              error={telephoneError}
              info="Click the country flag dropdown for more options"
              required
            />
          }


          <UIWhiteBoxInput
            label="Address:"
            placeholder="1234 Avenue Road, New City, NC 00000"
            value={address}
            onChange={handleAddressChange}
            error={addressError}
          />

          <Label>{'States licensed in:'} <span style={{ color: 'red' }}>*</span> <></></Label>
          <StateSelector
            key={1}
            selectedStates={selectedStates}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            states={states}
            statesPerGroup={[]}
            toggleStatesSelection={toggleStateSelectionCb}
          />
          <WhiteBoxErrorText visible={!!licensedStateError}>{licensedStateError}</WhiteBoxErrorText>


        </InputGroup>
      </UINucleusContentContainer>
      <UIDividerLine></UIDividerLine>
      <InputGroup>
        <NucleusLabelH2>
          Permissions:
        </NucleusLabelH2>
        <UINucleusColumnsMainContainer>
          <UINucleusColumnsContainer>
            <UINucleusColumnContent>
              <NucleusLabelH3> On the account level:</NucleusLabelH3>
              <UICheckboxLine
                tagId="isAdmin"
                label="Is admin"
                checked={isAdmin}
                onClick={(newPermission) => setIsAdmin(newPermission)}
              />
              <br />
              <UICheckboxLine
                tagId="isHippa"
                label="Is HIPAA authorized"
                checked={isHippa}
                onClick={(newValue) => setIsHippa(newValue)}
              />
              <br />
              <UICheckboxLine
                tagId="canAccessStaff"
                label="Can access staff tab"
                checked={canAccessStaff}
                onClick={(newValue) => setCanAccessStaff(newValue)}
              />
              <br />
              <UICheckboxLine
                tagId="canAccessClients"
                label="Can access clients tab"
                checked={canAccessClients}
                onClick={(newValue) => setCanAccessClients(newValue)}
              />
              <br />
              <UICheckboxLine
                tagId="canAccessQueue"
                label="Can access queue tab"
                checked={canAccessQueue}
                onClick={(newValue) => setCanAccessQueue(newValue)}
              />
              <br />
              <UICheckboxLine
                tagId="canAccessMessages"
                label="Can access message center tab"
                checked={canAccessMessages}
                onClick={(newValue) => setCanAccessMessages(newValue)}
              />
              <br />
              <UICheckboxLine
                tagId="canAccessAlerts"
                label="Can access alerts tab"
                checked={canAccessAlerts}
                onClick={(newValue) => setCanAccessAlerts(newValue)}
              />
              <br />
              <UICheckboxLine
                tagId="canAccessPhotos"
                label="Can access photos tab"
                checked={canAccessPhotos}
                onClick={(newValue) => setCanAccessPhotos(newValue)}
              />
              <br />
              <UICheckboxLine
                tagId="canAccessBulletin"
                label="Can access bulletin board tab"
                checked={canAccessBulletin}
                onClick={(newValue) => setCanAccessBulletin(newValue)}
              />
            </UINucleusColumnContent>
          </UINucleusColumnsContainer>
          <UINucleusColumnsContainer>
            <UINucleusColumnContent>
              <NucleusLabelH3>On the provider level:</NucleusLabelH3>
              <UICheckboxLine
                tagId="canAccessProvidersSchedule"
                label={"Can access provider’s “schedule” tab"}
                checked={canAccessProvidersSchedule}
                onClick={(newPermission) => setCanAccessProvidersSchedule(newPermission)}
              />
              <br />
              {/* <UICheckboxLine 
                      tagId="canAccessTodaySchedule"
                      label={"Can access “today’s schedule” tab"}
                      checked={canAccessTodaySchedule}
                      onClick={(newPermission) => setCanAccessTodaySchedule(newPermission)}
                    />
                    <UICheckboxLine 
                      tagId="canAccessScheduleAvailability"
                      label={"Can access “schedule availability” tab"}
                      checked={canAccessScheduleAvailability}
                      onClick={(newPermission) => setCanAccessScheduleAvailability(newPermission)}
                    />

                    <UICheckboxLine 
                      tagId="canAccessPatientAppointments"
                      label={"Can access “schedule patient appointments” tab"}
                      checked={canAccessPatientAppointments}
                      onClick={(newPermission) => setCanAccessPatientAppointments(newPermission)}
                    /> */}
              <UICheckboxLine
                tagId="canAccessProvidersQueue"
                label={"Can access provider’s “queue” tab"}
                checked={canAccessProvidersQueue}
                onClick={(newPermission) => setCanAccessProvidersQueue(newPermission)}
              />
              <br />
              {/* <UICheckboxLine 
                      tagId="canAccessProvidersAccounts"
                      label={"Can access provider’s “Accounts” tab"}
                      checked={canAccessProvidersAccounts}
                      onClick={(newPermission) => setCanAccessProvidersAccounts(newPermission)}
                    /> */}
              <UICheckboxLine
                tagId="canAccessProvidersCallLogs"
                label={"Can access provider’s “Call logs” tab"}
                checked={canAccessProvidersCallLogs}
                onClick={(newPermission) => setCanAccessProvidersCallLogs(newPermission)}
              />
              <br />
              <UICheckboxLine
                tagId="canAccessProvidersGrouping"
                label={"Can access provider’s “provider groupings” tab"}
                checked={canAccessProvidersGrouping}
                onClick={(newPermission) => setCanAccessProvidersGrouping(newPermission)}
              />
              <br />
              <UICheckboxLine
                tagId="canAccessManageUsers"
                label={"Can access provider’s “manage users” tab"}
                checked={canAccessManageUsers}
                onClick={(newPermission) => setCanAccessManageUsers(newPermission)}
              />
              <br />
            </UINucleusColumnContent>
          </UINucleusColumnsContainer>
        </UINucleusColumnsMainContainer>
      </InputGroup>
    </UINucleusContainer>
  </UIModal>
  )
}

const InputGroup = styled.div`
  margin-bottom: 20px;
`;

const Content = styled.div`
  position: relative;
`;

const Label = styled.p`
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 16px;
  color: #0a313f;
`;

const WhiteBoxErrorIcon = styled.img`
  position: absolute;
  right: 10px;
  top: 20%;
`;
const WhiteBoxErrorText = styled.div<{ visible?: boolean }>`
  color: #fe3824;
  font-size: 10px;
  margin-top: 5px;
  margin-bottom: 0px;
  height: 15px;
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
`;
const WhiteBoxHelpText = styled.div`
  font-size: 10px;
  margin-top: 5px;
  margin-bottom: 0px;
  height: 15px;
`;

const NucleusLabelH2 = styled.p`
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 15px;
  color: #0a313f;
`;

const NucleusLabelH3 = styled.p`
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 14px;
  color: #0a313f;
`;

const UINucleusColumnsMainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export default ProviderUserModal;