import $ from 'jquery';
import Message from '../utils/Message';
import React, { useState, useEffect, useRef } from 'react';
import { RootState, getAllCsUsers, deleteCsUser, csUsersSliceClean, httpApi } from '@nucleus-care/nucleuscare-backend-client';
import { useSelector, useDispatch } from 'react-redux';
import { SpinnerCircular } from 'spinners-react';
import AuthStore from '../stores/AuthStore';
import backendClient from '../utils/BackendClient';
import CsUserRow from './csUsers/CsUserRow';

const CsUsers = () => {
  const csUsersStore = useSelector((state: RootState) => {
    return state.csUsersStore.data;
  });

  const [stateLoading, setStateLoading] = useState(true);
  const [stateSearchText, setStateSearchText] = useState('');
  const txtAccountsChipsRef = useRef(null);
  const dispatch = useDispatch();

  const [stateSelectedUser, setStateSelectedUser] = useState({
    ID: null,
    FirstName: '',
    LastName: '',
    Email: '',
    Telephone: '',
    LastLogin: '',
    SuperUser: false,
    permissions: {
      canAccessAdminUsersTab: true,
      canAccessAccountsTab: true,
      canAccessReportsTab: true,
      canAccessContactsTab: true,
      canAccessProvidersTab: true,
      canAccessMDMTab: true,
      canAccessConsumerOrdersTab: true,
      canAccessDevicesTab: true,
      canAccessAppsTab: true,
      canAccessSettingsTab: true,
    },
  });

  const [stateEditUserAllowed, setStateEditUserAllowed] = useState(false);
  const [stateEditUserBtnStyle, setStateEditUserBtnStyle] = useState('nucleus-submit-btn');
  const [stateDeleteUserBtnStyle, setStateDeleteUserBtnStyle] = useState('nucleus-submit-btn-small');
  const [stateEditGroupsBtnStyle, setStateEditGroupsBtnStyle] = useState('nucleus-submit-btn');

  const [stateSortingStyles, setStateSortingStyles] = useState({
    userNameAsc: ' nucleus-icon-inactive ',
    userNameDesc: ' nucleus-icon-inactive hide ',
    onlineDateAsc: ' nucleus-icon-inactive ',
    onlineDateDesc: ' nucleus-icon-inactive hide ',
  });

  const [stateSortingMode, setStateSortingMode] = useState('userNameDesc');

  const handleSortingMode = (tag, tag2) => {
    console.log('handleSortingMode', tag, tag2);

    let object = {
      userNameAsc: ' nucleus-icon-inactive ',
      userNameDesc: ' nucleus-icon-inactive hide ',
      onlineDateAsc: ' nucleus-icon-inactive ',
      onlineDateDesc: ' nucleus-icon-inactive hide ',
    };
    object[tag] = ' nucleus-icon-active ';
    object[tag2] = ' nucleus-icon-inactive hide ';
    setStateSortingStyles(object);
    setStateSortingMode(tag);
  };

  const [stateModalLoading, setStateModalLoading] = useState(true);
  const [stateGroupsChipsData, setStateGroupsChipsData] = useState({});
  const [stateAutocompleteGroups, setStateAutocompleteGroups] = useState({});

  useEffect(() => {
    if (!AuthStore.superUser()) {
      window.location.assign('#/dashboard');
    }
    window.$('.modal').modal({
      dismissible: true,
      complete: () => {},
    });
    refreshReport();
  }, []);

  useEffect(() => {
    if (txtAccountsChipsRef.current) {
      console.log('entered the init');
      window.$(txtAccountsChipsRef.current).material_chip();
      window.$('.chips-initial').material_chip({
        data: [],
        placeholder: 'Type a group name',
        secondaryPlaceholder: 'Type another name',
        autocompleteOptions: {
          data: stateGroupsChipsData,
          limit: Infinity,
        },
        selectable: false,
      });
      window.$('.chips').click(() => {
        console.log('window.$(".chips").click');
      });
      window.$('.chips').on('chip.add', (e, chip) => {
        let existingGroups = [];
        let existingData = window.$('.chips-initial').material_chip('data');
        let groupAlreadyAdded = false;

        for (const item of existingData) {
          groupAlreadyAdded = stateAutocompleteGroups[item.tag];
          if (groupAlreadyAdded) {
            existingGroups.push(item);
          }
        }

        window.$('.chips-initial').material_chip({
          data: existingGroups,
          placeholder: 'Type a group name',
          secondaryPlaceholder: 'Type another name',
          autocompleteOptions: {
            data: stateGroupsChipsData,
            limit: Infinity,
          },
        });
        window.$('.chips').on('chip.select', (e, chip) => {
          console.log("hIrt'", e, chip);
          return false;
        });
      });
    }
    setStateModalLoading(false);
  }, [txtAccountsChipsRef.current]);

  useEffect(() => {
    console.log('CsUsers useEffect csUsersStore');
    if (!csUsersStore.loading) {
      setStateLoading(false);
      if (csUsersStore.complete) {
        dispatch(csUsersSliceClean());
      }
    } else if (csUsersStore.error) {
      Message.show('Error in CsUsers');
    }
  }, [csUsersStore.loading, csUsersStore.complete]);

  useEffect(() => {
    console.log('CsUsers:useEffect csUsersStore:Update');
    console.log('csUsersStore', csUsersStore);
    if (!csUsersStore.updateLoading) {
      setStateLoading(false);
      if (csUsersStore.updateComplete) {
        refreshReport();
        Message.show('User updated');
        handleCloseEditModal();
        dispatch(csUsersSliceClean());
      }
    } else if (csUsersStore.error) {
      Message.show('Error in CsUsers');
    }
  }, [csUsersStore.updateLoading, csUsersStore.updateComplete]);

  useEffect(() => {
    console.log('CsUsers:useEffect csUsersStore:Delete');
    console.log('csUsersStore', csUsersStore);

    if (!csUsersStore.deleteLoading) {
      setStateLoading(false);
      if (csUsersStore.deleteComplete) {
        refreshReport();
        Message.show('User deleted');
        setStateDeleteUserBtnStyle('nucleus-submit-btn-small');
        window.$('#modalDeleteUser').modal('close');
        handleCloseEditModal();
        dispatch(csUsersSliceClean());
      }
    } else if (csUsersStore.error) {
      Message.show('Error in CsUsers');
    }
  }, [csUsersStore.deleteLoading, csUsersStore.deleteComplete]);

  const onSearchTextChange = (textInput) => {
    console.log('onSearchTextChange', textInput.target.value);
    setStateSearchText(textInput.target.value);
  };

  const refreshReport = () => {
    console.log('refreshReport');

    setStateLoading(true);
    dispatch(getAllCsUsers());
    getAccountGroups();
  };

  const onRowClicked = (userData) => {
    console.log('onRowClicked', userData);
    setStateSelectedUser(userData);
    if (!userData.SuperUser) {
      getSelectedUserGroups(userData.ID.toLowerCase());
    }
    let editable = true;
    setStateEditUserAllowed(editable);
    setStateEditUserBtnStyle(editable ? 'nucleus-submit-btn' : 'nucleus-submit-btn-disabled');
    window.$('#modalEditCsUser').modal('open');

    setStateEditGroupsBtnStyle('nucleus-submit-btn-disabled');
    setStateModalLoading(true);
  };

  const handleCloseEditModal = () => {
    console.log('handleCloseEditModal');
    window.$('#modalEditCsUser').modal('close');
  };

  const onSaveUserClick = async () => {
    console.log('onSaveUserClick');
    console.log('ID:', stateSelectedUser.ID);
    console.log('Data:', stateSelectedUser);

    setStateEditUserBtnStyle('nucleus-submit-btn-disabled');

    try {
      const response = await httpApi.put(`cs_user/${stateSelectedUser.ID}`, {
        userId: stateSelectedUser.ID,
        firstName: stateSelectedUser.FirstName,
        lastName: stateSelectedUser.LastName,
        telephone: stateSelectedUser.Telephone,
        permissions: stateSelectedUser.permissions,
      });
      console.log('successfully updated user: ', response.data);
    } catch (error) {
      console.error('Error updating user:', error);
    } finally {
      setStateEditUserBtnStyle('');
      handleCloseEditModal();
      refreshReport();
    }
  };

  const onRemoveUserModal = () => {
    window.$('#modalDeleteUser').modal('open');

    setStateDeleteUserBtnStyle('nucleus-submit-btn-small');
  };

  const handleCloseDeleteModal = () => {
    if (stateDeleteUserBtnStyle === 'nucleus-submit-btn-small') {
      window.$('#modalDeleteUser').modal('close');
    }
  };

  const onDeleteUser = () => {
    console.log('onDeleteUser');
    console.log('AuthStore.getCsUserID().toLowerCase()', AuthStore.getCsUserID().toLowerCase());
    console.log('stateSelectedUser.ID', stateSelectedUser.ID);

    setStateDeleteUserBtnStyle('nucleus-submit-btn-small-disabled');

    dispatch(
      deleteCsUser({
        userId: stateSelectedUser.ID,
        deletedBy: AuthStore.getCsUserID().toLowerCase(),
      }),
    );
  };

  const getAccountGroups = () => {
    console.log('getAccountGroups');
    backendClient.get(`cs_user/get_account_groups_list`, (response) => {
      if (response?.ok && response.groups) {
        let dataset = {};
        response.groups.forEach((g) => {
          dataset[g.Name] = g.ID.toLowerCase();
        });
        console.log('dataset', dataset);
        let autocompleteGroups = {};
        let autocompleteGroupIDs = {};
        let groupNameKey = '';

        Object.keys(dataset).forEach(function (key, index) {
          groupNameKey = key.trim();
          autocompleteGroupIDs[groupNameKey] = dataset[key];
          autocompleteGroups[groupNameKey] = null;
        });
        console.log('autocompleteGroupIDs', autocompleteGroupIDs);
        console.log('autocompleteGroups', autocompleteGroups);
        setStateGroupsChipsData(autocompleteGroups);
        setStateAutocompleteGroups(autocompleteGroupIDs);

        window.$('.chips-initial').material_chip({
          data: [],
          placeholder: 'Type a group name',
          secondaryPlaceholder: 'Type another name',
          autocompleteOptions: {
            data: autocompleteGroups,
            limit: Infinity,
          },
        });
      }
    });
  };

  const getSelectedUserGroups = (userId) => {
    console.log('getSelectedUserGroups', userId);

    backendClient.get(`cs_user/${userId}/account_groups`, (response) => {
      if (response?.ok) {
        setStateModalLoading(false);
        setStateEditGroupsBtnStyle('nucleus-submit-btn');
        let currentUserGroups = [];
        for (const userGroup of response.userGroups) {
          let groupChip = {};
          groupChip['tag'] = userGroup.Name;
          groupChip['Name'] = userGroup.Name;
          groupChip['GroupID'] = userGroup.AccountGroupID.toLowerCase();
          currentUserGroups.push(groupChip);
        }
        console.log('currentUserGroups', currentUserGroups);
        window.$('.chips-initial').material_chip({
          data: currentUserGroups,
          placeholder: 'Type a group name',
          secondaryPlaceholder: 'Type another name',
          autocompleteOptions: {
            data: stateGroupsChipsData,
            limit: 2,
          },
        });
      } else {
        Message.show('Error loading user Accounts groups');
      }
    });
  };

  const handleUpdateAccountGroups = () => {
    let groupsIDsArray = null;
    let chipsObjectValue = window.$('.chips-initial').material_chip('data');
    console.log(chipsObjectValue);

    groupsIDsArray = '';
    $.each(chipsObjectValue, (key, value) => {
      let groupID = null;
      groupID = stateAutocompleteGroups[value.tag];
      if (groupsIDsArray.length > 0) {
        groupsIDsArray = groupsIDsArray + ',';
      }
      groupsIDsArray = groupsIDsArray + groupID;
    });
    console.log('Los Patients IDs son', groupsIDsArray);
    console.log('UserID', stateSelectedUser.ID);
    saveUserAccountGroups(stateSelectedUser.ID, groupsIDsArray);
  };

  const saveUserAccountGroups = (userId, groupsIdsArray) => {
    setStateEditGroupsBtnStyle('nucleus-submit-btn-disabled');

    backendClient.put(
      `cs_user/${userId}/set_account_groups`,
      {
        groupsIds: groupsIdsArray,
      },
      (response) => {
        setStateEditGroupsBtnStyle('nucleus-submit-btn');
        if (response?.ok) {
          Message.show('User Groups updated!');
        } else {
          Message.show('Error updating User account groups');
        }
      },
    );
  };

  const getTableRows = () => {
    let userRowsArray = [];
    if (csUsersStore?.users && csUsersStore?.users.length > 0) {
      let array = [].concat(csUsersStore.users);

      if (stateSortingMode === 'userNameDesc') {
        array = array.sort((a, b) => {
          let textA = a.FirstName.toLowerCase() + ' ' + a.LastName.toLowerCase();
          let textB = b.FirstName.toLowerCase() + ' ' + b.LastName.toLowerCase();
          if (textB < textA) return -1;
          if (textB > textA) return 1;
          return 0;
        });
      } else if (stateSortingMode === 'userNameAsc') {
        array = array.sort((a, b) => {
          let textA = a.FirstName.toLowerCase() + ' ' + a.LastName.toLowerCase();
          let textB = b.FirstName.toLowerCase() + ' ' + b.LastName.toLowerCase();
          if (textA < textB) return -1;
          if (textA > textB) return 1;
          return 0;
        });
      } else if (stateSortingMode === 'onlineDateDesc') {
        array = array.sort((a, b) => new Date(b.LastLogin) - new Date(a.LastLogin));
      } else if (stateSortingMode === 'onlineDateAsc') {
        array = array.sort((a, b) => new Date(a.LastLogin) - new Date(b.LastLogin));
      }

      array.forEach((csUser, key) => {
        if (stateSearchText.length === 0) {
          userRowsArray.push(<CsUserRow key={csUser.Email} data={csUser} onClick={onRowClicked}></CsUserRow>);
        } else {
          let text = stateSearchText.toLocaleLowerCase();
          let userName = csUser.FirstName + ' ' + csUser.LastName;
          let email = ''.concat(csUser.Email);
          let telephone = ''.concat(csUser.Telephone);
          let onlineDate = ''.concat(csUser.LastLogin);
          if (
            userName.toLowerCase().indexOf(text) >= 0 ||
            email.toLowerCase().indexOf(text) >= 0 ||
            telephone.toLowerCase().indexOf(text) >= 0 ||
            onlineDate.toLowerCase().indexOf(text) >= 0
          ) {
            userRowsArray.push(<CsUserRow key={email} data={csUser} onClick={onRowClicked}></CsUserRow>);
          }
        }
      });

      return <tbody>{userRowsArray}</tbody>;
    }
    return (
      <tbody className="center-align ">
        <tr style={{ borderBottomStyle: 'none' }}>
          <td colSpan={7} className="center-align ">
            <br />
            <br />
            <br />
            <span className="nucleus-table-header-medium text-gray"> {`There are no Admin users`} </span>
            <br />
            <br />
            <br />
          </td>
        </tr>
      </tbody>
    );
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setStateSelectedUser((prev) => ({
      ...prev,
      permissions: {
        ...prev.permissions,
        [name]: checked,
      },
    }));
  };

  const checkboxStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  };

  const labelStyle: React.CSSProperties = {
    fontSize: 16,
    color: '#0A313F',
    fontWeight: 300,
  };

  const inputStyle: React.CSSProperties = {
    opacity: 1,
    position: 'relative',
    pointerEvents: 'auto',
    boxShadow: 'none',
    width: 18,
    height: 18,
  };
  return (
    <div className="nucleus-tools-container">
      <div>
        <p className="nucleus-tools-page-title">
          <b>Admin Users</b>
        </p>
      </div>
      <div style={{}}>
        <div className="row a-bit-lower left-align">
          <div className="col m11 no-margin">
            <span className="nucleus-tools-page-text-title a-bit-lower">Search &nbsp;&nbsp;&nbsp;</span>
            <input type="text" className="nucleus-input-form-small-left" style={{ width: 500 }} onChange={onSearchTextChange} placeholder={'Name / Email / Telephone'} />
            &nbsp;&nbsp;&nbsp;
            <a className="txt-white nucleus-font-small nucleus-submit-btn-tools" onClick={refreshReport}>
              {' '}
              Refresh{' '}
            </a>
          </div>

          <div className="col m1 no-margin right-align">{stateLoading && <SpinnerCircular color="#2096F3" secondaryColor="rgba(0,0,0,0.16)" size="50" thickness={100} />}</div>
        </div>
      </div>

      <div className="row nucleus-fixed-container nucleus-75-table-container">
        <table className="bordered highlight nucleus-table">
          <thead>
            <tr>
              <th className="table-col-30">
                <span className="nucleus-table-header-small ">{'Name'}</span>
                <a className={'nucleus-font-small ' + stateSortingStyles.userNameDesc} onClick={() => handleSortingMode('userNameAsc', 'userNameDesc')}>
                  {' '}
                  &nbsp;▼
                </a>
                <a className={'nucleus-font-small ' + stateSortingStyles.userNameAsc} onClick={() => handleSortingMode('userNameDesc', 'userNameAsc')}>
                  &nbsp;▲
                </a>
              </th>
              <th className="table-col-30">
                <span className="nucleus-table-header-small">{'Email'}</span>
              </th>
              <th className="table-col-20 ">
                <span className="nucleus-table-header-small">{'Telephone'}</span>
              </th>
              <th className="table-col-25 ">
                <span className="nucleus-table-header-small">{'Last Login'}</span>
                <a className={'nucleus-font-small ' + stateSortingStyles.onlineDateDesc} onClick={() => handleSortingMode('onlineDateAsc', 'onlineDateDesc')}>
                  {' '}
                  &nbsp;▼
                </a>
                <a className={'nucleus-font-small ' + stateSortingStyles.onlineDateAsc} onClick={() => handleSortingMode('onlineDateDesc', 'onlineDateAsc')}>
                  &nbsp;▲
                </a>
              </th>
              <th className="table-col-5 center-align">
                <span className="nucleus-table-header-small">{'Super User'}</span>
              </th>
              <th className="table-col-5 center-align">
                <span className="nucleus-table-header-small">{'Accounts Groups'}</span>
              </th>
            </tr>
          </thead>
          {getTableRows()}
        </table>
      </div>

      <div className="fixed-action-btn btn-add-new">
        <a
          className="btn-floating btn-large waves-effect waves-light orange tooltipped"
          data-position="left"
          data-delay="50"
          data-tooltip="New User"
          onClick={() => {
            window.location.assign('#/newCsUser');
          }}
        >
          <i className="material-icons">add</i>
        </a>
        &nbsp;&nbsp;
      </div>

      <div id="modalEditCsUser" className="modal modalEditCsUser">
        <div className="modal-content nucleus-modal-wrapper-scrollable2">
          <img className="responsive-img img-close-modal" src="img/close_modal.png" onClick={handleCloseEditModal} />
          <h3 className="nucleus-page-subtitle"> Edit Admin User</h3>
          {!stateEditUserAllowed && (
            <a className={'nucleus-font nucleus-font-small nucleus-link'} onClick={onRemoveUserModal} style={{ marginLeft: 30 }}>
              <span className="txt-red">Delete</span>
            </a>
          )}

          <br />
          <br />
          <br />
          <div className="row no-margin">
            <div className="col s3">
              <span className="nucleus-labels bold-500"> Email</span>
            </div>
            <div className="col s9">
              <span className="nucleus-labels"> {stateSelectedUser.Email} </span>
            </div>
          </div>
          <br />

          <div className="row no-margin">
            <div className="col s3">
              <span className="nucleus-labels bold-500"> First Name </span>
            </div>
            <div className="col s9">
              <input
                type="text"
                value={stateSelectedUser.FirstName}
                placeholder="First Name"
                className="validate nucleus-search no-margin-bottom"
                onChange={(e) => {
                  let tempData = { ...stateSelectedUser };
                  tempData.FirstName = e.target.value;
                  setStateSelectedUser(tempData);
                }}
                disabled={!stateEditUserAllowed}
              />
              <br />
            </div>
          </div>
          <br />
          <div className="row no-margin">
            <div className="col s3">
              <span className="nucleus-labels bold-500"> Last Name </span>
            </div>
            <div className="col s9">
              <input
                type="text"
                value={stateSelectedUser.LastName}
                placeholder="First Name"
                className="validate nucleus-search no-margin-bottom"
                onChange={(e) => {
                  let tempData = { ...stateSelectedUser };
                  tempData.LastName = e.target.value;
                  setStateSelectedUser(tempData);
                }}
                disabled={!stateEditUserAllowed}
              />
              <br />
            </div>
          </div>
          <br />
          <div className="row no-margin">
            <div className="col s3">
              <span className="nucleus-labels bold-500"> Telephone </span>
            </div>
            <div className="col s9">
              <input
                type="text"
                value={stateSelectedUser.Telephone || ''}
                placeholder="Telephone"
                className="validate nucleus-search no-margin-bottom"
                onChange={(e) => {
                  let tempData = { ...stateSelectedUser };
                  tempData.Telephone = e.target.value;
                  setStateSelectedUser(tempData);
                }}
                disabled={!stateEditUserAllowed}
              />
              <br />
            </div>
          </div>

          <br />
          <div className="row no-margin">
            <div className="col s3">
              <span className="nucleus-labels bold-500"> Super User </span>
            </div>
            <div className="col s9">
              <span className="nucleus-labels bold-500"> {stateSelectedUser.SuperUser ? 'Y' : ''} </span>
              <br />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col s12">
              <div className="row">
                <div className="col s12 checkbox-container" style={checkboxStyle}>
                  <label style={labelStyle}>Can Access Accounts Tab</label>
                  <input
                    type="checkbox"
                    name="canAccessAccountsTab"
                    checked={stateSelectedUser.permissions.canAccessAccountsTab}
                    onChange={handleCheckboxChange}
                    style={inputStyle}
                  />
                </div>
                <div className="col s12 checkbox-container" style={checkboxStyle}>
                  <label style={labelStyle}>Can Access Reports Tab</label>
                  <input
                    type="checkbox"
                    name="canAccessReportsTab"
                    checked={stateSelectedUser.permissions.canAccessReportsTab}
                    onChange={handleCheckboxChange}
                    style={inputStyle}
                  />
                </div>
                <div className="col s12 checkbox-container" style={checkboxStyle}>
                  <label style={labelStyle}>Can Access Contacts Tab</label>
                  <input
                    type="checkbox"
                    name="canAccessContactsTab"
                    checked={stateSelectedUser.permissions.canAccessContactsTab}
                    onChange={handleCheckboxChange}
                    style={inputStyle}
                  />
                </div>
                <div className="col s12 checkbox-container" style={checkboxStyle}>
                  <label style={labelStyle}>Can Access Providers Tab</label>
                  <input
                    type="checkbox"
                    name="canAccessProvidersTab"
                    checked={stateSelectedUser.permissions.canAccessProvidersTab}
                    onChange={handleCheckboxChange}
                    style={inputStyle}
                  />
                </div>
                <div className="col s12 checkbox-container" style={checkboxStyle}>
                  <label style={labelStyle}>Can Access Admin Users Tab</label>
                  <input
                    type="checkbox"
                    name="canAccessAdminUsersTab"
                    checked={stateSelectedUser.permissions.canAccessAdminUsersTab}
                    onChange={handleCheckboxChange}
                    style={inputStyle}
                  />
                </div>
                <div className="col s12 checkbox-container" style={checkboxStyle}>
                  <label style={labelStyle}>Can Access MDM Tab</label>
                  <input type="checkbox" name="canAccessMDMTab" checked={stateSelectedUser.permissions.canAccessMDMTab} onChange={handleCheckboxChange} style={inputStyle} />
                </div>
                <div className="col s12 checkbox-container" style={checkboxStyle}>
                  <label style={labelStyle}>Can Access Consumer Orders Tab</label>
                  <input
                    type="checkbox"
                    name="canAccessConsumerOrdersTab"
                    checked={stateSelectedUser.permissions.canAccessConsumerOrdersTab}
                    onChange={handleCheckboxChange}
                    style={inputStyle}
                  />
                </div>
                <div className="col s12 checkbox-container" style={checkboxStyle}>
                  <label style={labelStyle}>Can Access Devices Tab</label>
                  <input
                    type="checkbox"
                    name="canAccessDevicesTab"
                    checked={stateSelectedUser.permissions.canAccessDevicesTab}
                    onChange={handleCheckboxChange}
                    style={inputStyle}
                  />
                </div>
                <div className="col s12 checkbox-container" style={checkboxStyle}>
                  <label style={labelStyle}>Can Access Apps Tab</label>
                  <input type="checkbox" name="canAccessAppsTab" checked={stateSelectedUser.permissions.canAccessAppsTab} onChange={handleCheckboxChange} style={inputStyle} />
                </div>
                <div className="col s12 checkbox-container" style={checkboxStyle}>
                  <label style={labelStyle}>Can Access Settings Tab</label>
                  <input
                    type="checkbox"
                    name="canAccessSettingsTab"
                    checked={stateSelectedUser.permissions.canAccessSettingsTab}
                    onChange={handleCheckboxChange}
                    style={inputStyle}
                  />
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
            <a
              className={'txt-white nucleus-font-small ' + stateEditUserBtnStyle}
              onClick={onSaveUserClick}
              style={{
                padding: '12px 24px',
                fontSize: '18px',
                lineHeight: '24px',
                width: '80%',
                textAlign: 'center',
                display: 'inline-block',
              }}
            >
              Save
            </a>
          </div>
          <br />
          {!stateSelectedUser.SuperUser && (
            <div className={'row  '}>
              <h3 className="nucleus-page-subtitle"> Admin User Account's Groups</h3>
              {stateModalLoading && (
                <div className="row center-align ">
                  <br />
                  <SpinnerCircular color="#2096F3" secondaryColor="rgba(0,0,0,0.16)" size="50" thickness={100} />
                </div>
              )}

              {!stateModalLoading && (
                <div className="no-margin">
                  <span className="nucleus-font nucleus-font-14">
                    Start typing the Account’s Group and we will display all options. Simply select from the list and you will see the Group's name show in the section below.
                  </span>

                  <div ref={txtAccountsChipsRef} id="txtAccountGroups" className="chips chips-initial no-margin nucleus-chips"></div>

                  <div className="col s12 center-align no-margin">
                    <br />
                    <a className={'txt-white nucleus-font-modal-btn ' + stateEditGroupsBtnStyle} onClick={handleUpdateAccountGroups}>
                      Update Groups
                    </a>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div id="modalDeleteUser" className="modal modalDeleteUser">
        <div className="modal-content modal-content-delete center-align">
          <h3 className="nucleus-page-subtitle"> Delete Admin User</h3>
          <p></p>
          <div className="row">
            <div className="col s12 center-align nucleus-font-medium">
              Are you sure you want to delete this user?
              <br />
            </div>
          </div>
          <div className="row ">
            <div className="col s12 center-align ">
              <p></p>
              <a className="txt-white nucleus-font-small nucleus-link" onClick={handleCloseDeleteModal}>
                Cancel
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <a className={'txt-white nucleus-font-small ' + stateDeleteUserBtnStyle} onClick={onDeleteUser}>
                Confirm
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CsUsers;
