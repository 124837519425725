import React, { useEffect, useState } from "react";
import { useSortBy, useTable, usePagination } from "react-table";
import {
  BackArrowIcon,
  FastForwardArrowIcon,
  ForwardArrowIcon,
  RewindArrowIcon,
  SortArrowDown,
  SortArrowUp,
  SortNeutral,
} from "./assets/NucleusPaginatedTableSVGs";
import { NucleusControlledSelect } from "../../components/NucleusControlledInput";
import { useForm } from "react-hook-form";
const NucleusPaginatedTable = ({
  columns,
  data,
  checkForDisabled = false,
  onRowClick = () => {},
  resultsCount,
  stateToRowClassMap,
  onTableLoad = () => {},
  stateField,
  loading,
  tableState = {}, // { pageIndex, pageSize, sortBy } - used to initialize the table state
  pageIndexState,
  indexChangeCallback = () => {},
  onPageChange = () => {},
  showResultsCount = true,
  disableTableSorting = false,
}) => {
  const {
    pageIndex: initialPageIndex = 0,
    pageSize: initialPageSize = 10,
    sortBy: initialSortBy = [],
  } = tableState;
  // NOTE - changes to tableState are not tracked. To update the table state, a new tableState object must be passed in.

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageSize, sortBy },
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false,
      initialState: { pageIndex: initialPageIndex, pageSize: initialPageSize, sortBy: initialSortBy},
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const {
    register,
    formState: { errors },
    clearErrors,
    setValue,
    watch,
  } = useForm();
  const [totalPages, setTotalPages] = useState(0);
  const [componentLoaded, setComponentLoaded] = useState(false);

  useEffect(() => {
    setTotalPages(Math.ceil(resultsCount / pageSize));
  }, [resultsCount, pageSize]);

  useEffect(() => {
    if(componentLoaded) {
      onPageChange({ pageIndex: pageIndexState, pageSize, sortBy });
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndexState, pageSize, sortBy]);


  // This is a trick to wait for the component to load the dom and then set the componentLoaded state to true
  useEffect(() => {
    onTableLoad({ pageIndex: pageIndexState, pageSize, sortBy });
    setComponentLoaded(true);
  }, []);

  const startRecord = pageIndexState * pageSize + 1;
  const endRecord = Math.min((pageIndexState + 1) * pageSize, resultsCount);

  return (
    <div>
      <style>
        {`
          .row-active {
            
          }
          .row-provisioning {
            background-color: #FFF5D4;
          }
          
          .row-lost {
            background-color: #FFE1DE;
          }
          .row-disabled {
            background-color: #E6E6E6;
          }
        `}
      </style>
      <span style={{
        display:showResultsCount ? 'inline' : 'none'
      }}>
      {resultsCount === 0
        ? "No results"
        : `Showing ${startRecord}-${endRecord} of ${resultsCount} records`}
      </span>
      <table
        {...getTableProps()}
        className="b-table b-table-colored b-table-hover b-table-active"
      >
        <thead className="nucleus-table-header">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(
                (column) =>
                  column.show !== false && (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={column.className}
                    >
                      <span className="nucleus-table-header">
                        {column.render("Header")}
                        {!disableTableSorting && column.canSort &&
                          (column.isSorted ? (
                            column.isSortedDesc ? (
                              <SortArrowDown />
                            ) : (
                              <SortArrowUp />
                            )
                          ) : (
                            <SortNeutral />
                          ))}
                      </span>
                    </th>
                  )
              )}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            const stateValue = row.original[stateField];
            const stateClass = stateToRowClassMap ? stateToRowClassMap[stateValue] : "";
            return (
              <tr
                {...row.getRowProps({
                  onClick: () => onRowClick({ title: "details", row }),
                  className: `${stateClass}`,
                })}
                style={{
                  color:
                    checkForDisabled && row.original.disabled
                      ? "#888"
                      : undefined,
                }}
              >
                {row.cells.map(
                  (cell) =>
                    cell.column.show !== false && (
                      <td {...cell.getCellProps()} className="nucleus-row">
                        {cell.render("Cell")}
                      </td>
                    )
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      {resultsCount === 0 && !loading && (
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            padding: 10,
          }}
        >
          <tr style={{ borderBottomStyle: "none" }}>
            <td colSpan="6" class="center-align">
              <span class="nucleus-table-header-medium text-gray">
                {`There is no data to display`}{" "}
              </span>
            </td>
          </tr>
        </div>
      )}
      {resultsCount > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "30%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <span style={{ flex: 5 }}>Results per page: </span>
            <div style={{ flex: 5 }}>
              <NucleusControlledSelect
                noLabel
                name={"pageSize"}
                register={register("pageSize")}
                value={pageSize}
                options={[
                  { value: 5 },
                  { value: 10 },
                  { value: 20 },
                  { value: 50 },
                  { value: 100 },
                ]}
                error={errors.pageSize}
                clearErrors={clearErrors}
                setValue={(name, value) => {
                  console.log('name-value', name, value);
                  setValue('pageSize')
                  setPageSize(value);
                }}
                labelAccessor="value"
              />
            </div>
          </div>
          <div>
            <ul
              className="pagination"
              style={{
                border: "2px solid #D8D8D8",
                borderRadius: 5,
                justifyContent: "space-evenly",
              }}
            >
              <li className={pageIndexState > 0 ? "waves-effect" : "disabled"}>
                <a
                  role="button"
                  onClick={() => {
                    if (pageIndexState > 0) {
                     indexChangeCallback(0)
                    }
                  }}
                >
                  <RewindArrowIcon
                    disabled={!(pageIndexState > 0)}
                    active={pageIndexState > 0}
                  />
                </a>
              </li>
              <li className={!pageIndexState > 0 ? "disabled" : "waves-effect"}>
                <a
                  role="button"
                  onClick={() => {
                    if (pageIndexState > 0)
                    indexChangeCallback(pageIndexState - 1)
                  }}
                >
                  <BackArrowIcon
                    disabled={!(pageIndexState > 0)}
                    active={pageIndexState > 0}
                  />
                </a>
              </li>
              {Array.from({ length: totalPages }, (_, index) => {
                if (
                  index === 0 ||
                  index === totalPages - 1 ||
                  Math.abs(index - pageIndexState) <= 2 ||
                  (pageIndexState <= 2 && index <= 4) || // Show first 5 pages when current page is less than or equal to 2
                  (pageIndexState >= totalPages - 3 && index >= totalPages - 5) // Show last 5 pages when current page is greater than or equal to totalPages - 3
                ) {
                  return (
                    <li
                      key={index}
                      className={
                        pageIndexState === index ? "active" : "waves-effect"
                      }
                    >
                      <a 
                        role="button" 
                        onClick={() =>
                          indexChangeCallback(index)
                        }
                      >
                        {index + 1}
                      </a>
                    </li>
                  );
                } else if (
                  (index === 1 && pageIndexState > 3) ||
                  (index === totalPages - 2 && pageIndexState < totalPages - 4)
                ) {
                  return (
                    <li key={index} className="disabled" style={{ width: 15 }}>
                      <span>...</span>
                    </li>
                  );
                }
                return null;
              })}
              <li
                className={
                  pageIndexState < totalPages - 1 ? "waves-effect" : "disabled"
                }
              >
                <a
                  role="button"
                  onClick={() => {
                    if (pageIndexState < totalPages - 1)
                      indexChangeCallback(pageIndexState + 1)
                  }}
                >
                  <ForwardArrowIcon
                    disabled={!(pageIndexState < totalPages - 1)}
                    active={pageIndexState < totalPages - 1}
                  />
                </a>
              </li>
              <li
                className={
                  pageIndexState < totalPages - 1
                    ? "waves-effect"
                    : "disabled"
                }
              >
                <a
                  role="button"
                  onClick={() => {
                    indexChangeCallback(totalPages - 1)
                  }}
                >
                  <FastForwardArrowIcon
                    disabled={
                      !(pageIndexState < totalPages - 1)
                    }
                    active={pageIndexState < totalPages - 1}
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default NucleusPaginatedTable;
