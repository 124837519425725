import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { UIButton, UIModal, useModal } from '../../../components/UI';
import NucleusTable from '../../../components/NucleusTable';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import Papa from 'papaparse';
import MissingDataErrorModal from './MissingDataErrorModal';
import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import { ErrorIcon, SuccessIcon } from '../../../components/UI/assets/SVG';
import { HoverToast, useHoverToast } from '../../../components/HoverToast';
import Message from '../../../utils/Message';
const ImportOrdersModal = ({ closeModal, isOpen }) => {
    type RowData = {
        fe_row_validation: string,
        uuid_fe_identifier: string,
        be_response_result_message?: string,
    }
    const [loading, setLoading] = useState(false);
    const [csvOriginalFileName, setCsvOriginalFileName] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState('');
    const [csvData, setCsvData] = useState<RowData[]>([]);
    const [inputKey, setInputKey] = useState(Date.now()); // To force input re-render
    const { register, watch, resetField } = useForm();
    const convertCsvDataToBlob = (csvData) => {
        const headers = [
            'Order ID',
            'Mdm ID',
            'Device Orderer First Name',
            'Device Orderer Last Name',
            'Device Orderer Phone Number',
            'Device Orderer Email',
            'Patient First Name',
            'Patient Last Name',
            'Patient Phone Number',
            'Shipping Address',
            'Shipped Status',
            'fe_row_validation',
            'uuid_fe_identifier',
            'be_response_result_message'
        ];

        const csvString = Papa.unparse({
            fields: headers,
            data: csvData.map(row => ({
                'Order ID': row['Order ID'],
                'Mdm ID': row['Mdm ID'],
                'Device Orderer First Name': row['Device Orderer First Name'],
                'Device Orderer Last Name': row['Device Orderer Last Name'],
                'Device Orderer Phone Number': row['Device Orderer Phone Number'],
                'Device Orderer Email': row['Device Orderer Email'],
                'Patient First Name': row['Patient First Name'],
                'Patient Last Name': row['Patient Last Name'],
                'Patient Phone Number': row['Patient Phone Number'],
                'Shipping Address': row['Shipping Address'],
                'Shipped Status': row['Shipped Status'],
                'fe_row_validation': row.fe_row_validation,
                'uuid_fe_identifier': row.uuid_fe_identifier,
                'be_response_result_message': row.be_response_result_message
            }))
        });

        return new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    };

    const [alreadyImported, setAlreadyImported] = useState(false);
    const saveCSV = async () => {
        try {
            setAlreadyImported(true)
            setLoading(true);
            const formData = new FormData();
            const csvFile = convertCsvDataToBlob(csvData);
            let fileName = csvOriginalFileName
            const forceCsvExtensionRegex = /\.csv$/i;
            if (!forceCsvExtensionRegex.test(fileName)) {
                fileName += '.csv';
            }
            formData.append('file', new File([csvFile], fileName, { type: 'text/csv' }));
            const { data } = await httpApi.post('/consumer-orders/import/csv', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            Message.show('Orders import done!')
            const updatedCsvData = csvData.map((row) => {
                const isSuccessful = data.results.successful.some(successRow => successRow.csvRecord.uuid_fe_identifier === row.uuid_fe_identifier);
                const isFailed = data.results.failed.some(failedRow => failedRow.csvRecord.uuid_fe_identifier === row.uuid_fe_identifier);
                const failedRecord = data.results.failed.find(failedRow => failedRow.csvRecord.uuid_fe_identifier === row.uuid_fe_identifier);
                const message = isFailed && failedRecord ? failedRecord.message : '';
                return {
                    ...row,
                    fe_row_validation: isSuccessful ? 'Y' : isFailed ? 'N' : row.fe_row_validation,
                    be_response_result_message: message
                };
            });
            setCsvData(updatedCsvData);
            setLoading(false);
        } catch (e) {
            Message.error('Orders import failed!')
            console.error('Error Saving CSV:', e);
            setLoading(false);
        }
    }
    const {
        closeModal: closeAlertModalHook,
        isOpen: isOpenAlertModal,
        openModal: openAlertModal,
    } = useModal();
    const closeAlertModal = () => {
        closeAlertModalHook();
    }
    const clearSelectedValues = () => {
        resetField('csvFileInput');
        setCsvOriginalFileName('');
        setCsvData([]);
        setAlreadyImported(false);
    };
    const closeAndReset = () => {
        closeModal();
        clearSelectedValues();
    }
    useEffect(() => { return () => clearSelectedValues() }, [])

    const onSubmitCsv = () => {
        if (errorMessage) {
            openAlertModal();
            return;
        }
        if (csvData.length) {
            saveCSV();
        }
    }

    const validateCsvContent = (file) => {
        setAlreadyImported(false);
        Papa.parse(file, {
            complete: (results) => {
                if (results.data.length === 0) {
                    setErrorMessage('The CSV file is empty.');
                    clearSelectedValues();
                    return;
                }
                const headers = Object.keys(results.data[0]);
                const requiredFields = [
                    'Device Orderer First Name',
                    'Device Orderer Last Name',
                    'Device Orderer Phone Number',
                    'Patient First Name',
                    'Patient Last Name',
                    'Patient Phone Number'
                ];
                const missingFields = requiredFields.filter(field => !headers.includes(field));
                if (missingFields.length > 0) {
                    setErrorMessage(`Missing required fields: ${missingFields.join(', ')}`);
                } else {
                    setErrorMessage('');
                }
                const dataWithValidation = results.data
                    .filter(row =>
                        Object.values(row).some(value => value !== null && value !== undefined && (value as string)?.trim() !== '')
                    )
                    .map(row => {
                        const hasAllRequiredFields = requiredFields.every(field =>
                            row[field] && (row[field] as string)?.trim() !== ''
                        );
                        const rowData = {
                            'Order ID': row['Order ID'] || '',
                            'Mdm ID': row['Mdm ID'] || '',
                            'Device Orderer First Name': row['Device Orderer First Name'] || '',
                            'Device Orderer Last Name': row['Device Orderer Last Name'] || '',
                            'Device Orderer Phone Number': row['Device Orderer Phone Number'] || '',
                            'Device Orderer Email': row['Device Orderer Email'] || '',
                            'Patient First Name': row['Patient First Name'] || '',
                            'Patient Last Name': row['Patient Last Name'] || '',
                            'Patient Phone Number': row['Patient Phone Number'] || '',
                            'Shipping Address': row['Shipping Address'] || '',
                            'Shipped Status': row['Shipped Status']?.trim() || '', // Trim to handle extra newline characters
                            uuid_fe_identifier: uuidv4(),
                            fe_row_validation: hasAllRequiredFields ? 'Y' : 'N',
                            be_response_result_message: ''
                        };
                        Object.keys(row).forEach(key => {
                            if (!requiredFields.includes(key) && key !== '__parsed_extra') { // Ignore __parsed_extra
                                rowData[key] = row[key];
                            }
                        });
                        return rowData;
                    });
                setCsvData(dataWithValidation);
            },
            header: true,
            skipEmptyLines: true,
            beforeFirstChunk: (chunk) => {
                // Clean up new lines and carriage returns
                return chunk.replace(/\r\n|\r|\n/g, '\n');
            }
        });
    };

    useEffect(() => {
        const csvFileInput = watch('csvFileInput');
        if (csvFileInput?.[0]) {
            const fileName = csvFileInput[0].name;
            setCsvOriginalFileName(fileName);
            validateCsvContent(csvFileInput[0]);
            setInputKey(Date.now());
        }
    }, [watch('csvFileInput')]);

    const dataColumns = useMemo(() => {
        const commonColumns = [
            {
                Header: '',
                canSort: false,
                disableSortBy: true,
                accessor: 'fe_row_validation',
                Cell: alreadyImported && !loading ? RenderStatusCell : RenderBeforeSendingStatusCell
            },
            { Header: 'Order ID', accessor: 'Order ID', disableSortBy: true, Cell: ShowValueOnHover },
            { Header: 'Mdm ID', accessor: 'Mdm ID', disableSortBy: true },
            { Header: 'Device Orderer/Phone', accessor: 'Device Orderer Phone Number', disableSortBy: true, Cell: DeviceOrderPhoneCell },
            { Header: 'Device Orderer Email', accessor: 'Device Orderer Email', disableSortBy: true },
            { Header: 'Patient/Phone', accessor: 'Patient Phone Number', disableSortBy: true, Cell: PatientPhoneCell },
            { Header: 'Shipping Address', accessor: 'Shipping Address', disableSortBy: true, Cell: ShippingAddressCell },
            { Header: 'Shipped Status', accessor: 'Shipped Status', disableSortBy: true, Cell: ShippedStatusCell },
            { Header: 'Details', accessor: 'be_response_result_message', disableSortBy: true }
        ];

        return commonColumns;
    }, [alreadyImported, loading]);

    return (
        <UIModal
            title="Import Orders"
            size="large"
            close={() => {
                clearSelectedValues();
                closeModal();
            }}
            isOpen={isOpen}
            style={{
                width: '90%',
                height: '90%',
                overflow: 'auto',
            }}
        >
            <MainContainer>
                <LabelText style={{ marginBottom: 15 }}>CSV File:</LabelText>
                <CsvFileInputContainer>
                    {!alreadyImported && <CsvFileInputLabel htmlFor="csvFileInput">
                        <CsvFileInput
                            key={inputKey}
                            id="csvFileInput"
                            type="file"
                            accept=".csv"
                            multiple={false}
                            {...register('csvFileInput')}
                            disabled={loading}
                        />
                        <SelectCSVFile>Select CSV File</SelectCSVFile>
                    </CsvFileInputLabel>}
                    <AdviseCSVFile>
                        Consider the file must have the header row with no spaces between the header.
                    </AdviseCSVFile>
                    <CsvSelected show={true}>
                        <span style={{ opacity: csvOriginalFileName ? 1 : 0.5 }}>{csvOriginalFileName?.toString() || "CSV File Name"}</span>
                        {(csvOriginalFileName && !alreadyImported) && <ClearButton type='button' disabled={loading || alreadyImported}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                clearSelectedValues();
                                setInputKey(Date.now());
                            }}
                        >
                            X
                        </ClearButton>}
                    </CsvSelected>
                    {!alreadyImported && <UIButton
                        text='Submit'
                        onClick={onSubmitCsv}
                        variant='primary'
                        disabled={loading || alreadyImported || !csvData?.length}
                    />}
                </CsvFileInputContainer>
                {!!csvData.length ? <CsvContentContainer>
                    <NucleusTable
                        tableStyles={{
                            paddingBottom: '10px',
                        }}
                        columns={dataColumns}
                        data={csvData}
                    />
                </CsvContentContainer> : <div style={{ display: 'flex', flex: 1, minHeight: 390 }}></div>}
                {(alreadyImported && !loading) ? <ImportDataContainer>
                    <SuccessfullyStatusMessage>
                        <SuccessIcon size={20} />
                        <SuccessMessage>
                            {csvData.filter(row => row.fe_row_validation === 'Y').length} Orders imported successfully
                        </SuccessMessage>
                    </SuccessfullyStatusMessage>
                    <FailedStatusMessage>
                        <ErrorIcon size={20} />
                        <FailedRowsMessage>
                            {csvData.filter(row => row.fe_row_validation === 'N').length} Errors
                        </FailedRowsMessage>
                    </FailedStatusMessage>
                </ImportDataContainer> : <ImportDataContainer style={{ display: 'flex', flex: 1, minHeight: 30 }} />}
                <div style={{
                    marginTop: 10
                }}>
                    <UIButton
                        text='Close'
                        onClick={closeAndReset}
                        variant='secondary'
                        disabled={loading}
                    />
                </div>
            </MainContainer>
            <MissingDataErrorModal
                errorMessage={errorMessage}
                isOpen={isOpenAlertModal}
                closeModal={closeAlertModal}
            />
        </UIModal>
    );
};

ImportOrdersModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
};

export default ImportOrdersModal;

const RenderStatusCell = ({ cell: { row, value } }) => {
    const { handleMouseEnter, handleMouseLeave, handleMouseMove, showToast, coords } = useHoverToast();
    return value === 'Y' ? <span><SuccessIcon size={18} /></span> :
        <HoverToast
            key={row.original.fe_row_validation}
            message={row.original.be_response_result_message}
            showToast={showToast}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            handleMouseMove={handleMouseMove}
            coords={coords}
            variant="error"
            toastInfoStyle={{ width: '460px' }}
        >
            <ErrorIcon key={row.original.fe_row_validation} size={18} />
        </HoverToast>
}
const RenderBeforeSendingStatusCell = ({ cell: { row, value } }) => {
    const { handleMouseEnter, handleMouseLeave, handleMouseMove, showToast, coords } = useHoverToast();
    return value === 'Y' ? <span></span> :
        <HoverToast
            key={row.original.fe_row_validation}
            message={'There are missing data in the row. This row may not be imported because some required fields might be missing, but you can proceed with the importation.'}
            showToast={showToast}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            handleMouseMove={handleMouseMove}
            coords={coords}
            variant="warning"
            toastInfoStyle={{ width: '400px' }}
        >
            <ErrorIcon fill={"#FFCA20"} key={row.original.fe_row_validation} size={18} />
        </HoverToast>
}
type ConsumerOrderCsvRecord = {
    ['Mdm ID']: string | undefined;
    ['Device Orderer First Name']: string;
    ['Device Orderer Last Name']: string;
    ['Device Orderer Phone Number']: string;
    ['Device Orderer Email']: string | undefined;
    ['Patient First Name']: string;
    ['Patient Last Name']: string;
    ['Patient Phone Number']: string;
    ['Shipping Address']: string | undefined;
    ['Shipped Status']: boolean | undefined;
};

const DeviceOrderPhoneCell = ({ cell: { row } }) => {
    const rowOriginal = row.original as ConsumerOrderCsvRecord
    const fullName = `${rowOriginal["Device Orderer First Name"]} ${rowOriginal["Device Orderer Last Name"]}`;
    const ordererPhone = rowOriginal["Device Orderer Phone Number"];
    return (
        <ColInfoContainer>
            <span>{fullName}</span>
            <span>{ordererPhone}</span>
        </ColInfoContainer>
    )
}

const ShowValueOnHover = ({ cell: { row } }) => {
    const ID = row.original?.['Order ID'] || row.original?.id;
    const { handleMouseEnter, handleMouseLeave, handleMouseMove, showToast, coords } = useHoverToast();
    const [justClicked, setJustClicked] = useState(false);
    const copyToClipboard = () => {
        navigator.clipboard.writeText(ID);
    }
    const handleClick = () => {
        setJustClicked(true);
        copyToClipboard();
        setTimeout(() => {
            setJustClicked(false);
        }, 1000);
    }
    return (
        <IDContainer>
            {(ID) && <HoverToast
                key={ID}
                message={justClicked ? 'Copied!' : ID}
                showToast={showToast}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                handleMouseMove={handleMouseMove}
                coords={coords}
                toastInfoStyle={{
                    width: '300px',
                }}
            >
                <EllipsisText maxWidth='80px' onClick={handleClick}>{ID}</EllipsisText>
            </HoverToast>}
        </IDContainer >
    )
}

const PatientPhoneCell = ({ cell: { row } }) => {
    const rowOriginal = row.original as ConsumerOrderCsvRecord
    const fullName = `${rowOriginal["Patient First Name"]} ${rowOriginal["Patient Last Name"]}`;
    const orderPhone = rowOriginal["Patient Phone Number"];
    return (
        <ColInfoContainer>
            <span>{fullName}</span>
            <span>{orderPhone}</span>
        </ColInfoContainer>
    )
}

const ShippedStatusCell = ({ cell: { row } }) => {
    const shippedStatus = row.original["Shipped Status"];
    return <EllipsisText>{(shippedStatus && String(shippedStatus)?.toLowerCase() === 'true' || String(shippedStatus)?.toLowerCase() === 'yes') ? 'Yes' : 'No'}</EllipsisText>
}
const ShippingAddressCell = ({ cell: { row } }) => {
    const rowOriginal = row.original as ConsumerOrderCsvRecord
    const shippingAddress = rowOriginal["Shipping Address"];
    let street = '';
    let city = '';
    if (shippingAddress) {
        const address = shippingAddress?.split(',');
        street = `${address?.[0] ?? ''}`;
        city = address?.slice(1)?.join(',') ?? '';
    }
    return (
        <ColInfoContainer>
            <span>{`${street}${city ? ',' : ''}`}</span>
            <span>{`${city}`}</span>
        </ColInfoContainer>
    );
}

const ColInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

const IDContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
`;

const EllipsisText = styled.p<{ maxWidth?: string }>`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: ${props => props.maxWidth || '100px'};
    text-align: center;
`;

const MainContainer = styled.div`
  display: flex;
  flex:1;
  flex-direction: column;
  height: 90%;
`;

const CsvFileInputContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
const ImportDataContainer = styled.div`
    flex:1;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
    gap: 5px;
`;
const SuccessfullyStatusMessage = styled.div`
        display: flex;
        align-items: center;
        gap: 5px;
`;
const FailedStatusMessage = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;
const SuccessMessage = styled.span`
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    color: #008000;
`;
const FailedRowsMessage = styled.span`
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    color: #FE3824;
`;

const LabelText = styled.span`
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #0a313f;
`;

const CsvFileInputLabel = styled.label`
  display: flex;
  flex-direction: column;
`;

const CsvFileInput = styled.input`
  display: none;
`;

const SelectCSVFile = styled(LabelText)`
  line-height: 23px;
  text-decoration-line: underline;
  color: #0092ff;
  cursor: pointer;
`;

const AdviseCSVFile = styled.span`
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #0a313f;
`;

const CsvSelected = styled.div<{ show: boolean }>`
    display: ${({ show }) => (show ? 'flex' : 'none')};
    align-items: center;
    padding: 10px;
    font-size: 15px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    margin: 10px 0px;
    position: relative;
    color: #0a313f;
`;

const ClearButton = styled.button`
    background: none;
    border: none;
    color: #ff0000;
    cursor: pointer;
    font-size: 16px;
    position: absolute;
    right: 8px;
`;

const CsvContentContainer = styled.div`
    margin-top: 10px;
    min-width: 100%;
    min-height: 390px;
    max-height: 390px;
    overflow: auto;
    background-color: #fff;
`;
