import React from 'react';
import ReactDOM from "react-dom";
import $ from "jquery";
import { IndexLink, Link } from "react-router-dom";
import Message from "../utils/Message";
import { useState, useEffect, useRef } from 'react';
import NucleuscareConnectData,
{
	RootState,
	getAllCsUsers,
    updateCsUser,
    deleteCsUser,
	csUsersSliceClean,
} from '@nucleus-care/nucleuscare-backend-client';
import {
	useSelector,
	useDispatch
	//DefaultRootState
} from 'react-redux';
import { SpinnerCircular } from 'spinners-react';
import DayjsUtils from '@date-io/dayjs';
import MomentUtils from '@date-io/moment';
import {
	DateTimePicker,
	KeyboardDateTimePicker,
	MuiPickersUtilsProvider
} from "@material-ui/pickers";
import moment from 'moment';
import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import AuthStore from "../stores/AuthStore";
import backendClient from '../utils/BackendClient';
//Components
import CsUserRow from './csUsers/CsUserRow';

const CsUsers = () => {

	const csUsersStore = useSelector((state: RootState) => {
		//console.log("ReportCsUsers useSelector", state);
		return state.csUsersStore.data;
	});

	const [stateLoading, setStateLoading] = useState(true);
	const [stateSearchText, setStateSearchText] = useState("");
    const txtAccountsChipsRef = useRef(null);
	const dispatch = useDispatch();

    const [stateSelectedUser, setStateSelectedUser] = useState({
        ID: null,
        FirstName: "",
        LastName:"",
        Email: "",
        Telephone: "",
        LastLogin: "",
        SuperUser:false
    });

    const [stateEditUserAllowed, setStateEditUserAllowed] = useState(false);
    const [stateEditUserBtnStyle, setStateEditUserBtnStyle] = useState("nucleus-submit-btn");
    const [stateDeleteUserBtnStyle, setStateDeleteUserBtnStyle] = useState("nucleus-submit-btn-small");
    const [stateEditGroupsBtnStyle, setStateEditGroupsBtnStyle] = useState("nucleus-submit-btn");

    const [stateSortingStyles, setStateSortingStyles] = useState({
			userNameAsc: " nucleus-icon-inactive ",
			userNameDesc : " nucleus-icon-inactive hide ",
			onlineDateAsc : " nucleus-icon-inactive ",
			onlineDateDesc : " nucleus-icon-inactive hide ",
    });

    const [stateSortingMode, setStateSortingMode] = useState("userNameDesc");

    const handleSortingMode = (tag, tag2) => {
			console.log("handleSortingMode", tag, tag2);

			let object = {
					userNameAsc: " nucleus-icon-inactive ",
					userNameDesc : " nucleus-icon-inactive hide ",
					onlineDateAsc : " nucleus-icon-inactive ",
					onlineDateDesc : " nucleus-icon-inactive hide "
			};
			object[tag] = " nucleus-icon-active ";
			object[tag2] = " nucleus-icon-inactive hide ";
			setStateSortingStyles(object);
			setStateSortingMode(tag);
    }

    const [stateModalLoading, setStateModalLoading] = useState(true);
    const [stateGroupsChipsData, setStateGroupsChipsData] = useState({}); //autocompletePatientsList
    const [stateAutocompleteGroups, setStateAutocompleteGroups] = useState({}); // updatePatientsList

    useEffect(() => {
        if (!AuthStore.superUser()){
            window.location.assign("#/dashboard");
        }
        window.$('.modal').modal({
            dismissible: true,
            complete: () => {  } // Callback for Modal close
        });
        refreshReport();
    }, [])

	useEffect(() => {
        if(txtAccountsChipsRef.current) {   
            console.log("entered the init");   
            window.$(txtAccountsChipsRef.current).material_chip();  
            window.$('.chips-initial').material_chip({
                data: [],
                placeholder: 'Type a group name',
                secondaryPlaceholder: 'Type another name',
                autocompleteOptions: {
                data: stateGroupsChipsData,
                limit: Infinity
                },
                selectable : false
            });
            window.$(".chips").click(()=>{
                console.log('window.$(".chips").click');
            });
            window.$('.chips').on('chip.add', (e, chip) =>{
                //console.log("Selected >"+chip.tag+"<");
                //console.log("Groups available: ", stateAutocompleteGroups);
                let existingGroups = [];
                let existingData = window.$('.chips-initial').material_chip('data');
                let groupAlreadyAdded = false;

                for (var i = 0; i < existingData.length; i++){
                        groupAlreadyAdded = stateAutocompleteGroups[existingData[i].tag];
                        if (groupAlreadyAdded){
                                existingGroups.push(existingData[i]);
                        }
                }

                window.$('.chips-initial').material_chip({
                    data: existingGroups,
                    placeholder: 'Type a group name',
                    secondaryPlaceholder: 'Type another name',
                    autocompleteOptions: {
                            data: stateGroupsChipsData,
                            limit: Infinity
                    }
                });
                window.$('.chips').on('chip.select', (e, chip) =>{
                    console.log("hIrt'", e, chip);
                    return false;
                });
                //window.$('#txtPatients input:first').focus();
            });
        }
        setStateModalLoading(false);
	}, [txtAccountsChipsRef.current]);


	useEffect(() => {
		console.log("CsUsers useEffect csUsersStore");
		//console.log("csUsersStore", csUsersStore);
		if (!csUsersStore.loading){
			setStateLoading(false);
			if (csUsersStore.complete){
				//Message.show("Report updated");
				dispatch(
					csUsersSliceClean()
				);
			}
		}
		else if (csUsersStore.error){
			Message.show("Error in CsUsers");
		}

	}, [csUsersStore.loading, csUsersStore.complete])

    useEffect(() => {
		console.log("CsUsers:useEffect csUsersStore:Update");
		console.log("csUsersStore", csUsersStore);
		if (!csUsersStore.updateLoading){
			setStateLoading(false);
			if (csUsersStore.updateComplete){
				refreshReport();
				Message.show("User updated");
				handleCloseEditModal();
				dispatch(
						csUsersSliceClean()
				);
			}
		}
    else if (csUsersStore.error){
			Message.show("Error in CsUsers");
		}

	}, [csUsersStore.updateLoading, csUsersStore.updateComplete])

  useEffect(() => {
		console.log("CsUsers:useEffect csUsersStore:Delete");
		console.log("csUsersStore", csUsersStore);

		if (!csUsersStore.deleteLoading){
			setStateLoading(false);
			if (csUsersStore.deleteComplete){
				refreshReport();
				Message.show("User deleted");
				setStateDeleteUserBtnStyle("nucleus-submit-btn-small");
				window.$('#modalDeleteUser').modal('close');
				handleCloseEditModal();
				dispatch(
						csUsersSliceClean()
				);
			}
		}
		else if (csUsersStore.error){
			Message.show("Error in CsUsers");
		}

	}, [csUsersStore.deleteLoading, csUsersStore.deleteComplete])

  const onSearchTextChange = (textInput) =>{
    console.log("onSearchTextChange", textInput.target.value);
		setStateSearchText(textInput.target.value);
  }

  const refreshReport = ()=>{
		console.log("refreshReport");

		setStateLoading(true);
		dispatch(
			getAllCsUsers()
		);
		getAccountGroups();
	}

    const onRowClicked = (userData)=>{
        console.log("onRowClicked", userData);
        setStateSelectedUser(userData);
				if (!userData.SuperUser){
					getSelectedUserGroups(userData.ID.toLowerCase());
				}
        let editable = (AuthStore.getCsUserID().toLowerCase() == userData.ID.toLowerCase());
        setStateEditUserAllowed(editable);
        setStateEditUserBtnStyle(editable ? "nucleus-submit-btn": "nucleus-submit-btn-disabled");
        window.$('#modalEditCsUser').modal('open');

        setStateEditGroupsBtnStyle("nucleus-submit-btn-disabled");
				setStateModalLoading(true);
    }

    const handleCloseEditModal = ()=>{
        console.log("handleCloseEditModal");
        window.$('#modalEditCsUser').modal('close');
    }

    const onSaveUserClick = ()=>{
        console.log("onSaveUserClick");
        console.log("ID:",stateSelectedUser.ID);
        console.log("Data:",stateSelectedUser);

        setStateEditUserBtnStyle("nucleus-submit-btn-disabled");

        dispatch(
            updateCsUser({
                userId: stateSelectedUser.ID,
                firstName: stateSelectedUser.FirstName,
                lastName: stateSelectedUser.LastName,
                telephone: stateSelectedUser.Telephone
            })
        );
    }

    const onRemoveUserModal=()=>{
			window.$('#modalDeleteUser').modal('open');

			setStateDeleteUserBtnStyle("nucleus-submit-btn-small");
    }

    const handleCloseDeleteModal =()=>{
			if (stateDeleteUserBtnStyle == "nucleus-submit-btn-small"){
					window.$('#modalDeleteUser').modal('close');
			}
    }

    const onDeleteUser = ()=>{
        console.log("onDeleteUser");
        console.log("AuthStore.getCsUserID().toLowerCase()", AuthStore.getCsUserID().toLowerCase());
        console.log("stateSelectedUser.ID", stateSelectedUser.ID);

        setStateDeleteUserBtnStyle("nucleus-submit-btn-small-disabled");

        dispatch(
            deleteCsUser({
                userId: stateSelectedUser.ID,
                deletedBy: AuthStore.getCsUserID().toLowerCase()
            })
        );
    }

		const getAccountGroups=()=>{
			console.log("getAccountGroups");
			backendClient.get(`cs_user/get_account_groups_list`, (response)=>{
				if (response && response.ok && response.groups){
					let dataset = {};
					response.groups.forEach(g => {
						dataset[g.Name] = g.ID.toLowerCase();
					});
					console.log("dataset", dataset);
					let autocompleteGroups = {};
					let autocompleteGroupIDs = {};
					let groupNameKey = "";

					Object.keys(dataset).forEach(function(key,index) {
							groupNameKey = key.trim();
							autocompleteGroupIDs[groupNameKey] = dataset[key];
							autocompleteGroups[groupNameKey] = null;
					});
					console.log("autocompleteGroupIDs", autocompleteGroupIDs);
					console.log("autocompleteGroups", autocompleteGroups);
					setStateGroupsChipsData(autocompleteGroups);
					setStateAutocompleteGroups(autocompleteGroupIDs);

					window.$('.chips-initial').material_chip({
						data: [],
						placeholder: 'Type a group name',
            			secondaryPlaceholder: 'Type another name',
						autocompleteOptions: {
							data: autocompleteGroups,
							limit: Infinity
						}
					});
					//const [stateGroupsChipsData, setStateGroupsChipsData] = useState({}); //autocompletePatientsList
					//const [stateAutocompleteGroups, setStateAutocompleteGroups] = useState({}); // updatePatientsList
				}
			});
		}

		const getSelectedUserGroups=(userId)=>{
			console.log("getSelectedUserGroups", userId);

			backendClient.get(`cs_user/${userId}/account_groups`, (response)=>{
				if (response && response.ok){
					setStateModalLoading(false);
					setStateEditGroupsBtnStyle("nucleus-submit-btn");
					let currentUserGroups = [];
					for (var i = 0; i < response.userGroups.length; i++){
						let groupChip =  {};
						groupChip["tag"] = response.userGroups[i].Name;
						groupChip["Name"] = response.userGroups[i].Name;
						groupChip["GroupID"] = response.userGroups[i].AccountGroupID.toLowerCase();
						currentUserGroups.push(groupChip);
					}
					console.log("currentUserGroups", currentUserGroups);
					window.$('.chips-initial').material_chip({
						data: currentUserGroups,
						placeholder: 'Type a group name',
            			secondaryPlaceholder: 'Type another name',
						autocompleteOptions: {
							data: stateGroupsChipsData,
							limit: 2
						}
					});
				}
				else{
					Message.show("Error loading user Accounts groups");
				}
			})
			//let rsp;
			//httpApi
		}

    const handleUpdateAccountGroups=()=>{
        let groupsIDsArray = null;
        let chipsObjectValue = window.$('.chips-initial').material_chip('data');
        console.log(chipsObjectValue);

        groupsIDsArray = "";
        $.each(chipsObjectValue, (key, value)=> {
            //console.log("Seleccionado", value);
            //console.log("SeleccionadoDE", stateAutocompleteGroups);
            let groupID = null;
            groupID = stateAutocompleteGroups[value.tag];
            //groupsIDsArray.push(groupID);
            if (groupsIDsArray.length > 0){
                    groupsIDsArray = groupsIDsArray + ",";
            }
            groupsIDsArray = groupsIDsArray + groupID ;
            //console.log("The PatientID es: ", groupID);
        });
        //console.log("this.state.selectedImage.MediaID", this.state.selectedImage.MediaID);
        console.log("Los Patients IDs son", groupsIDsArray);
        console.log("UserID", stateSelectedUser.ID);
        saveUserAccountGroups(stateSelectedUser.ID, groupsIDsArray);
    }

    const saveUserAccountGroups=(userId, groupsIdsArray)=>{
        //setStateModalLoading(true);
        setStateEditGroupsBtnStyle("nucleus-submit-btn-disabled");

        backendClient.put(
            `cs_user/${userId}/set_account_groups`,
            {
                groupsIds: groupsIdsArray
            },
            (response)=>{
                setStateEditGroupsBtnStyle("nucleus-submit-btn");
                if (response && response.ok){
                    Message.show("User Groups updated!");
                }
                else{
                    Message.show("Error updating User account groups");
                }
            });

    }

    const getTableRows = ()=>{
        //console.log("render getTableRows>", csUsersStore);
        let userRowsArray = [];
        if (csUsersStore && csUsersStore.users && csUsersStore.users && csUsersStore.users.length > 0){

            let array = [].concat(csUsersStore.users);

            if (stateSortingMode == "userNameDesc"){
                array = array.sort((a, b) => {
										let textA = a.FirstName.toLowerCase() + " " + a.LastName.toLowerCase();
                    let textB = b.FirstName.toLowerCase() + " " + b.LastName.toLowerCase();
                    if (textB < textA) //sort string ascending
                        return -1
                    if (textB > textA)
                        return 1
                    return 0
                });
            }
            else if (stateSortingMode == "userNameAsc"){
                array = array.sort((a, b) => {
										let textA = a.FirstName.toLowerCase() + " " + a.LastName.toLowerCase();
                    let textB = b.FirstName.toLowerCase() + " " + b.LastName.toLowerCase();
                    if (textA < textB) //sort string ascending
                        return -1
                    if (textA > textB)
                        return 1
                    return 0
                });
            }
						else if (stateSortingMode == "onlineDateDesc"){
                array = array.sort((a, b) => (new Date(b.LastLogin)) - (new Date(a.LastLogin)));
            }
            else if (stateSortingMode == "onlineDateAsc"){
                array = array.sort((a, b) => (new Date(a.LastLogin)) - (new Date(b.LastLogin)));
            }

						//devicesReportRef.current = array;
            array.map((csUser, key) => {

				if (stateSearchText.length == 0){
					userRowsArray.push(
						<CsUserRow key={key} data={csUser} onClick={onRowClicked} ></CsUserRow>
					);
				}
				else {
					let text = stateSearchText.toLocaleLowerCase();
					let userName = csUser.FirstName + " " + csUser.LastName;
					let email =  "".concat(csUser.Email);
					let telephone = "".concat(csUser.Telephone);
					let onlineDate =  "".concat(csUser.LastLogin);
					if (
						(userName.toLowerCase().indexOf(text) >= 0) ||
						(email.toLowerCase().indexOf(text) >= 0) ||
						(telephone.toLowerCase().indexOf(text) >= 0) ||
						(onlineDate.toLowerCase().indexOf(text) >= 0)
					){
						userRowsArray.push(
							<CsUserRow key={key} data={csUser} onClick={onRowClicked} ></CsUserRow>
						);
					}
				}
            });

            return (
                <tbody >
                    {userRowsArray}
                </tbody>
            );
        }
        return(
            <tbody className="center-align ">
                <tr style={{borderBottomStyle:"none"}}>
                    <td colSpan="7" className="center-align ">
                        <br/><br/><br/>
                        <span className="nucleus-table-header-medium text-gray"> {`There are no Admin users`} </span>
                        <br/><br/><br/>
                    </td>
                </tr>
            </tbody>
        );
    }

    return(
        <div className="nucleus-tools-container">
            <div >
                <p className="nucleus-tools-page-title"><b>Admin Users</b></p>
            </div>
            <div style={{}}>
				<div className="row a-bit-lower left-align">
					<div className="col m11 no-margin">
						<span className="nucleus-tools-page-text-title a-bit-lower">
							Search &nbsp;&nbsp;&nbsp;
						</span>
						<input
							type="text"
							className="nucleus-input-form-small-left"
							style={{width:500}}
							onChange={onSearchTextChange}
							placeholder={"Name / Email / Telephone"}
						/>
                        &nbsp;&nbsp;&nbsp;
                        <a className="txt-white nucleus-font-small nucleus-submit-btn-tools" onClick={refreshReport}> Refresh </a>
					</div>

					<div className="col m1 no-margin right-align">
						{
							(stateLoading) &&

								<SpinnerCircular
									color="#2096F3"
									secondaryColor='rgba(0,0,0,0.16)'
									size='50'
									thickness='100'
								/>
						}
					</div>
				</div>
            </div>


				<div className="row nucleus-fixed-container nucleus-75-table-container">
            <table className="bordered highlight nucleus-table" >
                <thead>
                    <tr>
                        <th className="table-col-30">
                            <span className="nucleus-table-header-small ">{"Name"}</span>
                            <a className={ "nucleus-font-small " + stateSortingStyles.userNameDesc } onClick={()=>handleSortingMode("userNameAsc","userNameDesc")}> &nbsp;▼</a>
                            <a className={ "nucleus-font-small " + stateSortingStyles.userNameAsc  } onClick={()=>handleSortingMode("userNameDesc","userNameAsc")} >&nbsp;▲</a>
                        </th>
												<th className="table-col-30">
                            <span className="nucleus-table-header-small">{"Email"}</span>
                        </th>
                        <th className="table-col-20 ">
                            {/* <span className="nucleus-table-header-medium">{`${} Name`}</span> */}
                            <span className="nucleus-table-header-small">{"Telephone"}</span>
                        </th>
                        <th className="table-col-25 ">
                            <span className="nucleus-table-header-small">{"Last Login"}</span>
                            <a className={ "nucleus-font-small " + stateSortingStyles.onlineDateDesc }  onClick={()=>handleSortingMode("onlineDateAsc","onlineDateDesc")}> &nbsp;▼</a>
                            <a className={ "nucleus-font-small " + stateSortingStyles.onlineDateAsc }   onClick={()=>handleSortingMode("onlineDateDesc","onlineDateAsc")} >&nbsp;▲</a>
                        </th>
                        <th className="table-col-5 center-align">
							<span className="nucleus-table-header-small">{"Super User"}</span>
						</th>
						<th className="table-col-5 center-align">
							<span className="nucleus-table-header-small">{"Accounts Groups"}</span>
						</th>
                    </tr>
                </thead>
				{
					getTableRows()
				}
            </table>
            </div>

            <div className="fixed-action-btn btn-add-new">

                <a
					className="btn-floating btn-large waves-effect waves-light orange tooltipped"
					data-position="left"
					data-delay="50"
					data-tooltip="New User"
                  	onClick={()=>{window.location.assign("#/newCsUser");}}>
                    <i className="material-icons">add</i>
                </a>

                &nbsp;&nbsp;
            </div>

            <div id="modalEditCsUser" className="modal modalEditCsUser">
                <div className="modal-content nucleus-modal-wrapper-scrollable2">
                    <img className="responsive-img img-close-modal" src="img/close_modal.png" onClick={handleCloseEditModal}/>
                    <h3 className="nucleus-page-subtitle"> Edit Admin User</h3>
                    {
                        (!stateEditUserAllowed) &&
                        <a className={"nucleus-font nucleus-font-small nucleus-link"} onClick={onRemoveUserModal} style={{marginLeft:30}}>
                            <span className="txt-red">Delete</span>
                        </a>
                    }

                    <br/><br/><br/>
                    <div className="row no-margin">
                        <div className="col s3">
                            <span className="nucleus-labels bold-500"> Email</span>
                        </div>
                        <div className="col s9">
                            <span className="nucleus-labels"> {stateSelectedUser.Email} </span>
                        </div>
                    </div>
                    <br/>

                    <div className="row no-margin">
                        <div className="col s3">
                            <span className="nucleus-labels bold-500"> First Name </span>
                        </div>
                        <div className="col s9">
                            <input
                                type="text"
                                value={stateSelectedUser.FirstName}
                                placeholder="First Name"
                                className="validate nucleus-search no-margin-bottom"
                                onChange={(e)=>{
                                    //console.log("Value", e.target.value);
                                    let tempData = Object.assign({}, stateSelectedUser);
                                    tempData.FirstName = e.target.value;
                                    setStateSelectedUser(tempData);
                                }}
                                disabled={!stateEditUserAllowed}
                                />
                            <br/>
                            {/* <span className={"nucleus-form-error " + this.state.editCategoryNameError }> Please enter the Category Name</span>&nbsp; */}
                        </div>
                    </div>
                    <br/>
                    <div className="row no-margin">
                        <div className="col s3">
                            <span className="nucleus-labels bold-500"> Last Name </span>
                        </div>
                        <div className="col s9">
                            <input
                                type="text"
                                value={stateSelectedUser.LastName}
                                placeholder="First Name"
                                className="validate nucleus-search no-margin-bottom"
                                onChange={(e)=>{
                                    //console.log("Value", e.target.value);
                                    let tempData = Object.assign({}, stateSelectedUser);
                                    tempData.LastName = e.target.value;
                                    setStateSelectedUser(tempData);
                                }}
                                disabled={!stateEditUserAllowed}
                                />
                            <br/>
                            {/* <span className={"nucleus-form-error " + this.state.editCategoryNameError }> Please enter the Category Name</span>&nbsp; */}
                        </div>
                    </div>
                    <br/>
                    <div className="row no-margin">
                        <div className="col s3">
                            <span className="nucleus-labels bold-500"> Telephone </span>
                        </div>
                        <div className="col s9">
                            <input
                                type="text"
                                value={stateSelectedUser.Telephone || ""}
                                placeholder="Telephone"
                                className="validate nucleus-search no-margin-bottom"
                                onChange={(e)=>{
                                    //console.log("Value", e.target.value);
                                    let tempData = Object.assign({}, stateSelectedUser);
                                    tempData.Telephone = e.target.value;
                                    setStateSelectedUser(tempData);
                                }}
                                disabled={!stateEditUserAllowed}
                                />
                            <br/>
                            {/*
							<span className={"nucleus-form-error " + this.state.editCategoryNameError }>
								Please enter the Category Name
							</span>
							*/}
                        </div>
                    </div>

										<br/>
                    <div className="row no-margin">
                        <div className="col s3">
                            <span className="nucleus-labels bold-500"> Super User </span>
                        </div>
                        <div className="col s9">
												<span className="nucleus-labels bold-500"> {stateSelectedUser.SuperUser ? "Y": "" } </span>
                            <br/>
                        </div>
                    </div>

                    <div className="input-field col s12 modalCallLogContent2">
                        <div className="row no-margin">
                            <div className="col s12 center-align no-margin">
                                <p></p>
                                <span className=" modalSeparator"> <label> &nbsp;</label></span>
                                <a className={"txt-white nucleus-font-small " + stateEditUserBtnStyle } onClick={onSaveUserClick}>Save</a>
                                <div>&nbsp;</div>
                            </div>
                        </div>
                        <br/>
                    </div>
                    {
                        (
                            !stateSelectedUser.SuperUser &&
                            <div className={"row  "} >
                                <h3 className="nucleus-page-subtitle"> Admin User Account's Groups</h3>
                                {
									(stateModalLoading) &&
									<div className="row center-align ">
										<br />
										<SpinnerCircular
											color="#2096F3"
											secondaryColor='rgba(0,0,0,0.16)'
											size='50'
											thickness='100'
										/>
									</div>
                                }

                                {
                                    (
                                        !stateModalLoading &&
                                        <div className="no-margin">

                                            <span className="nucleus-font nucleus-font-14">
                                                Start typing the Account’s Group and we will display all options.
                                                Simply select from the list and you will see the Group's name show in the section below.
                                            </span>

                                            <div
                                                ref={txtAccountsChipsRef}
                                                id="txtAccountGroups"
                                                className="chips chips-initial no-margin nucleus-chips"
                                            >
                                                
                                            </div>

                                            <div className="col s12 center-align no-margin">
                                                <br/>
                                                <a
                                                    className={"txt-white nucleus-font-modal-btn " + stateEditGroupsBtnStyle }
                                                    onClick={handleUpdateAccountGroups}
                                                >
                                                    Update Groups
                                                </a>
                                            </div>
                                            {/*<span className={ "nucleus-form-error center-align " + this.state.selectPatientsError }>Please select at least one { AuthStore.getPatientLabel() }.</span> */ }
                                        </div>
                                    )
                                }

                            </div>
                        )
                    }
                </div>
            </div>

            <div id="modalDeleteUser" className="modal modalDeleteUser">
                <div className="modal-content modal-content-delete center-align">
                    <h3 className="nucleus-page-subtitle"> Delete Admin User</h3>
                    <p></p>
                    <div className="row">
                        <div className="col s12 center-align nucleus-font-medium">
                            Are you sure you want to delete this user?
                            <br/>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col s12 center-align ">
                            <p></p>
                            <a className="txt-white nucleus-font-small nucleus-link" onClick={handleCloseDeleteModal}>Cancel</a>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <a className={"txt-white nucleus-font-small " + stateDeleteUserBtnStyle} onClick={onDeleteUser}>Confirm</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default CsUsers;
