import React, { useState } from 'react';

const CloseSVG = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Floating Button - Exit">
        <path
          id="Line 2"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.8278 19.1579L24.2849 25.615L25.0913 26.4213L26.7038 24.8088L25.8975 24.0025L19.4403 17.5453L18.634 16.739L17.0215 18.3516L17.8278 19.1579Z"
          fill="#0A313F"
        />
        <path
          id="Line 2_2"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.4404 25.611L25.8973 19.1541L26.7036 18.3478L25.091 16.7352L24.2847 17.5415L17.8278 23.9984L17.0215 24.8047L18.6341 26.4173L19.4404 25.611Z"
          fill="#0A313F"
        />
      </g>
    </svg>
  );
};

const NucleusModal = ({
  setIsOpen,
  isOpen = true,
  children,
  type,
  width,
  maxWidth,
  height,
  maxHeight,
  modalHeight,
  outsideComponent
}) => {
  return (
    <React.Fragment>
      <div
        style={{
          display: isOpen ? 'flex' : 'none',
        }}
        className="darkBG"
        onClick={() => setIsOpen(false)}
      />
      {!!isOpen && outsideComponent}
      <div
        style={{
          display: isOpen ? 'flex' : 'none',
          minHeight: 400,
          height: modalHeight ? modalHeight : 'auto',
          zIndex: 1000,
        }}
        className="centered"
      >
        <div
          className={type !== 'apps' ? 'mDMModal' : 'mDMModalAddApps'}
          style={{
            width: width || 'auto',
            maxWidth: maxWidth || undefined,
            height: height || 'auto',
            maxHeight: maxHeight || undefined,
          }}
        >
          <div className="modalHeader"></div>
          <button
            className=""
            style={{
              position: 'absolute',
              right: 0,
              background: 'transparent',
              border: 'none',
            }}
            onClick={() => setIsOpen(false)}
          >
            <CloseSVG />
          </button>
          <div
            className="modalContent"
            style={{
              overflowX: 'auto',
              overflowY: 'auto',
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NucleusModal;
