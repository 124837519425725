import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSortBy, useTable } from 'react-table';
import {
  SortArrowDown,
  SortArrowUp,
  SortNeutral,
} from './assets/NucleusTableSVGs';
import classNames from 'classnames';

const NucleusTable = ({
  tHeadStyle,
  tableStyles,
  tbodyStyles,
  stickyThead,
  columns,
  data,
  checkForDisabled = false, // if true, will check for a disabled property on the row and render it as disabled
  disabledKey = 'disabled',
  enableExtraRow,
  onRowClick = () => {},
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);
  const renderSortIcon = useCallback(
    (column) => {
      if (column.isSorted) {
        if (column.isSortedDesc) {
          return <SortArrowDown />;
        } else {
          return <SortArrowUp />;
        }
      } else {
        return <SortNeutral />;
      }
    },
    [headerGroups],
  );
  return (
    <table
      {...getTableProps()}
      className="b-table b-table-colored b-table-hover b-table-active"
      style={tableStyles}
    >
      <thead
        className={
          stickyThead
            ? 'nucleus-table-header sticky-thead'
            : 'nucleus-table-header'
        }
        style={{
          ...tHeadStyle,
        }}
      >
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(
              (column) =>
                column.show !== false && (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={
                      classNames(column.centerAlign && 'center-align') ||
                      ''
                    }
                  >
                    <span className={'nucleus-table-header '}>
                      {column.render('Header')}
                      {column.canSort && renderSortIcon(column)}
                    </span>
                  </th>
                ),
            )}
          </tr>
        ))}
      </thead>
      <tbody style={tbodyStyles} {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps({
                onClick: () => onRowClick({ title: 'details', row }),
              })}
              style={{
                color:
                  checkForDisabled && row.original[disabledKey]
                    ? '#888'
                    : undefined,
              }}
            >
              {row.cells.map((cell) => {
                return (
                  cell.column.show !== false && (
                    <td
                      {...cell.getCellProps()}
                      className={
                        classNames(cell.column.centerAlign && 'center-align') ||
                        ''
                      }
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                );
              })}
            </tr>
          );
        })}
        {enableExtraRow && <tr>{columns.map(() => (<td></td>))}</tr>}
      </tbody>
    </table>
  );
};
NucleusTable.propTypes = {
  tableStyles: PropTypes.object,
  tbodyStyles: PropTypes.object,
  stickyThead: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  checkForDisabled: PropTypes.bool,
  onRowClick: PropTypes.func,
};
export default NucleusTable;
