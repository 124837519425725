import { CSSProperties, useEffect, useState } from "react";
import AuthStore from "../../../stores/AuthStore";

export type TabItem = {
  name: string;
}

export const AccountTabsHeader = ({
  onSelectedTab,
  tabs
 
}: {
  onSelectedTab: (tab: TabItem) => void;
  tabs: TabItem[];
}) => {

  
  const [activeTab, setActiveTab] = useState<TabItem>(tabs[0]);

  useEffect(() => {
    
    onSelectedTab(activeTab);
  }, [activeTab]);

  return <div className="row no-margin">
  {tabs.map((tab: TabItem) => {
    const isActive = activeTab.name === tab.name;
    const tabStyle: CSSProperties = isActive ? {
      paddingBottom: '3px',
      borderBottom: '3px solid #2A97FF'
    } : undefined;
    return (
      <span key={tab.name} className={'staffTabs2 nucleus-link '} style={tabStyle} onClick={() => setActiveTab(tab)}>
        <span className="nucleusTabText">{tab.name}</span>
      </span>
    );
  })}
  </div>;
};
