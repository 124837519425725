import React, {useEffect, useRef, useState} from 'react';
import $ from "jquery";
import Config from '../Config';
import {
	KeyboardDatePicker,
    KeyboardTimePicker,
	MuiPickersUtilsProvider
} from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import PatientRequestSection from './PatientRequestSection';
import CareFamilyMemberItemRow from './CareFamilyMemberItemRow';
import CallLogItemRow from './CallLogItemRow';
import {Link} from 'react-router-dom';
import AuthStore from '../stores/AuthStore';
import PatientConfigSection from './PatientConfigSection';
import ScheduleView from './ScheduleView';
import GeneralStore from "../stores/GeneralStore";
import CarePatientStore from '../stores/CarePatientStore';
import CareAccountStore from "../stores/CareAccountStore";
import Spinner from "react-activity/dist/Spinner";
import IntlTelInput from "react-intl-tel-input";
import LogsDetailModal from '../components/LogsDetailModal';
import Message from "../utils/Message";

const AccountUserPatientProfileActivityAndConfiguration = ({patientId, accountId}) => {

    const [familyMembersList, setFamilyMembersList] = useState([]);
    const [callLogsList, setCallLogsList] = useState([]);
    const [patientCallLogs, setPatientCallLogs] = useState([]);
    const [callLogsTab, setCallLogsTab] = useState("");
    const [requestLogsTab, setRequestLogsTab] = useState("");
    const [careFamilyTab, setCareFamilyTab] = useState("");
    const [deviceSettingsTab, setDeviceSettingsTab] = useState("");
    const [callLogsContent, setCallLogsContent] = useState("hide");
    const [requestLogsContent, setRequestLogsContent] = useState("hide");
    const [careFamilyContent, setCareFamilyContent] = useState("hide")
    const [fromDateTimeLogs, setFromDateTimeLogs] = useState(null);
    const [toDateTimeLogs, setToDateTimeLogs] = useState(null);
    const [moveFamilyEnabled, setMoveFamilyEnabled] = useState(false);
    const [patientID, setPatientID] = useState(patientId);
    const [logsDatesErrorVisible, setLogsDatesErrorVisible] = useState("hide");
    const [logsDatesErrorText, setLogsDatesErrorText] = useState("The From Date has to be before the To Date.")
    const [configurationContent, setConfigurationContent] = useState("hide");
    const [scheduleContent, setScheduleContent] = useState("hide");
    const [requestsLogsClicked, setRequestsLogsClicked] = useState(false);
    const [tabSet, setTabSet] = useState(false);
    const [familyMembersArray, setFamilyMembersArray] = useState([]);
    const [patientLogsTable, setPatientLogsTable] = useState("");
    const [loadingLogs, setLoadingLogs] = useState(false);
    const [patientLogsLoading, setPatientLogsLoading] = useState("hide");
    const [dateOptions, setDateOptions ] = useState({ day: "2-digit", month: "2-digit", year: "numeric" });
	const [timeOptions, setTimeOptions] = useState({ hour12: true, hour: "2-digit", minute: "2-digit" });
    const [patientCallLogsResponse, setPatientCallLogsResponse] = useState(false);
    const [familyMembersArrayResponse, setFamilyMembersArrayResponse] = useState(false);
    const [patientsResponse, setPatientsResponse] = useState(false);
    const [deviceCallShortcutValue, setDeviceCallShortcutValue] = useState(false);
    const [patientTimezone, setPatientTimezone] = useState(false);
    const [PhotosCount, setPhotosCount] = useState(false);
    const [enableBiometrics, setEnableBiometrics] = useState(false);
    const [enableAccountBiometrics, setEnableAccountBiometrics] = useState(false);
    const [enableBiometricsFeature, setEnableBiometricsFeature] = useState(false);
    const [enableBluetoothButtons, setEnableBluetoothButtons] = useState(false);
    const [enableAccountBluetoothButtons, setEnableAccountBluetoothButtons] = useState(false);
    const [enableBluetoothButtonsFeature, setEnableBluetoothButtonsFeature] = useState(false);
    const [enableAutoAnswer, setEnableAutoAnswer] = useState(false);
    const [enableAccountAutoAnswer, setEnableAccountAutoAnswer] = useState(false);
    const [enableImpairedRingtone, setEnableImpairedRingtone] = useState(false);
    const [enableAppIncomingCall, setEnableAppIncomingCall] = useState(false);
    const [enableAccountAppIncomingCall, setEnableAccountAppIncomingCall] = useState(false);
    //const [enableButtonsSchedule, setEnableButtonsSchedule] = useState(false);
    const [showAccountCallButton, setShowAccountCallButton] = useState(false);
    const [showCallButton, setShowCallButton] = useState(false);
    const [showAccountCallButton2, setShowAccountCallButton2] = useState(false);
    const [showCallButton2, setShowCallButton2] = useState(false);
    const [showAccountCheckInButton, setShowAccountCheckInButton] = useState(false);
    const [showCheckInButton, setShowCheckInButton] = useState(false);
    const [showAccountEmergencyButton, setShowAccountEmergencyButton] = useState(false);
    const [showEmergencyButton, setShowEmergencyButton] = useState(false);
    const [showWeather, setShowWeather] = useState(false);
    const [showCallPhoneFromDevice, setShowCallPhoneFromDevice] = useState(false);
    const [enableAccountMemoryBox, setEnableAccountMemoryBox] = useState(false);
    const [enableMemoryBox, setEnableMemoryBox] = useState(false);
    const [enableBlankMemoryBox, setEnableBlankMemoryBox] = useState(false);
    const [showCallButtonsBar, setShowCallButtonsBar] = useState(false);
    const [showAccountCallButtonsBar, setShowAccountCallButtonsBar] = useState(false);
    const [enableVoiceMessage, setEnableVoiceMessage] = useState(false);
    const [patientZipCode, setPatientZipCode] = useState("28017");
    const [accountAutoAnswer, setAccountAutoAnswer] = useState(false);
    const [selectedMemberID, setSelectedMemberID] = useState("");
    const [selectedMemberFirstName, setSelectedMemberFirstName] = useState("");
    const [selectedMemberLastName, setSelectedMemberLastName] = useState("");
    const [selectedMemberEmail, setSelectedMemberEmail] = useState("");
    const [selectedMemberTelephone, setSelectedMemberTelephone] = useState("");
    const [selectedMemberAllTelephone, setSelectedMemberAllTelephone] = useState("");
    const [selectedMemberReceiveUpdates, setSelectedMemberReceiveUpdates] = useState("");
    const [selectedMemberProfileCreated, setSelectedMemberProfileCreated] = useState("");
    const [selectedMemberAccountAutoAnswer, setSelectedMemberAccountAutoAnswer] = useState("");
    const [selectedMemberAutoAnswer, setSelectedMemberAutoAnswer] = useState("");
    const [selectedMemberAdmin, setSelectedMemberAdmin] = useState("");
    const [memberModalOptionLabel, setMemberModalOptionLabel] = useState("");
    const [memberModeEdit, setMemberModeEdit] = useState("");
    const [memberModeDelete, setMemberModeDelete] = useState("");
    const [memberModalOptionColor, setMemberModalOptionColor] = useState("");
    const [deleteMemberBtnStyle, setDeleteMemberBtnStyle] = useState("");
    const [btnResendMemberEmailStyle, setBtnResendMemberEmailStyle] = useState("");
    const [errorMemberEmailVisibility, setErrorMemberEmailVisibility] = useState("hide");
    const [errorMemberTelephoneVisibility, setErrorMemberTelephoneVisibility] = useState("hide");
    const [currentUserAdmin, setCurrentUserAdmin] = useState(true);
    const [errorMemberTelephoneText, setErrorMemberTelephoneText] = useState("Telephone already registered. ");
    const [autoAnswerVisibility, setAutoAnswerVisibility] = useState("");
    const [autoAnswerValue, setAutoAnswerValue] = useState(selectedMemberAutoAnswer && selectedMemberAccountAutoAnswer ? "checked" : "");
    const [autoAnswerEnabled, setAutoAnswerEnabled] = useState(currentUserAdmin && selectedMemberAccountAutoAnswer ? "" : "disabled")
    const [receiveUpdatesValue, setReceiveUpdatesValue] = useState(selectedMemberReceiveUpdates ? "checked" : "");
    const [btnResendVisibility, setBtnResendVisibility] = useState(" hide");
    const [editMemberBtnStyle, setEditMemberBtnStyle] = useState("nucleus-submit-btn");
    const [familyMemberModalOpen, setFamilyMemberModalOpen] = useState(false);
    const [updatedFamilyMember, setUpdatedFamilyMember] = useState(false);
    const [deletedFamilyMember, setDeletedFamilyMember] = useState(false);
    const [memberFirstName, setMemberFirstName] = useState("");
    const [memberLastName, setMemberLastName] = useState("");
    const [memberEmail, setMemberEmail] = useState("");
    const [memberTelephone, setMemberTelephone] = useState("");
    const [callLogID, setCallLogID] = useState("");
    const [callLogStartTime, setCallLogStartTime] = useState("");
    const [callLogStatus, setCallLogStatus] = useState("");
    const [callLogDuration, setCallLogDuration] = useState("");
    const [callLogParticipants, setCallLogParticipants] = useState("");
    const [callLogRequest, setCallLogRequest] = useState("");
    const [callLogNotes, setCallLogNotes] = useState("");
    const [callLogModalBtnState, setCallLogModalBtnState] = useState("nucleus-submit-btn");
    const [callLogModalTxtState, setCallLogModalTxtState] = useState("");
    const [textCallLogNotes, setTextCallLogNotes] = useState("");
    const [updatedCallLogNotes, setUpdatedCallLogNotes] = useState(false);
    const [strings, setStrings] = useState({})
    let controlTimezone = 0;

    const resetTabs = () => {
		/*this.setState({
			callLogsContent: "hide",
			requestLogsContent: "hide",
			careLogsContent: "hide",
			careFamilyContent: "hide",
			careNotesContent: "hide",
			careMessagesContent: "hide",
			biometricsContent: "hide",
			configurationContent: "hide",
			scheduleContent: "hide",
			bulletinContent: "hide",

			callLogsTab: "",
			requestLogsTab: "",
			careLogsTab: "",
			careFamilyTab: "",
			careNotesTab: "",
			careMessagesTab: "",
			biometricsTab: "",
			configTab: "",

			deviceSettingsTab: "",
			biometricsAlertsTab: "",
			buttonsTab: "",
			scheduleTab: "",
			bulletinTab: "",
			appsTab: "",
		});*/
        setCallLogsContent("hide");
        setRequestLogsContent("hide");
        setCareFamilyContent("hide");
        setConfigurationContent("hide");

        setCallLogsTab("")
        setRequestLogsTab("");
        setCareFamilyTab("");
        setDeviceSettingsTab("");

        setPatientCallLogs([]);
	}

    const handleCallLogsTab = () => {
		handleDefaultTab();
		/*
		CarePatientStore.getPatientCallLogs({
			PatientID : this.props.patientId
		});
		*/
		//Message.show("Loading Call Logs...");
	}

	const handleFamilyTab = () => {
		resetTabs();
        setCareFamilyContent("");
        setCareFamilyTab("staffSubTab");
		GeneralStore.setClientInnerTab(2);
		//Message.show("Loading Family Members...");
	}

    const handleDeviceSettingsTab = () =>{
		resetTabs();
        setConfigurationContent("");
        setDeviceSettingsTab("staffSubTab");
		GeneralStore.setClientInnerTab(3);
		CarePatientStore.openDevicesSubTab();
	}


    const handleSaveNotes = () => {
        console.log(callLogID);


        CarePatientStore.updateCallLogNotes({
            CallLogID : callLogID,
            Notes: textCallLogNotes,
            UserID : AuthStore.getCsUserID()
        });

    }

    const validateTxtMemberInput = () => {
		/*if (this.refs.memberEmail.value.length > 0) {
			this.setState({
				errorMemberEmailVisibility: "hide",
			});
		}
		if (this.memberTelephone.length > 0) {
			this.setState({
				errorMemberTelephoneVisibility: "hide",
			});
		}*/
	}

    const handleDefaultTab = () => {
		resetTabs();
        setCallLogsContent("");
        setCallLogsTab("staffSubTab");
		GeneralStore.setClientInnerTab(1);
	}

    const handleLogDates = (date, name) => {
        if(name === 'fromDateTimeLogs') {
            setFromDateTimeLogs(date)
        } else {
            setToDateTimeLogs(date)
        }

    }

    const handleCloseEditMemberModal = () => {
		window.$("#modalEditMember").modal("close");
	}

    const handleMemberModalMode = () => {
		if (memberModalOptionLabel == "Delete") {
            setMemberModalOptionLabel("Cancel");
            setMemberModeEdit("hide");
            setMemberModeDelete("");
            setMemberModalOptionColor("");
		} else {
            setMemberModalOptionLabel("Delete");
            setMemberModeEdit("");
            setMemberModeDelete("hide");
            setMemberModalOptionColor("txt-red");
		}
	}

    const validateTxtMemberTelephone = (status, value, countryData, number, id) => {
		//console.log("validateTxtTelephone", status, value, countryData, number, id);
		//console.log("validateTxtTelephone status ", status);
		console.log("validateTxtMemberTelephone VALUE ", value);
		console.log("validateTxtMemberTelephone NUMBER ", number);
		//console.log("validateTxtTelephone COUNTRY ", countryData);

		if (number) {
			setMemberTelephone(number);
			console.log("validateTxtMemberTelephone", memberTelephone);
			if (value.length > 0) {
                setErrorMemberTelephoneVisibility("hide");
			}
            setSelectedMemberTelephone(value);
		} else {
			setMemberTelephone("+" + value.dialCode + selectedMemberTelephone);
		}
	}

    const handleEnableMemberAutoAnswer = (event) => {
		console.log("handleEnableMemberAutoAnswer", event.target.checked);

		CarePatientStore.updateFamilyMemberEnableAutoAnswer({
			PatientID: patientID,
			FamilyID: selectedMemberID,
			EnableAutoAnswer: event.target.checked,
		});
	}

    const handleEnableMemberAdmin = (event) => {
		console.log("handleEnableMemberAutoAnswer", event.target.checked);

		CarePatientStore.updateFamilyMemberAdmin({
			PatientID: patientID,
			FamilyID: selectedMemberID,
			Admin: event.target.checked,
		});
	}

    const handleResendMemberEmail = () => {
        setBtnResendMemberEmailStyle("nucleus-submit-btn-disabled");
		CarePatientStore.reSendInvite({
			FamilyMemberID: selectedMemberID,
		});
		//Listen to "onResendInvite"
	}

    const handleFamilyMemberInputs = (e) => {
        if(e.target.name === 'memberFirstName') {
            setMemberFirstName(e.target.value);
        } else if(e.target.name === 'memberLastName') {
            setMemberLastName(e.target.value);
        }
    }

    const handleSubmitEditMember = ()  => {
		let familyMemberTelephone = memberTelephone;
		if (familyMemberTelephone.length > 0) {
			//console.log("LE Telephone avant:", familyMemberTelephone, familyMemberTelephone.length);
			familyMemberTelephone = familyMemberTelephone.replace(/\(/g, "");
			familyMemberTelephone = familyMemberTelephone.replace(/\)/g, "");
			familyMemberTelephone = familyMemberTelephone.replace(/-/g, "");
			familyMemberTelephone = familyMemberTelephone.replace(/ /g, "");
			//console.log("LE Telephone est:", familyMemberTelephone, familyMemberTelephone.length);

			let containsLetter = /[a-zA-Z]/.test(familyMemberTelephone);
			if (containsLetter) {
                setErrorMemberEmailVisibility("hide");
                setErrorMemberTelephoneVisibility("");
                setErrorMemberTelephoneText("Invalid Telephone");
				return;
			}

			let onlyNumber = selectedMemberTelephone;
			onlyNumber = onlyNumber.replace(/\(/g, "");
			onlyNumber = onlyNumber.replace(/\)/g, "");
			onlyNumber = onlyNumber.replace(/-/g, "");
			onlyNumber = onlyNumber.replace(/ /g, "");
			//console.log("The only number is: ", onlyNumber, onlyNumber.length);
			if (onlyNumber.length < 8) {
                setErrorMemberEmailVisibility("hide");
                setErrorMemberTelephoneVisibility("");
                setErrorMemberTelephoneText("Invalid Telephone. It must be at least 8 digits.");
				return;
			}
		}

        setEditMemberBtnStyle("nucleus-submit-btn-disabled");

		CarePatientStore.updateFamilyMember({
			FamilyMemberID: selectedMemberID,
			FirstName: memberFirstName,
			LastName: memberLastName,
			Telephone: familyMemberTelephone,
			Email: '', //this.refs.memberEmail.value,
			Password: '',
			//ReceiveUpdates: this.refs.checkMemberReceiveUpdates.checked
		});

		// CarePatientStore.updateFamilyMemberTelephone({
		//     FamilyMemberID : this.state.selectedMemberID,
		//     Telephone : this.refs.memberTelephone.value
		// });

		// CarePatientStore.updateFamilyMemberName({
		//     FamilyMemberID : this.state.selectedMemberID,
		//     FirstName : this.refs.memberFirstName.value,
		//     LastName : this.refs.memberLastName.value
		// });
        setErrorMemberEmailVisibility("hide");
        setErrorMemberTelephoneVisibility("hide");
	}

    const handleSubmitDeleteMember = () => {
        setDeleteMemberBtnStyle("nucleus-submit-btn-disabled");

		CarePatientStore.deleteFamilyMember({
			FamilyMemberID: selectedMemberID,
			PatientID: patientId,
		});
	}

    const handleNotes = (e) => {
        setTextCallLogNotes(e.target.value);
    }

    const handleCloseModal =() => {
        window.$('#modal1').modal('close');
    }

    const handleOpenParticipantStats = (callParticipantLogID) => {
        console.log("handleOpenParticipantStats", callParticipantLogID);
        var URL = Config.rootDomain + "/ws/ShowVideoCallStatsForParticipant.aspx?PLogID=" + callParticipantLogID;
        window.open(URL, '_blank');
    }

    useEffect(() => {
        if(strings.ok) {
            return;
        }
        CareAccountStore.getAccountStringsConfig(
            accountId,
            "EN",

            (response)=>{
                console.log("CareAccountStore.getAccountStringsConfig callback", response);
                if (response.ok){
                    setStrings(response);
                }
            }
        );
        return;
    }, [strings])

    useEffect(() => {
        if (patientCallLogs.length != null) {
            patientCallLogs.map((log, key) => {
                callLogsList.push(
                    <CallLogItemRow
                        key={key}
                        LogID={log.ID}
                        StartTime={log.StartTime}
                        DateString={log.DateString}
                        TimeString={log.TimeString}
                        WasAnswered={log.WasAnswered}
                        InProgress={log.InProgress}
                        EndTime={log.EndTime}
                        Participants={log.Participants}
                        PatientTimeZone={log.patientTimezone}
                        RequestID={log.RequestID}
                        RequestType={log.RequestType}
                        RequestTime={log.RequestTime}
                        RequestEscalated={log.Escalated}
                        Class={
                            {
                                ID: log.ClassID
                            }
                        }
                        HasNotes={log.HasNotes}
                        Notes={log.Notes}
                        NotesUserID={log.NotesUserID}
                        PatientLabel={log.clientRoleName}
                        Status={log.Status}
                    />
                );
            });
        }
        let currentDate = new Date();
        let passedDate = new Date();

        currentDate.setHours(
            currentDate.getHours(),
            currentDate.getMinutes() + 30,
            0
        );

        passedDate.setDate(passedDate.getDate() - 7);
        passedDate.setHours(8, 0, 0);

        let currentTimezone = (currentDate.getTimezoneOffset() / 60) * -1;
        console.log("TIMEZONE:", currentTimezone);
        //console.log("TIMEZONE FORMAT:", currentDate.toString().match(/([-\+][0-9]+)\s/)[1]);
        controlTimezone = currentTimezone;
        console.log("CONTROL TIMEZONE:", controlTimezone);


        setFromDateTimeLogs(passedDate);
        setToDateTimeLogs(currentDate);

        passedDate.setHours(0);
        passedDate.setMinutes(0);
        passedDate.setSeconds(0);
        passedDate.setHours(passedDate.getHours() + controlTimezone * -1);

        currentDate.setHours(23);
        currentDate.setMinutes(59);
        currentDate.setSeconds(0);
        currentDate.setHours(currentDate.getHours() + controlTimezone * -1);

        console.log("PatientProfileInfo.js", "fromDate " + passedDate);
        console.log("PatientProfileInfo.js", "toDate " + currentDate);

    }, [patientCallLogs])

    const handleFilterPatientCallLogs = () => {
		console.log("handleFilterPatientCallLogs");

		let fromDateTime = new Date(fromDateTimeLogs);
		let toDateTime = new Date(toDateTimeLogs);

		if (toDateTime < fromDateTime) {
            setLogsDatesErrorVisible(" ");
            setLogsDatesErrorText(" The From Date has to be before the To Date.");
			return;
		}

		fromDateTime.setHours(0);
		fromDateTime.setMinutes(0);
		fromDateTime.setSeconds(9);

		fromDateTime.setHours(
			fromDateTime.getHours() + controlTimezone * -1
		);

		toDateTime.setHours(23);
		toDateTime.setMinutes(59);
		toDateTime.setSeconds(0);

		toDateTime.setHours(
			toDateTime.getHours() + controlTimezone * -1
		);

		let fromParam = fromDateTime.toLocaleDateString("en-US") + " " +
						fromDateTime.toLocaleTimeString("en-US",{ hour: '2-digit', minute: '2-digit' });

		let toParam = toDateTime.toLocaleDateString("en-US") + " " +
					toDateTime.toLocaleTimeString("en-US",{ hour: '2-digit', minute: '2-digit' });

		console.log("handleFilterPatientCallLogs", "fromDate " + fromParam);
		console.log("handleFilterPatientCallLogs", "toDate " + toParam);
        setCallLogsList([])
        setPatientCallLogs([])
        CarePatientStore.getPatientCallLogs({
            PatientID: patientId,
            From: fromParam,
            To: toParam,
        });
        CarePatientStore.on(
            "onPatientGetCallLogs", (response) => {
                console.log("patientProfile:onPatientGetCallLogsAction", response);
                if (response.ok) {
                    setPatientCallLogs(response.CallLogs);
                    setLoadingLogs(false);
                    setFromDateTimeLogs(fromParam);
                    setToDateTimeLogs(toParam);
                } else {
                    console.log("Error getting " + AuthStore.getPatientLabel().toLowerCase() + " information");
                }
            }
        );
        if (patientCallLogs.length != null) {
            patientCallLogs.map((log, key) => {
                callLogsList.push(
                    <CallLogItemRow
                        key={key}
                        LogID={log.ID}
                        StartTime={log.StartTime}
                        DateString={log.DateString}
                        TimeString={log.TimeString}
                        WasAnswered={log.WasAnswered}
                        InProgress={log.InProgress}
                        EndTime={log.EndTime}
                        Participants={log.Participants}
                        PatientTimeZone={log.patientTimezone}
                        RequestID={log.RequestID}
                        RequestType={log.RequestType}
                        RequestTime={log.RequestTime}
                        RequestEscalated={log.Escalated}
                        Class={
                            {
                                ID: log.ClassID
                            }
                        }
                        HasNotes={log.HasNotes}
                        Notes={log.Notes}
                        NotesUserID={log.NotesUserID}
                        PatientLabel={log.clientRoleName}
                        Status={log.Status}
                    />
                );
            });
        }
       CarePatientStore.removeListener(
            "onPatientGetCallLogs",
            () => {}
        );
	}

    useEffect(() => {
        if(tabSet) {
            return;
        }
        if (GeneralStore.getClientInnerTab() == 1) {
			handleCallLogsTab();
            setTabSet(true);
		}
		else if (GeneralStore.getClientInnerTab() == 2) {
			handleFamilyTab();
            setTabSet(true);
		}
		else if (GeneralStore.getClientInnerTab() == 3) {
			//this.handleConfigTab();
			handleDeviceSettingsTab();
            setTabSet(true);
		}
		else {
			handleDefaultTab();
            setTabSet(true);
		}
        return;
    },[tabSet])

    const removeToolTipOnPageChange = () => {
        window.$(".tooltipped").tooltip("remove");
    }

    useEffect(() => {
        if(familyMembersArrayResponse) {
            return;
        }
        CarePatientStore.getFamilyMembers({
            PatientID: patientId,
        });
        CarePatientStore.on(
            "onGetPatientFamilyMembers", (response) => {
                if (response.ok) {
                    setFamilyMembersArray(response.FamilyMembers);
                    setMoveFamilyEnabled(response.MoveFamilyEnabled);
                    setFamilyMembersArrayResponse(true);
                }
            }
        );
        return () => CarePatientStore.removeListener(
            "onGetPatientFamilyMembers",
            () => {}
        );
    }, [familyMembersArrayResponse]);

    useEffect(() => {
        if (familyMembersArray.length > 0) {
            familyMembersArray.map((family, key) => {
                console.log("family member: ", family)
                setFamilyMembersList(oldArray => [
                    ...oldArray,
                    <CareFamilyMemberItemRow
                        key={key}
                        MemberID={family.ID}
                        Admin={family.Admin}
                        Name={family.FirstName + " " + family.LastName}
                        FirstName={family.FirstName}
                        LastName={family.LastName}
                        Email={family.Email}
                        Telephone={family.Telephone}
                        UserImageThumb={family.UserImageThumb}
                        ProfileCreated={family.ProfileCreated}
                        //ReceiveUpdates={family.ReceiveUpdates}
                        AutoAnswer={family.AutoAnswer}
                        AccountAutoAnswer={family.AccountAutoAnswer}
                    />
                ]);
            });
        }

    }, [familyMembersArray])

    useEffect(() => {
        if(patientCallLogsResponse) {
            return;
        }
        let currentDate = new Date();
		let passedDate = new Date();
        // currentDate.setHours(
		// 	currentDate.getHours(),
		// 	currentDate.getMinutes() + 30,
		// 	0
		// );
        currentDate.setHours(23);
		currentDate.setMinutes(59);
		currentDate.setSeconds(0);

		passedDate.setDate(passedDate.getDate() - 7);
		passedDate.setHours(0, 0, 0);
        let fromParamString =
			passedDate.toLocaleDateString("en-US", dateOptions) + " " +
			passedDate.toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit' });
		let toParamString =
			currentDate.toLocaleDateString("en-US", dateOptions) + " " +
			currentDate.toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit' });
        CarePatientStore.getPatientCallLogs({
            PatientID: patientId,
            From: fromParamString,
            To: toParamString,
        });
        CarePatientStore.on(
            "onPatientGetCallLogs", (response) => {
                console.log("patientProfile:onPatientGetCallLogsAction", response);
                if (response.ok) {
                    setPatientCallLogs(response.CallLogs);
                    setLoadingLogs(false);
                    setPatientCallLogsResponse(true);
                } else {
                    console.log("Error getting " + AuthStore.getPatientLabel().toLowerCase() + " information");
                }
            }
        );
        return () => CarePatientStore.removeListener(
            "onPatientGetCallLogs",
            () => {}
        );
    }, [patientCallLogsResponse]);

    useEffect(() => {
        if(familyMemberModalOpen) {
            return;
        }
        window.$("#modalEditMember").modal();
        GeneralStore.on(
			"onOpenFamilyMemberModal", (memberData) => {
                console.log("onOpenFamilyMemberModalAction", memberData);
                setSelectedMemberID(memberData.memberID);
                setSelectedMemberFirstName(memberData.memberFirstName);
                setSelectedMemberLastName(memberData.memberLastName);
                setSelectedMemberEmail(memberData.memberEmail);
                setSelectedMemberTelephone(memberData.memberTelephone ? memberData.memberTelephone : "");
                setSelectedMemberAllTelephone(memberData.memberTelephone ? memberData.memberTelephone : "");
                setSelectedMemberReceiveUpdates(memberData.memberReceiveUpdates);
                setSelectedMemberProfileCreated(memberData.memberProfileCreated);
                setSelectedMemberAccountAutoAnswer( memberData.AccountAutoAnswer);
                setSelectedMemberAutoAnswer(memberData.MemberAutoAnswer);
                setSelectedMemberAdmin(memberData.MemberAdmin);
                setMemberModalOptionLabel( "Delete");
                setMemberModeEdit("");
                setMemberModeDelete("hide");
                setMemberModalOptionColor("txt-red");
                setDeleteMemberBtnStyle("nucleus-submit-btn");
                setBtnResendMemberEmailStyle("nucleus-submit-btn");
                //this.refs.memberFirstName.value = memberData.memberFirstName;
                setMemberFirstName(memberData.memberFirstName);
                //this.refs.memberLastName.value = memberData.memberLastName;
                setMemberLastName(memberData.memberLastName);
                //this.refs.memberEmail.value = memberData.memberEmail;
                setMemberEmail(memberData.memberEmail);
                //this.memberTelephone = memberData.memberTelephone ? memberData.memberTelephone : "";
                setMemberTelephone(memberData.memberTelephone);

                window.$("#modalEditMember").modal("open");
                setFamilyMemberModalOpen(true);
            }
		);
        return () => GeneralStore.removeListener(
            "onOpenFamilyMemberModal",
            () => {}
        );
    }, [familyMemberModalOpen])

    useEffect(() => {
        if(patientsResponse) {
            return;
        }
        CarePatientStore.getPatientData({
            PatientID : patientId
        })
        CarePatientStore.on("onPatientGetData", (response) => {
            console.log("patientProfile:onPatientGetDataAction", response);

		if (response.ok) {
			setDeviceCallShortcutValue(response.DeviceCallShortcut);
            setPatientTimezone(response.TimezoneString);
            setPhotosCount(response.PhotosCount);
            setEnableBiometrics(response.EnableBiometrics);
            setEnableAccountBiometrics(response.AccountEnableBiometrics);
            setEnableBiometricsFeature(response.EnableBiometrics && response.AccountEnableBiometrics);
            setEnableBluetoothButtons(response.EnableBluetoothButtons);
            setEnableAccountBluetoothButtons(response.AccountEnableBluetoothButtons);
            setEnableBluetoothButtonsFeature(response.EnableBluetoothButtons && response.AccountEnableBluetoothButtons);
            setEnableAutoAnswer(response.EnableAutoAnswer);
            setEnableAccountAutoAnswer(response.AccountEnableAutoAnswer);
            setEnableImpairedRingtone(response.EnableImpairedRingtone);
            setEnableAppIncomingCall(response.EnableAppIncomingCall);
            setEnableAccountAppIncomingCall(response.AccountEnableAppIncomingCall);
            //setEnableButtonsSchedule(response.EnableCallButtonSchedule || response.EnableEmergencyButtonSchedule);
            setShowAccountCallButton(response.AccountShowCallButton);
            setShowCallButton(response.ShowCallButton);
            setShowAccountCallButton2(response.AccountShowCallButton2);
            setShowCallButton2(response.ShowCallButton2);
            setShowAccountCheckInButton(response.AccountShowCheckInButton);
            setShowCheckInButton(response.ShowCheckInButton);
            setShowAccountEmergencyButton(response.AccountShowEmergencyButton);
            setShowEmergencyButton(response.ShowEmergencyButton);
            setShowWeather(response.ShowWeather);
			setShowCallPhoneFromDevice(response.CallPhoneFromDevice);
            setEnableAccountMemoryBox(response.AccountEnableMemoryBox);
            setEnableMemoryBox(response.EnableMemoryBox);
            setEnableBlankMemoryBox(response.EnableBlankMemoryBox);
            setShowCallButtonsBar(response.ShowCallButtonsBar);
            setShowAccountCallButtonsBar(response.AccountShowCallButtonsBar);
			setEnableVoiceMessage(response.EnableVoiceMessage);
            setPatientsResponse(true);
		} else {
			console.log(
				"Error getting " +
				AuthStore.getPatientLabel().toLowerCase() +
				" Information"
			);
		}
        });

        window.$(".tooltipped").tooltip({ delay: 50 });
        return () => CarePatientStore.removeListener(
                "onPatientGetData",
                () => {}
            );
    },[patientsResponse]);

    useEffect(() => {
        if(updatedFamilyMember) {
            return;
        }
        CarePatientStore.on(
			"onUpdateFamilyMember", (response) =>{
                if (response.ok) {
                    // this.setState({
                    //     familyMembersArray : response.FamilyMembers
                    // });
                    setFamilyMembersArrayResponse(false);
                    setFamilyMembersList([])
                    setFamilyMembersArray([])
                    window.$("#modalEditMember").modal("close");
                    Message.show(AuthStore.getFamilyLabel()+" updated");
                } else if (response.emailRegistered) {
                    setErrorMemberEmailVisibility("");
                    setErrorMemberTelephoneVisibility("hide");
                } else if (response.telephoneRegistered) {
                    setErrorMemberEmailVisibility("hide");
                    setErrorMemberTelephoneVisibility("");
                    setErrorMemberTelephoneText("Telephone already registered.");
                } else {
                    setErrorMemberEmailVisibility("hide");
                    setErrorMemberTelephoneVisibility("");
                    setErrorMemberTelephoneText(response.error);
                }
                setEditMemberBtnStyle(" nucleus-submit-btn");
                setUpdatedFamilyMember(true);
            }
		);
        return () => CarePatientStore.removeListener(
			"onUpdateFamilyMember",
			() => {}
		);
    }, [updatedFamilyMember])

    useEffect(() => {
        CarePatientStore.on("onOpenCallLogDetailsAction", (callLog) => {
            console.log("onOpenCallLogDetailsAction");
            console.log(callLog);

            let isAutor = true;
            if (callLog.NotesUserID && callLog.NotesUserID.toUpperCase() != AuthStore.getCsUserID().toUpperCase()){
                isAutor = false;
            }
            //console.log("Is Autor", isAutor);

            if (callLog.ok){
                setCallLogID(callLog.LogID)
                setCallLogStartTime(callLog.LogStartTime);
                setCallLogStatus(callLog.LogStatus);
                setCallLogDuration((callLog.LogDuration != "") ? callLog.LogDuration : "-");
                setCallLogParticipants(callLog.LogParticipants);
                setCallLogRequest(callLog.LogRequest);
                setCallLogNotes(callLog.Notes);
                setCallLogModalBtnState(isAutor ? 'nucleus-submit-btn' : 'nucleus-submit-btn-disabled');
                setCallLogModalTxtState(isAutor ? '' : 'disabled');

                //this.refs.textCallLogNotes.value = callLog.Notes || "";
                window.$('#modal1').modal('open');
            }
            else{
                Message.show("There was a problem getting the call log notes");
            }
        });

    })

    useEffect(() => {
        if(deletedFamilyMember) {
            return;
        }
        CarePatientStore.on(
			"onDeleteFamilyMember", (response) => {
                if (response.ok) {
                    // this.setState({
                    //     familyMembersArray : response.FamilyMembers
                    // });
                    setFamilyMembersArrayResponse(false);
                    setFamilyMembersList([]);
                    window.$("#modalEditMember").modal("close");
                    Message.show("Family Member deleted");
                    setDeletedFamilyMember(true);
                } else {
                    Message.show("Error deleting Family Member");
                }
                setDeleteMemberBtnStyle("nucleus-submit-btn")
            }
		);
        return () => CarePatientStore.removeListener(
			"onDeleteFamilyMember",
			() => {}
		);
    }, [deletedFamilyMember])

    useEffect(() => {
        if(updatedCallLogNotes) {
            return;
        }
        CarePatientStore.on("onUpdateCallLogNotesAction", (response) => {
            if (response.ok){
                window.$('#modal1').modal('close');
                //CarePatientStore.refreshPatientCallLogs();
                setPatientCallLogs([]);
                setCallLogsList([]);
                setPatientCallLogsResponse(false);
            }else{
                window.$('#modal1').modal('close');
                Message.show("There was a problem updating the call log note");
            }
            setUpdatedCallLogNotes(true);
        });
        return () => CarePatientStore.removeListener(
            "onUpdateCallLogNotesAction", () => {}
        );

    }, [updatedCallLogNotes])

    useEffect(() => {
        if(!updatedCallLogNotes) {
            return;
        }
        CarePatientStore.on("onRefreshPatientGetCallLogs", () => {
            setPatientCallLogs([]);
            setPatientCallLogsResponse(false);
        });
        setUpdatedCallLogNotes(false);
        return () => CarePatientStore.removeListener(
			"onRefreshPatientGetCallLogs", () => {}
        );
    }, [updatedCallLogNotes])

    if (loadingLogs) {
        setPatientLogsTable(" hide");
        setPatientLogsLoading("")
    }

    let callLogDetailsParticipants = [];
    if (callLogParticipants != null && callLogParticipants.length > 0) {
        callLogParticipants.map((participantData, key) => {

            if (participantData.HasVideoStats){
                callLogDetailsParticipants.push(
                    <div class="no-margin a-bit-lower" key={key}>
                        <a class="btn-floating btn-sm waves-effect waves-light nucleus-floating-btn-mini a-left" onClick={() => handleOpenParticipantStats(participantData.ID)}>
                            <i class="material-icons nucleus-floating-btn-icon-mini">save_alt</i>
                        </a>
                        &nbsp; <span class="nucleus-labels nucleus-font-label-body">{ participantData.Description }</span>
                    </div>
                );
            } else{
                callLogDetailsParticipants.push(
                    <div class="no-margin a-bit-lower" key={key}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span class="nucleus-labels nucleus-font-label-body">{ participantData.Description }</span>
                    </div>
                );
            }
        });
    }

    let callDetailsColumn1 = "col s8";
    let callDetailsColumn2 = "col s4";

    if (callLogRequest != null && callLogRequest.ID){
        callDetailsColumn1 = "col s6";
        callDetailsColumn2 = "col s6";
    }

    return (
        <React.Fragment>
            <h3 class="new-nucleus-page-subtitle"> Activity & Configuration</h3>
            <div class="patientSettings">
                <div class="bottom-border-menu ">
                    <div class="tabSmallContainer">
                        <a	id={'tab-call-logs'}
                            class={"staffTabsSmall2 nucleus-link " + callLogsTab}
                            onClick={handleCallLogsTab}
                        >
                            &nbsp;&nbsp;Outgoing Call Logs&nbsp;&nbsp;
                        </a>
                    </div>

                    <div class="tabSmallContainer">
                        <a	id='tab-care-family'
                            class={"staffTabsSmall2 nucleus-link " + careFamilyTab}
                            onClick={handleFamilyTab}
                        >
                            &nbsp;&nbsp;{strings.FamilyRoleNamePlural ? strings.FamilyRoleNamePlural : AuthStore.getFamilyLabelPlural()}&nbsp;&nbsp;
                        </a>
                    </div>

                    <div class="tabSmallContainer">
                        <a
                            id='tab-device-settings'
                            class={"staffTabsSmall2 nucleus-link " + deviceSettingsTab}
                            onClick={handleDeviceSettingsTab}
                        >
                            &nbsp;&nbsp; Device Settings &nbsp;&nbsp;
                        </a>
                    </div>
                </div>
                <br />

                <div class={"row padding-15 " + callLogsContent}>
                    {/*
                    <div class="assignments-table-pending nucleus-table-container" >
                    */}
                    <div class="row  ">
                        &nbsp;&nbsp;
                        <span class="nucleus-labels nucleus-bold">From:</span> &nbsp;
                        {/* <input
                            id="fromDateTimeLogs"
                            name="fromDateTimeLogs"
                            type="text"
                            class="validate nucleus-date-picker-short width90"
                        /> */}
                        <div style={{width:140, display:'inline-flex'}}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    name="fromDateTimeLogs"
                                    variant="dialog"
                                    class="browser-default"
                                    value={fromDateTimeLogs}
                                    onChange={(date) => handleLogDates(date, "fromDateTimeLogs")}
                                    autoOk={true}
                                    onError={console.log}
                                    //minDate={new Date("2018-01-01T00:00")}
                                    format={"MM/DD/YYYY"}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        &nbsp;&nbsp;&nbsp;
                        <span class="nucleus-labels nucleus-bold">To:</span> &nbsp;
                        {/* <input
                            id="toDateTimeLogs"
                            name="toDateTimeLogs"
                            type="text"
                            class="validate nucleus-date-picker-short width90"
                        /> */}
                        <div style={{width:140, display:'inline-flex'}}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    name="toDateTimeLogs"
                                    variant="dialog"
                                    class="browser-default"
                                    value={toDateTimeLogs}
                                    onChange={(date) => handleLogDates(date, "toDateTimeLogs")}
                                    autoOk={true}
                                    onError={console.log}
                                    //minDate={(new Date(fromDateTimeLogs)).setDate(new Date(fromDateTimeLogs).getDate() + 2)}
                                    format={"MM/DD/YYYY"}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        &nbsp;&nbsp;&nbsp;
                        <a class={"txt-white nucleus-font-small nucleus-submit-btn-small"}
                            onClick={handleFilterPatientCallLogs}
                        >
                            Filter Results
                        </a>
                        &nbsp;
                        <br />
                        <span class={"nucleus-form-error center-align " + logsDatesErrorVisible}>
                            {logsDatesErrorText}
                        </span>
                    </div>
                    <br />
                    <div class={"assignments-table-pending " + patientLogsTable}>
							<table class="bordered highlight  nucleus-table">
								<thead>
									<tr>
										<th class="table-col-25">
											<span class="nucleus-table-header-small">Date & Time</span>
										</th>
										<th class="table-col-10 left-align">
											<span class="nucleus-table-header-small">Call Type</span>
										</th>
										<th class="table-col-10 left-align">
											<span class="nucleus-table-header-small">Status</span>
										</th>
										<th class="table-col-10 left-align">
											<span class="nucleus-table-header-small">Duration</span>
										</th>
										<th class="table-col-30 left-align">
											<span class="nucleus-table-header-small">Participants</span>
										</th>
										<th class="table-col-10 center-align">
											<span class="nucleus-table-header-small">Emergency</span>
										</th>
										<th class="table-col-5 right-align">
											<span class="nucleus-table-header-small">
												Notes&nbsp;
											</span>
										</th>
									</tr>
								</thead>
								<tbody>{callLogsList}</tbody>
							</table>
						</div>
                        <div class={"row center-align " + patientLogsLoading}>
							<br />
							<br />
							<br />
							<Spinner
								size={34}
								speed={0.6}
								animating={loadingLogs}
								style={{ display: 'inline-flex' }}
							/>
							<br />
							<br />
							<br />
						</div>
                    {/* <div class="fixed-action-btn btn-add-new">
                        <a class="btn-floating btn-large waves-effect waves-light orange" onClick={this.handleNewAssignment}>
                            <i class="material-icons">add</i>
                        </a>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div> */}
                </div>

                <div class={"row padding-15 " + requestLogsContent}>
                    <PatientRequestSection
                        patientID={patientID}
                        //patientFullName={this.props.patientFullName}
                        //currentOffset={this.controlTimezone}
                    />
                </div>

                <div class={"row padding-15 " + careFamilyContent}>
                    <div class="assignments-table-pending ">
                        <table class="bordered highlight  nucleus-table">
                            <thead>
                                <tr>
                                    <th class="table-col-25 left-align">
                                        <span class="nucleus-table-header-small">Name</span>
                                    </th>
                                    <th class="table-col-25 left-align">
                                        <span class="nucleus-table-header-small">
                                            Phone Number
                                        </span>
                                    </th>
                                    <th class="table-col-25 left-align">
                                        <span class="nucleus-table-header-small">Email</span>
                                    </th>
                                    <th class="table-col-15 center-align">
                                        <span class="nucleus-table-header-small">Status</span>
                                    </th>
                                    <th class="table-col-10 center-align">
                                        <span class="nucleus-table-header-small">Admin</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{familyMembersList}</tbody>
                        </table>

                        {(familyMembersList.length > 0 && moveFamilyEnabled) && (
                            <div style={{ marginTop: 25 }}>
                                <a
                                    class="txt-white nucleus-font-14 nucleus-red-btn"
                                    //onClick={this.deleteAllFamilyMembers}
                                    style={{ marginTop: 25 }}
                                >
                                    {"Delete All "+AuthStore.getUserLabelPlural()}
                                </a>

                                <a
                                    class="txt-white nucleus-font-14 nucleus-red-btn"
                                    //onClick={this.moveAllFamilyMembers}
                                    style={{ marginTop: 25, marginLeft: 25 }}
                                >
                                    {"Move All "+AuthStore.getFamilyLabelPlural()}
                                </a>
                            </div>

                            // <button
                            //   className="btn danger"
                            //   style={{
                            //     marginTop: 50,
                            //     backgroundColor: "red",

                            //   }}
                            //   onClick={this.deleteAllFamilyMembers}
                            // >
                            //   Delete All Family Members
                            // </button>
                        )}
                    </div>

                    <div class="fixed-action-btn btn-add-new">
                        <Link to={"/newFamilyMember/" + patientID}>
                            <span
                                class="btn-floating btn-large waves-effect waves-light orange tooltipped"
                                style={{"line-height": 56}}
                                data-position="left"
                                data-delay="50"
                                data-tooltip={"New "+ AuthStore.getFamilyLabel()}
                                onClick={removeToolTipOnPageChange}
                            >
                                <i class="material-icons">add</i>
                            </span>
                        </Link>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                </div>

                <div class={"row " + configurationContent}>
                    <PatientConfigSection
                        patientID={patientID}
                        accountID={accountId}
                        PhotosCount={PhotosCount}
                        showAccountCallButton={showAccountCallButton}
                        showCallButton={showCallButton}
                        showAccountCallButton2={showAccountCallButton2}
                        showCallButton2={showCallButton2}
                        showAccountCheckInButton={showAccountCheckInButton}
                        showCheckInButton={showCheckInButton}
                        showAccountEmergencyButton={showAccountEmergencyButton}
                        showEmergencyButton={showEmergencyButton}
                        showWeather={showWeather}
                        showCallPhoneFromDevice={showCallPhoneFromDevice}
                        enableAccountMemoryBox={enableAccountMemoryBox}
                        enableMemoryBox={enableMemoryBox}
                        enableBlankMemoryBox={enableBlankMemoryBox}
                        showCallButtonsBar={showCallButtonsBar}
                        showAccountCallButtonsBar={showAccountCallButtonsBar}
                        zipCode={patientZipCode}
                        enableBiometrics={enableBiometrics}
                        enableAccountBiometrics={enableAccountBiometrics}
                        enableBluetoothButtons={enableBluetoothButtons}
                        enableAccountBluetoothButtons={
                            enableAccountBluetoothButtons
                        }
                        enableAutoAnswer={enableAutoAnswer}
                        enableAccountAutoAnswer={enableAccountAutoAnswer}
                        enableAccountAppIncomingCall={
                            enableAccountAppIncomingCall
                        }
                        enableAppIncomingCall={enableAppIncomingCall}
                        enableImpairedRingtone={enableImpairedRingtone}
                    />
                </div>

            </div>
            <div
					id="modalEditMember"
					class="modal modalPatientProfile modalScrollable"
				>
					<div class="modal-content nucleus-modal-content txt-special-color nucleus-modal-wrapper-scrollable2">
						<img
							class="responsive-img img-close-modal"
							src="img/close_modal.png"
							onClick={handleCloseEditMemberModal}
						/>
						<h3 class="nucleus-page-subtitle">&nbsp; Edit {AuthStore.getFamilyLabel()}</h3>
						<a
							class={"nucleus-font nucleus-font-small nucleus-link"}
							onClick={handleMemberModalMode}
						>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<span class={memberModalOptionColor}>
								{" "}{memberModalOptionLabel}
							</span>
						</a>
						<br />
						<br />
						<div class={" modallScrollable " + memberModeEdit}>
							<div class="row">
								<div class="col s5">
									<span class="nucleus-labels">
										<b>Email</b>
									</span>
								</div>
								<div class="col s7" key={selectedMemberEmail}>
									<input
										id="memberEmail"
										name="memberEmail"
										type="text"
										disabled="disabled"
										class="validate nucleus-input-form"
										defaultValue={selectedMemberEmail}
										onChange={validateTxtMemberInput}
									/>
									<span
										class={
											"nucleus-form-error center-align " +
											errorMemberEmailVisibility
										}
									>
										Email already exists.
									</span>
								</div>
							</div>

							<div class="row">
								<div class="col s5">
									<span class="nucleus-labels">
										<b>First Name</b>
									</span>
								</div>
								<div class="col s7" key={selectedMemberFirstName}>
									<input
										id="memberFirstName"
										name="memberFirstName"
                                        value={memberFirstName}
										type="text"
										class="validate nucleus-input-form"
										defaultValue={selectedMemberFirstName}
                                        onChange={handleFamilyMemberInputs}
									/>
								</div>
							</div>
							<div class="row">
								<div class="col s5">
									<span class="nucleus-labels">
										<b>Last Name</b>
									</span>
								</div>
								<div class="col s7" key={selectedMemberLastName}>
									<input
										id="memberLastName"
										name="memberLastName"
                                        value={memberLastName}
										type="text"
										class="validate nucleus-input-form"
										defaultValue={selectedMemberLastName}
                                        onChange={handleFamilyMemberInputs}
									/>
								</div>
							</div>

							<div class="row">
								<div class="col s5">
									<span class="nucleus-labels">
										<b>Telephone</b>
									</span>
								</div>
								<div
									class="col s7 a-bit-left"
									key={selectedMemberAllTelephone}
								>
									{/*<input id="memberTelephone" name="memberTelephone" type="text" class="validate nucleus-input-form" disabled={phoneEditableStyle} defaultValue={ selectedMemberTelephone} onChange={this.validateTxtMemberInput}/> */}
									<IntlTelInput
										css={[
											"intl-tel-input validate width100",
											"nucleus-input-form width83",
										]}
										utilsScript={"libphonenumber.js"}
										autoHideDialCode={true}
										separateDialCode={true}
										nationalMode={false}
										format={true}
										disabled={false}
										preferredCountries={["us"]}
										defaultValue={selectedMemberAllTelephone}
										onSelectFlag={validateTxtMemberTelephone}
										onPhoneNumberChange={validateTxtMemberTelephone}
									/>

									<span
										class={
											"nucleus-form-error center-align " +
											errorMemberTelephoneVisibility
										}
									>
										{errorMemberTelephoneText}
									</span>
								</div>
							</div>

							<div class={"row " + autoAnswerVisibility}>
								<div class="col s5">
									<span class="nucleus-labels">
										<b>Enable Auto Answer</b>
									</span>
								</div>
								<div class="col s7" key={autoAnswerValue}>
									<input
										name="checkEnableMemberAutoAnswer"
										type="checkbox"
										class="filled-in"
										id="checkEnableMemberAutoAnswer"
										defaultChecked={autoAnswerValue}
										disabled={autoAnswerEnabled}
										onChange={handleEnableMemberAutoAnswer}
									/>
									<label for="checkEnableMemberAutoAnswer">&nbsp;</label>
								</div>
							</div>

							{
								(currentUserAdmin) &&
								<div class={"row "}>
									<div class="col s5">
										<span class="nucleus-labels">
											<b>Admin</b>
										</span>
									</div>
									<div class="col s7" key={selectedMemberAdmin ? "checked": ""}>
										<input
											name="checkEnableMemberAdmin"
											type="checkbox"
											class="filled-in"
											id="checkEnableMemberAdmin"
											defaultChecked={selectedMemberAdmin ? "checked": ""}
											onChange={handleEnableMemberAdmin}
										/>
										<label for="checkEnableMemberAdmin">&nbsp;</label>
									</div>
								</div>
							}


							{/* Disabled/ Deprecated*/}
							<div class="row hide">
								<div class="col s5">
									<span class="nucleus-labels">
										<b>Receive Updates</b>
									</span>
								</div>
								<div class="col s7">
									<div class="switch">
										<label key={receiveUpdatesValue}>
											<input
												type="checkbox"
												defaultChecked={receiveUpdatesValue}
												name="checkMemberReceiveUpdates"
											/>
											<span class="lever"></span>
										</label>
									</div>
								</div>
							</div>
							<br />
							<div class={"input-field col s12 " + btnResendVisibility}>
								<div class="row no-margin">
									<div class="col s12 center-align no-margin">
										<span class=" modalSeparator">
											{" "}<label> &nbsp;</label>
										</span>
										<a
											class={"txt-white nucleus-font-modal-btn " + btnResendMemberEmailStyle}
											onClick={handleResendMemberEmail}
										>
											Resend Invite
										</a>
										<div>&nbsp;</div>
									</div>
								</div>
							</div>
							<br />
							<div class="input-field col s12 modalCallLogContent2">
								<div class="row no-margin">
									<div class="col s12 center-align no-margin">
										<span class=" modalSeparator">
											{" "}<label> &nbsp;</label>
										</span>
										<a
											class={"txt-white nucleus-font-modal-btn " + editMemberBtnStyle}
											onClick={handleSubmitEditMember}
										>
											Save
										</a>
										<div>&nbsp;</div>
									</div>
								</div>
							</div>
							<br />
							<br />
						</div>

						<div class={" " + memberModeDelete}>
							<div class="row">
								<div class="col s12">
									<span class="nucleus-labels">
										{" "}Are you sure you want to delete this family member ?{" "}
									</span>
								</div>
							</div>

							<div class="input-field col s12 modalCallLogContent2">
								<br />
								<div class="row no-margin">
									<div class="col s12 center-align no-margin">
										<p></p>
										<span class=" modalSeparator">
											{" "}<label> &nbsp;</label>
										</span>
										<a
											class={"txt-white nucleus-font-modal-btn " + deleteMemberBtnStyle}
											onClick={handleSubmitDeleteMember}
										>
											Delete
										</a>
										<div>&nbsp;</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
                <LogsDetailModal handleCloseModal={handleCloseModal} callDetailsColumn1={callDetailsColumn1} callLogStartTime={callLogStartTime} callLogStatus={callLogStatus} callLogDuration={callLogDuration} callDetailsColumn2={callDetailsColumn2} callLogRequest={callLogRequest} callLogDetailsParticipants={callLogDetailsParticipants} callLogNotes={callLogNotes} textCallLogNotes={textCallLogNotes} callLogModalTxtStatecallLogModalTxtState handleNotes={handleNotes} callLogModalBtnState={callLogModalBtnState} handleSaveNotes={handleSaveNotes} />
        </React.Fragment>
    )
};

export default AccountUserPatientProfileActivityAndConfiguration;
