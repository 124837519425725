import React from 'react';
import ReactDOM from "react-dom";
import $ from "jquery";
import AuthStore from "../stores/AuthStore";
import CareAccountStore from "../stores/CareAccountStore";
import GeneralStore from "../stores/GeneralStore";

import { IndexLink, Link } from "react-router-dom";
import classNames from 'classnames';
import Message from "../utils/Message";

import IntlTelInput from 'react-intl-tel-input';
//import '../../../node_modules/react-intl-tel-input/dist/libphonenumber.js';
import 'react-intl-tel-input/dist/main.css';
import {isEmailValid} from "../utils/validators";

class NewAccountUser extends React.Component {

    constructor() {
        super();

        this.state = {
            accountID : '',
            panel : "Form",
            newUserEmail : '',
            btnStyle : " disabled",
            errorEmailVisibility : 'hide',
            errorTelephoneVisibility : 'hide',
            errorEmailText : 'Invalid Email',
            errorTelephoneText : 'Telephone already registered',

            userTelephoneValue : '',
            userTelephoneNumber : ''
        };

        this.validateTxtInputs = this.validateTxtInputs.bind(this);
        this.validateTxtTelephone = this.validateTxtTelephone.bind(this);

        this.handleCreateAccountUser = this.handleCreateAccountUser.bind(this);
        this.onAccountUserCreatedAction = this.onAccountUserCreatedAction.bind(this);
        this.handleUserCreated = this.handleUserCreated.bind(this);

        this.txtNewUserTelephone = '';

        this.TwoFactorEnabled = CareAccountStore.get2FactorAuthEnabled();
        console.log("this.TwoFactorEnabled", this.TwoFactorEnabled);
    }


    componentDidMount() {
        CareAccountStore.on("onAccountUserCreated", this.onAccountUserCreatedAction);
        window.$('.tooltipped').tooltip({delay: 50});
        const {match} = this.props;
        if (match.params.accountId != null){
            this.setState({
                accountID : match.params.accountId
            });
        }
        CareAccountStore.getAllAccountsData({
            UserID: AuthStore.getCsUserID(),
            Token: AuthStore.getCsUserToken(),
        });
    }

    componentWillUnmount() {
        CareAccountStore.removeListener("onAccountUserCreated", this.onAccountUserCreatedAction);
        window.$('.tooltipped').tooltip('remove');
    }


    validateTxtInputs(e){

        let phoneLength = this.state.userTelephoneValue.length;
        console.log("Log 1", phoneLength);
        if (!this.TwoFactorEnabled && (phoneLength < 1)) {
            phoneLength = 1;
            console.log("Log 2");
        }

        if (this.refs.txtNewUserFirstName.value.length > 0
         && this.refs.txtNewUserLastName.value.length > 0
         && this.refs.txtNewUserEmail.value.length > 0
            && phoneLength > 0 ) {

            this.setState({
                btnStyle : ""
            });
        } else {

            this.setState({
                btnStyle : "disabled"
            });
        }

        if (this.refs.txtNewUserEmail.value.length > 0){
            this.setState({
                errorEmailVisibility : 'hide'
            });
        }

        if (this.txtNewUserTelephone.length > 0){
            this.setState({
                errorTelephoneVisibility : 'hide'
            });
        }
    }

    validateTxtTelephone(status, value, countryData, number, id){
        //console.log("validateTxtTelephone", status, value, countryData, number, id);
        //console.log("validateTxtTelephone status ", status);
        console.log("validateTxtTelephone VALUE ", value);
        console.log("validateTxtTelephone NUMBER ", number);

        if (number){
            this.txtNewUserTelephone = number;
            console.log("txtNewUserTelephone", this.txtNewUserTelephone);
            if (value.length > 0){
                this.setState({
                    btnStyle : "",
                    errorTelephoneVisibility : 'hide'
                });
            }
            this.setState({
                userTelephoneValue : value,
                userTelephoneNumber : number
            });
        }
        else {
            this.txtNewUserTelephone = "+" + value.dialCode + this.state.userTelephoneValue ;
        }

    };

    handleCreateAccountUser(){

        if(!isEmailValid({email:this.refs.txtNewUserEmail.value})){
            //Message.show('Ups! The email is not valid.');

            this.setState({
                errorEmailVisibility : '',
                errorTelephoneVisibility : 'hide',
                errorEmailText : 'Invalid Email'
            });
            return;
        }
        console.log("SENDING VALUE", this.txtNewUserTelephone);
        console.log("SENDING STATE", this.state.userTelephoneValue);

        if (this.txtNewUserTelephone.length > 0 ){

            this.txtNewUserTelephone = this.txtNewUserTelephone.replace(/\(/g, "");
            this.txtNewUserTelephone = this.txtNewUserTelephone.replace(/\)/g, "");
            this.txtNewUserTelephone = this.txtNewUserTelephone.replace(/-/g, "");
            this.txtNewUserTelephone = this.txtNewUserTelephone.replace(/ /g, "");

            let number=this.state.userTelephoneValue;
            number = number.replace(/\(/g, "");
            number = number.replace(/\)/g, "");
            number = number.replace(/-/g, "");
            number = number.replace(/ /g, "");

            console.log("Le nombre est:", number, number.length);
            if (number.length == 0){
                this.txtNewUserTelephone = "";
            } 
            else{
                if (number.length > 0 && number.length < 10){
                    this.setState({
                        errorEmailVisibility : 'hide',
                        errorTelephoneVisibility : '',
                        errorTelephoneText : 'Telephone must be at least 10 digits'
                    });
                    return;
                }
                let containsLetter = /[a-zA-Z]/.test(number);
                if (containsLetter){
                    this.setState({
                        errorEmailVisibility : 'hide',
                        errorTelephoneVisibility : '',
                        errorTelephoneText : 'Invalid Telephone.'
                    });
                    return;
                } 
                console.log("Le nombre finale est:", this.txtNewUserTelephone, this.txtNewUserTelephone.length);
                const phoneNumberRegex = /^(?:\+\d{1,3})?\d{10,10}$/;   
                if (!phoneNumberRegex.test(this.txtNewUserTelephone)) {
                    this.setState({
                        errorEmailVisibility : 'hide',
                        errorTelephoneVisibility : '',
                        errorTelephoneText : 'Invalid Telephone.'
                    });
                    return;
                }   
            }
        }

        const newAccountUserData = {
            AccountID : this.state.accountID,
            FirstName : this.refs.txtNewUserFirstName.value,
            LastName : this.refs.txtNewUserLastName.value,
            Email : this.refs.txtNewUserEmail.value,
            Telephone : this.txtNewUserTelephone + '',
            Address : this.refs.txtNewUserAddress.value + '',
            IsAdmin : this.refs.checkIsAdmin.checked,
            //ReceiveEscalationAlerts : false, // this.refs.checkReceiveEscalation.checked,
            CanSeeStaffTab : this.refs.checkStaffAccess.checked,
            CanSeeClientsTab : this.refs.checkClientAccess.checked,
            CanSeeQueueTab : this.refs.checkQueueAccess.checked,
            CanSeeMessageCenterTab : this.refs.checkMessagesAccess.checked,
            CanSeeAlertsTab : this.refs.checkAlertsAccess.checked,
            CanSeeMemoryBox : this.refs.checkMemoryBoxAccess.checked,
            CanSeeBulletinBoard :  this.refs.checkBulletinAccess.checked,
            CanAccessGroups :  this.refs.checkGroupsAccess.checked
            //CanSendMessageToAllPatients : this.refs.checkMessageAllPatients.checked
        }

        this.setState({
            btnStyle : "disabled"
        });
        //Get the User Data from WS
        CareAccountStore.createNewUser(newAccountUserData);
    }

    onAccountUserCreatedAction(response){
        console.log("onAccountUserCreatedAction", response);
        if (response.ok){
            this.setState({
                panel : "Message",
                newUserEmail : this.refs.txtNewUserEmail.value
            });

            GeneralStore.requestHandleBack();
            // window.location.assign("#/users" );
            setTimeout(function(){
                Message.show("User created");
            }, 100);

        } else if (response.emailRegistered) {
            this.setState({
                errorEmailVisibility : '',
                errorTelephoneVisibility : 'hide',
                errorEmailText : response.error
            });
        } else if (response.telephoneRegistered) {
            this.setState({
                errorEmailVisibility : 'hide',
                errorTelephoneVisibility : '',
                errorTelephoneText : response.error
            });
        } else {
            if (response.error){
                Message.show(response.error);
            }
            else {
                Message.show("Error creating new User");
            }

        }

        this.setState({
            btnStyle : ""
        });
    }

    handleUserCreated(){
        window.location.assign("#/accountDetails/" + this.state.accountID );
    }


    render() {

        let style = {
            height:window.innerHeight
        };

        let formVisibility = classNames({
            hide : (this.state.panel != "Form")
        });
        let messageVisibility = classNames({
            hide : (this.state.panel != "Message")
        });

        return (
            <div class="nucleus-hard-container">
            <div class="nucleus-all-scrollable-page">
                <p class="nucleus-page-title">New User</p>
                <br/>
                <div class={ "nucleus-user-form-pending nucleus-form-container " + formVisibility }>
                    <br/>
                    <div class="row">
                        <div class="col s2 m1 l1">

                        </div>
                        <div class="col s8 m8 l6">
                            <div class="row">
                                <div class="col s5 valgin-wrapper left-align">
                                    <span class="nucleus-labels">First Name</span> <span class="nucleus-labels txt-red">*</span>
                                </div>
                                <div class="col s7">
                                    <input 
                                        ref="txtNewUserFirstName" 
                                        type="text" 
                                        class="validate nucleus-input-form" 
                                        placeholder="First Name" 
                                        onChange={this.validateTxtInputs}
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s5 valgin-wrapper left-align">
                                    <span class="nucleus-labels">Last Name</span> <span class="nucleus-labels txt-red">*</span>
                                </div>
                                <div class="col s7">
                                    <input 
                                        ref="txtNewUserLastName" 
                                        type="text" 
                                        class="validate nucleus-input-form" 
                                        placeholder="Last Name" 
                                        onChange={this.validateTxtInputs} 
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s5 left-align">
                                    <span class="nucleus-labels"> Email</span> <span class="nucleus-labels txt-red">*</span>
                                </div>
                                <div class="col s7">
                                    <input 
                                        ref="txtNewUserEmail"  
                                        type="email" 
                                        class="validate nucleus-input-form" 
                                        placeholder="Email" 
                                        onChange={this.validateTxtInputs}
                                    />
                                    <span class={ "nucleus-form-error center-align " + this.state.errorEmailVisibility } > 
                                        {this.state.errorEmailText}
                                    </span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col s5 left-align">
                                    <span class=" nucleus-labels"> Telephone</span>
                                        {
                                            (this.TwoFactorEnabled) &&
                                            <span class="nucleus-labels txt-red">&nbsp;*</span>
                                        }
                                </div>
                                <div class="col s7">
                                    {/*<input  ref="txtNewUserTelephone" id="txtPhone" type="text" class="validate nucleus-input-form" placeholder="(000)000-0000" onKeyUp={this.validateTxtInputs } onChange={this.validateTxtInputs}/>*/}
                                    <IntlTelInput
                                        css={['intl-tel-input validate width100', 'nucleus-input-form width83']}
                                        utilsScript={'libphonenumber.js'}
                                        autoHideDialCode={true}
                                        separateDialCode={true}
                                        nationalMode={true}
                                        format={false}
                                        preferredCountries={['us']}
                                        onPhoneNumberBlur={this.validateTxtInputs}
                                        onSelectFlag={this.validateTxtTelephone}
                                        onPhoneNumberChange={this.validateTxtTelephone}
                                        style={{fontSize:16, width:'70%'}}/>
                                    <br/>
                                    <span class={"nucleus-form-error left-align txt-black-forced " }>
                                        Click the country code icon for more country codes
                                    </span>
                                    <br/>
                                    <span class={"nucleus-form-error center-align " + this.state.errorTelephoneVisibility }>
                                        {this.state.errorTelephoneText}
                                    </span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col s5 left-align">
                                    <span class=" nucleus-labels"> Address</span>
                                </div>
                                <div class="col s7">
                                    <input 
                                        ref="txtNewUserAddress" 
                                        type="text" 
                                        class="validate nucleus-input-form" 
                                        placeholder="1234 Street Lane, New City, NC 00000" 
                                        onChange={this.validateTxtInputs}
                                    />
                                </div>
                            </div>

                            <div class="row ">
                                <div class="col s5">
                                    <span class=" nucleus-labels"> Is Admin</span>
                                </div>
                                <div class="col s7">
                                    <div class="switch">
                                        <label>
                                            <input 
                                                ref="checkIsAdmin" 
                                                type="checkbox" 
                                                class="filled-in" 
                                                id={"checkIsAdmin" + this.state.accountID } 
                                            />
                                            <label for={"checkIsAdmin"+ this.state.accountID }>&nbsp;</label>
                                        </label>
                                    </div>

                                </div>
                            </div>

                            {/*
                            // DEPRECATED - January 24th, 2018 - CARE-2304
                            <div class="row ">
                                <div class="col s5">
                                    <span class=" nucleus-labels"> Can Send Message to All Patients</span>
                                </div>
                                <div class="col s7">
                                   <span >
                                        <input ref="checkMessageAllPatients" type="checkbox" class="filled-in" id={"checkMsjBroadcast" + this.state.accountID }/>
                                        <label for={"checkMsjBroadcast" + this.state.accountID }>&nbsp;</label>
                                    </span>
                                </div>
                            </div>
                            */}


                            {/*
                            // DEPRECATED - January 17th, 2019 - CARE-4047
                            <div class="row  medium-space ">
                                <div class="col s5">
                                    <span class=" nucleus-labels"> Receive Escalation Alerts</span>
                                </div>
                                <div class="col s7">
                                   <span>
                                        <input ref="checkReceiveEscalation" type="checkbox" class="filled-in" id="checkReceiveEscalation"/>
                                        <label for="checkReceiveEscalation">&nbsp;</label>
                                    </span>
                                </div>
                            </div>
                            */}


                            <div class="row medium-space ">
                                <div class="col s5">
                                    <span class=" nucleus-labels">Can Access Staff Tab</span>
                                </div>
                                <div class="col s7">
                                   <span >
                                        <input 
                                            ref="checkStaffAccess" 
                                            type="checkbox" 
                                            class="filled-in" 
                                            id="checkStaffAccess" 
                                            defaultChecked={true} 
                                        />
                                        <label for="checkStaffAccess">&nbsp;</label>
                                    </span>
                                </div>
                            </div>
                            <div class="row medium-space ">
                                <div class="col s5">
                                    <span class=" nucleus-labels">Can Access Clients Tab</span>
                                </div>
                                <div class="col s7">
                                   <span >
                                        <input 
                                            ref="checkClientAccess" 
                                            type="checkbox" 
                                            class="filled-in" 
                                            id="checkClientAccess" 
                                            defaultChecked={true} 
                                        />
                                        <label for="checkClientAccess">&nbsp;</label>
                                    </span>
                                </div>
                            </div>
                            <div class="row medium-space ">
                                <div class="col s5">
                                    <span class=" nucleus-labels">Can Access Queue Tab</span>
                                </div>
                                <div class="col s7">
                                   <span >
                                        <input 
                                            ref="checkQueueAccess" 
                                            type="checkbox" 
                                            class="filled-in" 
                                            id="checkQueueAccess" 
                                            defaultChecked={true} 
                                        />
                                        <label for="checkQueueAccess">&nbsp;</label>
                                    </span>
                                </div>
                            </div>
                            <div class="row medium-space ">
                                <div class="col s5">
                                    <span class=" nucleus-labels"> Can Access Message Center Tab</span>
                                </div>
                                <div class="col s7">
                                   <span>
                                        <input 
                                            ref="checkMessagesAccess" 
                                            type="checkbox" 
                                            class="filled-in" 
                                            id="checkMessagesAccess" 
                                            defaultChecked={true} 
                                        />
                                        <label for="checkMessagesAccess">&nbsp;</label>
                                    </span>
                                </div>
                            </div>
                            <div class="row medium-space ">
                                <div class="col s5">
                                    <span class=" nucleus-labels"> Can Access Alerts Tab</span>
                                </div>
                                <div class="col s7">
                                   <span>
                                        <input 
                                            ref="checkAlertsAccess" 
                                            type="checkbox" 
                                            class="filled-in" 
                                            id="checkAlertsAccess" 
                                            defaultChecked={true} 
                                        />
                                        <label for="checkAlertsAccess">&nbsp;</label>
                                    </span>
                                </div>
                            </div>

                            <div class="row medium-space ">
                                <div class="col s5">
                                    <span class=" nucleus-labels"> Can Access Memory Box Tab</span>
                                </div>
                                <div class="col s7">
                                   <span>
                                        <input 
                                            ref="checkMemoryBoxAccess" 
                                            type="checkbox" 
                                            class="filled-in" 
                                            id="checkMemoryBoxAccess" 
                                            defaultChecked={true}
                                        />
                                        <label for="checkMemoryBoxAccess">&nbsp;</label>
                                    </span>
                                </div>
                            </div>

                            <div class="row medium-space ">
                                <div class="col s5">
                                    <span class=" nucleus-labels"> Can Access Bulletin Board Tab</span>
                                </div>
                                <div class="col s7">
                                   <span>
                                        <input 
                                            ref="checkBulletinAccess" 
                                            type="checkbox" 
                                            class="filled-in" 
                                            id="checkBulletinAccess" 
                                            defaultChecked={true}
                                        />
                                        <label for="checkBulletinAccess">&nbsp;</label>
                                    </span>
                                </div>
                            </div>

                            <div class="row medium-space ">
                                <div class="col s5">
                                    <span class=" nucleus-labels"> Can Access Groups Tab</span>
                                </div>
                                <div class="col s7">
                                   <span>
                                        <input 
                                            ref="checkGroupsAccess" 
                                            type="checkbox" 
                                            class="filled-in" 
                                            id="checkGroupsAccess" 
                                        />
                                        <label for="checkGroupsAccess">&nbsp;</label>
                                    </span>
                                </div>
                            </div>


                        </div>
                        <div class="col s2 m3 l5">
                        </div>
                    </div>

                    <div class="fixed-action-btn btn-add-new">
                        <a class="btn-floating btn-large waves-effect waves-light light-blue darken-4 tooltipped " 
                            disabled={this.state.btnStyle} 
                            data-position="left" 
                            data-delay="50" 
                            data-tooltip="Save new User" 
                            onClick={this.handleCreateAccountUser}
                        >
                          <i class="material-icons">save</i>
                        </a>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                </div>

                <div id="nucleus-new-user-confirmation-pending" class={ "nucleus-new-user-form-pending " + messageVisibility }>
                    <div class="row">
                        <div class="col s12">
                            <p class=" nucleus-labels"> Complete! An account set up email has been sent to { this.state.newUserEmail }</p>
                            <a class="nucleus-link nucleus-font-medium" onClick={this.handleUserCreated}>Go to Account Details </a>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        );
    }
}

export default NewAccountUser;
