import React from 'react';
import AuthStore from "../stores/AuthStore";
import CareQueueStore from "../stores/CareQueueStore";
import GeneralStore from "../stores/GeneralStore";
import { connect } from 'react-redux'
import CarePatientStore from "../stores/CarePatientStore";
////import classNames from 'classnames';
import { Link } from "react-router-dom";
import createHashHistory from 'history/lib/createHashHistory';
import { 
  AccountsSVG, 
  CSSettingsSVG, 
  FamilySVG, 
  ProvidersSVG,
  CsUsersSVG, 
  CsConsumerSVG, 
  NucleusLogoSVG,
  ReportsSVG, 
  DevicesSVG, 
  AppsSVG, 
  DeviceMDMPoliciesSVG,
  LogoutSVG
} from './SideBarSVGs/SidebarSVGs';
import { setPolicyScreen } from '@nucleus-care/nucleuscare-backend-client';

class SideBar extends React.Component {

    constructor() {
        super();
        this.state = {
          accountsMenuClass : " ",
          familyMenuClass : " ",
          reportsMenuClass : " ",
          toolsMenuClass : " ",
          userMenuClass : " ",
          providersMenuClass : " ",
          policiesMenuClass: " ",
          consumerMenuClass : " ",
          devicesMenuClass :  " ",
          queueMenuClass : " ",
          appsMenuClass: " ",
          settingsMenuClass : " ",
          queueNumber : null,
          queueNumberVisibility : " hide",

          lastCallRequestID : '',
          lastCallRequestPatientID : '',
          callRequests : CareQueueStore.getQueueNumber()
        }
        this.handleLogout = this.handleLogout.bind(this);
        this.selectAccountsMenu = this.selectAccountsMenu.bind(this);
        this.selectFamilyMenu = this.selectFamilyMenu.bind(this);
        this.selectToolsMenu = this.selectToolsMenu.bind(this);
        this.selectUserMenu = this.selectUserMenu.bind(this);
        this.selectConsumerMenu = this.selectConsumerMenu.bind(this);
        this.selectDevicesMenu = this.selectDevicesMenu.bind(this);
        //this.selectQueueMenu = this.selectQueueMenu.bind(this);
        this.selectAppsMenu = this.selectAppsMenu.bind(this);
        this.selectSettingsMenu = this.selectSettingsMenu.bind(this);
        this.onWindowResize = this.onWindowResize.bind(this);
        this.resetMenus = this.resetMenus.bind(this);
        this.onGetQueueNumberAction = this.onGetQueueNumberAction.bind(this);
        this.onGetCareCallRequestsQueueAction = this.onGetCareCallRequestsQueueAction.bind(this);
        this.showNotifications = this.showNotifications.bind(this);

        this.startPollingQueue = this.startPollingQueue.bind(this);

        this.createNotification = this.createNotification.bind(this);
        this.stopNotifications = this.stopNotifications.bind(this);

        this.onStartPollingQueueAction = this.onStartPollingQueueAction.bind(this);
        this.onQueueItemClickedAction = this.onQueueItemClickedAction.bind(this);

        this.onHandledRequestRemovedFromStoreAction = this.onHandledRequestRemovedFromStoreAction.bind(this);

        this.hashHistory = createHashHistory({
          basename: '',             // The base URL of the app (see below)
          hashType: 'slash',        // The hash type to use (see below)
          // A function to use to confirm navigation with the user (see below)
          getUserConfirmation: (message, callback) => callback(window.confirm(message))
        });

        this.callRequests = CareQueueStore.getQueueNumber();
        this.TestFunction = this.TestFunction.bind(this);

        this.isOnProd = this.isOnProd.bind(this);
        //this.openCampaigns = this.openCampaigns.bind(this); DEPRECATED 6/16/2023 REMOVE CAMPAIGN BUTTON
    }

    componentDidMount()
    {
        //Register Listener
        CareQueueStore.on("onGetQueueNumber", this.onGetQueueNumberAction);
        CareQueueStore.on("onGetCareCallRequestsQueue", this.onGetCareCallRequestsQueueAction);
        CareQueueStore.on("onStartPollingQueue", this.onStartPollingQueueAction);
        CareQueueStore.on("onQueueItemClicked", this.onQueueItemClickedAction);
        CareQueueStore.on("onHandledRequestRemovedFromStore", this.onHandledRequestRemovedFromStoreAction);

        window.addEventListener('resize', this.onWindowResize);
        this.onWindowResize();

        this.hashHistory.listen((location, action) => {
          ////console.log(`The current URL is ${location.pathname}${location.search}${location.hash}`);
          this.handleMenusVisibility(location.pathname);
        });


        if (this.state.queueMenuClass === " "){
            this.isPolling = true;
            // CareQueueStore.setPollingQueueNumber(true);
            // CareQueueStore.getCareQueueNumber({
            //     UserID : AuthStore.getUserID()
            // });
        }
        else {
            //CareQueueStore.setPollingQueueNumber(false);
        }

        // this.notificationSound = new Audio('https://care.nucleuslife.io/callSound/ring.mp3');
        // this.ringingStatus = false;

    }

    componentWillUnmount()
    {
      //Remove Listener
        CareQueueStore.removeListener("onGetQueueNumber", this.onGetQueueNumberAction);
        CareQueueStore.removeListener("onGetCareCallRequestsQueue", this.onGetCareCallRequestsQueueAction);
        CareQueueStore.removeListener("onStartPollingQueue", this.onStartPollingQueueAction);
        CareQueueStore.removeListener("onQueueItemClicked", this.onQueueItemClickedAction);
        //this.hashHistory.unlisten();
        CareQueueStore.removeListener("onHandledRequestRemovedFromStore", this.onHandledRequestRemovedFromStoreAction);
    }

    onStartPollingQueueAction(){

      this.startPollingQueue();
    }

    startPollingQueue(){
      // console.log("startPollingQueue " + !this.isPolling);
      // console.log("QUEUES NOW!!!! : ", CareQueueStore.getQueueNumber());
      // if (!this.isPolling){
      //   this.isPolling = true;
      //   CareQueueStore.setPollingQueueNumber(true);
      //   CareQueueStore.getCareQueueNumber({
      //       UserID : AuthStore.getUserID()
      //   });
      //   // Listen to "onGetQueueNumber"
      // }

      GeneralStore.setStaffTab(1);

    }

    onGetQueueNumberAction(response){

      let newQueues = response.number - CareQueueStore.getQueueNumber();
      this.isPolling = false;

      console.log(">>>>#>: ", newQueues);
      //console.log(response);
      ////console.log(this.isPolling);

      if (this.state.queueMenuClass === " ") {

        if (newQueues > 0){
          this.setState({
            queueNumber : newQueues,
            queueNumberVisibility : " "
          });

          this.createNotification();
        }

        if (!this.isPolling){
          this.isPolling = true;
          setTimeout(function(){
              CareQueueStore.getCareQueueNumber({
                  UserID : AuthStore.getUserID()
              });
              //Listen to "onGetQueueNumber"
          }, 10000);
        }
      }

    }

    onGetCareCallRequestsQueueAction(success){
      console.log("onGetCareCallRequestsQueueAction");

      if (this.state.queueMenuClass === " menu-active") {

        if (this.callRequests < CareQueueStore.getQueueNumber()){
          console.log("There was a pending request on Queue");
          this.callRequests = CareQueueStore.getQueueNumber();
          this.createNotification();
        }

        setTimeout(function(){
            ////Tconsole.log("Pedimos");
            CareQueueStore.getCareCallRequestsQueueForUser({
              UserID : AuthStore.getUserID()
            });
            //Listen to "onGetCareCallRequestsQueue"
        }, 10000);
      }
    }

    onHandledRequestRemovedFromStoreAction(){
        console.log("Refrescando");
        this.callRequests = CareQueueStore.getQueueNumber();
    }

    onQueueItemClickedAction(){
      this.stopNotifications();
    }


    onWindowResize()
    {
      /*this.setState({
        menuStyle : {
            height: window.innerHeight
          }
      });
      */

    }

    selectAccountsMenu(){
      this.setState({
        accountsMenuClass : " menu-active",
      });
    }

    selectFamilyMenu(){
      this.setState({
        familyMenuClass : " menu-active",
      });
    }

    selectReportsMenu(){
      this.setState({
        reportsMenuClass : " menu-active",
      });
    }

    selectToolsMenu(){
      this.setState({
        toolsMenuClass : " menu-active",
      });

    }

    selectProvidersMenu = () => {
      this.setState({
        providersMenuClass : " menu-active",
      });

    }

    selectUserMenu(){
      this.setState({
        userMenuClass : " menu-active",
      });

    }

    selectPoliciesMenu = () => {
      this.setState({
        policiesMenuClass: " menu-active"
      })
    }

    selectConsumerMenu(){
      this.setState({
        consumerMenuClass : " menu-active",
      });
    }

    selectDevicesMenu(){
      this.setState({
        devicesMenuClass : " menu-active"
      });
    }

    selectAppsMenu(){
      this.setState({
        appsMenuClass :  " menu-active"
      });
    }

    selectSettingsMenu(){
      this.setState({
        settingsMenuClass : " menu-active"
      });
    }

    resetMenus(){
      this.setState({
        accountsMenuClass : " ",
        familyMenuClass : " ",
        reportsMenuClass : " ",
        toolsMenuClass : " ",
        providersMenuClass: " ",
        userMenuClass : " ",
        policiesMenuClass: " ",
        consumerMenuClass : " ",
        devicesMenuClass : " ",
        queueMenuClass : " ",
        appsMenuClass: " ",
        settingsMenuClass : " "
      });
    }

    handleMenusVisibility(currentPath){
      console.log("PAGE : ", currentPath);

      if (currentPath.includes("/accounts") && !(currentPath.includes("/providerDetails") && currentPath.includes("/accounts"))  || currentPath.includes("/accountDetails") || currentPath.includes("/accountUserProfile") || currentPath.includes("/newAccountUser") ) {
        this.resetMenus();
        this.selectAccountsMenu();
      }
      else if (currentPath.includes("/reports")) {
        this.resetMenus();
        this.selectReportsMenu();
      }
      else if (currentPath.includes("/family")) {
        this.resetMenus();
        this.selectFamilyMenu();
      }
      else if (currentPath.includes("/providers") || (currentPath.includes("/providerDetails") && currentPath.includes("/accounts")) || currentPath.includes("/providerDetails") || currentPath.includes("/providerDetails/accounts")) {
        this.resetMenus();
        this.selectProvidersMenu();
      }
      else if (currentPath.includes("/csUsers")) {
        this.resetMenus();
        this.selectUserMenu();
      }
      else if (currentPath.includes("/policies") || currentPath.includes("/policyDetails") || currentPath.includes("/newPolicy")) {
        this.resetMenus();
        this.selectPoliciesMenu();
      }
      else if (currentPath.includes("/consumer")) {
        this.resetMenus();
        this.selectConsumerMenu();
      }
      else if (currentPath.includes("/devices")) {
        this.resetMenus();
        this.selectDevicesMenu();
      }
      else if (currentPath.includes("/externalApps")) {
        this.resetMenus();
        this.selectAppsMenu();
      }
      else if (currentPath.includes("/tools")) {
        this.resetMenus();
        this.selectToolsMenu();
      }
      else if (currentPath.includes("/settings")) {
        this.resetMenus();
        this.selectSettingsMenu();
      }
      else {
          this.resetMenus();
      }

        // if(currentPath.includes("/users") {
        //   this.selectUserMenu();
        // } else if(currentPath.includes("/patients")  || currentPath.includes("/newFamilyMember")){
        //   this.selectPatientMenu();
        // } else if ( currentPath.includes("/caregiverProfile")) {
        //   //this.selectuserMenu();
        //   this.selectUserMenu();
        // }else if (currentPath.includes("/settings")) {
        //   this.selectSettingsMenu();
        // } else if (currentPath.includes("/queue")) {
        //   this.selectQueueMenu();
        // } else {
        //   this.resetMenus();
        // }
    }

    handleLogout(){
      const { dispatch } = this.props
      ////console.log("handleLogout");
      AuthStore.logout(dispatch);
    }

    createNotification(){

      var lastCallRequest = CareQueueStore.getLastRequest();
      console.log("NEW REQUEST: ", lastCallRequest);

      if (AuthStore.getOnDuty().toString() === "true"){

        //(lastCallRequest.PatientID != this.state.lastCallRequestPatientID ) &&
        if ( (lastCallRequest.RequestID !== this.state.lastCallRequestID) ){
            this.setState({
              lastCallRequestID : lastCallRequest.RequestID,
              lastCallRequestPatientID : lastCallRequest.PatientID
            });
            var patient = CarePatientStore.getPatientInfo(lastCallRequest.PatientID);
            if (patient){
                console.log("EMERGECNY PATIENT: ", patient);
                this.showNotifications(patient.Name, lastCallRequest.DeviceName, lastCallRequest.PatientID);
            }
        }
      }
    }

    showNotifications(patientName, deviceName, patientID){
      if (Notification.permission !== "granted") {
          Notification.requestPermission();
      }
      else {
          // var notification = new Notification('Nucleus care - Call request', {
          //     body: "Patient " + patientName + " - " + deviceName + " requests a call.",
          // });
          // notification.onclick = () =>{
          //   console.log("Notification Click");
          //   window.location.assign("#/patientProfile/" + patientID + "/null");
          //   this.stopNotifications();
          // };
          // notification.onclose = () =>{
          //   console.log("Notification Close");
          //   this.stopNotifications();
          // };
          // if (!this.ringingStatus){
          //   this.notificationSound.loop = true;
          //   this.notificationSound.play();
          //   this.ringingStatus = true;
          // }
      }
    }

    stopNotifications(){
      console.log("PAUSE");

        if (this.ringingStatus){
          if (this.notificationSound === undefined){
            console.log("Notification Click UNDEFINED");
            this.notificationSound = new Audio('https://care.nucleuslife.io/callSound/ring.mp3');

          }
          this.notificationSound.pause();
          this.notificationSound.currentTime = 0;
          this.ringingStatus = false;
        }
    }

    TestFunction(){
      //alert();
    }

    isOnProd(){
      console.log("isOnProd", window.location.hostname);
      const url = window.location.hostname;
      if (url.includes('localhost') || url.includes('dev') || url.includes('test')){
        return false;
      }
      return true;
    } 
    
    // DEPRECATED 6/16/2023 REMOVE CAMPAIGN BUTTON
    // openCampaigns = ()=>{
    //   console.log("open openCampaigns");
    //   let url = "";
    //   console.log("Url", window.location.hostname);

    //   switch(window.location.hostname){
    //     case "localhost":
    //     case "care-dev.nucleuslife.io":
    //     case "care-test.nucleuslife.io":
    //       url = "https://campaign-test.nucleuslife.io/";
    //       break;
    //     default:
    //       url = "https://campaign.nucleuslife.io/";
    //       break;
    //   }

    //   window.open(url, "_blank");
    // }

    render() {

      return (
        <div class="blue col s3 m2 l1 xl1 no-margin nucleus-sidebar">

          <div class="row txt-white menu-content">

            <Link to="/dashboard" class="txt-white">
              <div class="sidebar-logo center-align" onClick={this.startPollingQueue}>
                <br/>
                <NucleusLogoSVG />

              </div>
            </Link>

            <Link to="/accounts" class="txt-white">
              <div class={ "center-align row nucleus-menu" + this.state.accountsMenuClass } >
                <div class="col m12" >
                  {/* <img class="responsive-img" src="img/nucleus_care_user.png"/> */}
                  <AccountsSVG active={this.state.accountsMenuClass.length > 1}/>
                </div>
                <div class="col m12 sideBarFont" >
                  Accounts
                </div>
              </div>
            </Link>

            {
              ( AuthStore.superUser() &&
                <Link to="/reports" class="txt-white">
                  <div class={ "center-align row nucleus-menu" + this.state.reportsMenuClass } >
                    <div class="col m12" >
                      {/* <img class="responsive-img" src="img/nucleus_care_reports.png"/> */}
                      <ReportsSVG active={this.state.reportsMenuClass.length > 1}/>
                    </div>
                    <div class="col m12 sideBarFont" >
                      Reports
                    </div>
                  </div>
                </Link>
              )
            }

            {
              ( AuthStore.superUser() &&
                <Link to="/family" class="txt-white">
                  <div class={ "center-align row nucleus-menu" + this.state.familyMenuClass } >
                    <div class="col m12" >
                      {/* <img class="responsive-img" src="img/nucleus_care_user.png"/> */}
                      <FamilySVG active={this.state.familyMenuClass.length > 1}/>
                    </div>
                    <div class="col m12 sideBarFont" >
                      Family
                    </div>
                  </div>
                </Link>
              )
            }

            {
              (AuthStore.superUser() &&
                <Link to="/providers" class="txt-white">
                  <div class={ "center-align row nucleus-menu" + this.state.providersMenuClass } >
                    <div class="col m12" >
                      <ProvidersSVG active={this.state.providersMenuClass.length > 1}/>
                    </div>
                    <div class="col m12 sideBarFont" >
                      Providers
                    </div>
                  </div>
                </Link>
              )
            }

            {
              (AuthStore.superUser() &&
                <Link to="/csUsers" class="txt-white">
                  <div class={ "center-align row nucleus-menu" + this.state.userMenuClass } >
                    <div class="col m12" >
                      <CsUsersSVG active={this.state.userMenuClass.length > 1}/>
                    </div>
                    <div class="col m12 sideBarFont" >
                      Admin<br/>
                      Users
                    </div>
                  </div>
                </Link>
              )
            }

            {
              ((AuthStore.superUser())&&
                <Link to="/policies" class="txt-white">
                  <div class={ "center-align row nucleus-menu" + this.state.policiesMenuClass } >
                    <div class="col m12" >
                      <DeviceMDMPoliciesSVG active={this.state.policiesMenuClass.length > 1}/>
                    </div>
                    <div class="col m12 sideBarFont" >
                      MDM
                    </div>
                  </div>
                </Link>
              )
            }

            {
              (
                AuthStore.superUser() &&
                <Link to="/consumers" class="txt-white">
                  <div class={ "center-align row nucleus-menu" + this.state.consumerMenuClass } >
                    <div class="col m12" >
                      <CsConsumerSVG active={this.state.consumerMenuClass.length > 1}/>
                    </div>
                    <div class="col m12 sideBarFont" >
                      Consumer Orders
                    </div>
                  </div>
                </Link>
              )
            }

            <Link to="/devices" class="txt-white">
              <div class={ "center-align row nucleus-menu" + this.state.devicesMenuClass } >
                <div class="col m12" >
                  <DevicesSVG active={this.state.devicesMenuClass.length > 1}/>
                </div>
                <div class="col m12 sideBarFont" >
                  Devices
                </div>
              </div>
            </Link>

            {/* DEPRECATED 6/16/2023 REMOVE CAMPAIGN BUTTON
              (
                AuthStore.superUser() &&
                <div class={ "center-align row nucleus-menu"} onClick={this.openCampaigns}>
                  <div class="col m12" >
                    <CampaignsSVG />
                  </div>
                  <div class="col m12" >
                    Campaigns
                  </div>
                </div>
              )
              */}

            {
              (
                AuthStore.superUser() &&
                <Link to="/externalApps" class="txt-white">
                  <div class={ "center-align row nucleus-menu" + this.state.appsMenuClass } >
                    <div class="col m12" >
                      <AppsSVG active={this.state.appsMenuClass.length > 1}/>
                    </div>
                    <div class="col m12 sideBarFont" >
                      Apps
                    </div>
                  </div>
                </Link>
              )
            }

            {/* DEPRECATED 6/16/2023 REMOVE TOOLS BUTTON
              (
                AuthStore.superUser() &&
                <Link to="/tools" class="txt-white">
                  <div class={ "center-align row nucleus-menu" + this.state.toolsMenuClass } >
                    <div class="col m12" >
                      {/ <img class="responsive-img" src="img/nucleus_care_tools.png"/> /}
                      <ToolsSVG active={this.state.toolsMenuClass.length > 1}/>
                    </div>
                    <div class="col m12" >
                      Tools
                    </div>
                  </div>
                </Link>
              )
              */}


            { /*
            <Link to="/patients" class="txt-white hide">
              <div class={ "center-align row nucleus-menu" + this.state.patientMenuClass } onClick={this.startPollingQueue}>
                <div class="col m12" >
                  <img class="responsive-img" src="img/nucleus_care_patients.png"/>
                </div>
                <div class="col m12" >
                  Clients
                </div>
              </div>
            </Link>

            <Link to="/queue" class="txt-white hide">
              <div class={ "center-align row nucleus-menu  " + this.state.queueMenuClass } onClick={this.selectQueueMenu}>
                <div class="row no-margin">
                  <div class="col m12 nucleus-active-menu" >
                    <img class="responsive-img" src="img/nucleus_care_queue.png"/>
                    <span class={ "nucleus-menu-notification " + this.state.queueNumberVisibility }>&nbsp;&nbsp; {this.state.queueNumber} &nbsp;&nbsp;</span>
                  </div>
                  <div class="col m12" >
                    Queue
                  </div>
                </div>
              </div>
            </Link>
            */ }

            {
              (
                AuthStore.superUser() &&
                <Link to="/settings" class="txt-white">
                  <div class={ "center-align row nucleus-menu" + this.state.settingsMenuClass }>
                    <div class="col m12" >
                      {/* <img class="responsive-img" src="img/nucleus_care_settings.png"/> */}
                      <CSSettingsSVG active={this.state.settingsMenuClass.length > 1}/>
                    </div>
                    <div class="col m12 sideBarFont" >
                      Settings
                    </div>
                  </div>
                </Link>
              )
            }


            <div>
              <Link to="/" class="txt-white menu-logout">
                <div class="row txt-white nucleus-sidebar-bottom" onClick={this.handleLogout}>

                  <div class="center-align row nucleus-menu">
                    <div class="col m12" style={{paddingLeft:25}}>
                      <LogoutSVG active={false}/>
                    </div>
                    <div class="col m12 sideBarFont" >
                      Logout
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            {/*<span onClick={this.TestFunction}>Test</span> */}

          </div>


        </div>
      );
    }
}

function dispatchToProps (dispatch) {
  return {
    dispatch,
  }
}

export default connect(null, dispatchToProps)(SideBar);
